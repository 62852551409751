import AddNewTeen from './AddNewTeen.vue';
import { compose, withProps } from 'vue-compose';
import addTeenEnhancer from 'shared/components/AddTeenEnhancer';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';

const store = createStore()
const { getCurrentRegion } = useRegionStore(store)

export default compose(
  withProps(() => ({
    regionId: getCurrentRegion()
  })),
  addTeenEnhancer
)(AddNewTeen);
