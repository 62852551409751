import Single from './Single.vue';
import { compose, withData } from 'vue-compose';
import addToFamily from 'shared/components/AddToFamilyEnhancer';
import removeChildFromFamilyEnhancer from 'shared/components/RemoveChildFromFamilyEnhancer';
import { Props } from './types';
import { toCompactFamily } from './utils';
import { addParentToFamilyEnhancer } from 'shared/components/AddParentToFamily';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  useMarkFamilyAsPrimaryMutation,
  useSingleFamilyQuery,
  useUpdateFamilyMaritalStatusMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

const singleFamilyEnhancer = wrapComponent<Props, Pick<Props, 'family' | 'familyLoading'>>(
  (props) => {
    const { loading, result } = useSingleFamilyQuery(
      computed(() => ({ familyId: +props.familyId }))
    );

    return computed(() => ({
      family: result.value?.singleFamily ? toCompactFamily(result.value.singleFamily) : null,
      familyLoading: loading.value
    }));
  }
);

const updateFamilyMaritalStatusEnhancer = wrapComponent<
  Props,
  Pick<Props, 'updateFamilyMaritalStatus' | 'updateFamilyMaritalStatusLoading'>
>((props) => {
  const { loading, mutate } = useUpdateFamilyMaritalStatusMutation();

  return computed(() => ({
    updateFamilyMaritalStatus: (status) =>
      mutate(
        { familyId: +props.familyId, status },
        {
          optimisticResponse: {
            updateFamilyMaritalStatus: {
              ...props.family!.original,
              status
            }
          },
          update(client, { data }) {
            if (data && +props.familyId !== data.updateFamilyMaritalStatus.id) {
              props.router.history.push(`/families/${data.updateFamilyMaritalStatus.id}`);
            }
          }
        }
      ),
    updateFamilyMaritalStatusLoading: loading.value
  }));
});

const markFamilyAsPrimaryEnhancer = wrapComponent<
  Props,
  Pick<Props, 'markFamilyAsPrimary' | 'markingFamilyAsPrimaryRunning'>
>((props) => {
  const { mutate, loading } = useMarkFamilyAsPrimaryMutation();

  return computed(() => ({
    markingFamilyAsPrimaryRunning: loading.value,
    markFamilyAsPrimary(childId) {
      return mutate(
        {
          input: {
            familyId: +props.familyId,
            childId
          }
        },
        {
          optimisticResponse: {
            markFamilyAsPrimary: {
              ...props.family!.original
            }
          }
        }
      );
    }
  }));
});

export default compose(
  withData({
    addingNewChild: {
      initialValue: false
    },
    addingExistingChild: {
      initialValue: false
    },
    removingChild: {
      initialValue: false
    },
    markingFamilyAsPrimary: {
      initialValue: false
    }
  }),
  addToFamily,
  singleFamilyEnhancer,
  updateFamilyMaritalStatusEnhancer,
  removeChildFromFamilyEnhancer,
  markFamilyAsPrimaryEnhancer,
  addParentToFamilyEnhancer
)(Single);
