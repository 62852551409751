import Reports from './Reports.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  useGetRecentAndStarredQuery,
  useReportsQuery,
  useToggleStarReportMutation
} from 'shared/generated/graphql-types';

type OuterProps = Pick<Props, 'regionId' | 'staffId'>;
type ReportProps = Pick<Props, 'reports' | 'isLoading'>;

const getReportsEnhancer = wrapComponent<OuterProps, ReportProps>((props) => {
  const { loading, result } = useReportsQuery({ fetchPolicy: 'network-only' });

  return computed(() => ({
    isLoading: loading.value,
    reports: result.value?.reports || []
  }));
});

type RecentAndStarredProps = Pick<Props, 'starred' | 'recent' | 'staffId'>;
const getMetaDataEnhancer = wrapComponent<OuterProps, RecentAndStarredProps>((props) => {
  const { result } = useGetRecentAndStarredQuery(
    computed(() => ({ staffID: props.staffId!, regionId: props.regionId })),
    { fetchPolicy: 'cache-and-network' }
  );

  return computed(() => ({
    recent: result.value?.singleStaff?.recentReports || [],
    starred: result.value?.singleStaff?.starredReports || [],
    advisorRegionId: result.value?.singleStaff?.AdvisorRegionId
  }));
});

const toggleReportEnhancer = wrapComponent<
  Pick<Props, 'staffId' | 'advisorRegionId' | 'starred' | 'regionId'>,
  Pick<Props, 'toggleStar'>
>((props) => {
  const { mutate } = useToggleStarReportMutation();

  return computed(() => ({
    toggleStar: (reportId) => {
      const starred = props.starred.slice();
      const index = starred.indexOf(reportId);

      if (index === -1) {
        starred.push(reportId);
      } else {
        starred.splice(index, 1);
      }
      mutate(
        { reportID: reportId, advisorID: props.advisorRegionId! },
        {
          optimisticResponse: {
            toggleStarReport: {
              __typename: 'AdvisorRegion',
              AdvisorRegionId: props.advisorRegionId!,
              starredReports: starred
            }
          }
        }
      );
    }
  }));
});

export const enhancer = compose(getMetaDataEnhancer, getReportsEnhancer, toggleReportEnhancer);
export default enhancer(Reports);
