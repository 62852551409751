import { computed } from 'vue';
import { compose } from 'vue-compose';
import LeadStatusLookup from './LeadStatusLookup.vue';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useSetLeadStatusMutation } from 'shared/generated/graphql-types';

const setLeadStatusEnhancer = wrapComponent<Props, Pick<Props, 'setLeadStatus' | 'isLoading'>>(
  () => {
    const { loading, mutate } = useSetLeadStatusMutation();

    return computed(() => ({
      isLoading: loading.value,
      setLeadStatus: (leadId, status) => mutate({ leadId, status })
    }));
  }
);

export default compose(setLeadStatusEnhancer)(LeadStatusLookup);
