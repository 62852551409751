import { computed } from 'vue';
import { compose, withData } from 'vue-compose';
import Busing from './Busing.vue';
import { Props } from './types';
import removeFromBusEnhancer from './removeFromBusEnhancer';
import addToBusEnhancer from './addToBusEnhancer';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  useGetBusesForEventQuery,
  StatusType,
  useEditBusMutation,
  useCreateBusMutation,
  GetBusesForEventQuery,
  GetBusesForEventDocument,
  useDeleteBusMutation
} from 'shared/generated/graphql-types';

const getBusesEnhancer = wrapComponent<
  { eventId: number },
  Pick<Props, 'loadingBuses' | 'eventId' | 'buses'>
>((props) => {
  const { loading, result } = useGetBusesForEventQuery(
    computed(() => ({ eventId: props.eventId }))
  );

  return computed(() => ({
    // Filter out cached cancalled registrations
    buses: (result.value?.event.Buses || []).map((bus) => ({
      ...bus,
      ReturnFromEventRegistrations: bus.ReturnFromEventRegistrations.filter(
        (fr) => fr.status !== StatusType.Cancelled
      ),
      TravelToEventRegistrations: bus.TravelToEventRegistrations.filter(
        (tr) => tr.status !== StatusType.Cancelled
      )
    })),
    event: result.value?.event || null,
    loadingBuses: loading.value
  }));
});

const editBusEnhancer = wrapComponent<Props, Pick<Props, 'editBus'>>((props) => {
  const { mutate } = useEditBusMutation();

  return computed(() => ({
    editBus: (bus, originalBus) =>
      mutate(
        { eventId: props.event!.eventId, bus },
        {
          optimisticResponse: {
            editBus: {
              ...originalBus,
              ...bus
            }
          }
        }
      )
  }));
});

const createBusEnhancer = wrapComponent<Props, Pick<Props, 'createBus'>>((props) => {
  const { mutate } = useCreateBusMutation();

  return computed(() => ({
    createBus: (bus) =>
      mutate(
        { eventId: props.event!.eventId, bus },
        {
          optimisticResponse: {
            createBus: {
              ...bus,
              capacity: bus.capacity || null,
              cost: bus.cost || null,
              time: bus.time || null,
              busID: Math.round(Math.random() * -1000000),
              ReturnFromEventEventGuests: [],
              TravelToEventEventGuests: [],
              ReturnFromEventEventStaff: [],
              TravelToEventEventStaff: [],
              ReturnFromEventRegistrations: [],
              TravelToEventRegistrations: [],
              passengerCount: 0,
              __typename: 'Bus' as 'Bus'
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetBusesForEventQuery>({
              query: GetBusesForEventDocument,
              variables: { eventId: props.event!.eventId }
            });
            if (data && updateData?.createBus) {
              const buses = data.event.Buses.slice();
              buses.push(updateData.createBus);
              proxy.writeQuery<GetBusesForEventQuery>({
                query: GetBusesForEventDocument,
                variables: { eventId: props.event!.eventId },
                data: {
                  event: {
                    ...data.event,
                    Buses: buses
                  }
                }
              });
            }
          }
        }
      )
  }));
});

const deleteBusEnhancer = wrapComponent<Props, Pick<Props, 'deleteBus'>>((props) => {
  const { mutate } = useDeleteBusMutation();

  return computed(() => ({
    deleteBus: (busID) =>
      mutate(
        { eventId: props.event!.eventId, busID },
        {
          optimisticResponse: {
            deleteBus: null
          },
          update: (proxy) => {
            const data = proxy.readQuery<GetBusesForEventQuery>({
              query: GetBusesForEventDocument,
              variables: { eventId: props.event!.eventId }
            });

            if (data?.event) {
              const buses = data.event.Buses.slice();
              const busIndex = buses.findIndex((b) => b.busID === busID);
              buses.splice(busIndex, 1);
              proxy.writeQuery<GetBusesForEventQuery>({
                query: GetBusesForEventDocument,
                variables: { eventId: props.event!.eventId },
                data: {
                  event: {
                    ...data.event,
                    Buses: buses
                  }
                }
              });
            }
          }
        }
      )
  }));
});

export default compose(
  getBusesEnhancer,
  editBusEnhancer,
  createBusEnhancer,
  deleteBusEnhancer,
  addToBusEnhancer,
  removeFromBusEnhancer,
  withData({
    addingNewBus: {
      initialValue: false
    },
    direction: {
      initialValue: null
    },
    capacity: {
      initialValue: null
    },
    term: {
      initialValue: ''
    }
  })
)(Busing);
