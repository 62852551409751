import Goals from './Goals.vue';
import Vue, { computed, VueConstructor } from 'vue';
import { compose } from 'vue-compose';
import { getCurrentSeason, getOptions, normalizeProps } from 'shared/util';
import { Props, Filters } from './types';
import { currentUserEnhancer } from 'shared/enhancers/currentUserEnhancer';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetGoalsQuery } from 'shared/generated/graphql-types';

export const filtersInit = (regionId: number) => ({
  regionId,
  chapterId: null,
  season: getCurrentSeason()
});

const getGoalEnhancer = wrapComponent<Props, Pick<Props, 'goals' | 'goalsTotal' | 'loading'>>(
  (props) => {
    const { loading, result } = useGetGoalsQuery(
      computed(() => ({
        filter: {
          ...props.filters,
          regionId: props.regionId !== 33 ? props.regionId : null
        },
        offset: (props.currentPage - 1) * props.limit,
        limit: props.limit
      })),
      { fetchPolicy: 'network-only' }
    );

    return computed(() => ({
      loading: loading.value,
      goals: result.value?.goals.goals || [],
      goalsTotal: result.value?.goals.total || 0
    }));
  }
);

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { currentPage, limit, filters, setFilter, clearFilters, ...propsToUse } = props;

  interface Data {
    filters: Filters;
    currentPage: number;
    limit: number;
  }

  return Vue.extend<
    Data,
    Pick<Props, 'setFilter' | 'clearFilters'>,
    {},
    Pick<Props, 'setFilter' | 'clearFilters' | 'limit' | 'goals' | 'loading'>
  >({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        filters: filtersInit(this.regionId),
        currentPage: 1,
        limit: 30
      };
    },
    methods: {
      setFilter(key, value) {
        this.filters = {
          ...this.filters,
          [key]: value
        };
      },
      clearFilters() {
        this.filters = filtersInit(this.regionId);
        this.currentPage = 1;
      }
    },
    render(h) {
      return h(Component, {
        on: this.$listeners,
        props: {
          ...this.$props,
          filters: this.filters,
          currentPage: this.currentPage,
          limit: this.limit,
          setFilter: this.setFilter,
          clearFilters: this.clearFilters
        }
      });
    }
  });
};

export default compose(withFilters, getGoalEnhancer, currentUserEnhancer)(Goals);
