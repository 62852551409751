import AddParentToFamily from './AddParentToFamily.vue';
import { Props } from './types';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import { useAddParentToFamilyMutation } from 'shared/generated/graphql-types';

export const addParentToFamilyEnhancer = wrapComponent<
  Props,
  Pick<Props, 'addParentToFamily' | 'addingParentToFamily'>
>(() => {
  const { loading, mutate } = useAddParentToFamilyMutation();

  return computed(() => ({
    addParentToFamily: (input) => mutate({ input }),
    addingParentToFamily: loading.value
  }));
});

export default compose(addParentToFamilyEnhancer)(AddParentToFamily);
