import range from 'lodash/range';

function thisSchoolYear() {
  const isMay = new Date().getMonth() >= 4 && new Date().getDate() > 17;
  const year = new Date().getFullYear();
  if (isMay) {
    return `${year}-${year + 1}`;
  }
  return `${year - 1}-${year}`;
}

const seasonOptions = () => range(2019, Number(thisSchoolYear().split('-')[1]) + 1).map(i => `${i}-${i + 1}`);

export {
  thisSchoolYear,
  seasonOptions
};
