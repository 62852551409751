import Avatar from 'vue-avatar';
import { defineComponent, h } from 'vue';

const colors = ['#FFDB8E', '#8298D6', '#74C6CE'];

export default defineComponent({
  props: Avatar.props,
  setup(props) {
    const color = props.backgroundColor
      ? props.backgroundColor
      : colors[Math.floor(Math.random() * colors.length)];

    return () =>
      h(Avatar, {
        props: {
          ...props,
          backgroundColor: color
        }
      });
  }
});
