import Summary from "./Summary.vue";
import { compose } from "vue-compose";
import {
  updatePersonEnhancer,
  updateEmailEnhancer,
  updatePhoneEnhancer,
  deletePhoneEnhancer,
  updateAddressEnhancer,
  createEmailEnhancer,
  createPhoneEnhancer,
  createAddressEnhancer
} from 'shared/enhancers/parentsEnhancer';
import { changePasswordEnhancer } from "shared/enhancers/changePasswordEnhancer";

export const enhancer = compose(
  updatePersonEnhancer,
  updateEmailEnhancer,
  updatePhoneEnhancer,
  deletePhoneEnhancer,
  updateAddressEnhancer,
  createEmailEnhancer,
  createPhoneEnhancer,
  createAddressEnhancer,
  changePasswordEnhancer
);

export default enhancer(Summary);
