import Vue, { computed, VueConstructor } from 'vue';
import Layout from './Layout.vue';
import { compose, acceptProps } from 'vue-compose';
import { ApolloQueryResult } from '@apollo/client';
import currentUser from 'shared/components/CurrentUserEnhancer';
import ActiveFilters from './shared/ActiveFilters';
import { getOptions, normalizeProps } from 'shared/util';
import { Props } from './shared/Props';
import { StateChanger } from 'vue-infinite-loading';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetPaginatedGlaubachFellowsQuery,
  SortType,
  useGetPaginatedGlaubachFellowsQuery
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { filterOptionsEnhancer } from 'shared/enhancers/filterOptionsEnhancer';
import { FetchMoreOptions } from 'shared/types';

type Staff = ArrayElement<
  GetPaginatedGlaubachFellowsQuery['getPaginatedGlaubachFellows']['glaubachFellows']
>;

interface TChildProps {
  staff: Staff[];
  loading: boolean;
  initialLoading: boolean;
  fetchMore: (
    { limit, offset }: FetchMoreOptions,
    stateChanger: StateChanger
  ) => Promise<ApolloQueryResult<GetPaginatedGlaubachFellowsQuery>> | undefined;
}
export const getGlaubachFellowsEnhancer = wrapComponent<Props, TChildProps>((props) => {
  const { loading, result, fetchMore } = useGetPaginatedGlaubachFellowsQuery(
    computed(() => ({
      limit: 60,
      filter: {
        query: props.activeFilters.term,
        sortBy: props.activeFilters.sortBy,
        filters: props.activeFilters.filters
      }
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    initialLoading: loading.value && !result.value?.getPaginatedGlaubachFellows,
    loading: loading.value,
    staff: result.value?.getPaginatedGlaubachFellows.glaubachFellows || [],
    total: result.value?.getPaginatedGlaubachFellows.total || 0,
    fetchMore: ({ limit, offset }, stateChanger) =>
      fetchMore({
        variables: {
          limit,
          offset,
          filter: {
            query: props.activeFilters.term,
            sortBy: props.activeFilters.sortBy,
            filters: props.activeFilters.filters
          }
        },
        updateQuery(previousResult, { fetchMoreResult }) {
          if (!fetchMoreResult) {
            return previousResult;
          }
          if (
            !fetchMoreResult.getPaginatedGlaubachFellows.glaubachFellows.length ||
            fetchMoreResult.getPaginatedGlaubachFellows.glaubachFellows.length < limit
          ) {
            stateChanger.complete();

            if (!fetchMoreResult.getPaginatedGlaubachFellows.glaubachFellows.length) {
              return previousResult;
            }
          }

          const ids = previousResult.getPaginatedGlaubachFellows.glaubachFellows!.map(
            (x: Staff) => x.staffID
          );
          const newData = fetchMoreResult.getPaginatedGlaubachFellows.glaubachFellows!.filter(
            (x: Staff) => !ids.includes(x.staffID)
          );

          return {
            getPaginatedGlaubachFellows: {
              __typename: 'GlaubachFellowPage',
              total: fetchMoreResult.getPaginatedGlaubachFellows.total,
              glaubachFellows: [
                ...previousResult.getPaginatedGlaubachFellows!.glaubachFellows,
                ...newData
              ]
            }
          };
        }
      })
  }));
});

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { activeFilters, setFilters, ...propsToUse } = props;

  return Vue.extend({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        activeFilters: {
          term: '',
          filters: {},
          sortBy: { name: SortType.Name, ascending: true }
        }
      };
    },
    methods: {
      setFilters(args: Partial<ActiveFilters>, cb?: () => void) {
        this.activeFilters = { ...this.activeFilters, ...args };
        if (cb) {
          cb();
        }
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          activeFilters: this.activeFilters,
          setFilters: this.setFilters
        },
        on: this.$listeners
      });
    }
  });
};

export const enhancer = compose(
  withFilters,
  getGlaubachFellowsEnhancer,
  currentUser,
  filterOptionsEnhancer,
  compose(acceptProps(['currentStaffId']))
);

export default enhancer(Layout);
