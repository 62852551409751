import ParentLookupComponent from './ParentLookup.vue';
import { withData, compose } from 'vue-compose';
import { toCompactParents } from './utils';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { Gender, useParentLookupQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';

const parentLookupEnhancer = wrapComponent<Props, Pick<Props, 'parents' | 'searching'>>((props) => {
  const { loading, result } = useParentLookupQuery(
    computed(() => ({
      regionId: props.regionId,
      term: props.term,
      gender: props.gender === Gender.Male ? '0' : '1'
    })),
    { fetchPolicy: 'network-only', enabled: computed(() => !!props.term) }
  );

  return computed(() => ({
    parents: result.value?.parents ? toCompactParents(result.value.parents.parents) : [],
    searching: loading.value
  }));
});

export default compose(
  withData({
    term: {
      initialValue: null
    }
  }),
  parentLookupEnhancer
)(ParentLookupComponent);
