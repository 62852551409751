import { Props, FilterOption } from './types';
import Filters from './Filters.vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetFilterOptionsQuery, useGetFilterOptionsQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';

type OptionKey = keyof Omit<GetFilterOptionsQuery, '__typename'>;
type PropTypes = GetFilterOptionsQuery[OptionKey];

const mapOptions = (options: PropTypes | null | undefined): FilterOption[] =>
  (options || []).map((o) => ({
    key: o.key || '',
    displayValue: o.displayValue || ''
  }));

const enhancer = wrapComponent<Props, Pick<Props, 'filters'>>((props) => {
  const { result } = useGetFilterOptionsQuery(
    computed(() => ({ regionId: props.regionId })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    filters:
      (result.value && [
        {
          name: 'chapterOptions',
          filterGroup: 'Chapter',
          displayName: 'Chapters',
          options: mapOptions(result.value.chapterOptions)
        },
        {
          name: 'gradeOptions',
          filterGroup: 'Grade',
          displayName: 'Grade',
          options: mapOptions(result.value.gradeOptions)
        },
        {
          name: 'schoolOptions',
          filterGroup: 'School',
          displayName: 'School',
          options: mapOptions(result.value.schoolOptions)
        },
        {
          name: 'schoolTypeOptions',
          filterGroup: 'SchoolType',
          displayName: 'School Type',
          options: mapOptions(result.value.schoolTypeOptions)
        },
        {
          name: 'collegeOptions',
          filterGroup: 'College',
          displayName: 'College',
          options: mapOptions(result.value.collegeOptions)
        },
        {
          name: 'gapYearProgramOptions',
          filterGroup: 'GapYearProgram',
          displayName: 'Gap Year',
          options: mapOptions(result.value.gapYearProgramOptions)
        },
        {
          name: 'seasonOptions',
          filterGroup: 'Season',
          displayName: 'Season',
          options: mapOptions(result.value.seasonOptions)
        },
        {
          name: 'mediaConsentOptions',
          filterGroup: 'MediaConsent',
          displayName: 'Media Consent',
          options: mapOptions(result.value.mediaConsentOptions)
        },
        {
          name: 'parentMediaConsentOptions',
          filterGroup: 'ParentMediaConsent',
          displayName: 'Parent Media Consent',
          options: mapOptions(result.value.parentMediaConsentOptions)
        },
        {
          name: 'alumniOptions',
          filterGroup: 'Alumni',
          displayName: 'Current Teens or Alumni',
          options: mapOptions(result.value.alumniOptions)
        }
      ]) ||
      []
  }));
});

export default enhancer(Filters);
