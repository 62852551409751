import { MutateResult } from '@vue/apollo-composable';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  useResetPasswordMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

type ChangePasswordProps = {
  changePassword: (args: ResetPasswordMutationVariables) => MutateResult<ResetPasswordMutation>;
  changingPassword: boolean;
};

export const changePasswordEnhancer = wrapComponent<{}, ChangePasswordProps>(() => {
  const { loading, mutate } = useResetPasswordMutation();

  return computed(() => ({
    changePassword: ({ password, personId }) => mutate({ password, personId }),
    changingPassword: loading.value
  }));
});
