import config from '../../config';
import { VuexOidcClientSettings, VuexOidcState, vuexOidcCreateStoreModule } from 'vuex-oidc';

export type OidcState = VuexOidcState

const { issuer, client_id } = config;
const { protocol, hostname, port } = window.location;

const oidcSettings: VuexOidcClientSettings = {
  clientId: client_id,
	redirectUri: `${protocol}//${hostname}${port ? `:${port}` : ''}/callback`,
	responseType: 'token id_token',
	scope: 'openid profile email',
	silentRedirectUri: `${protocol}//${hostname}${port
		? `:${port}`
		: ''}/silent_renew.html`,
	authority: issuer,
	automaticSilentRenew: true,
	filterProtocolClaims: true,
	loadUserInfo: true,
  // TODO maybe change to window.location.origin?
	postLogoutRedirectUri: `${protocol}//${hostname}${port ? `:${port}` : ''}`,
};

export default vuexOidcCreateStoreModule(oidcSettings, { namespaced: true })
