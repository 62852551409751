import ReportItem from './ReportItem.vue';
import { compose, withData } from 'vue-compose';
import generateReportEnhancer from './generateReportEnhancer';
import { currentUserEnhancer } from 'shared/enhancers/currentUserEnhancer';

export const enhancer = compose(
  currentUserEnhancer,
  withData({
    showEventsLookup: {
      initialValue: false
    },
    showCtrl: {
      initialValue: false
    }
  }),
  generateReportEnhancer
);
export default enhancer(ReportItem);
