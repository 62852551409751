import AddNewFamily from './AddNewFamily.vue';
import { compose, withData } from 'vue-compose';
import { computed } from 'vue';
import { withRouter } from 'shared/components/router';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  CreateFamilyMutationVariables,
  useCreateFamilyMutation
} from 'shared/generated/graphql-types';

const createFamilyEnhancer = wrapComponent<CreateFamilyMutationVariables, Props>((props) => {
  const { mutate: createFamily } = useCreateFamilyMutation();

  return computed(() => ({
    createFamily: (input) => createFamily(input)
  }));
});

export default compose(
  createFamilyEnhancer,
  withData({
    addingFather: {
      initialValue: false
    },
    addingMother: {
      initialValue: false
    },
    addingChild: {
      initialValue: false
    }
  }),
  withRouter
)(AddNewFamily);
