import { render, staticRenderFns } from "./TeenDuplicates.vue?vue&type=template&id=6a7a2592&scoped=true&"
import script from "./TeenDuplicates.vue?vue&type=script&setup=true&lang=ts&"
export * from "./TeenDuplicates.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./TeenDuplicates.vue?vue&type=style&index=0&id=6a7a2592&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a7a2592",
  null
  
)

export default component.exports