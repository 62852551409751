import { compose, withProps } from 'vue-compose';
import ZoomUsersLookup from './ZoomUsersLookup.vue';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import { computed } from 'vue';
import { GetZoomUsersQuery, useGetZoomUsersQuery } from 'shared/generated/graphql-types';

interface InnerProps {
  regionId: number | null;
}

interface ChildProps {
  loading: boolean;
  users: GetZoomUsersQuery['zoomUsers'];
}

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

export default compose(
  withProps<InnerProps, InnerProps>((props) => {
    return {
      regionId: props.regionId || getCurrentRegion()
    };
  }),
  wrapComponent<InnerProps, ChildProps>((props) => {
    const { loading, result } = useGetZoomUsersQuery(
      computed(() => ({ dept: props.regionId ? props.regionId.toString() : null }))
    );
    return computed(() => ({
      loading: loading.value,
      users: result.value?.zoomUsers || []
    }));
  })
)(ZoomUsersLookup);
