import { computed } from 'vue';
import { sourceString } from '../shared/Participant';
import PotentialHousee from './shared/PotentialHousee';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetHousesForEventDocument,
  GetHousesForEventQuery,
  UnsetHouseMutation,
  useUnsetHouseMutation
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type House = ArrayElement<GetHousesForEventQuery['houses']>;

interface Props {
  eventId: number;
}

interface UnsetHouseProps {
  unsetHouse: (id: number, type: string, house: House, p: PotentialHousee) => void;
}

function getTypeNameFromType(type: sourceString) {
  switch (type) {
    case 'registration':
      return 'Registration';
    case 'eventguests':
      return 'EventGuest';
    case 'eventstaff':
      return 'EventStaff';
  }
}

export const unsetHouseEnhancer = wrapComponent<Props, UnsetHouseProps>((props) => {
  const { mutate } = useUnsetHouseMutation();

  return computed(() => ({
    unsetHouse: (id, type, house, p) =>
      mutate(
        { id, type },
        {
          optimisticResponse: {
            unsetHouse: {
              ...p.model,
              __typename: getTypeNameFromType(type as sourceString),
              House: house
            } as UnsetHouseMutation['unsetHouse']
          },
          update: (proxy) => {
            const data = proxy.readQuery<GetHousesForEventQuery>({
              query: GetHousesForEventDocument,
              variables: { eventId: props.eventId }
            });

            if (data && data.houses) {
              const houses = data.houses.slice();
              const currentHouseIndex = houses.findIndex((h) => h.houseId === house.houseId);
              switch (type) {
                case 'registration': {
                  const registrations = houses[currentHouseIndex].Registrations.slice();
                  const index = registrations.findIndex((r) => r.registrationID === id);
                  registrations.splice(index, 1);
                  houses[currentHouseIndex] = {
                    ...houses[currentHouseIndex],
                    Registrations: registrations
                  };
                  break;
                }
                case 'eventguests': {
                  const eventGuests = houses[currentHouseIndex].EventGuests.slice();
                  const index = eventGuests.findIndex((eg) => eg.eventGuestId === id);
                  eventGuests.splice(index, 1);
                  houses[currentHouseIndex] = {
                    ...houses[currentHouseIndex],
                    EventGuests: eventGuests
                  };
                  break;
                }
                case 'eventstaff': {
                  const eventStaff = houses[currentHouseIndex].EventStaff.slice();
                  const index = eventStaff.findIndex((es) => es.eventStaffId === id);
                  eventStaff.splice(index, 1);
                  houses[currentHouseIndex] = {
                    ...houses[currentHouseIndex],
                    EventStaff: eventStaff
                  };
                  break;
                }
              }

              houses[currentHouseIndex].registrationCount =
                houses[currentHouseIndex].EventGuests.length +
                houses[currentHouseIndex].EventStaff.length +
                houses[currentHouseIndex].Registrations.length;

              proxy.writeQuery<GetHousesForEventQuery>({
                query: GetHousesForEventDocument,
                variables: { eventId: props.eventId },
                data: {
                  houses
                }
              });
            }
          }
        }
      )
  }));
});
