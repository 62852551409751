import EmailInput from './EmailInput.vue';
import { computed } from 'vue';
import { compose, withProps } from 'vue-compose';
import { FetchResult } from '@apollo/client';
import { Person, Props, Staff } from './types';
import { toCompactStaff, toCompactPerson } from './utils';
import { EntityUnionViewModel } from './shared/EntityUnionViewModel';
import startCase from 'lodash/startCase';
import filter from 'lodash/filter';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import {
  EntityUnionEnum,
  GetEntityUnionDocument,
  GetEntityUnionQuery,
  SearchDuplicateEmailsQuery,
  useDeleteEmailMutation,
  useGetEntityUnionQuery,
  useSearchDuplicateEmailsQuery
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

type PersonOwner = Extract<GetEntityUnionQuery['entityUnion'], { personID: number }>;

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

interface SearchDuplicateEmailsProps {
  duplicate: EntityUnionViewModel | null;
  searching: boolean;
  searchDuplicates: (email: string) => Promise<FetchResult<SearchDuplicateEmailsQuery>> | undefined;
}
const searchDuplicateEmailsEnhancer = wrapComponent<Props, SearchDuplicateEmailsProps>((props) => {
  const { loading, result, refetch } = useSearchDuplicateEmailsQuery(
    computed(() => ({
      email: '',
      type: startCase(props.ownerType).replace(/\s/g, '') as EntityUnionEnum
    }))
  );

  return computed(() => ({
    duplicate:
      toCompactStaff(result.value?.searchDuplicateEmails as Staff) ||
      toCompactPerson(result.value?.searchDuplicateEmails as Person),
    searchDuplicates: (email) =>
      refetch({ email, type: startCase(props.ownerType).replace(/\s/g, '') as EntityUnionEnum }),
    searching: loading.value
  }));
});

interface GetEntityUnionProps {
  owner: EntityUnionViewModel | null;
}
const getEntityUnionEnhancer = wrapComponent<Props, GetEntityUnionProps>((props) => {
  const { result } = useGetEntityUnionQuery(
    computed(() => ({
      id: props.ownerId,
      type: startCase(props.ownerType).replace(/\s/g, '') as EntityUnionEnum
    })),
    { enabled: computed(() => !!props.ownerId && !!props.ownerType) }
  );

  return computed(() => ({
    owner:
      toCompactStaff(result.value?.entityUnion as Staff) ||
      toCompactPerson(result.value?.entityUnion as Person)
  }));
});

interface DeleteEmailProp {
  deleteEmail: (id: number) => void;
  deletingEmail: boolean;
}
export const deleteEmailEnhancer = wrapComponent<Props, DeleteEmailProp>((props) => {
  const { loading, mutate } = useDeleteEmailMutation();

  return computed(() => ({
    deletingEmail: loading.value,
    deleteEmail: (id) =>
      mutate(
        { id },
        {
          optimisticResponse: {
            deleteEmail: true
          },
          update: (proxy, { data }) => {
            let owner = proxy.readQuery<GetEntityUnionQuery>({
              query: GetEntityUnionDocument,
              variables: {
                id: props.owner.id,
                type: startCase(props.ownerType).replace(/\s/g, '') as EntityUnionEnum
              }
            });
            if (owner?.entityUnion) {
              const emailAddresses = filter(
                (owner.entityUnion as PersonOwner).EmailAddresses,
                (emailObj) => emailObj.id !== id
              );
              proxy.writeQuery<GetEntityUnionQuery>({
                query: GetEntityUnionDocument,
                variables: {
                  id: props.owner.id,
                  type: startCase(props.ownerType).replace(/\s/g, '') as EntityUnionEnum
                },
                data: {
                  entityUnion: {
                    ...(owner.entityUnion as PersonOwner),
                    EmailAddresses: emailAddresses
                  }
                }
              });
            }
          }
        }
      )
  }));
});

export default compose(
  withProps(() => ({
    regionId: getCurrentRegion()
  })),
  getEntityUnionEnhancer,
  deleteEmailEnhancer,
  searchDuplicateEmailsEnhancer
)(EmailInput);
