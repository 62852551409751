import { computed } from 'vue';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  ExtendedTeenProfileFragment,
  ExtendedTeenProfileFragmentDoc,
  useAddToFamilyMutation
} from 'shared/generated/graphql-types';

const addToFamilyEnhancer = wrapComponent<Props, Pick<Props, 'addToFamily'>>((props) => {
  const { mutate } = useAddToFamilyMutation();

  return computed(() => ({
    addToFamily: (values, family) =>
      mutate(
        { ...values },
        {
          optimisticResponse: {
            addToFamily: {
              ...family,
              Children: (family.Children || []).length ? family.Children : []
            }
          },
          update(client, { data }) {
            const extendedTeenProfileData = client.readFragment<ExtendedTeenProfileFragment>({
              id: `${values.input.childId}Teen`,
              fragment: ExtendedTeenProfileFragmentDoc,
              fragmentName: 'ExtendedTeenProfile'
            });
            if (extendedTeenProfileData && data?.addToFamily) {
              let teenProfileData;
              if (
                !extendedTeenProfileData.Person.ChildOf ||
                !extendedTeenProfileData.Person.ChildOf.length
              ) {
                teenProfileData = {
                  ...extendedTeenProfileData,
                  Person: {
                    ...extendedTeenProfileData.Person,
                    ChildOf: [data.addToFamily]
                  }
                };
              } else if (
                !extendedTeenProfileData.Person.ChildOf.find((f) => f.id === data.addToFamily.id)
              ) {
                teenProfileData = {
                  ...extendedTeenProfileData,
                  Person: {
                    ...extendedTeenProfileData.Person,
                    ChildOf: [...extendedTeenProfileData.Person.ChildOf, data.addToFamily]
                  }
                };
              }
              if (teenProfileData) {
                client.writeFragment<ExtendedTeenProfileFragment>({
                  id: `${values.input.childId}Teen`,
                  fragment: ExtendedTeenProfileFragmentDoc,
                  fragmentName: 'ExtendedTeenProfile',
                  data: teenProfileData
                });
              }
            }
          }
        }
      )
  }));
});

export default addToFamilyEnhancer;
