import TicketChanger from './TicketChanger.vue';
import { compose } from 'vue-compose';
import Vue, { Component, computed } from 'vue';
import { getOptions, normalizeProps, isValueless } from 'shared/util';
import omit from 'lodash/omit';
import gql from 'graphql-tag';
import {
  ChangeEventTicketInput,
  ChangeEventTicketMutation,
  GetEventForEditQuery,
  GetEventTicketsQuery,
  NotAvailableReason,
  useChangeEventTicketMutation,
  useGetEventTicketsQuery
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { wrapComponent } from 'shared/apollo-hoc';
import { MutateResult } from '@vue/apollo-composable';

type EventTicket = ArrayElement<GetEventForEditQuery['event']['EventTickets']>;
type EventTickets = GetEventTicketsQuery['event']['EventTickets'];

interface Props {
  ticketID: number;
  registrationID: number;
  eventID: number;
}

interface ChangeEventTicket {
  changeEventTicket: (vars: ChangeEventTicketInput) => MutateResult<ChangeEventTicketMutation>;
  updatingTicket: boolean;
}

interface GetEventTickets {
  eventTickets: EventTickets;
}

const changeEventTicketEnhancer = wrapComponent<Props, ChangeEventTicket>((props) => {
  const { loading, mutate } = useChangeEventTicketMutation();

  return computed(() => ({
    updatingTicket: loading.value,
    changeEventTicket: (input) =>
      mutate(
        { input },
        {
          update(client, result) {
            const eventForEditDataFragment = client.readFragment<{ EventTickets: EventTicket[] }>({
              id: `${props.eventID}Event`,
              fragment: gql`
                fragment Event on Event {
                  EventTickets {
                    EventTicketID
                    registrationsCount
                  }
                }
              `
            });
            if (
              eventForEditDataFragment &&
              eventForEditDataFragment.EventTickets.length &&
              result.data?.changeEventTicket &&
              result.data.changeEventTicket.registration !== null
            ) {
              const eventTickets = eventForEditDataFragment.EventTickets.slice();
              const ticketIndex = eventTickets.findIndex(
                (e) => e.EventTicketID === input.eventTicketID
              );
              if (ticketIndex > -1) {
                eventTickets[ticketIndex] = {
                  ...eventTickets[ticketIndex],
                  registrationsCount: (eventTickets[ticketIndex].registrationsCount || 0) + 1
                };
              }

              client.writeFragment<{ EventTickets: EventTicket[] }>({
                id: `${props.eventID}Event`,
                fragment: gql`
                  fragment Event on Event {
                    EventTickets {
                      EventTicketID
                      registrationsCount
                    }
                  }
                `,
                data: {
                  ...eventForEditDataFragment,
                  EventTickets: eventTickets
                }
              });
            }
          }
        }
      )
  }));
});

export const eventTicketChanger = (component: Component): Component => {
  const options = getOptions(component);

  return changeEventTicketEnhancer(
    Vue.extend({
      props: {
        ...omit(normalizeProps(options.props), 'needsApproval'),
        registrationID: {
          type: Number
        }
      },
      name: 'EventTicketChanger',
      data() {
        return {
          needsApproval: false
        };
      },
      render(h) {
        return h(component, {
          on: this.$listeners,
          props: {
            ...this.$props,
            changeEventTicket: async (ticketID: number, force?: boolean) => {
              this.needsApproval = false;

              const result = await this.changeEventTicket({
                eventTicketID: ticketID,
                registrationID: this.registrationID,
                force
              });

              if (
                result.data?.changeEventTicket!.notAvailableReason === NotAvailableReason.OverLimit
              ) {
                this.needsApproval = true;
              } else if (!isValueless(result.data?.changeEventTicket!.notAvailableReason)) {
                alert('There was an error changing the ticket');
              }

              return result;
            },
            needsApproval: this.needsApproval
          }
        });
      }
    })
  );
};

export default compose(
  eventTicketChanger,
  wrapComponent<Props, GetEventTickets>((props) => {
    const { result } = useGetEventTicketsQuery(computed(() => ({ eventId: props.eventID })));

    return computed(() => ({
      eventTickets: result.value?.event.EventTickets || []
    }));
  })
)(TicketChanger);
