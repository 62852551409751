import Staff from './Staff.vue';
import { compose, withData } from 'vue-compose';
import { AddEventStaffExtendedVariables } from './types';
import {
  AddJuniorEventStaffMutationVariables,
  GetEventQuery,
  GetEventStaffDocument,
  GetEventStaffQuery,
  GetStaffQuery,
  GetTeensQuery,
  useAddEventStaffMutation,
  useAddJuniorEventStaffMutation,
  useGetEventStaffQuery,
  useGetStaffQuery,
  useGetTeensQuery,
  useRemoveEventStaffMutation
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { computed } from 'vue';
import { ArrayElement } from 'shared/util/types';

type Event = GetEventQuery['event'];
type EventStaff = ArrayElement<GetEventStaffQuery['eventStaff']>;
type Teen = ArrayElement<GetTeensQuery['teens']['teens']>;

const graduationYear =
  new Date().getMonth() > 7 ? new Date().getFullYear() + 2 : new Date().getFullYear() + 1;

interface Props {
  event: Event;
  staff: GetStaffQuery['staff'];
  regionId: number | null;
}

// Mutations
interface JuniorStaffProps {
  addJuniorEventStaff: (vars: AddJuniorEventStaffMutationVariables) => void;
}
const addJuniorStaffEnhancer = wrapComponent<Props, JuniorStaffProps>((props) => {
  const { mutate } = useAddJuniorEventStaffMutation();

  return computed(() => ({
    addJuniorEventStaff: ({
      email,
      eventId,
      firstName,
      gender,
      lastName,
      regionId,
      teenId,
      chapterId
    }) =>
      mutate(
        { email, eventId, firstName, gender, lastName, regionId, teenId, chapterId },
        {
          optimisticResponse: {
            addJuniorEventStaff: {
              eventStaffId: Math.round(Math.random() * -1000000),
              Staff: {
                __typename: 'Staff',
                firstName,
                lastName,
                teenId,
                staffID: Math.round(Math.random() * -1000000),
                AdvisorRegions: [
                  {
                    AdvisorRegionId: -1,
                    staffType: 'Junior Staff',
                    Region: {
                      regionId,
                      __typename: 'Region'
                    },
                    __typename: 'AdvisorRegion'
                  }
                ]
              },
              __typename: 'EventStaff'
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetEventStaffQuery>({
              query: GetEventStaffDocument,
              variables: {
                eventId
              }
            });

            if (data?.eventStaff && updateData?.addJuniorEventStaff) {
              const eventStaff = data.eventStaff.slice();
              eventStaff.push(updateData.addJuniorEventStaff);

              proxy.writeQuery<GetEventStaffQuery>({
                query: GetEventStaffDocument,
                variables: {
                  eventId
                },
                data: {
                  eventStaff
                }
              });
            }
          }
        }
      )
  }));
});

interface AddEventStaffProps {
  addEventStaff: (vars: AddEventStaffExtendedVariables) => void;
}

const addEventStaffEnhancer = wrapComponent<Props, AddEventStaffProps>((props) => {
  const { mutate } = useAddEventStaffMutation();

  return computed(() => ({
    addEventStaff: ({ eventId, firstName, lastName, regionId, staffId, staffType }) =>
      mutate(
        { eventId, staffId },
        {
          optimisticResponse: {
            addEventStaff: {
              eventStaffId: Math.round(Math.random() * -1000000),
              Staff: {
                __typename: 'Staff',
                firstName,
                lastName,
                teenId: null,
                staffID: staffId,
                AdvisorRegions: [
                  {
                    AdvisorRegionId: -1,
                    staffType,
                    Region: {
                      regionId: Number(regionId),
                      __typename: 'Region'
                    },
                    __typename: 'AdvisorRegion'
                  }
                ]
              },
              __typename: 'EventStaff'
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetEventStaffQuery>({
              query: GetEventStaffDocument,
              variables: {
                eventId
              }
            });

            if (data?.eventStaff && updateData?.addEventStaff) {
              const eventStaff = data.eventStaff.slice();
              eventStaff.push(updateData.addEventStaff);

              proxy.writeQuery<GetEventStaffQuery>({
                query: GetEventStaffDocument,
                variables: {
                  eventId
                },
                data: {
                  eventStaff
                }
              });
            }
          }
        }
      )
  }));
});

interface RemoveStaffProps {
  removeEventStaff: (eventStaffId: number) => void;
}
const removeEventStaffEnhancer = wrapComponent<Props, RemoveStaffProps>((props) => {
  const { mutate } = useRemoveEventStaffMutation();

  return computed(() => ({
    removeEventStaff: (eventStaffId) =>
      mutate(
        { eventStaffId },
        {
          optimisticResponse: {
            removeEventStaff: eventStaffId
          },
          update: (proxy, result) => {
            const data = proxy.readQuery<GetEventStaffQuery>({
              query: GetEventStaffDocument,
              variables: {
                eventId: props.event.eventId
              }
            });

            if (data?.eventStaff) {
              const eventStaff = data.eventStaff.slice();
              const index = eventStaff.findIndex((staff) => staff!.eventStaffId === eventStaffId);

              if (index > -1) {
                eventStaff.splice(index, 1);
              }

              proxy.writeQuery<GetEventStaffQuery>({
                query: GetEventStaffDocument,
                variables: {
                  eventId: props.event.eventId
                },
                data: {
                  eventStaff
                }
              });
            }
          }
        }
      )
  }));
});

// Queries
interface StaffProps {
  staff: GetStaffQuery['staff'];
  loadingStaff: boolean;
}

const staffByRegion = wrapComponent<Props, StaffProps>((props) => {
  const { loading, result } = useGetStaffQuery(
    computed(() => ({ filter: { regionId: props.regionId, active: true } }))
  );

  return computed(() => ({
    staff: result.value?.staff || [],
    loadingStaff: loading.value
  }));
});

interface EventStaffProps {
  eventStaff: EventStaff[];
  loadingEventStaff: boolean;
}
const getEventStaffEnhancer = wrapComponent<Props, EventStaffProps>((props) => {
  const { loading, result } = useGetEventStaffQuery(
    computed(() => ({ eventId: props.event.eventId }))
  );

  return computed(() => ({
    eventStaff: result.value?.eventStaff || [],
    loadingEventStaff: loading.value
  }));
});

interface TeenProps {
  teens: Teen[];
  loadingTeens: boolean;
}

const getTeens = wrapComponent<Props, TeenProps>((props) => {
  const { loading, result } = useGetTeensQuery(
    computed(() => ({
      filter: {
        regionId: props.regionId,
        isAlumni: false,
        graduationYear: { lte: String(graduationYear) }
      }
    }))
  );

  return computed(() => ({
    loadingTeens: loading.value,
    teens: result.value?.teens.teens || []
  }));
});

const args = [
  addEventStaffEnhancer,
  addJuniorStaffEnhancer,
  staffByRegion,
  getTeens,
  getEventStaffEnhancer,
  removeEventStaffEnhancer
];

export default compose(
  ...args,
  withData({
    addingNewStaff: {
      initialValue: false
    }
  })
)(Staff);
