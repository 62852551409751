import { h, defineComponent } from 'vue';
import Loading from 'shared/components/Loading.vue';

export default defineComponent({
  props: {
    showEmptyTag: { type: Boolean },
    tag: { type: String },
    isAllowed: { type: Boolean },
    loading: { type: Boolean }
  },
  setup(props, { slots }) {
    return () => {
      if (props.loading) {
        return h(Loading);
      } else if (props.isAllowed) {
        return h('div', slots.default && slots.default({}));
      } else if (slots.restricted) {
        return h('div', slots.restricted({}));
      } else if (props.showEmptyTag) {
        return h('div', props.tag || '');
      }

      return h('div', 'Forbidden');
    };
  }
});
