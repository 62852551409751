var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.teen)?_c(_setup.BorderedList,[_c('li',{staticClass:"teen",class:{ active: _vm.selectedTeen === _vm.teen.personId },on:{"click":function($event){return _setup.emit('update:selectedTeen', _vm.teen.personId)}}},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"thumb-container"},[_c(_setup.Avatar,{attrs:{"size":36,"username":_vm.teen.name,"src":_vm.teen.thumbnail,"backgroundColor":_setup.getGenderColor(_vm.teen.gender)}})],1),_c('div',[_c('span',{staticClass:"name__text"},[_vm._v(_vm._s(_vm.teen.name))]),_c('span',{staticClass:"name__meta"},[_vm._v(_vm._s(_vm.teen.chapterName)+" | "+_vm._s(_vm.teen.graduationYear))])])]),_c('div',{staticClass:"events text-center"},[_vm._v(" "+_vm._s(_vm.teen.totalEvents)+" ")]),_c('div',{staticClass:"impact text-center"},[(_vm.teen.eventImpacted)?_c('font-awesome-icon',{staticClass:"check-icon",attrs:{"icon":"check","fixed-width":""}}):_c('span',[_vm._v("—")])],1),_c('div',{staticClass:"college"},[_c('div',{staticClass:"form-row"},[_c('div',{staticStyle:{"flex":"2"},on:{"click":function($event){$event.stopPropagation();}}},[_c(_setup.AutoSaveField,{attrs:{"name":"college","entity":_setup.teenCopy,"transform":(c) => ({
                  value: c ? String(c.schoolID) : null,
                  fieldName: _setup.UpdateTeenField.CollegeId
                })},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.PostHighSchoolLookup,_vm._g(_vm._b({attrs:{"type":"College","placeholder":"Select Program"}},'PostHighSchoolLookup',$props,false),$listeners))]}}],null,false,1954357067)})],1),_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c(_setup.AutoSaveField,{attrs:{"name":"collegeStartYear","entity":_setup.teenCopy,"transform":(c) => ({
                  value: String(c),
                  fieldName: _setup.UpdateTeenField.CollegeStartYear
                }),"validators":[
                {
                  name: 'validateNumber',
                  validator: (value) => _setup.validateNumber(value, true)
                }
              ]},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.UISelect,_vm._g(_vm._b({attrs:{"options":_setup.calendarYears,"placeholder":"Year"}},'UISelect',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("College Start year must be a number")]):_vm._e()]}}],null,false,3681003478)})],1)])]),_c('div',{staticClass:"program"},[_c('div',{staticClass:"form-row"},[_c('div',{staticStyle:{"flex":"2"},on:{"click":function($event){$event.stopPropagation();}}},[_c(_setup.AutoSaveField,{attrs:{"name":"gapYearProgram","entity":_setup.teenCopy,"transform":(c) => ({
                  value: c ? String(c.schoolID) : null,
                  fieldName: _setup.UpdateTeenField.GapYearProgramId
                })},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.PostHighSchoolLookup,_vm._g(_vm._b({attrs:{"type":"Israel","placeholder":"Select Program"}},'PostHighSchoolLookup',$props,false),$listeners))]}}],null,false,2260598080)})],1),_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c(_setup.AutoSaveField,{attrs:{"name":"gapYearProgramStartYear","entity":_setup.teenCopy,"transform":(c) => ({
                  value: String(c),
                  fieldName: _setup.UpdateTeenField.GapYearProgramStartYear
                }),"validators":[
                {
                  name: 'validateNumber',
                  validator: (value) => _setup.validateNumber(value, true)
                }
              ]},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.UISelect,_vm._g(_vm._b({attrs:{"options":_setup.calendarYears,"placeholder":"Year"}},'UISelect',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Gap Year Program Start year must be a number")]):_vm._e()]}}],null,false,1761832144)})],1)])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }