import Financials from './Financials.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import remove from 'lodash/remove';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  DeletePaymentMutation,
  GetRegistrationQuery,
  SelectedRegistrationFragment,
  SelectedRegistrationFragmentDoc,
  useDeletePaymentMutation
} from 'shared/generated/graphql-types';
import { MutateResult } from '@vue/apollo-composable';

type Registration = GetRegistrationQuery['registration'];

interface Props {
  registration: Registration;
}

interface DeletePaymentProps {
  deletePayment: (paymentId: number) => MutateResult<DeletePaymentMutation>;
  deletingPayment: boolean;
}

const deletePaymentEnhancer = wrapComponent<Props, DeletePaymentProps>((props) => {
  const { loading, mutate } = useDeletePaymentMutation();

  return computed(() => ({
    deletingPayment: loading.value,
    deletePayment: (paymentId) =>
      mutate(
        { paymentId, registrationId: props.registration.registrationID },
        {
          optimisticResponse: {
            deletePayment: paymentId
          },
          update(client) {
            try {
              const registrationData = client.readFragment<SelectedRegistrationFragment>({
                fragment: SelectedRegistrationFragmentDoc,
                id: `${props.registration.registrationID}Registration`,
                fragmentName: 'SelectedRegistration'
              });
              if (registrationData) {
                const lineItems = registrationData.LineItems.slice();
                const { amount } = lineItems.find((lineItem) => lineItem.trackById === paymentId)!;
                const lineItemBalanceIndex = lineItems.findIndex(
                  (lineItem) => lineItem.description === 'Balance'
                );
                lineItems[lineItemBalanceIndex] = {
                  ...lineItems[lineItemBalanceIndex],
                  amount: lineItems[lineItemBalanceIndex].amount + Math.abs(amount)
                };

                client.writeFragment<SelectedRegistrationFragment>({
                  fragment: SelectedRegistrationFragmentDoc,
                  id: `${props.registration.registrationID}Registration`,
                  fragmentName: 'SelectedRegistration',
                  data: {
                    ...registrationData,
                    balance: registrationData.balance + Math.abs(amount),
                    LineItems: remove(lineItems, (lineItem) => lineItem.trackById !== paymentId)
                  }
                });
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
      )
  }));
});

export default compose(deletePaymentEnhancer)(Financials);
