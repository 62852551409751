import { ServerSideFilterOption } from "shared/components/Filters/FilterOption";
import { getCurrentSeason } from "shared/util";
import has from "lodash/has";
import { SortBy, SortType } from "shared/generated/graphql-types";

const currentSeason = Number(getCurrentSeason().split('-')[0]);

export interface ActiveFiltersType {
	filters: { [key: string]: ServerSideFilterOption[] };
	query: string;
  sortBy: SortBy;
  isAlumni: boolean;
  isJewish: boolean;
  relationshipImpact: boolean;
  eventImpact: boolean;
  reached: boolean;
  season: string;
}

export default class ActiveFilters {
	filters: { [key: string]: ServerSideFilterOption[] } = {};
	query: string = '';
  sortBy: SortBy = { name: SortType.Name, ascending: true };
  isAlumni: boolean = false;
  isJewish: boolean = true;
  relationshipImpact: boolean = false;
  eventImpact: boolean = false;
  reached: boolean = false;
  season: string = `${currentSeason}-${currentSeason + 1}`;

  constructor(args: Partial<ActiveFiltersType> = {}) {
    this.filters = args && args.filters || this.filters;
    this.query = args && args.query || this.query;
    this.sortBy = args && args.sortBy || this.sortBy;
    this.isAlumni = has(args, 'isAlumni') ? !!args.isAlumni : this.isAlumni;
    this.isJewish = has(args, 'isJewish') ? !!args.isJewish : this.isJewish;
    this.relationshipImpact = has(args, 'relationshipImpact') ? !!args.relationshipImpact : this.relationshipImpact;
    this.eventImpact = has(args, 'eventImpact') ? !!args.eventImpact : this.eventImpact;
    this.reached = has(args, 'reached') ? !!args.reached : this.reached;
    this.season = args && args.season || this.season;
  }
}
