import SideBar from './SideBar.vue';
import { unescapeVariables } from '../../util';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  CreateTemplateInput,
  GetOverridesDocument,
  GetOverridesQuery,
  SendTestEmailMutationVariables,
  UpdateTemplateInput,
  useCreateTemplateMutation,
  useSendTestEmailMutation,
  useUpdateTemplateMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface Props {
  personId: number;
}

interface SendTestEmailProps {
  sendTestEmail: (vars: SendTestEmailMutationVariables) => void;
  sendingTestEmail: boolean;
}
const sendTestEmailEnhancer = wrapComponent<Props, SendTestEmailProps>((props) => {
  const { loading, mutate } = useSendTestEmailMutation();

  return computed(() => ({
    sendingTestEmail: loading.value,
    sendTestEmail: ({ id, email, type, variables }) => mutate({ id, email, type, variables })
  }));
});

interface UpdateTemplateProps {
  updateTemplate: (args: UpdateTemplateInput) => void;
  updatingTemplate: boolean;
}
const updateTemplateEnhancer = wrapComponent<Props, UpdateTemplateProps>(() => {
  const { loading, mutate } = useUpdateTemplateMutation();

  return computed(() => ({
    updateTemplate: (args) =>
      mutate({ args: { ...args, body: unescapeVariables(args.body, true) } }),
    updatingTemplate: loading.value
  }));
});

interface CreateTemplateProps {
  createTemplate: (args: CreateTemplateInput) => void;
  creatingTemplate: boolean;
}
const createTemplateEnhancer = wrapComponent<Props, CreateTemplateProps>(() => {
  const { loading, mutate } = useCreateTemplateMutation();

  return computed(() => ({
    createTemplate: (args) =>
      mutate(
        { args },
        {
          optimisticResponse: {
            createTemplate: {
              __typename: 'Template',
              templateId: -1,
              templateTypeId: args.templateTypeId,
              subject: args.subject || null,
              body: unescapeVariables(args.body, true),
              fromAddress: args.fromAddress || null,
              fromName: args.fromName || null,
              displayName: '',
              regionId: args.regionId,
              chapterId: args.chapterId || null,
              eventId: args.eventId || null,
              templateTypeKey: '',
              invalidated: false,
              replyToAddress: args.replyToAddress || null,
              Chapter: null,
              Event: null,
              Region: null
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetOverridesQuery>({
              query: GetOverridesDocument,
              variables: {
                templateTypeId: args.templateTypeId,
                regionId: args.regionId
              }
            });
            if (data && updateData?.createTemplate) {
              const overrides = data.overrides.slice();
              overrides.push(updateData.createTemplate);

              proxy.writeQuery<GetOverridesQuery>({
                query: GetOverridesDocument,
                variables: {
                  templateTypeId: args.templateTypeId,
                  regionId: args.regionId
                },
                data: { overrides }
              });
            }
          }
        }
      ),
    creatingTemplate: loading.value
  }));
});

export const enhancer = compose(
  sendTestEmailEnhancer,
  createTemplateEnhancer,
  updateTemplateEnhancer
);

export default enhancer(SideBar);
