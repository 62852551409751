import Participant from '../../shared/Participant';
import { GetBusesForEventQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type ReturnEventGuest = ArrayElement<ArrayElement<GetBusesForEventQuery['event']['Buses']>['ReturnFromEventEventGuests']>
type ToEventGuest = ArrayElement<ArrayElement<GetBusesForEventQuery['event']['Buses']>['TravelToEventEventGuests']>
type ReturnEventStaff = ArrayElement<ArrayElement<GetBusesForEventQuery['event']['Buses']>['ReturnFromEventEventStaff']>
type ToEventStaff = ArrayElement<ArrayElement<GetBusesForEventQuery['event']['Buses']>['TravelToEventEventStaff']>
type ReturnRegistration = ArrayElement<ArrayElement<GetBusesForEventQuery['event']['Buses']>['ReturnFromEventRegistrations']>
type ToRegistration = ArrayElement<ArrayElement<GetBusesForEventQuery['event']['Buses']>['TravelToEventRegistrations']>

type ParticipantTypeForBusing =
  (ToRegistration &
  ReturnRegistration) |
  (ToEventGuest &
  ReturnEventGuest) |
  (ToEventStaff &
  ReturnEventStaff);
export default class PotentialPassenger extends Participant<ParticipantTypeForBusing> {
	hasBusToEvent: boolean = this.model.ToBus !== null && this.model.ToBus !== undefined;
  hasBusFromEvent: boolean = this.model.FromBus !== null && this.model.FromBus !== undefined;
  hasBusBothDirections: boolean = this.hasBusFromEvent && this.hasBusToEvent;
}
