var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.event)?_c(_setup.Loading):_c(_setup.PanelsGrid,{attrs:{"bottomOffset":"175"}},[_c(_setup.PanelsSidebar,[_c('template',{staticClass:"header",slot:"header"},[_vm._v("Registrations ("+_vm._s((_vm.event.Registrations || []).length)+")")]),_c(_setup.FiltersPanel,_vm._b({},'FiltersPanel',{
        filters: _setup.filters,
        sortBy: _setup.sort,
        eventTypeId:
          (_vm.event.EventSubType &&
            _vm.event.EventSubType.EventType &&
            _vm.event.EventSubType.EventType.eventTypeId) ||
          null,
        sortDescending: _setup.sortDescending,
        registrations: _vm.event.Registrations,
        setFilter: _setup.setFilter,
        setFilters: _setup.setFilters,
        setSortField: (sortField) => (_setup.sort = sortField),
        setSortDescending: (descending) => (_setup.sortDescending = descending),
        claims: _vm.claims
      },false))],2),(_setup.noRegistration)?_c('div',{staticClass:"no-registration"},[_c('img',{attrs:{"src":"https://res.cloudinary.com/ncsy/image/upload/v1518432353/drop.jpg","alt":"Nope"}}),_vm._v(" No Registrations added."),_c('br')]):_vm._e(),(!_setup.noRegistration)?_c('div',{staticClass:"registration-list"},[_c('div',{staticClass:"list-header"},[_c('span',{staticClass:"list-header"},[_vm._v("Registration ID")]),_c('span',{staticClass:"list-header"},[_vm._v("Full Name")]),_c('span',{staticClass:"list-header"},[_vm._v("Registration Date")]),_c('span',{staticClass:"list-header"},[_vm._v("Balance")]),_c('span',{staticClass:"list-header"},[_vm._v("Amount")])]),_c(_setup.FinancialList,{attrs:{"filters":_setup.computedFilters,"event":_vm.event,"registrations":_vm.event.Registrations,"sortBy":_setup.sort,"sortDescending":_setup.sortDescending,"limit":_setup.limit},scopedSlots:_vm._u([{key:"default",fn:function({
          paginatedRegistrations,
          currentPage,
          totalPages,
          total,
          limit,
          addPayment,
          pageChange,
          loading
        }){return [_c(_setup.ScrollablePanel,{directives:[{name:"fullheight",rawName:"v-fullheight"}]},[_c('div',{staticClass:"list"},[_c(_setup.BorderedList,{attrs:{"liPadding":30}},_vm._l((paginatedRegistrations),function(registration){return _c('li',{key:registration.registrationID,staticClass:"registration-list__item"},[_c('div',{staticClass:"registration-item__fields"},[_c('span',{staticClass:"registration-field item-id"},[_c(_setup.RouterLink,{attrs:{"to":`/events/${_vm.event.eventId}/registrations/${registration.registrationID}`}},[_vm._v(" #"+_vm._s(registration.registrationID)+" ")])],1),_c('span',{staticClass:"registration-field item-full-name"},[_vm._v(_vm._s(registration.Teen.Person.firstName)+" "+_vm._s(registration.Teen.Person.lastName))]),_c('span',{staticClass:"registration-field item-date"},[_vm._v(_vm._s(_setup.formatDate(registration.registrationDate, _vm.event.TimeZone || 'UTC', '%m/%d/%Y')))]),_c('span',{staticClass:"registration-field item-balance"},[_vm._v(_vm._s(registration.balance))]),_c('div',{staticClass:"registration-field item-amount",class:{ loading: loading }},[_c(_setup.AutoSaveField,{attrs:{"name":"amount","entity":{},"validators":[
                        {
                          amount: 0,
                          validator: (amount) =>
                            Number(amount) > 0 && Number(amount) <= registration.balance
                        }
                      ]},on:{"save":(amount) => addPayment(registration.registrationID, amount.value)},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.UIInput,_vm._g(_vm._b({attrs:{"data-test":"amountField","type":"number"}},'UIInput',$props,false),$listeners)),(hasError('amount'))?_c('span',{staticClass:"error-label"},[_vm._v("Please enter a valid amount")]):_vm._e()]}}],null,true)}),(loading)?_c(_setup.Loading,{staticClass:"processing-payment"}):_vm._e()],1)])])}),0),_c(_setup.Pagination,{staticClass:"pagination",attrs:{"total-pages":totalPages,"total":total,"page":currentPage},on:{"pagechanged":pageChange}})],1)])]}}],null,false,1474549700)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }