import { ApolloClient } from '@apollo/client';
import {
  GetFinancialsQuery,
  RegistrationPaymentStatus,
  StatusType
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Event = GetFinancialsQuery['event'];
type Registration = ArrayElement<GetFinancialsQuery['event']['Registrations']>;
type Person = Registration['Teen']['Person'];

export enum ScholarshipStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Denied = 'Denied'
}

export interface Filters {
  paymentStatus: RegistrationPaymentStatus | 'NoPaymentsMade' | null;
  registrationStatus: StatusType | null;
  gender: Person['gender'] | null;
  scholarship: ScholarshipStatus | null;
}

export interface Props {
  eventId: number;
  event?: Event;
  registration?: Registration;
  client?: ApolloClient<any>;
}
