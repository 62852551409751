import { compose } from 'vue-compose';
import Vue, { VueConstructor } from 'vue';
import { withRouter } from 'shared/components/router';
import { getOptions, normalizeProps } from 'shared/util';
import Layout from './Layout.vue';
import { teenListEnhancer } from './teenListEnhancer';
import ActiveFilters from './shared/ActiveFilters';
import { SortType } from 'shared/generated/graphql-types';
import { filterOptionsEnhancer } from 'shared/enhancers/filterOptionsEnhancer';

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { activeFilters, setFilters, ...propsToUse } = props;

  return Vue.extend<
    { activeFilters: ActiveFilters },
    { setFilters: (args: Partial<ActiveFilters>, cb?: () => void) => void },
    {},
    {}
  >({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        activeFilters: {
          term: '',
          isAlumni: false,
          interests: [],
          filters: {},
          sortBy: { name: SortType.DateUpdated, ascending: true }
        }
      };
    },
    methods: {
      setFilters(args: Partial<ActiveFilters>, cb?: () => void) {
        this.activeFilters = { ...this.activeFilters, ...args };
        if (cb) {
          cb();
        }
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          activeFilters: this.activeFilters,
          setFilters: this.setFilters
        },
        on: this.$listeners
      });
    }
  });
};

export default compose(withRouter, withFilters, teenListEnhancer, filterOptionsEnhancer)(Layout);
