import { GetExpandedClassListQuery, MaritalStatus } from "shared/generated/graphql-types";
import {
  getCurrentEmail,
  getCurrentCellNumber,
  phoneFormat,
  getCurrentLandlineNumber,
  getCurrentAddress,
  formatAddressToString,
} from "shared/util";
import { ArrayElement } from "shared/util/types";

type ExpandedStudent = ArrayElement<GetExpandedClassListQuery['jfeTeens']>

function getParent(s: ExpandedStudent, gender: number) {
  return (
    (s.Person.Parents || []).find((p) => !!(p && p.gender === gender)) || {
      firstName: "No name on file",
      lastName: "",
      EmailAddresses: [],
      Phones: [],
      personID: 0,
      maritalStatus: MaritalStatus.Unknown,
      gender: null,
      __typename: "Person",
    }
  );
}

function getEmergencyContact(s: ExpandedStudent) {
  return (
    (s.EmergencyContacts || [])[0] || {
      relationship: "",
      phoneNumber: "",
      name: "No emergency contact on file",
      emergencyContactId: 0,
      email: "",
      __typename: "EmergencyContact",
    }
  );
}

export function csvForExpandedClassList(
  students: ExpandedStudent[],
  season: string
) {
  const headers = [
    "First Name",
    "LastName",
    "Gender",
    "Mother First Name",
    "Mother Last Name",
    "Mother Cell Number",
    "Mother Email",
    "Father First Name",
    "Father Last Name",
    "Father Cell Number",
    "Father Email",
    "Address",
    "Home Number",
    "Parents Marital Status",
    "Emergency Contact Name",
    "Emergency Contact Relation",
    "Emergenct Contact Number",
    "Emergency Contact Email",
    "Medicines",
    "Allergies",
    "Learning Style",
    "Hebrew Reading Background", // Contains extra information as well
    "Special Needs And Requests",
    "IEP Regularly Attended",
    "Additional Information",
    "Plan Name",
  ].join(",");
  const teenData = students
    .map((s) => {
      const mother = getParent(s, 1);
      const father = getParent(s, 0);
      const emergencyContact = getEmergencyContact(s);
      return [
        s.Person.firstName,
        s.Person.lastName,
        s.Person.gender ? "Female" : "Male",
        mother.firstName,
        mother.lastName,
        phoneFormat(
          (getCurrentCellNumber(mother.Phones) || { phoneNumber: "" })
            .phoneNumber
        ) || "",
        (getCurrentEmail(mother.EmailAddresses) || { email: "" }).email || "",
        father.firstName,
        father.lastName,
        phoneFormat(
          (getCurrentCellNumber(father.Phones) || { phoneNumber: "" })
            .phoneNumber
        ) || "",
        (getCurrentEmail(father.EmailAddresses) || { email: "" }).email || "",
        formatAddressToString(getCurrentAddress(s.Person.Addresses)),
        phoneFormat(
          (
            getCurrentLandlineNumber(mother.Phones) ||
            getCurrentLandlineNumber(father.Phones) || { phoneNumber: "" }
          ).phoneNumber
        ) || "",
        `Father: ${father.maritalStatus} - Mother: ${mother.maritalStatus}`,
        emergencyContact.name,
        emergencyContact.relationship,
        phoneFormat(emergencyContact.phoneNumber),
        emergencyContact.email,
        s.medicines || "",
        s.allergyInformation || "",
        (s.ExtraQuestions && s.ExtraQuestions.LearningStyle) || "",
        (s.ExtraQuestions &&
          `${s.ExtraQuestions.HebrewReadingBackground}${
            s.ExtraQuestions.HebrewReadingBackgroundExtraInformation
              ? ` ${s.ExtraQuestions.HebrewReadingBackgroundExtraInformation}`
              : ""
          }`.trim()) ||
          "",
        (s.ExtraQuestions && s.ExtraQuestions.SpecialNeedsAndRequests) || "",
        (s.ExtraQuestions && s.ExtraQuestions.IEPRegularlyAttended) || "",
        (s.ExtraQuestions && s.ExtraQuestions.AdditionalInformation) || "",
        (
          s.Person.Memberships.find((m) => m.season === season) || {
            Plan: { name: "" },
          }
        ).Plan.name,
      ]
        .map((s) => (s || "").replace(/,/g, " "))
        .join(",");
    })
    .join("\n");

  return [`${headers},\n${teenData}`];
}
