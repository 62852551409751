import { compose, withProps } from 'vue-compose';
import { Props } from './types';
import Vue, { Component as VueComponent, computed } from 'vue';
import EventLookup from './EventLookup.vue';
import { getCurrentSeason, getOptions, normalizeProps } from 'shared/util';
import { Event, Filters } from './types';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetEventsForReportQuery } from 'shared/generated/graphql-types';

export const filtersInit = (): Filters => ({
  sortColumn: {
    column: null,
    ascending: false
  },
  eventSubTypeId: null,
  chapterId: null,
  searchByName: null,
  season: getCurrentSeason(),
  showMyEvents: false
});

interface GetEventsProps {
  events: Event[];
  isLoading: boolean;
  total: number;
}

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const getEventsEnhancer = wrapComponent<Props, GetEventsProps>((props) => {
  const { loading, result } = useGetEventsForReportQuery(
    computed(() => ({
      limit: props.limit,
      offset: (props.currentPage - 1) * props.limit,
      filter: {
        showTorahHighSeries: props.showTorahHighSeries || null,
        ...props.filters,
        regionId: props.regionId,
        advisorId: props.staffId
      }
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    isLoading: loading.value,
    events: result.value?.eventsLookup?.events || [],
    total: result.value?.eventsLookup?.total || 0
  }));
});

const withFilters = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);

  const { currentPage, filters, setFilters, clearFilters, setPage, ...propsToUse } = props;

  return Vue.extend({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        filters: filtersInit(),
        currentPage: 1
      };
    },
    methods: {
      setFilters(args: Partial<Filters>) {
        this.filters = { ...this.filters, ...args };
        this.setPage(1);
      },
      clearFilters() {
        this.filters = filtersInit();
        this.setPage(1);
      },
      setPage(page: number) {
        this.currentPage = page;
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          filters: this.filters,
          setFilters: this.setFilters,
          clearFilters: this.clearFilters,
          currentPage: this.currentPage,
          setPage: this.setPage
        },
        on: this.$listeners
      });
    }
  });
};

export const enhancer = compose(
  withProps<any, any>((props: Props) => ({
    limit: 10,
    regionId: props.regionId || getCurrentRegion()
  })),
  withFilters,
  getEventsEnhancer
);

export default enhancer(EventLookup);
