import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  from,
  InMemoryCache,
  InMemoryCacheConfig,
  NextLink,
  Observable,
  Operation,
  split
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from 'apollo-utilities';
import { OperationDefinitionNode } from 'graphql';
import config from './config';
import { createStore } from './store';
import { useRegionStore } from 'store/region/useRegionStore';
import { StrictTypedTypePolicies } from 'shared/generated/schema-type-policies';

const MAX_RETRY_ATTEMPTS = 3;
const ATTEMPT_DELAY = 1000;

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const batchHttpLink = new BatchHttpLink({
  // window.fetch is for showing the graphql req/res on LogRocket (https://docs.logrocket.com/docs/troubleshooting-sessions)
  fetch: (...args) => fetch(...(args as Parameters<typeof fetch>)),
  uri: `${config.graphql}/graphql`,
  batchKey: (operation) => {
    const excludedOperations = [
      'getTeens',
      'GetStaff',
      'reports',
      'getFilterOptions',
      'teensPostHigeSchool'
    ];
    return excludedOperations.indexOf(operation.operationName) > -1
      ? operation.operationName
      : 'normal';
  }
});

const wsLink = new WebSocketLink({
  uri: config.subscriptions,
  options: {
    reconnect: false
  }
});

const authLink = setContext(async () => {
  const token = store.getters['oidc/oidcAccessToken'];
  const user = store.getters['oidc/oidcUser'];
  const regionId = getCurrentRegion();

  return {
    headers: {
      authorization: `Bearer ${token}`,
      'x-selectedRegion': regionId ? regionId : null,
      'apollographql-client-name': `${user.name} - ${user.email} -  ${new Date().toUTCString()}`
    }
  };
});

const retryLink = new RetryLink({
  attempts: {
    max: MAX_RETRY_ATTEMPTS,
    retryIf: (error, operation) => {
      return !!(
        error &&
        error.message !== 'Not Found' &&
        !operation.query.definitions.some(
          (d) => (d as OperationDefinitionNode).operation === 'mutation'
        )
      );
    }
  },
  delay: {
    initial: ATTEMPT_DELAY
  }
});

const errorLink = onError(({ response, graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions && err.extensions.code) {
        case 'UNAUTHENTICATED':
          return new Observable((observer) => {
            store
              .dispatch('oidc/authenticateOidcSilent')
              .then(() => store.getters['oidc/oidcAccessToken'])
              .then((access_token) => {
                operation.setContext(() => ({
                  headers: {
                    authorization: `Bearer ${access_token}`
                  }
                }));
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                };

                // Retry last failed request
                forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                // No refresh or client token available, we force user to login
                store.dispatch('oidc/authenticateOidc', window.location.pathname);
              });
          });
        case 'FORBIDDEN':
          if (response && response.errors && response.errors.length) {
            response.errors = undefined;
          }
          // tslint:disable-next-line:no-console
          console.warn(
            `You do not have access to some of the fields from ${operation.operationName} query`
          );
          break;
        case '404':
          alert('Not Found');
          break;
      }
    }
  }
});

const forceErrorLink = new ApolloLink((operation, forward) => {
  return (forward as NextLink)(operation).map((data) => {
    if (data && data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0] && data.errors[0].message);
    }
    return data;
  });
});

const identifiers = {
  TeenPage: 'total',
  FamiliesPage: 'total',
  PhonePage: 'total',
  Staff: 'staffID',
  Region: 'regionId',
  AdvisorRegion: 'AdvisorRegionId',
  Event: 'eventId',
  School: 'schoolID',
  Chapter: 'chapterId',
  MetaData: 'MetaID',
  Series: 'seriesID',
  Registration: 'registrationID',
  Waiver: 'id',
  EventTicket: 'EventTicketID',
  EventGuest: 'eventGuestId',
  House: 'houseId',
  EventStaff: 'eventStaffId',
  Person: 'personID',
  Teen: 'personID',
  Role: 'id',
  RolePermission: 'id',
  Permission: 'id',
  Attendance: 'attendanceId',
  EmailAddress: 'id',
  Phone: 'id',
  Address: 'id',
  Synagogue: 'synagogueId',
  EventsAggregation: 'id',
  EmailHistory: 'id',
  EventSubType: 'eventSubTypeId',
  TeenDuplicate: 'personId',
  TeenAdvisor: 'teenAdvisorId',
  ServerFilterOption: 'id',
  EmergencyContact: 'emergencyContactId',
  LineItem: 'registrationLineItemID',
  EventTrack: 'id',
  AdditionalEventItem: 'id',
  AgendaEventGrouping: 'date',
  Child: 'personID',
  Report: 'id',
  Bus: 'busID',
  TemplateType: 'templateTypeId',
  EmailVariable: 'key',
  Template: 'templateId',
  ParentExtraInfo: 'personId',
  ExtraQuestions: 'personId',
  Interaction: 'id',
  InteractionType: 'id',
  RelationshipImpact: 'id',
  EventImpact: 'id',
  Note: 'id',
  ImpactStatistics: 'id',
  HousingRequestedBy: 'registrationID',
  EventType: 'eventTypeId',
  Payment: 'paymentID',
  ZoomUser: 'id',
  ZoomMeeting: 'id',
  EventImpression: 'id',
  Coupon: 'id',
  CouponAction: 'couponId',
  CouponTrigger: 'id',
  DiscountScheme: 'id',
  DiscountSchemeAction: 'discountSchemeId',
  DiscountSchemeTrigger: 'id',
  Membership: 'id',
  MembershipPlan: 'id',
  Order: 'id',
  OrderDonation: 'id',
  OrderPayment: 'id',
  GlaubachFellow: 'id',
  Goal: 'id',
  GoalType: 'id',
  Lead: 'id',
  TeenList: 'id',
  SchoolType: 'id',
  UploadedRegistrationFile: 'id',
  UploadedFile: 'id',
  Family: 'id'
};

const introspectionResult = {
  possibleTypes: {
    EntityUnion: ['Person', 'Staff'],
    EventParticipant: ['EventGuest', 'EventStaff', 'Registration'],
    GlobalSearchResult: ['Event', 'Family', 'Teen'],
    GoalEntity: [
      'EventSubType',
      'EventTrack',
      'EventType',
      'InteractionType',
      'School',
      'SchoolType'
    ]
  }
};

const typePolicies: StrictTypedTypePolicies = {
  Bus: {
    fields: {
      ReturnFromEventEventGuests: { merge: false },
      ReturnFromEventEventStaff: { merge: false },
      ReturnFromEventRegistrations: { merge: false },
      TravelToEventEventGuests: { merge: false },
      TravelToEventEventStaff: { merge: false },
      TravelToEventRegistrations: { merge: false }
    }
  },
  Event: {
    fields: {
      Attendances: { merge: false },
      Buses: { merge: false },
      EventGuests: { merge: false },
      EventStaff: { merge: false },
      Registrations: { merge: false }
    }
  },
  EventPage: {
    fields: {
      events: { merge: false }
    }
  },
  House: {
    fields: {
      Registrations: { merge: false }
    }
  },
  Person: {
    fields: {
      Notes: { merge: false }
    }
  },
  Registration: {
    fields: {
      Payments: { merge: false}
    }
  },
  Series: {
    fields: {
      Events: { merge: false }
    }
  },
  Staff: {
    fields: {
      AdvisorRegions: { merge: false }
    }
  },
  TeenPage: {
    fields: {
      teens: { merge: false }
    }
  },
  Query: {
    fields: {
      eventStaff: { merge: false },
      getPaginatedStaff: { merge: false },
      notesByStaff: { merge: false }
    }
  }
};

interface GraphqlEntity {
  __typename: keyof typeof identifiers;
  [x: string]: any;
}

const link = split(
  ({ query }: Operation) => {
    const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;

    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  from([retryLink as ApolloLink, forceErrorLink, errorLink, authLink, batchHttpLink])
);

export const apolloCacheConfig: InMemoryCacheConfig = {
  ...introspectionResult,
  typePolicies,
  addTypename: true,
  dataIdFromObject: (object: any) => {
    return identifiers[(object as GraphqlEntity).__typename]
      ? object[identifiers[(object as GraphqlEntity).__typename]] +
          (object as GraphqlEntity).__typename
      : defaultDataIdFromObject(object);
  }
};

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(apolloCacheConfig),
  connectToDevTools: true,
  queryDeduplication: true
});

export default client;
