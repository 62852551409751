// Holidays are get from https://www.hebcal.com/home/195/jewish-calendar-rest-api
import Vue, {Component} from 'vue';
import {getOptions, normalizeProps} from 'shared/util';
import axios from 'axios';

const withHolidays = (Component: Component) => {
  const props = {...normalizeProps(getOptions(Component).props)};
  return Vue.extend({
    name: `${Component.name}WithHolidays`,
    props,
    data() {
      return {
        isFetching: true,
        holidaysData: []
      };
    },
    async created () {
      try {
        this.isFetching = true;
        if (this.isSeries) {
          let yearFrom: string;
          let yearTo: string = '';
          for (const event of this.events) {
            yearFrom = new Date(event.startDate).getFullYear().toString();
            if (yearFrom !== yearTo) {
              this.holidaysData = this.holidaysData.concat(await this.getHolidays(yearFrom));
              yearTo = yearFrom;
            }
          }
        } else {
          this.holidaysData = this.holidaysData.concat(await this.getHolidays(this.date.year, this.date.month));
        }
        this.isFetching = false;
      } catch (e) {
        this.holidaysData = [];
        this.isFetching = false;
      }
    },
    methods: {
      async getHolidays(year: string, month?: string) {
        const response = await axios.get(`https://www.hebcal.com/hebcal/?v=1&cfg=json&maj=on&min=on&mod=on&nx=on&year=${year}&month=${month || 'x'}&ss=on&mf=on`);
        return (response!.data || {items: []}).items || [];
      }
    },
    render (h) {
      return h(Component, {
        props: {
          ...this.$props,
          holidaysFetching: this.isFetching,
          holidays: this.holidaysData
        },
        on: this.$listeners
      });
    }
  });
};

export default withHolidays;
