import CompactTeen from './shared/CompactTeen';
import { ActiveFiltersType } from './shared/ActiveFilters';
import { getFullName } from 'shared/util';
import { ArrayElement } from 'shared/util/types';
import { TeensPostHigeSchoolQuery } from 'shared/generated/graphql-types';

type Teen = ArrayElement<TeensPostHigeSchoolQuery['teens']['teens']>;

export function toCompactTeens(teens: Teen[], activeFilters: ActiveFiltersType): CompactTeen[] {
  if (!teens.length) return [];
  return teens.map((teen) => ({
    name: getFullName(teen.Person.firstName, teen.Person.lastName),
    personId: teen.personID,
    gender: teen.Person.gender,
    thumbnail: teen.thumbnail || null,
    graduationYear: teen.graduationYear || null,
    chapterName: teen.Chapter ? teen.Chapter.chapterName : null,
    college: teen.College ? teen.College : null,
    collegeStartYear: teen.collegeStartYear,
    gapYearProgram: teen.GapYearProgram ? teen.GapYearProgram : null,
    gapYearProgramStartYear: teen.gapYearProgramStartYear,
    totalEvents: +(teen.statistics[1] || {}).value! || 0,
    eventImpact: teen.EventImpacts.find((r) => r.season === activeFilters.season) || null,
    eventImpacted: !!teen.EventImpacts.find((r) => r.season === activeFilters.season)
  }));
}
