import Notes from './Notes.vue';
import { compose, withData } from 'vue-compose';
import AddStaffPersonNoteArgs from './AddStaffPersonNoteArgs';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetNotesByStaffDocument,
  GetNotesByStaffQuery,
  NoteCategory,
  useAddStaffPersonNoteMutation,
  useGetNotesByStaffQuery
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface Props {
  chapterId: number | null;
  staff: {
    staffId: string;
  };
  regionId: number;
}

type NotesProps = {
  notesLoading: boolean;
  notes: GetNotesByStaffQuery['notesByStaff'];
};
const notes = wrapComponent<Props, NotesProps>((props) => {
  const { loading, result } = useGetNotesByStaffQuery(
    computed(() => ({ advisorId: Number(props.staff.staffId) }))
  );

  return computed(() => ({
    notes: result.value?.notesByStaff || [],
    notesLoading: loading.value
  }));
});

type AddStaffNoteProps = {
  addStaffPersonNote: (vars: AddStaffPersonNoteArgs) => void;
  addingStaffPersonNote: boolean;
};
const addStaffPersonNoteEnhancer = wrapComponent<Props, AddStaffNoteProps>(() => {
  const { loading, mutate } = useAddStaffPersonNoteMutation();

  return computed(() => ({
    addingStaffPersonNote: loading.value,
    addStaffPersonNote: ({
      category,
      chapterId,
      contactDate,
      firstName,
      lastName,
      note,
      personId,
      regionId,
      staffId
    }) =>
      mutate(
        {
          category,
          contactDate,
          note,
          personId,
          staffId
        },
        {
          optimisticResponse: {
            addPersonNote: {
              note,
              category: NoteCategory.General,
              date: contactDate,
              id: Math.round(Math.random() * -1000000),
              Person: {
                personID: personId,
                firstName,
                lastName,
                __typename: 'Person',
                Teen: {
                  chapterId,
                  personID: personId,
                  __typename: 'Teen'
                }
              },
              __typename: 'Note'
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetNotesByStaffQuery>({
              query: GetNotesByStaffDocument,
              variables: { advisorId: staffId, regionId }
            });

            if (data?.notesByStaff && updateData?.addPersonNote) {
              const notesByStaff = data.notesByStaff.slice();
              notesByStaff.push(updateData.addPersonNote);
              proxy.writeQuery<GetNotesByStaffQuery>({
                query: GetNotesByStaffDocument,
                variables: { advisorId: staffId, regionId },
                data: {
                  notesByStaff
                }
              });
            }
          }
        }
      )
  }));
});

export default compose(
  notes,
  addStaffPersonNoteEnhancer,
  withData({
    addingNewNote: {
      initialValue: false
    }
  })
)(Notes);
