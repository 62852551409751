import Series from './Series.vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetSingleSeriesQuery, useGetSingleSeriesQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface Props {
  seriesId: string;
  loading: boolean;
  series?: GetSingleSeriesQuery['singleSeries'] | null;
}

export default compose(
  wrapComponent<Props, Pick<Props, 'loading' | 'series'>>((props) => {
    const { loading, result } = useGetSingleSeriesQuery(computed(() => ({ seriesID: Number(props.seriesId) })))

    return computed(() => ({
      loading: loading.value,
      series: result.value?.singleSeries
    }))
  })
)(Series);
