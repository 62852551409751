import { CompactTeen } from './shared/CompactTeen';
import { getCurrentEmail, getCurrentCellNumber, getCurrentLandlineNumber, getCurrentAddress, getGrade } from 'shared/util';
import { toFamilyViewModels } from 'shared/util/families';
import { SingleTeenQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Teen = SingleTeenQuery['singleTeen']
type Advisor = ArrayElement<SingleTeenQuery['singleTeen']['Advisors']>

function getAdvisor (advisors: Advisor[], type: string) {
  if (!advisors.length) return null;

  if (type === 'primary') {
    const primaryFiltered = advisors.filter(a => a.isPrimary);
    if (!primaryFiltered.length) return null;
    const { Advisor } = primaryFiltered[0];
    return Advisor;
  }
  else if (type === 'secondary') {
    const secondaryFiltered = advisors.filter(a => !a.isPrimary);
    if (!secondaryFiltered.length) return null;
    const { Advisor } = secondaryFiltered[secondaryFiltered.length - 1];
    return Advisor;
  }
  return null;
}

export function toCompactTeen(teen: Teen): CompactTeen {
  const currentAddress = getCurrentAddress(teen.Person.Addresses || []);
  const families = toFamilyViewModels(teen.Person.ChildOf || [], teen.Person.primaryFamilyId);
  const primaryFamily = families.find(f => f.primary) || families[0];
  const father = primaryFamily && primaryFamily.father;
  const mother = primaryFamily && primaryFamily.mother;

  return {
    address: currentAddress,
    street: currentAddress.street || '',
    street2: currentAddress.street2 || '',
    city: currentAddress.city || '',
    state: currentAddress.state || '',
    zipCode: currentAddress.zipCode || '',
    country: currentAddress.country || '',
    landline: getCurrentLandlineNumber(teen.Person.Phones || []) ? getCurrentLandlineNumber(teen.Person.Phones)!.phoneNumber : null,
    cellphone: getCurrentCellNumber(teen.Person.Phones || []) ? getCurrentCellNumber(teen.Person.Phones)!.phoneNumber : null,
    email: getCurrentEmail(teen.Person.EmailAddresses || []) ? getCurrentEmail(teen.Person.EmailAddresses)!.email : null,
    emails: teen.Person.EmailAddresses,
    chapter: teen.Chapter ? teen.Chapter : null,
    chapterName: teen.Chapter ? teen.Chapter.chapterName : null,
    school: teen.School ? teen.School : null,
    fullName: `${teen.Person.firstName} ${teen.Person.lastName}`,
    birthDate: teen.birthDate || '',
    schoolName: teen.School ? teen.School.name : null,
    synagogue: teen.Synagogue ? teen.Synagogue : null,
    firstName: teen.Person.firstName || '',
    lastName: teen.Person.lastName || '',
    personId: teen.personID,
    phoneNumbers: teen.Person.Phones,
    gender: teen.Person.gender,
    regionName: teen.Region.regionName,
    regionId: teen.Region.regionId,
    regionStates: teen.Region.ContainingStates,
    regionCountry: teen.Region.country,
    currencySymbol: teen.Region.currencySymbol,
    graduationYear: teen.graduationYear,
    grade: getGrade(teen.graduationYear),
    gapYearProgramStartYear: teen.gapYearProgramStartYear,
    shanaBetStartYear: teen.shanaBetStartYear,
    collegeStartYear: teen.collegeStartYear,
    isAlumni: teen.isAlumni,
    isUnaffiliated: teen.isUnaffiliated,
    motherPersonId: mother ? mother.parentId : null,
    fatherPersonId: father ? father.parentId : null,
    fatherName: father ? father.name : '',
    motherName: mother ? mother.name : '',
    motherHomePhone: mother ? mother.homePhone : null,
    motherCellPhone: mother ? mother.cellPhone : null,
    fatherHomePhone: father ? father.homePhone : null,
    fatherCellPhone: father ? father.cellPhone : null,
    fatherEmail: father ? father.email : null,
    motherEmail: mother ? mother.email : null,
    families,
    primaryFamily,
    father,
    mother,
    vegetarian: teen.vegetarian,
    shomerShabbat: teen.shomerShabbat,
    shomerTorahUMitzvos: teen.shomerTorahUMitzvos,
    olamiId: teen.Person.olamiId,
    regionalBoardMember: teen.regionalBoardMember,
    interests: teen.interests ? teen.interests : '',
    youthGroups: teen.youthGroups ? teen.youthGroups : '',
    notes: teen.Person.Notes,
    isJewish: teen.Person.isJewish,
    attendedGapYearDueToNCSY: teen.attendedGapYearDueToNCSY,
    events: [
      ...teen.Registrations.map(r => ({
        balance: r.balance,
        status: r.status,
        registrationID: r.Event && r.Event.registrationRequired ? r.registrationID : null,
        ...(r.Event! || {}),
      })),
      ...teen.Attendances.map(a => ({
        status: a.status,
        ...(a.Event! || {}),
      }))
    ],
    medicines: teen.medicines,
    primaryAdvisor: getAdvisor(teen.Advisors, 'primary'),
    secondaryAdvisor: getAdvisor(teen.Advisors, 'secondary'),
    socialMediaHandles: teen.socialMedia ? JSON.parse(teen.socialMedia) : {},
    college: teen.College ? teen.College : null,
    gapYearProgram: teen.GapYearProgram ? teen.GapYearProgram : null,
    middleSchool: teen.MiddleSchool ? teen.MiddleSchool : null,
    shanaBet: teen.ShanaBet ? teen.ShanaBet : null,
    emergencyContacts: teen.EmergencyContacts || [],
    teudatZehut: teen.Person.teudatZehut,
    aliyahDate: teen.Person.aliyahDate,
    original: teen
  };
}
