import { Module } from 'vuex'
import { v4 as uuid } from 'uuid';
import { RootState } from '..'

export type Notification = {
  id: string
  content: string
  duration: number
}

export type NotificationsState = {
  notifications: Notification[]
}

const module: Module<NotificationsState, RootState> = {
  namespaced: true,
  state: {
    notifications: []
  },
  getters: {
    getNotifications(state) {
      return state.notifications
    },
  },
  mutations: {
    addNotification(state, content: string) {
      state.notifications.push({
        id: uuid(),
        content,
        duration: 5000
      })
    },
    removeNotification(state, id: string) {
      const index = state.notifications.findIndex(n => n.id === id)
      if (index > -1) {
        state.notifications.splice(index, 1)
      }
    }
  }
}

export default module
