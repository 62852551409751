import JFEExport from './JFEExport.vue';
import { Props } from './types';
import { compose } from 'vue-compose';
import Vue, { computed, VueConstructor } from 'vue';
import { normalizeProps, getOptions } from 'shared/util';
import { thisSchoolYear } from '../shared/seasonHelper';
import { useGetJfeTeensByGradeQuery } from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

const withSeasonFilter = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { season, setSeason, ...propsToUse } = props;

  return Vue.extend({
    name: `${Component.name}WithSeasonFilter`,
    props: propsToUse,
    data() {
      const schoolYear = thisSchoolYear();
      return {
        season: {
          schoolYear: +schoolYear.split('-')[1],
          label: schoolYear
        }
      };
    },
    methods: {
      setSeason(season: { schoolYear: number; label: string }) {
        this.season = season;
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          season: this.season,
          setSeason: this.setSeason
        },
        on: this.$listeners
      });
    }
  });
};

const getTeensEnhancer = wrapComponent<
  Pick<Props, 'graduationYear' | 'season'>,
  Pick<Props, 'teens' | 'loading'>
>((props) => {
  const { loading, result } = useGetJfeTeensByGradeQuery(
    computed(() => ({
      graduationYear: Number(props.graduationYear)!,
      filter: {
        season: props.season.label
      }
    })),
    { enabled: computed(() => !!props.graduationYear) }
  );

  return computed(() => ({
    loading: loading.value,
    teens: result.value?.jfeTeens || []
  }));
});

export default compose(withSeasonFilter, getTeensEnhancer)(JFEExport);
