import { render, staticRenderFns } from "./TextStringConfiguration.vue?vue&type=template&id=c046bdee&scoped=true&"
import script from "./TextStringConfiguration.vue?vue&type=script&setup=true&lang=ts&"
export * from "./TextStringConfiguration.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c046bdee",
  null
  
)

export default component.exports