import { defineComponent, h, PropType, VNode } from 'vue';
import get from 'lodash/get';
import without from 'lodash/without';

const CheckboxArray = defineComponent({
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  emits: {
    input: (value: string[]) => true
  },
  setup(props, { emit, slots }) {
    return () => {
      const vNodes: VNode[] = (slots.default && slots.default()) || [];

      return h(
        'div',
        vNodes.map((vNode) => {
          const value = get(vNode, 'componentOptions.propsData.value') as unknown as string;

          if (vNode.componentOptions) {
            vNode.componentOptions.propsData = {
              ...vNode.componentOptions.propsData,
              toggle: () => {
                if ((props.value || []).includes(value)) {
                  emit('input', without(props.value, value));
                } else {
                  emit('input', [...(props.value || []), value]);
                }
              },
              checked: (props.value || []).includes(value)
            };
          }

          return vNode;
        })
      );
    };
  }
});

const CheckboxArrayItem = defineComponent({
  props: {
    toggle: { type: Function, required: true },
    checked: { type: Boolean, required: true },
    value: { type: String, required: true },
    label: { type: String },
    name: { type: String }
  },
  setup(props, { slots }) {
    const maybeWrap = (vnodes: string | VNode[]) =>
      !vnodes || typeof vnodes === 'string' || vnodes.length > 1 ? h('div', vnodes) : vnodes[0];

    const scopedSlot = slots.default;

    if (!scopedSlot) {
      return () => {
        const $input = h('input', {
          attrs: {
            type: 'checkbox',
            name: props.name
          },
          domProps: { checked: props.checked },
          on: { click: props.toggle }
        });

        return h('label', [$input, props.label]);
      };
    }

    return () => {
      const $vnode = scopedSlot({
        props: {
          checked: props.checked,
          name: props.name,
          value: props.checked
        },
        listeners: {
          click: props.toggle
        },
        label: props.label
      });

      return maybeWrap($vnode);
    };
  }
});

export { CheckboxArray, CheckboxArrayItem };
