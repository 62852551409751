import Profile from './Profile.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import currentUser from 'shared/components/CurrentUserEnhancer';
import { StaffCopy } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  CreateFellowInput,
  StaffDocument,
  StaffQuery,
  UpdateStaffInput,
  useAddGlaubachSeasonMutation,
  useUpdateGlaubachStaffMutation
} from 'shared/generated/graphql-types';

interface Props {
  regionId: number;
  addingGlaubachSeason: boolean;
  updateGlaubachStaff: (staff: UpdateStaffInput, staffViewModel: StaffCopy) => void;
  addGlaubachSeason: (staff: CreateFellowInput) => void;
}

const addGlaubachSeasonEnhancer = wrapComponent<
  Props,
  Pick<Props, 'addGlaubachSeason' | 'addingGlaubachSeason'>
>(() => {
  const { loading, mutate } = useAddGlaubachSeasonMutation();

  return computed(() => ({
    addGlaubachSeason: (staff) =>
      mutate(
        { staff },
        {
          optimisticResponse: {
            addGlaubachSeason: {
              __typename: 'GlaubachFellow',
              id: -1,
              community: staff.community || '',
              season: staff.season || '',
              staffId: staff.staffId
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<StaffQuery>({
              query: StaffDocument,
              variables: { staffID: staff.staffId }
            });

            if (data?.singleStaff && updateData?.addGlaubachSeason) {
              const glaubachFellows = data.singleStaff.Staff.GlaubachFellows.slice();
              glaubachFellows.push(updateData.addGlaubachSeason);

              proxy.writeQuery<StaffQuery>({
                query: StaffDocument,
                variables: {
                  staffID: staff.staffId
                },
                data: {
                  singleStaff: {
                    ...data.singleStaff,
                    Staff: {
                      ...data.singleStaff.Staff,
                      GlaubachFellows: glaubachFellows
                    }
                  }
                }
              });
            }
          }
        }
      ),
    addingGlaubachSeason: loading.value
  }));
});

const updateGlaubachStaffEnhancer = wrapComponent<Props, Pick<Props, 'updateGlaubachStaff'>>(() => {
  const { mutate } = useUpdateGlaubachStaffMutation();

  return computed(() => ({
    updateGlaubachStaff: (staff, staffViewModel) =>
      mutate(
        { staff },
        {
          optimisticResponse: {
            updateGlaubachStaff: {
              __typename: 'Staff',
              staffID: staff.staffId,
              firstName: staff.firstName,
              bio: staffViewModel.bio || '',
              lastName: staff.lastName,
              fullName: `${staff.firstName} ${staff.lastName}`,
              gender: staff.gender,
              email: staff.email || null
            }
          }
        }
      )
  }));
});

export default compose(
  updateGlaubachStaffEnhancer,
  addGlaubachSeasonEnhancer,
  currentUser
)(Profile);
