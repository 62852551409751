import { MutateResult } from '@vue/apollo-composable';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  ExtendedTeenProfileFragment,
  ExtendedTeenSideBarFragment,
  MarkFamilyAsPrimaryForTeenProfileMutation,
  MarkFamilyAsPrimaryForTeenSideBarMutation,
  useMarkFamilyAsPrimaryForTeenProfileMutation,
  useMarkFamilyAsPrimaryForTeenSideBarMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface ProvidedPropsForProfile {
  markFamilyAsPrimary: (
    childId: number,
    familyId: number,
    teen: ExtendedTeenProfileFragment
  ) => MutateResult<MarkFamilyAsPrimaryForTeenProfileMutation>;
  markFamilyAsPrimaryRunning: boolean;
}
const markFamilyAsPrimaryEnhancerForProfile = wrapComponent<{}, ProvidedPropsForProfile>(
  (props) => {
    const { loading, mutate } = useMarkFamilyAsPrimaryForTeenProfileMutation();

    return computed(() => ({
      markFamilyAsPrimaryRunning: loading.value,
      markFamilyAsPrimary: (childId, familyId, teen) => {
        return mutate(
          { input: { familyId, childId } },
          {
            optimisticResponse: {
              markFamilyAsPrimaryForTeen: {
                ...teen
              }
            }
          }
        );
      }
    }));
  }
);

interface ProvidedPropsForSideBar {
  markFamilyAsPrimary: (
    childId: number,
    familyId: number,
    teen: ExtendedTeenSideBarFragment
  ) => MutateResult<MarkFamilyAsPrimaryForTeenSideBarMutation>;
  markFamilyAsPrimaryRunning: boolean;
}
const markFamilyAsPrimaryEnhancerForSideBar = wrapComponent<{}, ProvidedPropsForSideBar>(
  (props) => {
    const { mutate, loading } = useMarkFamilyAsPrimaryForTeenSideBarMutation();

    return computed(() => ({
      markFamilyAsPrimaryRunning: loading.value,
      markFamilyAsPrimary: (childId, familyId, teen) => {
        return mutate(
          { input: { familyId, childId } },
          {
            optimisticResponse: {
              markFamilyAsPrimaryForTeen: {
                ...teen
              }
            }
          }
        );
      }
    }));
  }
);

export {
  ProvidedPropsForProfile,
  markFamilyAsPrimaryEnhancerForProfile,
  ProvidedPropsForSideBar,
  markFamilyAsPrimaryEnhancerForSideBar
};
