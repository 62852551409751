import CreateFamilyForm from './CreateFamilyForm.vue';
import { addParentEnhancer } from '../QuickAddParent';
import { Props } from './types';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  useCreateFamilyForTeenMutation,
  useCreateFamilyMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

// I think we get away with out an `update` method for now
const createFamilyForTeenEnhancer = wrapComponent<Props, Pick<Props, 'createFamilyForTeen'>>(() => {
  const { mutate } = useCreateFamilyForTeenMutation();

  return computed(() => ({
    createFamilyForTeen: (input, father, mother) => mutate(input)
  }));
});

const createFamilyEnhancer = wrapComponent<Props, Pick<Props, 'createFamily'>>(() => {
  const { mutate } = useCreateFamilyMutation();

  return computed(() => ({
    createFamily: (input) => mutate(input)
  }));
});

export default compose(
  createFamilyEnhancer,
  createFamilyForTeenEnhancer,
  addParentEnhancer
)(CreateFamilyForm);
