import { PermissionEnum } from "shared/generated/graphql-types";
import { defineComponent, h, PropType } from "vue";

export default defineComponent({
  props: {
    permission: { type: String as PropType<PermissionEnum>},
    claims: { type: Array as PropType<PermissionEnum[]>},
    showEmptyTag: {
      type: Boolean,
      required: false
    },
    tag: {
      type: String,
      required: false
    }
  },
  setup(props, { slots }) {
    return () => {
      if (props.claims && props.claims.find(p => p === props.permission)) {
        return h('div', slots.default && slots.default({}));
      } else if (props.showEmptyTag) {
        return h('div', props.tag || '');
      }
  
      return h('div', 'Forbidden');
    }
  }
})
