var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"month-container"},[(_vm.loading)?_c(_setup.Loading,{attrs:{"lockParent":""}}):_vm._e(),_c('header',{staticClass:"month-view-header"},_vm._l((_setup.weekDays),function(day,i){return _c('div',{key:i,class:{ monthHeaderToday: _setup.headerToday(i) }},[_c('span',{staticClass:"day-name"},[_vm._v(_vm._s(_setup.weekDays[i]))])])}),0),_c('div',{staticClass:"month-view",class:{ 'month-view--ext': _vm.weeks.length > 5 },staticStyle:{"position":"relative"}},[_vm._l((_vm.weeks),function(week,weekIndex){return _vm._l((week),function(day,i){return _c('div',{key:`${weekIndex}_${i}`,staticClass:"day",class:_setup.dayClass(day),style:({ gridArea: _setup.dayLabelPosition(day, week, weekIndex) })},[_c('span',{staticClass:"day-num",on:{"click":function($event){return _setup.selectDay(day)}}},[_vm._v(_vm._s(day.day))])])})}),_vm._l((_setup.eventsByWeek),function([row1, row2, row3, moreEventDays],weekIndex){return [_vm._l((row1),function(event){return [_c(_setup.Event,_vm._b({style:({ gridArea: _setup.getEventPosition(event, weekIndex, 0) }),attrs:{"multiday":_setup.isMultiday(event)}},'Event',{ event },false))]}),_vm._l((row2),function(event){return [_c(_setup.Event,_vm._b({style:({ gridArea: _setup.getEventPosition(event, weekIndex, 1) }),attrs:{"multiday":_setup.isMultiday(event)}},'Event',{ event },false))]}),_vm._l((row3),function(event){return [_c(_setup.Event,_vm._b({style:({ gridArea: _setup.getEventPosition(event, weekIndex, 2) }),attrs:{"multiday":_setup.isMultiday(event)}},'Event',{ event },false))]}),_vm._l((moreEventDays),function(day){return [(
            _setup.eventsOnDay(day, _vm.weeks[weekIndex], row1.concat(row2, row3))
              .length > 0
          )?_c('div',{staticClass:"event event--more past",style:({
            gridArea: _setup.getMoreEventPosition(day, _vm.weeks[weekIndex], weekIndex),
          })},[_c(_setup.Popover,{staticClass:"more-events",attrs:{"width":"190px","alignRight":"","height":250},nativeOn:{"mousewheel":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._v(" "+_vm._s(_setup.eventsOnDay(day, _vm.weeks[weekIndex], row1.concat(row2, row3)) .length)+" More Event"+_vm._s(_setup.eventsOnDay(day, _vm.weeks[weekIndex], row1.concat(row2, row3)) .length > 1 ? "s" : "")+" "),_c('font-awesome-icon',{attrs:{"icon":['far', 'angle-down']}})]},proxy:true}],null,true)},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"list-wrapper"},[_c(_setup.ScrollablePanel,[_c('ul',{staticClass:"more-events__list"},_vm._l((_setup.eventsOnDay(
                        day,
                        _vm.weeks[weekIndex],
                        row1.concat(row2, row3)
                      )),function(event){return _c('li',[_c(_setup.Event,{staticClass:"event-in-menu",attrs:{"event":event,"inMenu":true}})],1)}),0)])],1)])])],1):_vm._e()]})]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }