import Notes from './Notes.vue';
import { computed } from 'vue';
import { compose, withData, acceptProps } from 'vue-compose';
import { Props, Note } from './types';
import { DateTimezone } from 'date-timezone';
import { SimpleDate } from 'shared/util/types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetTeenWithNotesDocument,
  GetTeenWithNotesQuery,
  TeenBaseFragment,
  TeenBaseFragmentDoc,
  TeenInteractionsFragment,
  TeenInteractionsFragmentDoc,
  useAddTeenNoteMutation,
  useDeleteTeenNoteMutation,
  useGetCurrentUserQuery,
  useGetTeenWithNotesQuery,
  useUpdateTeenNoteMutation
} from 'shared/generated/graphql-types';

function convertDate(date: string) {
  const [year, month, day] = date.split('T')[0].split('-').map(Number);

  return { year, month, day };
}

function dateToString(date: SimpleDate) {
  return new DateTimezone(date.year, date.month - 1, date.day, 0, 0).toISOString();
}

type AddTeenNoteProps = Pick<Props, 'addTeenNote'>;

const addTeenNote = wrapComponent<{ personId: number }, AddTeenNoteProps>((props) => {
  const { mutate } = useAddTeenNoteMutation();

  return computed(() => ({
    addTeenNote: (args) =>
      mutate(
        {
          input: {
            staffId: args.staff.staffID,
            category: args.category!.value,
            date: dateToString(args.date),
            content: args.note,
            personId: props.personId
          }
        },
        {
          optimisticResponse: {
            addPersonNote: {
              __typename: 'Note',
              Staff: {
                __typename: 'Staff',
                firstName: args.staff.firstName,
                lastName: args.staff.lastName,
                fullName: `${args.staff.firstName} ${args.staff.lastName}`,
                staffID: args.staff.staffID
              },
              category: args.category!.value,
              date: dateToString(args.date),
              id: -1,
              note: args.note
            }
          },
          update(client, { data }) {
            const teen = client.readFragment<TeenBaseFragment>({
              fragment: TeenBaseFragmentDoc,
              id: `${props.personId}Teen`,
              fragmentName: 'TeenBase'
            });

            if (teen && data) {
              const notes = teen.Person.Notes.slice();
              notes.push({
                ...data.addPersonNote,
                personId: props.personId,
                staffId: data.addPersonNote.Staff.staffID
              });

              client.writeFragment<TeenBaseFragment>({
                fragment: TeenBaseFragmentDoc,
                id: `${props.personId}Teen`,
                fragmentName: 'TeenBase',
                data: {
                  ...teen,
                  Person: {
                    ...teen.Person,
                    Notes: notes
                  }
                }
              });
            }
          }
        }
      )
  }));
});

type UpdateNoteProps = Pick<Props, 'editTeenNote'>;

const updateTeenNote = wrapComponent<{ personId: number }, UpdateNoteProps>((props) => {
  const { mutate } = useUpdateTeenNoteMutation();

  return computed(() => ({
    editTeenNote: (noteId, args) =>
      mutate(
        {
          input: {
            category: args.category!.value,
            date: dateToString(args.date),
            content: args.note,
            personId: props.personId,
            noteId,
            staffId: args.staff.staffID
          }
        },
        {
          optimisticResponse: {
            updatePersonNote: {
              __typename: 'Note',
              Staff: {
                __typename: 'Staff',
                firstName: args.staff.firstName,
                lastName: args.staff.lastName,
                fullName: `${args.staff.firstName} ${args.staff.lastName}`,
                staffID: args.staff.staffID
              },
              category: args.category!.value,
              date: dateToString(args.date),
              id: noteId,
              note: args.note
            }
          },
          update(client, { data }) {
            const teen = client.readFragment<TeenBaseFragment>({
              fragment: TeenBaseFragmentDoc,
              id: `${props.personId}Teen`,
              fragmentName: 'TeenBase'
            });

            if (teen && data) {
              const notes = teen.Person.Notes.map((n) =>
                n.id === data.updatePersonNote.id
                  ? {
                      ...data.updatePersonNote,
                      personId: props.personId,
                      staffId: data.updatePersonNote.Staff.staffID
                    }
                  : n
              );

              client.writeFragment<TeenBaseFragment>({
                fragment: TeenBaseFragmentDoc,
                id: `${props.personId}Teen`,
                fragmentName: 'TeenBase',
                data: {
                  ...teen,
                  Person: {
                    ...teen.Person,
                    Notes: notes
                  }
                }
              });
            }

            const interactionsData = client.readFragment<TeenInteractionsFragment>({
              fragment: TeenInteractionsFragmentDoc,
              id: `${props.personId}Teen`,
              fragmentName: 'TeenInteractions'
            });

            if (interactionsData && data) {
              const interactions = interactionsData.Interactions.map((i) => {
                const notes = i.Notes.slice();
                const updatedNoteIndex = notes.findIndex((n) => n.id === data.updatePersonNote.id);
                if (updatedNoteIndex > -1) {
                  notes[updatedNoteIndex] = data.updatePersonNote;
                  return {
                    ...i,
                    note: notes.map((n) => n.note).join('\n')
                  };
                } else {
                  return i;
                }
              });

              client.writeFragment<TeenInteractionsFragment>({
                fragment: TeenInteractionsFragmentDoc,
                id: `${props.personId}Teen`,
                fragmentName: 'TeenInteractions',
                data: {
                  ...interactionsData,
                  Interactions: interactions
                }
              });
            }
          }
        }
      )
  }));
});

type DeleteTeenNoteProps = Pick<Props, 'removeTeenNote'>;

const deleteTeenNote = wrapComponent<{ personId: number }, DeleteTeenNoteProps>((props) => {
  const { mutate } = useDeleteTeenNoteMutation();

  return computed(() => ({
    removeTeenNote: (id) =>
      mutate(
        {
          noteId: id
        },
        {
          optimisticResponse: {
            deletePersonNote: true
          },
          update(client) {
            const teen = client.readQuery<GetTeenWithNotesQuery>({
              query: GetTeenWithNotesDocument,
              variables: {
                personID: props.personId
              }
            });

            if (teen?.singleTeen) {
              const notes = teen.singleTeen.Person.Notes.slice();
              const index = notes.findIndex((x) => x.id === id);

              notes.splice(index, 1);

              client.writeQuery<GetTeenWithNotesQuery>({
                query: GetTeenWithNotesDocument,
                variables: {
                  personID: props.personId
                },
                data: {
                  singleTeen: {
                    ...teen.singleTeen,
                    Person: {
                      ...teen.singleTeen.Person,
                      Notes: notes
                    }
                  }
                }
              });
            }

            const interactionsData = client.readFragment<TeenInteractionsFragment>({
              fragment: TeenInteractionsFragmentDoc,
              id: `${props.personId}Teen`,
              fragmentName: 'TeenInteractions'
            });

            if (interactionsData?.Interactions) {
              const interactions = interactionsData.Interactions.map((i) => {
                const notes = i.Notes.slice();
                const deletedNoteIndex = notes.findIndex((n) => n.id === id);
                if (deletedNoteIndex > -1) {
                  notes.splice(deletedNoteIndex, 1);
                  return {
                    ...i,
                    note: notes.map((n) => n.note).join('\n')
                  };
                } else {
                  return i;
                }
              });

              client.writeFragment<TeenInteractionsFragment>({
                fragment: TeenInteractionsFragmentDoc,
                id: `${props.personId}Teen`,
                fragmentName: 'TeenInteractions',
                data: {
                  ...interactionsData,
                  Interactions: interactions
                }
              });
            }
          }
        }
      )
  }));
});

type NoteProps = Pick<Props, 'notes' | 'isLoading'>;

const notes = wrapComponent<{ personId: number }, NoteProps>((props) => {
  const { loading, result } = useGetTeenWithNotesQuery(
    computed(() => ({ personID: props.personId }))
  );

  return computed(() => ({
    isLoading: loading.value,
    notes:
      result.value?.singleTeen.Person.Notes.map<Note>((i) => ({
        category: i.category,
        date: convertDate(i.date),
        noteId: i.id,
        note: i.note,
        staff: {
          firstName: i.Staff.firstName,
          lastName: i.Staff.lastName,
          id: i.Staff.staffID,
          fullName: i.Staff.fullName
        }
      })) || []
  }));
});

type CurrentUserProps = Pick<Props, 'currentStaff'>;

const currentStaff = wrapComponent<Props, CurrentUserProps>((props) => {
  const { result } = useGetCurrentUserQuery();

  return computed(() => ({
    currentStaff: result.value?.me
      ? {
          __typename: 'Staff',
          firstName: result.value.me.firstName,
          lastName: result.value.me.lastName,
          fullName: result.value.me.fullName,
          staffID: result.value.me.staffID
        }
      : undefined
  }));
});

const enhancer = compose(
  currentStaff,
  addTeenNote,
  notes,
  updateTeenNote,
  deleteTeenNote,
  acceptProps(['personId']),
  withData({
    addingNewNote: {
      initialValue: false
    }
  })
);

export default enhancer(Notes);
