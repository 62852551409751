import { computed } from 'vue';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  ExtendedTeenProfileFragment,
  ExtendedTeenProfileFragmentDoc,
  useRemoveChildFromFamilyMutation
} from 'shared/generated/graphql-types';

const removeChildFromFamilyEnhancer = wrapComponent<
  Props,
  Pick<Props, 'removeChildFromFamily' | 'removeChildFromFamilyRunning'>
>((props) => {
  const { loading, mutate } = useRemoveChildFromFamilyMutation();

  return computed(() => ({
    removeChildFromFamily: (values, family) => {
      return mutate(
        { ...values },
        {
          optimisticResponse: {
            removeChildFromFamily: {
              ...family,
              Children: []
            }
          },
          update(client, { data }) {
            const extendedTeenProfileData = client.readFragment<ExtendedTeenProfileFragment>({
              id: `${values.input.childId}Teen`,
              fragment: ExtendedTeenProfileFragmentDoc,
              fragmentName: 'ExtendedTeenProfile'
            });
            if (
              extendedTeenProfileData &&
              extendedTeenProfileData.Person.ChildOf &&
              extendedTeenProfileData.Person.ChildOf.length
            ) {
              client.writeFragment<ExtendedTeenProfileFragment>({
                id: `${values.input.childId}Teen`,
                fragment: ExtendedTeenProfileFragmentDoc,
                fragmentName: 'ExtendedTeenProfile',
                data: {
                  ...extendedTeenProfileData,
                  Person: {
                    ...extendedTeenProfileData.Person,
                    ChildOf: extendedTeenProfileData.Person.ChildOf.filter(
                      (f) => f.id !== family.id
                    )
                  }
                }
              });
            }
          }
        }
      );
    },
    removeChildFromFamilyRunning: loading.value
  }));
});

export default removeChildFromFamilyEnhancer;
