interface Config {
	issuer: string;
	client_id: string;
	graphql: string;
  subscriptions: string;
  alumniRegionId: number;
  sentry_dsn: string;
  cloudflareUrl: string;
}

export const devGraphql = 'graphql-ncsy.oudev.org';

const config: { [x: string]: Config } = {
	local: {
		issuer: 'https://ou.okta.com',
		client_id: '0oar8vsx8gwCe6sVb0x7',
		graphql: `http://localhost:3001`,
    subscriptions: `ws://localhost:3001/subscriptions`,
    alumniRegionId: 35,
    sentry_dsn: '',
    cloudflareUrl: 'https://ncsy-assets.oudev.org/ncsy/wysiwyg-images/'
	},
	development: {
		issuer: 'https://ou.okta.com',
		client_id: '0oar8vsx8gwCe6sVb0x7',
		graphql: `https://${devGraphql}`,
		subscriptions: `wss://${devGraphql}/subscriptions`,
    alumniRegionId: 35,
		sentry_dsn: 'https://d920c1accc814df9b83699e02da25bac@sentry.oudev.org/186',
    cloudflareUrl: 'https://ncsy-assets.oudev.org/ncsy/wysiwyg-images/'
	},
	staging: {
		issuer: 'https://ou.okta.com',
		client_id: '0oar8vsx8gwCe6sVb0x7',
		graphql: `https://${devGraphql}`,
		subscriptions: `wss://${devGraphql}/subscriptions`,
    alumniRegionId: 35,
		sentry_dsn: 'https://d920c1accc814df9b83699e02da25bac@sentry.oudev.org/186',
    cloudflareUrl: 'https://ncsy-assets.oudev.org/ncsy/wysiwyg-images/'
	},
	production: {
		issuer: 'https://ou.okta.com',
		client_id: '0oanj1ud7v0uJsvVt0x7',
		graphql: `https://graphql.ncsy.org`,
		subscriptions: `wss://graphql.ncsy.org/subscriptions`,
    alumniRegionId: 35,
		sentry_dsn: 'https://304859c9ea524ad1a596d7bd7c38ccb3@sentry.oustatic.com/95',
    cloudflareUrl: 'https://graphql-assets.ncsy.org/ncsy/wysiwyg-images/'
	}
};

config.test = config.development;

const env = process.env.NODE_ENV || 'development';

export default config[env];
