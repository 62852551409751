
import { defineComponent, getCurrentInstance, h, onMounted, ref } from 'vue';

export default defineComponent({
  setup(props, { slots }) {
    const vm = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>;
    const dimensions = ref<DOMRect | ClientRect | null>(null);

    onMounted(() => {
      dimensions.value = vm.proxy.$el.getBoundingClientRect();
    });

    return () => {
      const $vnodes = slots.default && slots.default({ dimensions: dimensions.value });
      if ($vnodes && $vnodes[0]) {
        return $vnodes[0];
      }
      return h('div');
    };
  }
});
