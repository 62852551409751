import { computed } from 'vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetRegionDataQuery, useGetRegionDataQuery } from 'shared/generated/graphql-types';

type Props = {
  regionId: number;
  region: GetRegionDataQuery['region'] | null;
  regionLoading: boolean;
};

export const getRegionEnhancer = wrapComponent<
  Pick<Props, 'regionId'>,
  Pick<Props, 'region' | 'regionLoading'>
>((props) => {
  const { loading, result } = useGetRegionDataQuery(computed(() => ({ regionId: props.regionId })));

  return computed(() => ({
    regionLoading: loading.value,
    region: result.value?.region || null
  }));
});
