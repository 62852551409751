import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetGoalDocument,
  GetGoalQuery,
  RemoveLeadMutation,
  useRemoveLeadMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { MutateResult } from '@vue/apollo-composable';

export interface Props {
  removeLead: (leadId: number, goalId: number) => MutateResult<RemoveLeadMutation>;
  removingLead: boolean;
}
const mutation = wrapComponent<{}, Pick<Props, 'removeLead' | 'removingLead'>>(() => {
  const { loading, mutate } = useRemoveLeadMutation();

  return computed(() => ({
    removeLead: (leadId, goalId) =>
      mutate(
        { leadId },
        {
          update(proxy, { data: updateData }) {
            let goal: GetGoalQuery | null = null;
            try {
              goal = proxy.readQuery<GetGoalQuery>({
                query: GetGoalDocument,
                variables: { id: goalId }
              });
            } catch (e) {}

            if (updateData?.removeLead && goal?.goal) {
              const leads = (goal.goal.Leads || []).filter((l) => l.id !== leadId);
              proxy.writeQuery<GetGoalQuery>({
                query: GetGoalDocument,
                variables: { id: goalId },
                data: {
                  goal: {
                    ...goal.goal,
                    Leads: leads
                  }
                }
              });
            }
          }
        }
      ),
    removingLead: loading.value
  }));
});

export const enhancer = mutation;
