import Agenda from './Agenda.vue';
import { computed } from 'vue';
import { compose, acceptProps } from 'vue-compose';
import { Props, EventGroup, Event, Holiday } from './types';
import groupBy from 'lodash/groupBy';
import { formatDate } from 'shared/util';
import { SimpleDate } from 'shared/util/types';
import withHolidays from '../../../shared/withHolidays';
import { EventFilterInput, GetAgendaQuery, useGetAgendaQuery } from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

export interface OuterProps {
  regionId: number;
  filters: EventFilterInput;
  date: SimpleDate;
  holidays: Holiday[];
}

type ChildProps = Pick<Props, 'eventGroups' | 'fetching'>;

function localDay(event: Event) {
  return formatDate(event.startDate, event.timeZone, '%Y-%m-%d');
}

function groupEvents(events: Event[], holidays: Holiday[]): EventGroup[] {
  return Object.entries(groupBy(events, localDay))
    .reduce<EventGroup[]>(
      (groups, [date, events]) => [
        ...groups,
        { date, events, holidays: holidays.filter((h) => h.date === date) }
      ],
      []
    )
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
}

function eventProcess(events: GetAgendaQuery['events']): Event[] {
  return events.map((e) => ({
    eventID: e.eventId,
    startDate: e.startDate,
    endDate: e.endDate,
    timeZone: e.TimeZone,
    name: e.eventName,
    chapter: e.chapter ? e.chapter.chapterName : ''
  }));
}

const fetchAgendaEnhancer = wrapComponent<OuterProps, ChildProps>((props) => {
  const { year, month } = props.date;

  const pad = (n: number) => (n < 10 ? `0${n}` : n);

  const { loading, result } = useGetAgendaQuery(
    computed(() => ({
      filter: {
        advisorID: props.filters.advisorID,
        EventSubTypeID: props.filters.EventSubTypeID,
        chapterId: props.filters.chapterId,
        EventType: props.filters.EventType,
        seriesID: props.filters.seriesID,
        trackId: props.filters.trackId ? props.filters.trackId.filter(Boolean) : undefined,
        term: props.filters.term,
        regionWide: props.filters.regionWide,
        regionId: props.regionId,
        range: {
          startDate: `${year}-${pad(month)}-01`,
          endDate: `${month < 12 ? year : year + 1}-${pad(month < 12 ? month + 1 : 1)}-01`
        }
      }
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    eventGroups: groupEvents(eventProcess(result.value?.events || []), props.holidays || []),
    fetching: loading.value
  }));
});

export const enhancer = compose(
  withHolidays,
  fetchAgendaEnhancer,
  compose(acceptProps(['regionId', 'filters', 'date']))
);

export default enhancer(Agenda);
