import ScholarshipHistory from './ScholarshipHistory.vue';
import { compose } from 'vue-compose';
import { TChildProps } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetScholarshipHistoryQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface Props {
  personID: number;
}

const getScholarshipHistoryEnhancer = wrapComponent<Props, TChildProps>((props) => {
  const { loading, result } = useGetScholarshipHistoryQuery(
    computed(() => ({ personID: props.personID }))
  );

  return computed(() => ({
    isLoading: loading.value,
    scholarshipHistory: (result.value?.singleTeen.Registrations || [])
      .filter((r) => r.scholarshipRequest && r.scholarshipRequest > 0)
      .map((r) => ({
        registrationDate: r.registrationDate,
        eventName: r.Event && r.Event.eventName,
        scholarshipRequest: r.scholarshipRequest,
        scholarshipGrant: r.scholarshipGrant,
        balance: r.balance
      }))
  }));
});

export default compose(getScholarshipHistoryEnhancer)(ScholarshipHistory);
