import cloneDeep from 'lodash/cloneDeep';
import { sourceString } from '../shared/Participant';
import { Props } from './types';
import {
  AddToBusMutation,
  GetBusesForEventDocument,
  GetBusesForEventQuery,
  useAddToBusMutation
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { computed } from 'vue';

type Registration = Extract<AddToBusMutation['addToBus'], { registrationID: number }>;
type EventGuest = Extract<AddToBusMutation['addToBus'], { eventGuestId: number }>;
type EventStaff = Extract<AddToBusMutation['addToBus'], { eventStaffId: number }>;

function getTypeNameFromType(type: sourceString) {
  switch (type) {
    case 'registration':
      return 'Registration';
    case 'eventguests':
      return 'EventGuest';
    case 'eventstaff':
      return 'EventStaff';
  }
}

export default wrapComponent<Props, Pick<Props, 'addToBus'>>((props) => {
  const { mutate } = useAddToBusMutation();

  return computed(() => ({
    addToBus: (id, bus, type, p) =>
      mutate(
        { id, busId: bus.busID, type },
        {
          optimisticResponse: {
            addToBus: {
              ...p.model,
              __typename: getTypeNameFromType(type as sourceString),
              ToBus: bus.directionFlag === 'T' || bus.directionFlag === 'B' ? bus : p.model.ToBus,
              FromBus:
                bus.directionFlag === 'R' || bus.directionFlag === 'B' ? bus : p.model.FromBus
            } as AddToBusMutation['addToBus']
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetBusesForEventQuery>({
              query: GetBusesForEventDocument,
              variables: { eventId: props.event!.eventId }
            });

            if (data?.event && updateData?.addToBus) {
              const event = cloneDeep(data.event);
              const busToInsertInIndex = event.Buses.findIndex((b) => b.busID === bus.busID);
              if (bus.directionFlag === 'R') {
                let busToMoveFrom = event.Buses.findIndex((h) =>
                  h.ReturnFromEventRegistrations.map((x) => x.registrationID).includes(
                    (updateData.addToBus as Registration).registrationID
                  )
                );

                if (!busToMoveFrom) {
                  busToMoveFrom = event.Buses.findIndex((h) =>
                    h.ReturnFromEventEventGuests.map((x) => x.eventGuestId).includes(
                      (updateData.addToBus as EventGuest).eventGuestId
                    )
                  );
                }

                if (!busToMoveFrom) {
                  busToMoveFrom = event.Buses.findIndex((h) =>
                    h.ReturnFromEventEventStaff.map((x) => x.eventStaffId).includes(
                      (updateData.addToBus as EventStaff).eventStaffId
                    )
                  );
                }
                switch (updateData.addToBus.__typename) {
                  case 'Registration':
                    event.Buses[busToInsertInIndex].ReturnFromEventRegistrations.push(
                      updateData.addToBus
                    );
                    if (busToMoveFrom !== -1) {
                      const i = event.Buses[busToMoveFrom].ReturnFromEventRegistrations.findIndex(
                        (x) =>
                          x.registrationID ===
                          (updateData.addToBus as Registration).registrationID
                      );
                      event.Buses[busToMoveFrom].ReturnFromEventRegistrations.splice(i, 1);
                    }
                    break;
                  case 'EventGuest':
                    event.Buses[busToInsertInIndex].ReturnFromEventEventGuests.push(
                      updateData.addToBus
                    );
                    if (busToMoveFrom !== -1) {
                      const i = event.Buses[busToMoveFrom].ReturnFromEventEventGuests.findIndex(
                        (x) =>
                          x.eventGuestId ===
                          (updateData.addToBus as EventGuest).eventGuestId
                      );
                      event.Buses[busToMoveFrom].ReturnFromEventEventGuests.splice(i, 1);
                    }
                    break;
                  case 'EventStaff':
                    event.Buses[busToInsertInIndex].ReturnFromEventEventStaff.push(
                      updateData.addToBus
                    );
                    if (busToMoveFrom !== -1) {
                      const i = event.Buses[busToMoveFrom].ReturnFromEventEventStaff.findIndex(
                        (x) =>
                          x.eventStaffId ===
                          (updateData.addToBus as EventStaff).eventStaffId
                      );
                      event.Buses[busToMoveFrom].ReturnFromEventEventStaff.splice(i, 1);
                    }
                    break;
                }
              } else if (bus.directionFlag === 'T') {
                let busToMoveFrom = event.Buses.findIndex((h) =>
                  h.TravelToEventRegistrations.map((x) => x.registrationID).includes(
                    (updateData.addToBus as Registration).registrationID
                  )
                );

                if (!busToMoveFrom) {
                  busToMoveFrom = event.Buses.findIndex((h) =>
                    h.TravelToEventEventGuests.map((x) => x.eventGuestId).includes(
                      (updateData.addToBus as EventGuest).eventGuestId
                    )
                  );
                }

                if (!busToMoveFrom) {
                  busToMoveFrom = event.Buses.findIndex((h) =>
                    h.TravelToEventEventStaff.map((x) => x.eventStaffId).includes(
                      (updateData.addToBus as EventStaff).eventStaffId
                    )
                  );
                }
                switch (updateData.addToBus.__typename) {
                  case 'Registration':
                    event.Buses[busToInsertInIndex].TravelToEventRegistrations.push(
                      updateData.addToBus
                    );
                    if (busToMoveFrom !== -1) {
                      const i = event.Buses[busToMoveFrom].TravelToEventRegistrations.findIndex(
                        (x) =>
                          x.registrationID ===
                          (updateData.addToBus as Registration).registrationID
                      );
                      event.Buses[busToMoveFrom].TravelToEventRegistrations.splice(i, 1);
                    }
                    break;
                  case 'EventGuest':
                    event.Buses[busToInsertInIndex].TravelToEventEventGuests.push(
                      updateData.addToBus
                    );
                    if (busToMoveFrom !== -1) {
                      const i = event.Buses[busToMoveFrom].TravelToEventEventGuests.findIndex(
                        (x) =>
                          x.eventGuestId ===
                          (updateData.addToBus as EventGuest).eventGuestId
                      );
                      event.Buses[busToMoveFrom].TravelToEventEventGuests.splice(i, 1);
                    }
                    break;
                  case 'EventStaff':
                    event.Buses[busToInsertInIndex].TravelToEventEventStaff.push(
                      updateData.addToBus
                    );
                    if (busToMoveFrom !== -1) {
                      const i = event.Buses[busToMoveFrom].TravelToEventEventStaff.findIndex(
                        (x) =>
                          x.eventStaffId ===
                          (updateData.addToBus as EventStaff).eventStaffId
                      );
                      event.Buses[busToMoveFrom].TravelToEventEventStaff.splice(i, 1);
                    }
                    break;
                }
              } else if (bus.directionFlag === 'B') {
                let returnBusToMoveFrom = event.Buses.findIndex((h) =>
                  h.ReturnFromEventRegistrations.map((x) => x.registrationID).includes(
                    (updateData.addToBus as Registration).registrationID
                  )
                );

                if (!returnBusToMoveFrom) {
                  returnBusToMoveFrom = event.Buses.findIndex((h) =>
                    h.ReturnFromEventEventGuests.map((x) => x.eventGuestId).includes(
                      (updateData.addToBus as EventGuest).eventGuestId
                    )
                  );
                }

                if (!returnBusToMoveFrom) {
                  returnBusToMoveFrom = event.Buses.findIndex((h) =>
                    h.ReturnFromEventEventStaff.map((x) => x.eventStaffId).includes(
                      (updateData.addToBus as EventStaff).eventStaffId
                    )
                  );
                }

                let toBusToMoveFrom = event.Buses.findIndex((h) =>
                  h.TravelToEventRegistrations.map((x) => x.registrationID).includes(
                    (updateData.addToBus as Registration).registrationID
                  )
                );

                if (!toBusToMoveFrom) {
                  toBusToMoveFrom = event.Buses.findIndex((h) =>
                    h.TravelToEventEventGuests.map((x) => x.eventGuestId).includes(
                      (updateData.addToBus as EventGuest).eventGuestId
                    )
                  );
                }

                if (!toBusToMoveFrom) {
                  toBusToMoveFrom = event.Buses.findIndex((h) =>
                    h.TravelToEventEventStaff.map((x) => x.eventStaffId).includes(
                      (updateData.addToBus as EventStaff).eventStaffId
                    )
                  );
                }
                switch (updateData.addToBus.__typename) {
                  case 'Registration':
                    event.Buses[busToInsertInIndex].TravelToEventRegistrations.push(
                      updateData.addToBus
                    );
                    event.Buses[busToInsertInIndex].ReturnFromEventRegistrations.push(
                      updateData.addToBus
                    );
                    if (toBusToMoveFrom !== -1) {
                      const i = event.Buses[toBusToMoveFrom].TravelToEventRegistrations.findIndex(
                        (x) =>
                          x.registrationID ===
                          (updateData.addToBus as Registration).registrationID
                      );
                      event.Buses[toBusToMoveFrom].TravelToEventRegistrations.splice(i, 1);
                    }
                    if (returnBusToMoveFrom !== -1) {
                      const i = event.Buses[
                        returnBusToMoveFrom
                      ].ReturnFromEventRegistrations.findIndex(
                        (x) =>
                          x.registrationID ===
                          (updateData.addToBus as Registration).registrationID
                      );
                      event.Buses[returnBusToMoveFrom].ReturnFromEventRegistrations.splice(i, 1);
                    }
                    break;
                  case 'EventGuest':
                    event.Buses[busToInsertInIndex].TravelToEventEventGuests.push(
                      updateData.addToBus
                    );
                    event.Buses[busToInsertInIndex].ReturnFromEventEventGuests.push(
                      updateData.addToBus
                    );
                    if (toBusToMoveFrom !== -1) {
                      const i = event.Buses[toBusToMoveFrom].TravelToEventEventGuests.findIndex(
                        (x) =>
                          x.eventGuestId ===
                          (updateData.addToBus as EventGuest).eventGuestId
                      );
                      event.Buses[toBusToMoveFrom].TravelToEventEventGuests.splice(i, 1);
                    }
                    if (returnBusToMoveFrom !== -1) {
                      const i = event.Buses[
                        returnBusToMoveFrom
                      ].ReturnFromEventEventGuests.findIndex(
                        (x) =>
                          x.eventGuestId ===
                          (updateData.addToBus as EventGuest).eventGuestId
                      );
                      event.Buses[returnBusToMoveFrom].ReturnFromEventEventGuests.splice(i, 1);
                    }
                    break;
                  case 'EventStaff':
                    event.Buses[busToInsertInIndex].TravelToEventEventStaff.push(
                      updateData.addToBus
                    );
                    event.Buses[busToInsertInIndex].ReturnFromEventEventStaff.push(
                      updateData.addToBus
                    );
                    if (toBusToMoveFrom !== -1) {
                      const i = event.Buses[toBusToMoveFrom].TravelToEventEventStaff.findIndex(
                        (x) =>
                          x.eventStaffId ===
                          (updateData.addToBus as EventStaff).eventStaffId
                      );
                      event.Buses[toBusToMoveFrom].TravelToEventEventStaff.splice(i, 1);
                    }
                    if (returnBusToMoveFrom !== -1) {
                      const i = event.Buses[
                        returnBusToMoveFrom
                      ].ReturnFromEventEventStaff.findIndex(
                        (x) =>
                          x.eventStaffId ===
                          (updateData.addToBus as EventStaff).eventStaffId
                      );
                      event.Buses[returnBusToMoveFrom].ReturnFromEventEventStaff.splice(i, 1);
                    }
                    break;
                }
              }

              event.Buses[busToInsertInIndex].passengerCount++;

              proxy.writeQuery<GetBusesForEventQuery>({
                query: GetBusesForEventDocument,
                variables: { eventId: props.event!.eventId },
                data: {
                  event
                }
              });
            }
          }
        }
      )
  }));
});
