import { computed } from 'vue';
import RegionLookup from './RegionLookup.vue';
import orderBy from 'lodash/orderBy';
import { GetRegionsQuery, useGetRegionsQuery } from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

interface TChildProps {
  regions: GetRegionsQuery['regions'];
  loading: boolean;
}

export default wrapComponent<{}, TChildProps>(() => {
  const { loading, result } = useGetRegionsQuery();

  return computed(() => ({
    loading: loading.value,
    regions: result.value?.regions ? orderBy(result.value.regions, ['regionName'], ['asc']) : []
  }));
})(RegionLookup);
