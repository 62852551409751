import { computed } from 'vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetEmailHistoryQuery, useGetEmailHistoryQuery } from 'shared/generated/graphql-types';
import EmailHistory from './EmailHistory.vue';

interface Props {
  communityMember: {
    email: string | null;
  };
}

interface ChildProps {
  data: {
    emailHistory: GetEmailHistoryQuery['emailHistory'];
  };
}

export default wrapComponent<Props, ChildProps>((props) => {
  const { result } = useGetEmailHistoryQuery(
    computed(() => ({
      toAddress: props.communityMember.email ? props.communityMember.email : ''
    }))
  );

  return computed(() => ({
    data: {
      emailHistory: result.value?.emailHistory || []
    }
  }));
})(EmailHistory);
