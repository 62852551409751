import AddToFamily from './AddToFamily.vue';
import { Props } from './types';
import { compose } from 'vue-compose';
import addToFamilyEnhancer from "shared/components/AddToFamilyEnhancer";
import { wrapComponent } from 'shared/apollo-hoc';
import { useCreateFamilyForTeenMutation } from 'shared/generated/graphql-types';
import { computed } from 'vue';

// I think we get away with out an `update` method for now
const createFamilyEnhancer = wrapComponent<Props, Pick<Props, 'createFamily'>>(() => {
  const { mutate } = useCreateFamilyForTeenMutation()

  return computed(() => ({
    createFamily: (input, father, mother) => mutate(input)
  }))
})

export default compose(
  createFamilyEnhancer,
  addToFamilyEnhancer
)(AddToFamily);
