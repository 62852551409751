import { wrapComponent } from 'shared/apollo-hoc';
import { useUpdateOrCreateMetaDataMutation } from 'shared/generated/graphql-types';
import { computed } from 'vue';
import MetaData from './MetaData.vue';
import { Props } from './types';

type ChildProps = Pick<Props, 'mutate'>;

export default wrapComponent<{}, ChildProps>(() => {
  const { mutate } = useUpdateOrCreateMetaDataMutation();

  return computed(() => ({ mutate }));
})(MetaData);
