export const nonSelectableEventSubTypes = [
  'JSU',
  'Latte and Learning',
  'Shabbaton',
  'Shabbat',
  'Holiday',
  'Yarchei Kallah',
  'Leadership Boot Camp',
  'Summer Programs'
];
