
import axios from 'axios';
import trim from 'lodash/trim';
import addressit from 'addressit';
import parser from 'parse-address';
import { defineComponent, h, PropType, ref } from 'vue';
import Countries from 'countries-list';
import { UpdateTeenMutation } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Address = ArrayElement<UpdateTeenMutation['updateTeen']['Person']['Addresses']>;

const commonCountries = {
  US: Countries.countries.US,
  CA: Countries.countries.CA,
  IL: Countries.countries.IL,
  CL: Countries.countries.CL,
  AR: Countries.countries.AR
};
const restOfCountries = {
  AU: Countries.countries.AU,
  BR: Countries.countries.BR,
  CH: Countries.countries.CH,
  CO: Countries.countries.CO,
  DE: Countries.countries.DE,
  DO: Countries.countries.DO,
  FR: Countries.countries.FR,
  GB: Countries.countries.GB,
  HU: Countries.countries.HU,
  MX: Countries.countries.MX,
  PL: Countries.countries.PL,
  PR: Countries.countries.PR,
  SG: Countries.countries.SG
};
const common = Object.entries(commonCountries);
const rest = Object.entries(restOfCountries);
const countries = common.concat(rest).map((k) => ({ code: k[0], name: k[1].name }));

const parseIntlAddress = (address: string, country: string | null) => {
  const attempt = addressit(address);

  return {
    street: attempt.extractStreet().street,
    city: attempt.regions[0],
    country: country
  };
};

const formatAddress = (address: Partial<Address>) =>
  trim(
    `${trim(`${address.street || ''} ${address.street2 || ''}`)} ${address.city || ''} ${
      address.state ? `, ${address.state || ''}` : ''
    } ${address.zipCode || ''}`
  );

const parseUSAddress = (address: string) => {
  const {
    city,
    zip,
    state,
    number = '',
    prefix = '',
    suffix = '',
    street,
    sec_unit_type: secUnitType = '',
    sec_unit_num: secUnitNum = '',
    type = ''
  } = parser.parseLocation(address);

  return {
    city,
    zipCode: zip,
    state: state,
    street: `${number} ${trim(`${prefix} ${street} ${suffix}`)} ${trim(
      `${secUnitType} ${secUnitNum}`
    )} ${type}`,
    country: 'US'
  };
};

const unverified = (response: any) => {
  return (
    response.raw[0].analysis &&
    !['Verified', 'Ambiguous'].includes(response.raw[0].analysis.verification_status)
  );
};

export default defineComponent({
  props: {
    value: { type: Object as PropType<Address> },
    defaultCountry: { type: String as PropType<string | null> }
  },
  emits: {
    input: (value: Partial<Address>) => true,
    blur: (value: Partial<Address>) => true
  },
  setup(props, { emit, slots }) {
    const country = ref<string | null>(
      (props.value && props.value.country) || props.defaultCountry || null
    );
    const address = ref<string>(formatAddress(props.value || ({} as Address)));
    const verifying = ref(false);

    async function lookupAddress(countryArg: string | null, addressArg: string) {
      if (!countryArg || !addressArg) return;

      verifying.value = true;
      const response = (
        await axios.get(
          `https://address-api.schoolsapi.oustatic.com?country=${countryArg}&street=${encodeURIComponent(
            addressArg
          )}`
        )
      ).data;

      verifying.value = false;

      let result: Partial<Address>;

      if (!response.result || (country.value !== 'US' && unverified(response))) {
        if (country.value === 'US') {
          result = parseUSAddress(address.value);
        } else if (response.success !== undefined && response.success === false) {
          result = parseIntlAddress(address.value, country.value);
        } else {
          result = {
            street: response.result.street,
            street2: response.result.street2,
            city: response.result.city,
            zipCode: response.result.zipCode,
            country: country.value
          };
        }
        result = { id: (props.value || {}).id, ...result, verified: false };
        emit('input', result);
        emit('blur', result);

        address.value = formatAddress(result);

        return;
      }

      result = {
        street: response.result.street,
        street2: response.result.street2,
        city: response.result.city,
        state: response.result.state,
        zipCode: response.result.zipCode,
        country: country.value,
        verified: true,
        id: (props.value || {}).id
      };

      emit('input', result);
      emit('blur', result);
      address.value = formatAddress(result);
    }

    return () => {
      return h('div', [
        slots.default &&
          slots.default({
            countries,
            setCountry: (value: string | null) => {
              country.value = value;
            },
            setAddress: (value: string) => {
              address.value = value;
            },
            country: country.value,
            address: address.value,
            lookupAddress: () => lookupAddress(country.value, address.value),
            verifying: verifying.value
          })
      ]);
    };
  }
});
