var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loadingHouses)?_c(_setup.Loading):_c(_setup.PanelsGrid,[_c(_setup.Sidebar,{attrs:{"housesLength":_setup.filteredHouses.length,"gridView":_vm.gridView,"gender":_vm.gender,"status":_vm.status,"addingNewHouse":_vm.addingNewHouse,"search":_vm.term,"stats":_setup.stats},on:{"update:search":function($event){return _setup.emit('term', $event)},"update:gender":function($event){return _setup.emit('gender', $event)},"update:status":function($event){return _setup.emit('status', $event)},"update:gridView":function($event){return _setup.emit('gridView', $event)},"update:addingNewHouse":function($event){return _setup.emit('addingNewHouse', $event)}}}),_c(_setup.ScrollablePanel,{directives:[{name:"fullheight",rawName:"v-fullheight"}]},[(_setup.filteredHouses.length == 0 && !_vm.addingNewHouse)?_c('div',{staticClass:"no-house"},[(_vm.houses.length > 0)?_c('div',[_vm._v("No Houses matched your search")]):[_c('img',{attrs:{"src":"https://res.cloudinary.com/ncsy/image/upload/v1518432353/drop.jpg","alt":"Nope"}}),_vm._v(" No accomodation has been created for the event"),_c('br'),_vm._v(" 'Add a New House' to get started ")]],2):_c('div',{staticClass:"housing",class:_vm.gridView ? 'housing__grid' : 'housing__list'},[_c(_setup.Modal,{attrs:{"show":_vm.addingNewHouse,"width":"600px"}},[[_c(_setup.AddHouseForm,_vm._g(_vm._b({attrs:{"data-test":"addNewHouseForm"}},'AddHouseForm',{ eventId: _vm.eventId, addNewHouse: _vm.addNewHouse, addHotel: _vm.addHotel, eventStaff: _setup.eventStaff },false),_vm.$listeners))]],2),_vm._l((_setup.filteredHouses),function(house){return _c(_setup.InView,{key:house.houseId,scopedSlots:_vm._u([{key:"default",fn:function({ inView }){return [_c(_setup.HouseDisplay,_vm._b({key:house.houseId,staticClass:"house"},'HouseDisplay',{
              inView,
              house,
              potentialHousees: _setup.potentialHousees,
              gridView: _vm.gridView,
              eventId: _vm.eventId,
              setHouse: _vm.setHouse,
              unsetHouse: _vm.unsetHouse,
              editHouse: _vm.editHouse,
              editHouseNotes: _vm.editHouseNotes,
              updateHouseRequests: _vm.updateHouseRequests,
              removeHouse: _vm.removeHouse,
              eventStaff: _setup.eventStaff
            },false))]}}],null,true)})})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }