import { computed } from 'vue';
import { compose, withData, withProps } from 'vue-compose';
import { getCurrentSeason } from 'shared/util';
import SeriesLookup from './SeriesLookup.vue';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import { SeriesQuery, useSeriesQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

export type Series = ArrayElement<SeriesQuery['series']>;

type Props = {
  term: string;
  regionId: number;
  series: Series[];
  eventSubTypeId: number;
  eventSubTypeDescription: string;
  season: string;
  loading: boolean;
  setTerm: (term: string) => void;
  clearTerm: () => void;
  value: number | Series;
};

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const getSeriesEnhancer = wrapComponent<Props, Pick<Props, 'loading' | 'series'>>((props) => {
  const { loading, result } = useSeriesQuery(
    computed(() => ({
      regionId: props.regionId,
      term: props.term,
      season: props.season ? props.season : getCurrentSeason(),
      eventSubTypeId: props.eventSubTypeId
    })),
    { enabled: computed(() => !!props.term) }
  );

  return computed(() => ({
    loading: loading.value,
    series: result.value?.series || []
  }));
});

export default compose(
  withData({
    term: {
      initialValue: null
    }
  }),
  withProps((props: { regionId: number | null }) => ({
    regionId: props.regionId || getCurrentRegion()
  })),
  getSeriesEnhancer
)(SeriesLookup);
