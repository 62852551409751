
import { defineComponent } from 'vue';
import { Props } from './types';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';

export default defineComponent({
  name: 'FeatureRequests',
  components: {
    ScrollablePanel
  },
  props: {
    staff: {
      type: Object as () => Props['staff']
    }
  }
});
