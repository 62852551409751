import autocomplete from '../autocomplete';
import { compose, acceptProps } from 'vue-compose';
import PostHighSchoolLookup from './PostHighSchoolLookup.vue';
import {
  GetSchoolsQuery,
  PrimaryInstitutionType,
  useGetSchoolsQuery
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { computed } from 'vue';

interface Results {
  schools: GetSchoolsQuery['postHighSchoolLookup'];
}

const PostHighSchoolLookupAutoComplete = autocomplete(
  wrapComponent<{ type: PrimaryInstitutionType; term: string }, Results>((props) => {
    const variables = computed(() => ({
      type: props.type,
      term: props.term
    }));
    const { result, loading } = useGetSchoolsQuery(variables);

    return computed(() => ({
      loading: loading.value,
      schools: result.value?.postHighSchoolLookup || []
    }));
  })
)(PostHighSchoolLookup);

export default compose(acceptProps(['debounce']))(PostHighSchoolLookupAutoComplete);
