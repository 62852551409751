import { ServerSideFilterOption } from "shared/components/Filters/FilterOption";
import { SortBy, SortType } from "shared/generated/graphql-types";

export interface ActiveFiltersType {
	filters: { [key: string]: ServerSideFilterOption[] };
	query: string;
  sortBy: SortBy;
  advisorId: number | null;
}

export default class ActiveFilters {
  filters: { [key: string]: ServerSideFilterOption[] } = {};
  advisorId?: number | null = null
	query: string = '';
  sortBy: SortBy = { name: SortType.Name, ascending: false };

  constructor(args: Partial<ActiveFiltersType> = {}) {
    this.filters = args && args.filters || this.filters;
    this.query = args && args.query || this.query;
    this.sortBy = args && args.sortBy || this.sortBy;
    this.advisorId = args && args.advisorId || this.advisorId;
  }
}

