import { render, staticRenderFns } from "./MissingSchools.vue?vue&type=template&id=4f73e313&scoped=true&"
import script from "./MissingSchools.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MissingSchools.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MissingSchools.vue?vue&type=style&index=0&id=4f73e313&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f73e313",
  null
  
)

export default component.exports