import { ServerSideFilterOption } from "shared/components/Filters/FilterOption";
import has from "lodash/has";
import { getCurrentSeason } from "shared/util";
import { SortBy, SortType } from "shared/generated/graphql-types";

const currentSeason = Number(getCurrentSeason().split('-')[0]);
export interface ActiveFiltersType {
	filters: { [key: string]: ServerSideFilterOption[] };
	query: string;
  sortBy: SortBy;
  isAlumni: boolean;
  missingCollege: boolean;
  missingIsraelProgram: boolean;
  eventImpact: boolean;
  season: string;
}

export default class ActiveFilters {
	filters: { [key: string]: ServerSideFilterOption[] } = {};
	query: string = '';
  sortBy: SortBy = { name: SortType.Name, ascending: false };
  isAlumni: boolean = true;
  missingCollege: boolean = false;
  missingIsraelProgram: boolean = false;
  eventImpact: boolean = false;
  season: string = `${currentSeason}-${currentSeason + 1}`;

  constructor(args: Partial<ActiveFiltersType> = {}) {
    this.filters = args && args.filters || this.filters;
    this.query = args && args.query || this.query;
    this.sortBy = args && args.sortBy || this.sortBy;
    this.isAlumni = args && args.isAlumni || this.isAlumni;
    this.missingCollege = args && args.missingCollege || this.missingCollege;
    this.missingIsraelProgram = args && args.missingIsraelProgram || this.missingIsraelProgram;
    this.eventImpact = has(args, 'eventImpact') ? !!args.eventImpact : this.eventImpact;
    this.season = args && args.season || this.season;
  }
}

