import { computed } from 'vue';
import { compose } from 'vue-compose';
import CustomQuestionsCollectionItem from './CustomQuestionsCollectionItem.vue';
import { Props } from './types';
import { Question } from './components/Question/types';
import {
  GetCustomQuestionsCollectionQuery,
  useDeleteCustomQuestionsCollectionMutation,
  useDeleteQuestionMutation,
  useGetCustomQuestionsCollectionQuery,
  useSaveQuestionMutation
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { wrapComponent } from 'shared/apollo-hoc';

type QuestionResponse = ArrayElement<
  NonNullable<GetCustomQuestionsCollectionQuery['customQuestionsCollection']>['customQuestions']
>;

const mapFields = (x: QuestionResponse) => ({
  ...x,
  isMultiSelect: x.isMultiSelect || null,
  maxLength: x.maxLength || null,
  minLength: x.minLength || null,
  options: x.options || [],
  isSaved: true
});

type CustomQuestionsProps = {
  loadingCustomQuestionsCollection: boolean;
  eventName?: string | null;
  name?: string;
  timestamp?: string;
  questions: Question[];
};
const getCustomQuestionsCollectionEnhancer = wrapComponent<Props, CustomQuestionsProps>((props) => {
  const { loading, result } = useGetCustomQuestionsCollectionQuery(
    computed(() => ({ id: props.customQuestionsCollectionId })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => {
    const customQuestionsCollection = result.value?.customQuestionsCollection;

    return {
      loadingCustomQuestionsCollection: loading.value,
      eventName: customQuestionsCollection?.eventName || undefined,
      name: customQuestionsCollection?.collectionName || undefined,
      timestamp: customQuestionsCollection?.timestamp || undefined,
      questions: (customQuestionsCollection?.customQuestions || []).map(mapFields),
      questionsAmount: customQuestionsCollection?.questionsAmount || 0
    };
  });
});

const saveQuestionEnhancer = wrapComponent<Props, Pick<Props, 'saveQuestion' | 'savingQuestion'>>(
  () => {
    const { loading, mutate } = useSaveQuestionMutation();

    return computed(() => ({
      async saveQuestion(question, customQuestionsCollectionId) {
        const { order, type, text, required, minLength, maxLength, options, isMultiSelect, customQuestionId } = question;
        return await mutate(
          { input: { customQuestionId, customQuestionsCollectionId, order, type, text, required, minLength, maxLength, options: options, isMultiSelect } },
          {
            optimisticResponse: {
              saveQuestion: {
                __typename: 'Question',
                order,
                type,
                text,
                required,
                customQuestionId: 0,
                timestamp: new Date(),
                minLength,
                maxLength,
                options,
                isMultiSelect
              }
            }
          }
        ).then((result) => {
          const question = result?.data?.saveQuestion;
          if (!question) {
            throw new Error('No question');
          }
          return mapFields(question);
        });
      },
      savingQuestion: loading.value
    }));
  }
);

const deleteQuestionEnhancer = wrapComponent<
  Props,
  Pick<Props, 'deleteQuestion' | 'deletingQuestion'>
>(() => {
  const { loading, mutate } = useDeleteQuestionMutation();

  return computed(() => ({
    deleteQuestion: async (questionId) => await mutate({ id: questionId }),
    deletingQuestion: loading.value
  }));
});

const deleteCustomQuestionsCollectionEnhancer = wrapComponent<
  Props,
  Pick<Props, 'removeCustomQuestionsCollection'>
>((props) => {
  const { mutate } = useDeleteCustomQuestionsCollectionMutation();
  return computed(() => ({
    removeCustomQuestionsCollection: async (id) =>
      await mutate(
        { id },
        {
          update() {
            props.router.history.push(`${props.path}`);
          }
        }
      )
  }));
});

export default compose(
  getCustomQuestionsCollectionEnhancer,
  saveQuestionEnhancer,
  deleteQuestionEnhancer,
  deleteCustomQuestionsCollectionEnhancer
)(CustomQuestionsCollectionItem);
