import { render, staticRenderFns } from "./SlotedTable.vue?vue&type=template&id=a728d5b0&scoped=true&"
var script = {}
import style0 from "./SlotedTable.vue?vue&type=style&index=0&id=a728d5b0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a728d5b0",
  null
  
)

export default component.exports