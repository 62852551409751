import { computed } from 'vue';
import Single from './Single.vue';
import { compose, withProps } from 'vue-compose';
import { CompactTeen } from './shared/CompactTeen';
import { toCompactTeen } from './utils';
import currentUser from 'shared/components/CurrentUserEnhancer';
import filter from 'lodash/filter';
import { Tabs } from './shared/types';
import { useSingleTeenQuery } from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { RouterType } from 'shared/components/router';

function enumToList(_enum: object) {
  return Object.entries(_enum).reduce(
    (collection, [key, value]) => ({ ...collection, [key]: value }),
    {}
  );
}
const getCurrentTab = (url = '') => {
  const optionalTabs: { [key: string]: string } = enumToList(Tabs);
  const currentTab = filter(url.split('/'), Boolean)[2] || Tabs.profile;
  return optionalTabs[currentTab] || Tabs.profile;
};

interface Props {
  personId: number;
  regionId: number | null;
  router: RouterType;
}

interface SingleTeenProps {
  teen?: CompactTeen | null;
  loading: boolean;
}
const singleTeen = wrapComponent<Props, SingleTeenProps>((props) => {
  const { loading, result } = useSingleTeenQuery(
    computed(() => ({ personID: props.personId, regionId: props.regionId }))
  );

  return computed(() => ({
    teen: result.value?.singleTeen ? toCompactTeen(result.value.singleTeen) : null,
    loading: loading.value
  }));
});

export default compose(
  withProps<any, any>((props: Props) => ({
    currentTab: getCurrentTab(props.router.location.pathname)
  })),
  currentUser,
  singleTeen,
)(Single);
