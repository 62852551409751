import { computed } from 'vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetHousesForEventQuery, useEditHouseNotesMutation } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type House = ArrayElement<GetHousesForEventQuery['houses']>;

interface EditNotesProps {
  editHouseNotes: (houseId: number, notes: string, house: House) => void;
}
export const editHouseNotesEnhancer = wrapComponent<{}, EditNotesProps>(() => {
  const { mutate } = useEditHouseNotesMutation();

  return computed(() => ({
    editHouseNotes: (houseId, notes, house) =>
      mutate(
        { houseId, notes },
        {
          optimisticResponse: {
            editHouseNotes: {
              __typename: 'House',
              houseId,
              notes
            }
          }
        }
      )
  }));
});
