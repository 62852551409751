
import AddInteraction from './AddInteraction.vue';
import { compose } from 'vue-compose';
import { enhancer as markAsLeadEnhancer } from 'shared/components/MarkAsLead';
import { enhancer as addToListEnhancer } from 'shared/components/AddTeenToList';

export default compose(
  markAsLeadEnhancer,
  addToListEnhancer
)(AddInteraction);
