import { render, staticRenderFns } from "./Interactions.vue?vue&type=template&id=50636e24&scoped=true&"
import script from "./Interactions.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Interactions.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Interactions.vue?vue&type=style&index=0&id=50636e24&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50636e24",
  null
  
)

export default component.exports