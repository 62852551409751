import Interactions from './Interactions.vue';
import { computed } from 'vue';
import { compose, withData } from 'vue-compose';
import { Props } from './types';
import { Interaction } from 'shared/components/InteractionForm/types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetTeenWithInteractionsDocument,
  GetTeenWithInteractionsQuery,
  useDeleteInteractionMutation,
  useGetTeenWithInteractionsQuery
} from 'shared/generated/graphql-types';

function convertDate(date: string) {
  const [year, month, day] = date.split('T')[0].split('-').map(Number);

  return { year, month, day };
}

const deleteInteraction = wrapComponent<{ personId: number }, Pick<Props, 'deleteInteraction'>>(
  (props) => {
    const { mutate } = useDeleteInteractionMutation();

    return computed(() => ({
      deleteInteraction: (id) =>
        mutate(
          { interactionID: id },
          {
            optimisticResponse: {
              deleteInteraction: id
            },
            update(client, { data }) {
              const teen = client.readQuery<GetTeenWithInteractionsQuery>({
                query: GetTeenWithInteractionsDocument,
                variables: {
                  personID: props.personId
                }
              });

              if (teen?.singleTeen && data) {
                const interactions = teen.singleTeen.Interactions.slice();
                const index = interactions.findIndex((x) => x.id === data.deleteInteraction);

                interactions.splice(index, 1);

                client.writeQuery<GetTeenWithInteractionsQuery>({
                  query: GetTeenWithInteractionsDocument,
                  variables: {
                    personID: props.personId
                  },
                  data: {
                    singleTeen: {
                      ...teen.singleTeen,
                      Interactions: interactions
                    }
                  }
                });
              }
            }
          }
        )
    }));
  }
);

const getInteractions = wrapComponent<
  { personId: number },
  Pick<Props, 'interactions' | 'isLoading'>
>((props) => {
  const { loading, result } = useGetTeenWithInteractionsQuery(
    computed(() => ({ personID: props.personId }))
  );

  return computed(() => ({
    interactions: (result.value?.singleTeen.Interactions || []).map<Interaction>((i) => ({
      date: convertDate(i.date),
      interactionId: i.id,
      type: i.InteractionType,
      staff: {
        staffID: i.Staff.staffID,
        fullName: i.Staff.fullName
      },
      leadId: i.leadId,
      eventId: i.eventId,
      note: i.note,
      personId: result.value!.singleTeen!.personID
    })),
    isLoading: loading.value
  }));
});

const enhancer = compose(
  getInteractions,
  deleteInteraction,
  withData({
    addingNewInteraction: {
      initialValue: false
    }
  })
);

export default enhancer(Interactions);
