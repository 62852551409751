import { personListsFragment, PersonListsFragment } from 'shared/components/AddTeenToList';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetTeenListDocument,
  GetTeenListQuery,
  RemoveFromTeenListMutation,
  useRemoveFromTeenListMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { MutateResult } from '@vue/apollo-composable';

export interface Props {
  removeFromTeenList: (
    teenListId: number,
    teenId: number
  ) => MutateResult<RemoveFromTeenListMutation>;
  removingFromTeenList: boolean;
}

const mutation = wrapComponent<Props, Pick<Props, 'removeFromTeenList'>>(() => {
  const { loading, mutate } = useRemoveFromTeenListMutation();

  return computed(() => ({
    removeFromTeenList: (teenListId, teenId) =>
      mutate(
        { teenId, teenListId },
        {
          update(proxy, { data: updateData }) {
            let teenList: GetTeenListQuery | null = null;
            try {
              teenList = proxy.readQuery<GetTeenListQuery>({
                query: GetTeenListDocument,
                variables: { teenListId }
              });
            } catch (e) {
              console.log('Skip cache update');
            }

            if (
              updateData?.removeFromTeenList &&
              teenList &&
              teenList.teenList &&
              teenList.teenList.Teens
            ) {
              const teens = teenList.teenList.Teens.filter((teen) => teen.personID !== teenId);
              proxy.writeQuery<GetTeenListQuery>({
                query: GetTeenListDocument,
                variables: { teenListId },
                data: {
                  teenList: {
                    ...teenList.teenList,
                    Teens: teens,
                    teensCount: teenList.teenList.teensCount - 1
                  }
                }
              });

              try {
                const person = proxy.readFragment<PersonListsFragment>({
                  id: `${teenId}Person`,
                  fragment: personListsFragment
                });
                if (person) {
                  const lists = person.Lists.filter((l) => l.id !== teenListId);

                  proxy.writeFragment<PersonListsFragment>({
                    id: `${teenId}Person`,
                    fragment: personListsFragment,
                    data: {
                      ...person,
                      Lists: lists
                    }
                  });
                }
              } catch (e) {
                console.log('Skip cache update');
              }
            }
          }
        }
      ),
    removingFromTeenList: loading.value
  }));
});

export const enhancer = mutation;
