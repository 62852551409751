import { compose, withData, acceptProps } from 'vue-compose';
import Vue, { Component as VueComponent, computed } from 'vue';
import omit from 'lodash/omit';
import Summary from './Summary.vue';
import { normalizeProps, getOptions, getCurrentSeason } from 'shared/util';
import { Props, Dates } from './types';
import { SimpleDate } from 'shared/util/types';
import { DateTimezone } from 'date-timezone';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  DeleteZoomMeetingMutationVariables,
  GetSeriesEventsDocument,
  GetSeriesEventsQuery,
  GetSeriesEventsQueryVariables,
  GetSingleSeriesDocument,
  GetSingleSeriesQuery,
  SeriesEventsFilterInput,
  useChangeDatesMutation,
  useDeleteEventMutation,
  useDeleteZoomMeetingMutation,
  useGetSeriesEventsQuery,
  useUpdateZoomMeetingMutation
} from 'shared/generated/graphql-types';
import { withRouter } from 'shared/components/router';

interface DeleteEventProps {
  deleteEvent: (eventId: number) => void;
  deletingEvent: boolean;
}

interface DeleteMeetingProps {
  deleteZoomMeeting: (zoomMeetingId: string, occurrence_id?: string) => void;
  deletingZoomMeeting: boolean;
}

export const filtersInit = (): SeriesEventsFilterInput => ({
  season: getCurrentSeason(),
  month: null,
  upcoming: false
});

const withDates = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);

  props.persistedDate = {};

  const ComponentWithDate = Vue.extend({
    name: 'DateContainer',
    props: omit(props, [
      'dates',
      'datesChanged',
      'currentDate',
      'setCurrentDate',
      'mode',
      'setMode'
    ]),
    data() {
      const persistedDate: SimpleDate = this.persistedDate;

      const today = persistedDate
        ? new DateTimezone(persistedDate.year, persistedDate.month - 1, persistedDate.day, 0, 0)
        : new Date();

      return {
        dates: {
          startDate: null,
          endDate: null
        },
        currentDate: {
          month: today.getMonth() + 1,
          year: today.getFullYear(),
          day: today.getDate()
        },
        mode: 'month'
      };
    },
    methods: {
      datesChanged(dates: Dates) {
        Object.assign(this.dates, dates);
      },
      setCurrentDate(date: SimpleDate) {
        this.currentDate = date;
      },
      setMode(mode: string) {
        this.mode = mode;
      }
    },
    render(h) {
      return h(Component, {
        on: this.$listeners,
        props: {
          ...this.$props,
          dates: this.dates,
          datesChanged: this.datesChanged,
          currentDate: this.currentDate,
          setCurrentDate: this.setCurrentDate,
          mode: this.mode,
          setMode: this.setMode
        }
      });
    }
  });

  return ComponentWithDate;
};

const withFiltersFromStorage = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);

  const ComponentWithPersistedFilters = Vue.extend({
    name: 'CalendarPersistance',
    props: omit(props, [
      'dates',
      'datesChanged',
      'currentDate',
      'setCurrentDate',
      'mode',
      'setMode',
      'persistState'
    ]),
    methods: {
      persistState(filters: SeriesEventsFilterInput, date: SimpleDate) {
        localStorage.calendarPersistance = JSON.stringify({
          persistedFilters: filters,
          persistedDate: date
        });
      }
    },
    render(h) {
      const persistedState = localStorage.getItem('calendarPersistance') || '{}';

      const { persistedFilters, persistedDate } = JSON.parse(persistedState);

      return h(Component, {
        on: this.$listeners,
        props: {
          ...this.$props,
          persistedDate,
          persistedFilters: {
            ...persistedFilters,
            month: persistedFilters && persistedFilters.month ? persistedFilters.month : undefined,
            season:
              persistedFilters && persistedFilters.season ? persistedFilters.season : undefined
          },
          persistState: this.persistState
        }
      });
    }
  });

  return ComponentWithPersistedFilters;
};

const persistFilters = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);
  props.persistState = {};

  const EnhancedComponent = Vue.extend({
    name: 'CalendarPersistance',
    props,
    watch: {
      currentDate() {
        this.persistState(this.filters, this.currentDate);
      },
      filters() {
        this.persistState(this.filters, this.currentDate);
      }
    },
    render(h) {
      return h(Component, {
        on: this.$listeners,
        props: {
          ...this.$props
        }
      });
    }
  });

  return EnhancedComponent;
};

const withFilters = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);

  const { currentPage, filters, setFilters, clearFilters, setPage, ...propsToUse } = props;

  return Vue.extend({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        filters: props.persistedFilters ? props.persistedFilters : filtersInit(),
        currentPage: 1
      };
    },
    methods: {
      setFilters(args: Partial<SeriesEventsFilterInput>) {
        this.filters = { ...this.filters, ...args };
        this.setPage(1);
      },
      clearFilters() {
        this.filters = filtersInit();
        this.setPage(1);
      },
      setPage(page: number) {
        this.currentPage = page;
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          filters: this.filters,
          setFilters: this.setFilters,
          clearFilters: this.clearFilters,
          currentPage: this.currentPage,
          setPage: this.setPage
        },
        on: this.$listeners
      });
    }
  });
};

const getEventsEnhancer = wrapComponent<Props, Pick<Props, 'events' | 'isLoading' | 'total'>>(
  (props) => {
    const { loading, result } = useGetSeriesEventsQuery(
      computed(() => ({
        seriesId: props.series.seriesID,
        ...(props.mode !== 'month'
          ? { limit: props.limit, offset: (props.currentPage - 1) * props.limit }
          : {}),
        filter: {
          ...(props.mode !== 'month' ? { season: props.filters.season } : {}),
          upcoming: props.filters.upcoming,
          ...(props.mode === 'month'
            ? {
                range: {
                  startDate: props.dates.startDate,
                  endDate: props.dates.endDate
                }
              }
            : {})
        }
      })),
      { fetchPolicy: 'cache-and-network', enabled: computed(() => !!props.dates.startDate) }
    );

    return computed(() => ({
      events: result.value?.seriesEvents?.events || [],
      total: result.value?.seriesEvents?.total || 0,
      isLoading: loading.value
    }));
  }
);

const editEventDatesEnhancer = wrapComponent<Props, Pick<Props, 'editEvent'>>((props) => {
  const { mutate } = useChangeDatesMutation();

  return computed(() => ({
    editEvent: ({ eventID, startDate, endDate }) =>
      mutate({ input: { eventID, startDate, endDate } })
  }));
});

const updateZoomEventEnhancer = wrapComponent<
  Props,
  Pick<Props, 'updateZoomMeeting' | 'updatingZoomMeeting'>
>((props) => {
  const { loading, mutate } = useUpdateZoomMeetingMutation();

  return computed(() => ({
    updateZoomMeeting: (id, input) => mutate({ id, input }),
    updatingZoomMeeting: loading.value
  }));
});

const deleteEventEnhancer = wrapComponent<Props, DeleteEventProps>((props) => {
  const { loading, mutate } = useDeleteEventMutation();

  return computed(() => ({
    deleteEvent: (eventId) =>
      mutate(
        { eventId },
        {
          optimisticResponse: {
            deleteEvent: eventId
          },
          update: (proxy) => {
            const variables: GetSeriesEventsQueryVariables = {
              seriesId: props.series.seriesID,
              ...(props.mode !== 'month'
                ? { limit: props.limit, offset: (props.currentPage - 1) * props.limit }
                : {}),
              filter: {
                ...(props.mode !== 'month' ? { season: props.filters.season } : {}),
                upcoming: props.filters.upcoming,
                ...(props.mode === 'month'
                  ? {
                      range: {
                        startDate: props.dates.startDate,
                        endDate: props.dates.endDate
                      }
                    }
                  : {})
              }
            };
            const data = proxy.readQuery<GetSeriesEventsQuery>({
              query: GetSeriesEventsDocument,
              variables
            });

            if (data?.seriesEvents) {
              const events = data.seriesEvents.events.slice();
              const index = events.findIndex((event) => event.eventId === eventId);

              if (index > -1) {
                events.splice(index, 1);
                proxy.writeQuery<GetSeriesEventsQuery>({
                  query: GetSeriesEventsDocument,
                  variables,
                  data: {
                    seriesEvents: {
                      ...data.seriesEvents,
                      events,
                      total: data.seriesEvents.total - 1
                    }
                  }
                });
              }
            }

            const singleSeries = proxy.readQuery<GetSingleSeriesQuery>({
              query: GetSingleSeriesDocument,
              variables: {
                seriesID: props.series.seriesID
              }
            });
            if (singleSeries?.singleSeries) {
              const events = singleSeries.singleSeries.Events.slice();
              const index = events.findIndex((e) => e.eventId === eventId);
              events.splice(index, 1);
              proxy.writeQuery<GetSingleSeriesQuery>({
                query: GetSingleSeriesDocument,
                variables: {
                  seriesID: props.series.seriesID
                },
                data: {
                  singleSeries: {
                    ...singleSeries.singleSeries,
                    Events: events
                  }
                }
              });
            }
          }
        }
      ),
    deletingEvent: loading.value
  }));
});

const deleteZoomMeetingEnhancer = wrapComponent<Props, DeleteMeetingProps>((props) => {
  const { loading, mutate } = useDeleteZoomMeetingMutation();

  return computed(() => ({
    deleteZoomMeeting: (zoomMeetingId, occurrence_id) => {
      const variables: DeleteZoomMeetingMutationVariables = {
        id: zoomMeetingId,
        occurrence_id: null
      };
      if (occurrence_id) {
        variables.occurrence_id = new Date(occurrence_id as string).getTime();
      }
      return mutate(variables, {
        optimisticResponse: {
          deleteZoomMeeting: zoomMeetingId
        }
      });
    },
    deletingZoomMeeting: loading.value
  }));
});

interface PersistedState {
  persistedFilters: SeriesEventsFilterInput;
  persistedDate: SimpleDate;
}

export const enhancer = compose(
  withFiltersFromStorage,
  withData<PersistedState, any>({
    limit: {
      initialValue: 10
    }
  }),
  acceptProps(['persistedDate', 'persistedFilters', 'persistState']),
  withFilters,
  withDates,
  persistFilters,
  withRouter,
  getEventsEnhancer,
  updateZoomEventEnhancer,
  editEventDatesEnhancer,
  deleteEventEnhancer,
  deleteZoomMeetingEnhancer
);

export default enhancer(Summary);
