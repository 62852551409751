import { render, staticRenderFns } from "./synagogueIdPicker.vue?vue&type=template&id=05cb24d8&scoped=true&"
import script from "./synagogueIdPicker.vue?vue&type=script&setup=true&lang=ts&"
export * from "./synagogueIdPicker.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05cb24d8",
  null
  
)

export default component.exports