import { computed } from 'vue';
import gql from 'graphql-tag';
import {
  AddToTeenListMutation,
  ExtendedTeenSideBarFragment,
  TeenListFragment,
  TeenListFragmentDoc,
  TeenListWithTeensFragment,
  TeenListWithTeensFragmentDoc,
  useAddToTeenListMutation
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { MutateResult } from '@vue/apollo-composable';

export type PersonListsFragment = Pick<ExtendedTeenSideBarFragment['Person'], 'Lists'>;

export const personListsFragment = gql`
  fragment PersonFragment on Person {
    Lists {
      id
      title
    }
  }
`;
export interface Props {
  addToTeenList: (teenListId: number, teenId: number) => MutateResult<AddToTeenListMutation>;
  addingToTeenList: boolean;
}

const mutation = wrapComponent<{}, Pick<Props, 'addToTeenList' | 'addingToTeenList'>>(() => {
  const { loading, mutate } = useAddToTeenListMutation();

  return computed(() => ({
    addToTeenList: (teenListId, teenId) =>
      mutate(
        { teenId, teenListId },
        {
          update(proxy, { data: updateData }) {
            let teenList: TeenListWithTeensFragment | null = null;
            try {
              teenList = proxy.readFragment<TeenListWithTeensFragment>({
                id: `${teenListId}TeenList`,
                fragment: TeenListWithTeensFragmentDoc,
                fragmentName: 'TeenListWithTeens'
              });
            } catch (e) {
              console.log('Skip cache update');
            }
            if (!teenList) {
              try {
                const teenListData = proxy.readFragment<TeenListFragment>({
                  id: `${teenListId}TeenList`,
                  fragment: TeenListFragmentDoc
                });
                teenList = teenListData
                  ? {
                      ...teenListData,
                      Teens: []
                    }
                  : null;
              } catch (e) {
                console.log('Skip cache update');
              }
            }

            if (teenList && updateData?.addToTeenList) {
              const teens = teenList.Teens.slice();
              teens.push(updateData.addToTeenList);
              proxy.writeFragment<TeenListWithTeensFragment>({
                id: `${teenListId}TeenList`,
                fragment: teenList.Teens ? TeenListWithTeensFragmentDoc : TeenListFragmentDoc,
                fragmentName: teenList.Teens ? 'TeenListWithTeens' : undefined,
                data: {
                  ...teenList,
                  Teens: teens,
                  teensCount: teenList.teensCount + 1
                }
              });

              try {
                const person = proxy.readFragment<PersonListsFragment>({
                  id: `${teenId}Person`,
                  fragment: personListsFragment
                });
                if (person) {
                  const lists = person.Lists.slice();
                  lists.push({
                    id: teenList.id,
                    title: teenList.title,
                    __typename: 'TeenList'
                  });

                  proxy.writeFragment<PersonListsFragment>({
                    id: `${teenId}Person`,
                    fragment: personListsFragment,
                    data: {
                      ...person,
                      Lists: lists
                    }
                  });
                }
              } catch (e) {
                console.log('Skip cache update');
              }
            }
          }
        }
      ),
    addingToTeenList: loading.value
  }));
});

export const enhancer = mutation;
