var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.PanelsGrid,{attrs:{"bottomOffset":"65"}},[_c(_setup.PanelsSidebar,{staticClass:"filter-sidebar"},[_c('div',{staticClass:"sidebar-header"},[_vm._v(" Events Calendar "),_c('span',{staticClass:"clear-filter",on:{"click":function($event){return _vm.clearFilters()}}},[_vm._v("clear filters")]),_c(_setup.RouterLink,{attrs:{"to":"/schedule-event","exact":true}},[_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Add New Event'),expression:"'Add New Event'",modifiers:{"right":true}}],attrs:{"icon":"plus-circle"}})],1)],1),_c(_setup.ScrollablePanel,{directives:[{name:"fullheight",rawName:"v-fullheight"}]},[_c('div',{staticClass:"inner-sidebar"},[_c(_setup.EventFilter,{attrs:{"regionId":_vm.currentRegion,"setFilterHandler":_setup.setFilterHandler,"user":_vm.user,"filters":_vm.filters,"clearFilters":_vm.clearFilters}}),_c(_setup.Divider,{attrs:{"lineStyle":"dashed","margin":"-15px 0 20px"}}),_c(_setup.RouterLink,{staticClass:"add-btn",attrs:{"to":"/schedule-event","exact":true}},[_vm._v(" + Add New Event ")])],1)])],1),_c('div',{staticClass:"tabs"},[_c('header',{staticClass:"tabs-header"},[_c('div',{staticClass:"tab-ctrl"},[_c('span',{staticClass:"ctrl--today",on:{"click":function($event){_setup.handleDateChange(new Date())}}},[_vm._v("Today")])]),_c('div',{staticClass:"tab-ctrl"},[_c('span',{staticClass:"ctrl--prev",on:{"click":function($event){return _vm.setCurrentDate({
              year: _vm.currentDate.year - 1,
              month: _vm.currentDate.month,
              day: _vm.currentDate.day,
            })}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'chevron-double-left']}})],1),_c('span',{staticClass:"ctrl--prev",attrs:{"data-test":"prevMonth"},on:{"click":_setup.handlePrevious}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'chevron-left']}})],1),_c('span',{staticClass:"ctrl--next",on:{"click":_setup.handleNext}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'chevron-right']}})],1),_c('span',{staticClass:"ctrl--next",on:{"click":function($event){return _vm.setCurrentDate({
              year: _vm.currentDate.year + 1,
              month: _vm.currentDate.month,
              day: _vm.currentDate.day,
            })}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'chevron-double-right']}})],1),_c('span',{staticClass:"current-month"},[_vm._v(_vm._s(_setup.monthNames[_vm.currentDate.month - 1] + " " + _vm.currentDate.year))])]),_c('nav',{staticClass:"tabs-nav"},[_c('a',{staticClass:"tabs-nav__itm",class:{ active: _vm.mode === 'month' },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setMode('month')}}},[_vm._v("Month")]),_c('a',{staticClass:"tabs-nav__itm",class:{ active: _vm.mode === 'day' },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setMode('day')}}},[_vm._v("Day")]),_c('a',{staticClass:"tabs-nav__itm",class:{ active: _vm.mode === 'agenda' },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setMode('agenda')}}},[_vm._v("Agenda")])])]),_c('div',{staticClass:"tabs-panel"},[(_vm.mode === 'agenda')?_c(_setup.AgendaView,{key:`${_vm.currentDate.month}-${_vm.currentDate.year}`,attrs:{"regionId":_vm.currentRegion,"filters":_vm.filters,"date":_vm.currentDate,"icon":"","today":_setup.today}}):_c(_setup.Calendar,{attrs:{"month":_vm.currentDate.month,"year":_vm.currentDate.year},on:{"update:dates":_vm.datesChanged},scopedSlots:_vm._u([{key:"default",fn:function({ weeks }){return [(_vm.mode === 'month')?_c(_setup.MonthView,_vm._b({on:{"selectedDay":function($event){_vm.setCurrentDate({
                year: $event.year,
                month: $event.month,
                day: $event.day,
              });
              _vm.setMode('day');},"dateChanged":_setup.handleDateChange},nativeOn:{"wheel":function($event){return _setup.handleMouseWheel.apply(null, arguments)}}},'MonthView',{
              weeks,
              date: new Date(
                _vm.currentDate.year,
                _vm.currentDate.month - 1,
                _vm.currentDate.day
              ),
              events: _vm.events,
              loading: _vm.loading,
            },false)):_vm._e(),(_vm.mode === 'day')?_c(_setup.DayView,_vm._b({on:{"dateChanged":_setup.handleDateChange}},'DayView',{ currentDate: _vm.currentDate, currentDay: _vm.currentDate.day, events: _vm.events },false)):_vm._e()]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }