import { GetHousesForEventQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type House = ArrayElement<GetHousesForEventQuery['houses']>;

export const addressFromHouse = (house: House) => {
  const parts = [
    `${house.street ? house.street : ''}${house.street2 ? ` ${house.street2}` : ''}`,
    house.city,
    house.state
  ];

  return parts.filter((p) => p && p.trim()).join(', ');
};
