import { render, staticRenderFns } from "./Housing.vue?vue&type=template&id=3dccf1cf&scoped=true&"
import script from "./Housing.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Housing.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Housing.vue?vue&type=style&index=0&id=3dccf1cf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dccf1cf",
  null
  
)

export default component.exports