import RegistrationInfo from './RegistrationInfo.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import {
  moveRegistrationToWaitListEnhancer,
  completeRegistrationEnhancer,
  cancelRegistrationEnhancer,
  resendRegistrationEmailsEnhancer,
  produceInvoiceEnhancer,
  resendWaiverEmailsEnhancer,
  promoteRegistrationEnhancer
} from './shared/enhancers';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetRegistrationQuery,
  UpdateTeenField,
  UpdateTeenMutationVariables,
  useGetRegistrationQuery,
  useUpdateHouseRequestsMutation,
  useUpdateMedicalNoteMutation,
  useUpdateTeenMutation
} from 'shared/generated/graphql-types';

interface Props {
  selectedRegistrationId: string;
  registration: GetRegistrationQuery['registration'] | null;
}

interface UpdateHouseRequestProps {
  updateHousingRequest(request: number, value: string): any;
}
interface Props {
  personID: number;
}
interface UpdateTeenProps {
  updateTeen: (args: Pick<UpdateTeenMutationVariables, 'personId' | 'value'>) => void;
}
interface updateMedicalNoteProps {
  updateMedicalNote: (registrationId: number, medicalNotes: string) => void;
}

interface GetRegistrationProps {
  fetchingRegistration: boolean;
  registration: GetRegistrationQuery['registration'] | null;
  registrationLineItems: GetRegistrationQuery['registration']['LineItems'];
}
const updateMedicalNoteEnhancer = wrapComponent<Props, updateMedicalNoteProps>(() => {
  const { mutate } = useUpdateMedicalNoteMutation();

  return computed(() => ({
    updateMedicalNote: (registrationId, medicalNote) => mutate({ medicalNote, registrationId })
  }));
});

const updateTeenEnhancer = wrapComponent<Props, UpdateTeenProps>(() => {
  const { loading, mutate } = useUpdateTeenMutation();

  return computed(() => ({
    updateTeen: (args) =>
      mutate({ personId: args.personId, fieldName: UpdateTeenField.Medicines, value: args.value }),
    updating: loading.value
  }));
});

const getRegistrationEnhancer = wrapComponent<Props, GetRegistrationProps>((props) => {
  const { loading, result } = useGetRegistrationQuery(
    computed(() => ({ registrationID: +props.selectedRegistrationId }))
  );

  return computed(() => ({
    fetchingRegistration: loading.value,
    registration: result.value?.registration || null,
    registrationLineItems: result.value?.registration.LineItems || []
  }));
});

const updateHousingRequestEnhancer = wrapComponent<Props, UpdateHouseRequestProps>((props) => {
  const { mutate } = useUpdateHouseRequestsMutation();

  return computed(() => ({
    updateHousingRequest: (request, value) => {
      const housingRequest1 =
        (request === 1 ? value : props.registration && props.registration.housingRequest1) || '';
      const housingRequest2 =
        (request === 2 ? value : props.registration && props.registration.housingRequest2) || '';
      const housingRequest3 =
        (request === 3 ? value : props.registration && props.registration.housingRequest3) || '';
      const housingRequest4 =
        (request === 4 ? value : props.registration && props.registration.housingRequest4) || '';

      return mutate(
        {
          registrationId: +props.selectedRegistrationId,
          housingRequest1,
          housingRequest2,
          housingRequest3,
          housingRequest4
        },
        {
          optimisticResponse: {
            updateHouseRequests: {
              __typename: 'Registration',
              housingRequest1,
              housingRequest2,
              housingRequest3,
              housingRequest4,
              registrationID: +props.selectedRegistrationId
            }
          }
        }
      );
    }
  }));
});

export default compose(
  getRegistrationEnhancer,
  updateHousingRequestEnhancer,
  updateTeenEnhancer,
  updateMedicalNoteEnhancer,
  moveRegistrationToWaitListEnhancer,
  promoteRegistrationEnhancer,
  completeRegistrationEnhancer,
  cancelRegistrationEnhancer,
  resendRegistrationEmailsEnhancer,
  produceInvoiceEnhancer,
  resendWaiverEmailsEnhancer
)(RegistrationInfo);
