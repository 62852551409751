import GoalForm from './GoalForm.vue';
import { GoalFormObject, Props } from './types';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { MutateResult } from '@vue/apollo-composable';
import { SubmitCallbackArgs } from 'vue-simpleform';
import { getCurrentSeason } from 'shared/util';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  CreateGoalMutation,
  EditGoalMutation,
  GetGoalsDocument,
  GetGoalsQuery,
  GetGoalTypesQuery,
  GoalFragment,
  GoalFragmentDoc,
  GoalTypeCategory,
  useCreateGoalMutation,
  useEditGoalMutation,
  useGetGoalTypesQuery
} from 'shared/generated/graphql-types';

type GoalTypesProps = {
  loading: boolean;
  goalTypes: GetGoalTypesQuery['goalTypes'];
};
const getGoalTypesEnhancer = wrapComponent<Props, GoalTypesProps>(() => {
  const { loading, result } = useGetGoalTypesQuery();

  return computed(() => ({
    loading: loading.value,
    goalTypes: result.value?.goalTypes || []
  }));
});

type CreateGoalProps = {
  createGoal: (args: SubmitCallbackArgs<GoalFormObject>) => MutateResult<CreateGoalMutation>;
};
const createGoalEnhancer = wrapComponent<Props, CreateGoalProps>((props) => {
  const { mutate } = useCreateGoalMutation();

  return computed(() => ({
    createGoal: (args) => {
      const {
        goalId,
        goalType,
        chapter,
        staff,
        school,
        schoolType,
        interactionType,
        eventTrack,
        eventType,
        eventSubType,
        startDate,
        endDate,
        target,
        ...rest
      } = args.values!;

      const getEntityId = (values: GoalFormObject) => {
        const category = values.goalType.category;
        if (
          category === GoalTypeCategory.InteractionsBySchoolType ||
          category === GoalTypeCategory.SummerRecruitmentBySchoolType
        ) {
          return values.schoolType.id;
        } else if (
          category === GoalTypeCategory.InteractionsBySchool ||
          category === GoalTypeCategory.SummerRecruitmentBySchool
        ) {
          return values.school.schoolID;
        } else if (category === GoalTypeCategory.InteractionsByInteractionType) {
          return values.interactionType.id;
        } else if (category === GoalTypeCategory.EventAttendanceByTrack) {
          return values.eventTrack.id;
        } else if (category === GoalTypeCategory.EventAttendanceByType) {
          return values.eventType.eventTypeId;
        } else if (
          category === GoalTypeCategory.EventAttendanceBySubType ||
          category === GoalTypeCategory.SummerRecruitmentBySubType
        ) {
          return values.eventSubType.eventSubTypeId;
        }
      };

      return mutate(
        {
          input: {
            ...rest,
            ...(chapter ? { chapterId: chapter.chapterId } : {}),
            ...(staff ? { staffId: staff.staffID } : {}),
            goalTypeId: goalType.id,
            kpi: false,
            season: getCurrentSeason(),
            regionId: props.regionId,
            entityId: getEntityId(args.values!),
            target: Number(target),
            startDate: startDate
              ? new Date(
                  Date.UTC(startDate.year, startDate.month - 1, startDate.day, 0, 0, 0)
                ).toISOString()
              : null,
            endDate: endDate
              ? new Date(
                  Date.UTC(endDate.year, endDate.month - 1, endDate.day, 0, 0, 0)
                ).toISOString()
              : null
          }
        },
        {
          update(proxy, { data: updateData }) {
            const data = proxy.readQuery<GetGoalsQuery>({
              query: GetGoalsDocument,
              variables: {
                filter: props.filters,
                offset: (props.currentPage - 1) * props.limit,
                limit: props.limit
              }
            });

            if (data?.goals && updateData?.createGoal) {
              const goals = data.goals.goals.slice();
              goals.push(updateData.createGoal);
              proxy.writeQuery<GetGoalsQuery>({
                query: GetGoalsDocument,
                variables: {
                  filter: props.filters,
                  offset: (props.currentPage - 1) * props.limit,
                  limit: props.limit
                },
                data: {
                  goals: {
                    ...data.goals,
                    goals,
                    total: data.goals.total + 1
                  }
                }
              });
            }
          }
        }
      );
    }
  }));
});

type EditGoalProps = {
  editGoal: (args: SubmitCallbackArgs<GoalFormObject>) => MutateResult<EditGoalMutation>;
};
const editGoalEnhancer = wrapComponent<Props, EditGoalProps>((props) => {
  const { mutate } = useEditGoalMutation();

  return computed(() => ({
    editGoal: (args) => {
      const {
        goalId,
        goalType,
        chapter,
        staff,
        school,
        schoolType,
        interactionType,
        eventTrack,
        eventType,
        eventSubType,
        startDate,
        endDate,
        target,
        ...rest
      } = args.values!;

      const getEntityId = (values: GoalFormObject) => {
        const category = values.goalType.category;
        if (
          category === GoalTypeCategory.InteractionsBySchoolType ||
          category === GoalTypeCategory.SummerRecruitmentBySchoolType
        ) {
          return values.schoolType.id;
        } else if (
          category === GoalTypeCategory.InteractionsBySchool ||
          category === GoalTypeCategory.SummerRecruitmentBySchool
        ) {
          return values.school.schoolID;
        } else if (category === GoalTypeCategory.InteractionsByInteractionType) {
          return values.interactionType.id;
        } else if (category === GoalTypeCategory.EventAttendanceByTrack) {
          return values.eventTrack.id;
        } else if (category === GoalTypeCategory.EventAttendanceByType) {
          return values.eventType.eventTypeId;
        } else if (
          category === GoalTypeCategory.EventAttendanceBySubType ||
          category === GoalTypeCategory.SummerRecruitmentBySubType
        ) {
          return values.eventSubType.eventSubTypeId;
        }
      };

      return mutate(
        {
          input: {
            ...rest,
            ...(chapter ? { chapterId: chapter.chapterId } : {}),
            ...(staff ? { staffId: staff.staffID } : {}),
            goalId: goalId!,
            goalTypeId: goalType.id,
            kpi: false,
            season: getCurrentSeason(),
            regionId: props.regionId,
            entityId: getEntityId(args.values!),
            target: Number(target),
            startDate: startDate
              ? new Date(
                  Date.UTC(startDate.year, startDate.month - 1, startDate.day, 0, 0, 0)
                ).toISOString()
              : null,
            endDate: endDate
              ? new Date(
                  Date.UTC(endDate.year, endDate.month - 1, endDate.day, 0, 0, 0)
                ).toISOString()
              : null
          }
        },
        {
          update(proxy, { data: updateData }) {
            if (updateData?.editGoal) {
              const data = proxy.readFragment<GoalFragment>({
                id: `${updateData.editGoal.id}Goal`,
                fragment: GoalFragmentDoc
              });

              if (data) {
                proxy.writeFragment<GoalFragment>({
                  id: `${updateData.editGoal.id}Goal`,
                  fragment: GoalFragmentDoc,
                  data: {
                    ...data,
                    ...updateData.editGoal
                  }
                });
              }
            }
          }
        }
      );
    }
  }));
});

export default compose(getGoalTypesEnhancer, createGoalEnhancer, editGoalEnhancer)(GoalForm);
