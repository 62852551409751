import { DirectiveOptions } from "vue";

const listeners = new WeakMap();

export default {
  inserted(el, binding) {
    const { bottom } = binding.value || { bottom: 0 }
    el.dataset.oldTop = String(el.getBoundingClientRect().top);
    listeners.set(el, () => {
      // https://plainjs.com/javascript/styles/get-the-position-of-an-element-relative-to-the-document-24/
      function offset(el: Element) {
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
      }

      const getProp = (prop: 'marginTop' | 'paddingTop') =>
        Number(getComputedStyle(document.body)[prop]!.replace('px', ''))

      const height = window.innerHeight - offset(el).top - getProp('marginTop') - getProp('paddingTop') - bottom;

      el.style.height = `${height}px`;
    });

    listeners.get(el)();

    window.addEventListener('resize', listeners.get(el));

    requestAnimationFrame(function check () {
      if (!listeners.get(el)) return;

      const newTop = String(el.getBoundingClientRect().top);

      if (newTop === el.dataset.oldTop) {
        requestAnimationFrame(check);
      }
      else {
        listeners.get(el)();
        el.dataset.oldTop = String(el.getBoundingClientRect().top);

        requestAnimationFrame(check);
      }
    })
  },
  unbind (el) {
    window.removeEventListener('resize', listeners.get(el));
    listeners.delete(el);
  }
} as DirectiveOptions
