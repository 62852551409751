import Participant from '../../shared/Participant';
import { GetHousesForEventQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type EventGuest = ArrayElement<ArrayElement<GetHousesForEventQuery['houses']>['EventGuests']>
type EventStaff = ArrayElement<ArrayElement<GetHousesForEventQuery['houses']>['EventStaff']>
type Registration = ArrayElement<ArrayElement<GetHousesForEventQuery['houses']>['Registrations']>

type ParticipantTypeForHousing = Registration | EventGuest | EventStaff;
export default class PotentialHousee extends Participant<ParticipantTypeForHousing> {
  housed: boolean = !!this.model.House;
  House: any = this.model.House;
  // @ts-ignore
  registrationStatus: any = this.model.status;
}
