var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.communityMember)?_c('div',[_c('div',{staticClass:"edit-summary"},[_c(_setup.SimpleForm,{key:_vm.communityMember.personId,attrs:{"value":_vm.communityMember,"validate":(x) => true},on:{"submit":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, submitting }){return [_c('form',{attrs:{"autocomplete":"off"}},[_c(_setup.CollapsibleSection,{attrs:{"header":"About","startOpen":true,"noCollapse":""},scopedSlots:_vm._u([{key:"sectionBody",fn:function(props){return [_c('section',[_c('div',{staticClass:"form-row"},[_c('div',[_c('label',[_vm._v("Name")]),_c(_setup.AutoSaveField,{attrs:{"name":"fullName","entity":_setup.communityMemberCopy,"transform":(c) => ({
                          value: c,
                          info: {
                            handler: _setup.updatePersonHandler,
                            data: {
                              personId: _vm.personId,
                              fieldName: _setup.updateCommunityMemberField.fullName,
                            },
                          },
                        }),"validators":[
                        {
                          name: 'fullName',
                          validator: (name) => !name || _setup.validateName(name),
                        },
                      ]},on:{"update:entity":function($event){_setup.communityMemberCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.UIInput,_vm._g(_vm._b({attrs:{"data-test":"nameField","type":"text"}},'UIInput',$props,false),$listeners)),(hasError('fullName'))?_c('span',{staticClass:"error-label"},[_vm._v("Please enter the first and last name")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"form-row"},[_c('div',[_c(_setup.AutoSaveField,{attrs:{"name":"gender","entity":_setup.communityMemberCopy,"transform":(c) => ({
                          value: c,
                          info: {
                            handler: _setup.updatePersonHandler,
                            data: {
                              personId: _vm.personId,
                              fieldName: _setup.updateCommunityMemberField.gender,
                            },
                          },
                        })},on:{"update:entity":function($event){_setup.communityMemberCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.RadioGroup,_vm._g(_vm._b({staticClass:"radio-inline"},'RadioGroup',$props,false),$listeners),[_c(_setup.RadioGroupItem,{attrs:{"value":0,"name":"Male"},scopedSlots:_vm._u([{key:"default",fn:function({ props, listeners }){return [_c(_setup.UIRadio,_vm._g(_vm._b({attrs:{"labelText":"Male"}},'UIRadio',props,false),listeners))]}}],null,true)}),_c(_setup.RadioGroupItem,{attrs:{"value":1,"name":"Female"},scopedSlots:_vm._u([{key:"default",fn:function({ props, listeners }){return [_c(_setup.UIRadio,_vm._g(_vm._b({attrs:{"labelText":"Female"}},'UIRadio',props,false),listeners))]}}],null,true)})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"form-row"},[_c('div',[_c('label',[_vm._v("Olami ID")]),_c(_setup.AutoSaveField,{attrs:{"name":"olamiId","entity":_setup.communityMemberCopy,"transform":(c) => ({
                          value: c,
                          info: {
                            handler: _setup.updatePersonHandler,
                            data: {
                              personId: _vm.personId,
                              fieldName: _setup.updateCommunityMemberField.olamiId,
                            },
                          },
                        })},on:{"update:entity":function($event){_setup.communityMemberCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.UIInput,_vm._g(_vm._b({attrs:{"type":"text","placeholder":"Olami ID"}},'UIInput',$props,false),$listeners))]}}],null,true)})],1)]),_c('br'),_c('br'),_c(_setup.Divider,{attrs:{"marginTop":4,"marginBottom":4,"lineStyle":"dashed"}}),_c('div',{staticClass:"form-row smaller"},[_c('div',{staticClass:"position-relative"},[_c('label',[_vm._v("Email Address")]),_c(_setup.AutoSaveField,{attrs:{"name":"email","entity":_setup.communityMemberCopy,"transform":(c) => ({ value: c, info: _setup.emailData })},on:{"update:entity":function($event){_setup.communityMemberCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.EmailInput,_vm._g(_vm._b({attrs:{"ownerType":"communityMember","required":true,"name":"email","placeholder":"Email","ownerId":_vm.personId,"showErrors":true,"left":true}},'EmailInput',$props,false),$listeners))]}}],null,true)})],1)]),_c('div',{staticClass:"form-row"},[_c('div',[_c('label',[_vm._v("Home Phone Number")]),_c(_setup.AutoSaveField,{attrs:{"name":"landline","entity":_setup.communityMemberCopy,"toForm":(c) =>
                          _setup.validateNumber(c)
                            ? _setup.phoneFormat(c, _vm.communityMember.country)
                            : '',"toEntity":_setup.phoneInternational,"transform":(c) => ({ value: c, info: _setup.landlineData }),"validators":[
                        {
                          name: 'validateNumber',
                          validator: (value) => _setup.validateNumber(value, true),
                        },
                      ]},on:{"update:entity":function($event){_setup.communityMemberCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.PhoneInput,_vm._g(_vm._b({attrs:{"type":"tel","name":"landline"}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Home Phone Number must be a number")]):_vm._e()]}}],null,true)})],1),_c('div',[_c('label',[_vm._v("Cellphone Number")]),_c(_setup.AutoSaveField,{attrs:{"name":"cellphone","entity":_setup.communityMemberCopy,"toForm":(c) =>
                          _setup.validateNumber(c)
                            ? _setup.phoneFormat(c, _vm.communityMember.country)
                            : '',"toEntity":_setup.phoneInternational,"transform":(c) => ({ value: c, info: _setup.cellphoneData }),"validators":[
                        {
                          name: 'validateNumber',
                          validator: (value) => _setup.validateNumber(value, true),
                        },
                      ]},on:{"update:entity":function($event){_setup.communityMemberCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.PhoneInput,_vm._g(_vm._b({attrs:{"type":"tel","name":"cellphone"}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Cellphone Number must be a number")]):_vm._e()]}}],null,true)})],1)])],1),_c('section',[_c('div',[_c(_setup.AutoSaveField,{attrs:{"name":"address","entity":_setup.communityMemberCopy,"transform":(c) => ({ value: JSON.stringify(c), info: _setup.addressData })},on:{"update:entity":function($event){_setup.communityMemberCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.Address,_vm._g(_vm._b({key:JSON.stringify(_setup.communityMemberCopy.address || {}),attrs:{"defaultCountry":_vm.country},scopedSlots:_vm._u([{key:"default",fn:function({
                            countries,
                            setCountry,
                            setAddress,
                            country,
                            address,
                            lookupAddress,
                          }){return [_c('div',{staticClass:"form-row smaller"},[_c('label',[_vm._v("Country")]),_c('select',{domProps:{"value":country},on:{"change":(e) => {
                                  setCountry(e.currentTarget.value);
                                  lookupAddress();
                                }}},_vm._l((countries),function(country){return _c('option',{domProps:{"value":country.code}},[_vm._v(" "+_vm._s(country.name)+" ")])}),0)]),_c('div',{staticClass:"form-row smaller"},[_c(_setup.UITextarea,{attrs:{"placeholder":"Address","value":address},on:{"blur":lookupAddress,"input":function($event){return setAddress($event)}}})],1)]}}],null,true)},'Address',$props,false),$listeners))]}}],null,true)})],1)])]}}],null,true)})],1)]}}],null,false,2882835348)})],1)]):_c('div',[_vm._v("Loading")])
}
var staticRenderFns = []

export { render, staticRenderFns }