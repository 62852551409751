import EmailHistory from './EmailHistory.vue';
import { Props } from './types';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetEmailHistoryQuery } from 'shared/generated/graphql-types';

const getEmailHistoryEnhancer = wrapComponent<Props, Pick<Props, 'emailHistory' | 'loading'>>(
  (props) => {
    const { loading, result } = useGetEmailHistoryQuery(
      computed(() => ({ toAddress: props.parent.email || '' }))
    );

    return computed(() => ({
      loading: loading.value,
      emailHistory: result.value?.emailHistory || []
    }));
  }
);

export default compose(getEmailHistoryEnhancer)(EmailHistory);
