import { computed } from 'vue';
import { MutateResult } from '@vue/apollo-composable';
import { wrapComponent } from 'shared/apollo-hoc';
import AddEventToSeriesForm from './AddEventToSeriesForm.vue';
import { withRouter } from 'shared/components/router';
import { compose } from 'vue-compose';
import { Filters, Dates } from '../../types';
import {
  AddEventToSeriesMutation,
  EventInput,
  GetSeriesEventsDocument,
  GetSeriesEventsQuery,
  GetSingleSeriesDocument,
  GetSingleSeriesQuery,
  useAddEventToSeriesMutation
} from 'shared/generated/graphql-types';

type Event = AddEventToSeriesMutation['scheduleEvent'];
type Series = GetSingleSeriesQuery['singleSeries'];

interface Props {
  series: Series;
  addingEvent: boolean;
  dates: Dates;
  filters: Filters;
}

interface AddEventToSeriesProps {
  addEventToSeries: (
    event: EventInput,
    targetEvent: Event
  ) => MutateResult<AddEventToSeriesMutation>;
  addingEvent: boolean;
}
export const addEventToSeriesEnhancer = wrapComponent<Props, AddEventToSeriesProps>((props) => {
  const { loading, mutate } = useAddEventToSeriesMutation();

  return computed(() => ({
    addEventToSeries: (event, targetEvent) =>
      mutate(
        { event },
        {
          optimisticResponse: {
            scheduleEvent: {
              ...targetEvent,
              ...(event as Event),
              chapterId: event.chapterID!,
              chapter: targetEvent.chapter,
              EventTrack: targetEvent.EventTrack,
              EventSubType: targetEvent.EventSubType,
              Owner: targetEvent.Owner,
              address2: targetEvent.address2,
              eventId: Math.round(Math.random() * -1000000),
              __typename: 'Event'
            }
          },
          update: (proxy, { data: updateData }) => {
            try {
              const data = proxy.readQuery<GetSeriesEventsQuery>({
                query: GetSeriesEventsDocument,
                variables: {
                  seriesId: props.series.seriesID,
                  filter: {
                    upcoming: props.filters.upcoming,
                    range: {
                      startDate: props.dates.startDate,
                      endDate: props.dates.endDate
                    }
                  }
                }
              });
              if (data?.seriesEvents && updateData?.scheduleEvent) {
                const events = data.seriesEvents.events.slice();
                events.push(updateData.scheduleEvent);
                proxy.writeQuery<GetSeriesEventsQuery>({
                  query: GetSeriesEventsDocument,
                  variables: {
                    seriesId: props.series.seriesID,
                    filter: {
                      upcoming: props.filters.upcoming,
                      range: {
                        startDate: props.dates.startDate,
                        endDate: props.dates.endDate
                      }
                    }
                  },
                  data: {
                    seriesEvents: {
                      ...data.seriesEvents,
                      events,
                      total: data.seriesEvents.total + 1
                    }
                  }
                });
              }

              const singleSeries = proxy.readQuery<GetSingleSeriesQuery>({
                query: GetSingleSeriesDocument,
                variables: {
                  seriesID: props.series.seriesID
                }
              });
              if (singleSeries?.singleSeries && updateData?.scheduleEvent) {
                const events = singleSeries.singleSeries.Events.slice();
                events.push(updateData.scheduleEvent);
                proxy.writeQuery<GetSingleSeriesQuery>({
                  query: GetSingleSeriesDocument,
                  variables: {
                    seriesID: props.series.seriesID
                  },
                  data: {
                    singleSeries: {
                      ...singleSeries.singleSeries,
                      Events: events
                    }
                  }
                });
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
      ),
    addingEvent: loading.value
  }));
});

export const enhancer = compose(withRouter, addEventToSeriesEnhancer)(AddEventToSeriesForm);

export default enhancer;
