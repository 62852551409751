import { computed } from 'vue';
import { compose, withData } from 'vue-compose';
import {
  getCurrentAddress,
  getCurrentLandlineNumber,
  getCurrentCellNumber,
  getCurrentEmail,
  formatAddressToString
} from 'shared/util';
import TeenViewModel from './shared/TeenViewModel';
import SelectDuplicatesFields from './SelectDuplicatesFields.vue';
import { mergeTeenDuplicatesEnhancer } from './mergeTeenDuplicatesEnhancer';
import {
  Gender,
  GetTeensByIdQuery,
  Origin,
  useGetTeensByIdQuery
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { wrapComponent } from 'shared/apollo-hoc';

type Teen = ArrayElement<GetTeensByIdQuery['teensByID']>;

interface Props {
  teenIds: number[];
}

const toViewModel = (teens: Teen[]): TeenViewModel[] => {
  return teens.map((teen) => ({
    name: `${teen.Person.firstName} ${teen.Person.lastName}`,
    personId: teen.personID,
    regionId: teen.regionId,
    firstName: teen.Person.firstName || '',
    lastName: teen.Person.lastName || '',
    dateCreated: teen.dateCreated,
    dateUpdated: teen.dateUpdated,
    origin: teen.Person.origin || Origin.Unknown,
    address: {
      fullAddress: formatAddressToString(getCurrentAddress(teen.Person.Addresses || [])),
      id: getCurrentAddress(teen.Person.Addresses).id
    },
    school: teen.School ? { schoolName: teen.School.name, id: teen.School.schoolID } : null,
    landline: getCurrentLandlineNumber(teen.Person.Phones || []),
    cellphone: getCurrentCellNumber(teen.Person.Phones || []),
    email: getCurrentEmail(teen.Person.EmailAddresses || []),
    gender: teen.Person.gender === 0 ? Gender.Male : Gender.Female,
    birthDate: teen.birthDate,
    graduationYear: teen.graduationYear,
    vegetarian: teen.vegetarian,
    isJewish: teen.Person.isJewish,
    gapYear: teen.GapYearProgram
      ? { schoolName: teen.GapYearProgram.name, id: teen.GapYearProgram.schoolID }
      : null,
    shanaBet: teen.ShanaBet ? { schoolName: teen.ShanaBet.name, id: teen.ShanaBet.schoolID } : null,
    college: teen.College ? { schoolName: teen.College.name, id: teen.College.schoolID } : null,
    chapter: teen.Chapter,
    hasProfilePicture: !!teen.hasProfilePicture,
    medicines: teen.medicines,
    Registrations: teen.Registrations,
    families: teen.Person.ChildOf || []
  }));
};

const getTeensByIdEnhancer = wrapComponent<Props, { teenDuplicates: TeenViewModel[] }>((props) => {
  const { result } = useGetTeensByIdQuery(
    computed(() => ({
      personID: props.teenIds
    }))
  );

  return computed(() => ({
    teenDuplicates: toViewModel((result.value || { teensByID: [] }).teensByID || [])
  }));
});

export default compose(
  mergeTeenDuplicatesEnhancer,
  withData({
    mergingTeen: {
      initialValue: false
    }
  }),
  getTeensByIdEnhancer
)(SelectDuplicatesFields);
