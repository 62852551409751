import { computed } from 'vue';
import { Props, CommunityMemberUpdateArgs } from 'shared/components/CommunityMemberProfile/types';
import { calcCommunityMemberUpdate } from 'shared/components/CommunityMemberProfile/utils';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  CreateAddress,
  CreateEmail,
  CreatePhone,
  ExtendedPersonFragment,
  ExtendedPersonFragmentDoc,
  UpdateAddress,
  UpdateEmail,
  UpdatePerson,
  UpdatePhone,
  useCreateAddressMutation,
  useCreateEmailMutation,
  useCreatePhoneMutation,
  useDeletePhoneMutation,
  useUpdateAddressMutation,
  useUpdateEmailMutation,
  useUpdatePersonMutation,
  useUpdatePhoneMutation
} from 'shared/generated/graphql-types';

interface DeletePhoneProp {
  deletePhone: (id: number) => void;
}
export const deletePhoneEnhancer = wrapComponent<Props & { personId?: number }, DeletePhoneProp>(
  (props) => {
    const { mutate } = useDeletePhoneMutation();

    return computed(() => ({
      deletePhone: (id) =>
        mutate(
          { id },
          {
            optimisticResponse: {
              deletePhone: true
            },
            update: (client, { data }) => {
              const personId = props.personId || props.personID;
              const communityMember = client.readFragment<ExtendedPersonFragment>({
                fragment: ExtendedPersonFragmentDoc,
                id: `${personId}Person`,
                fragmentName: 'ExtendedPerson'
              });
              if (communityMember) {
                client.writeFragment<ExtendedPersonFragment>({
                  fragment: ExtendedPersonFragmentDoc,
                  id: `${personId}Person`,
                  fragmentName: 'ExtendedPerson',
                  data: {
                    ...communityMember,
                    Phones: communityMember.Phones.filter((p) => p.id !== id)
                  }
                });
              }
            }
          }
        )
    }));
  }
);

interface UpdatePersonProps {
  updatePerson: (args: UpdatePerson, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  updatingPerson: boolean
}
export const updatePersonEnhancer = wrapComponent<Props, UpdatePersonProps>((props) => {
  const { loading, mutate } = useUpdatePersonMutation();

  return computed(() => ({
    updatePerson: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                updatePerson: {
                  ...calcCommunityMemberUpdate(
                    props.communityMember.original,
                    optimisticResponseData
                  ),
                  liabilitySigned: false,
                  Children: []
                }
              }
            : undefined
        }
      ),
    updatingPerson: loading.value
  }));
});

interface UpdateEmailProps {
  updateEmail: (args: UpdateEmail, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  updatingEmail: boolean
}
export const updateEmailEnhancer = wrapComponent<Props, UpdateEmailProps>((props) => {
  const { loading, mutate } = useUpdateEmailMutation();

  return computed(() => ({
    updateEmail: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                updateEmail: {
                  ...calcCommunityMemberUpdate(
                    props.communityMember.original,
                    optimisticResponseData
                  ),
                  liabilitySigned: false,
                  Children: []
                }
              }
            : undefined
        }
      ),
    updatingEmail: loading.value
  }));
});

interface CreateEmailProps {
  createEmail: (args: CreateEmail, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  creatingEmail: boolean
}
export const createEmailEnhancer = wrapComponent<Props, CreateEmailProps>((props) => {
  const { loading, mutate } = useCreateEmailMutation();

  return computed(() => ({
    createEmail: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                createEmail: {
                  ...calcCommunityMemberUpdate(
                    props.communityMember.original,
                    optimisticResponseData
                  ),
                  liabilitySigned: false,
                  Children: []
                }
              }
            : undefined
        }
      ),
    creatingEmail: loading.value
  }));
});

interface UpdatePhoneProps {
  updatePhone: (args: UpdatePhone, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  updatingPhone: boolean
}
export const updatePhoneEnhancer = wrapComponent<Props, UpdatePhoneProps>((props) => {
  const { loading, mutate } = useUpdatePhoneMutation();

  return computed(() => ({
    updatePhone: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                updatePhone: {
                  ...calcCommunityMemberUpdate(
                    props.communityMember.original,
                    optimisticResponseData
                  ),
                  liabilitySigned: false,
                  Children: []
                }
              }
            : undefined
        }
      ),
    updatingPhone: loading.value
  }));
});

interface CreatePhoneProps {
  createPhone: (args: CreatePhone, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  creatingPhone: boolean
}
export const createPhoneEnhancer = wrapComponent<Props, CreatePhoneProps>((props) => {
  const { loading, mutate } = useCreatePhoneMutation();

  return computed(() => ({
    createPhone: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                createPhone: {
                  ...calcCommunityMemberUpdate(
                    props.communityMember.original,
                    optimisticResponseData
                  ),
                  liabilitySigned: false,
                  Children: []
                }
              }
            : undefined
        }
      ),
    creatingPhone: loading.value
  }));
});

interface UpdateAddressProps {
  updateAddress: (args: UpdateAddress, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  updatingAddress: boolean
}
export const updateAddressEnhancer = wrapComponent<Props, UpdateAddressProps>((props) => {
  const { loading, mutate } = useUpdateAddressMutation();

  return computed(() => ({
    updateAddress: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                updateAddress: {
                  ...calcCommunityMemberUpdate(
                    props.communityMember.original,
                    optimisticResponseData
                  ),
                  liabilitySigned: false,
                  Children: []
                }
              }
            : undefined
        }
      ),
    updatingAddress: loading.value
  }));
});

interface CreateAddressProps {
  createAddress: (args: CreateAddress, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  creatingAddress: boolean
}
export const createAddressEnhancer = wrapComponent<Props, CreateAddressProps>((props) => {
  const { loading, mutate } = useCreateAddressMutation();

  return computed(() => ({
    createAddress: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                createAddress: {
                  ...calcCommunityMemberUpdate(
                    props.communityMember.original,
                    optimisticResponseData
                  ),
                  liabilitySigned: false,
                  Children: []
                }
              }
            : undefined
        }
      ),
    creatingAddress: loading.value
  }));
});
