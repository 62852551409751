import Profile from './Profile.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { withRouter } from 'shared/components/router';
import { Permission } from '../../types';
import currentUser from 'shared/components/CurrentUserEnhancer';
import { StaffCopy } from './types';
import {
  CreateFellowInput,
  StaffDocument,
  StaffQuery,
  UpdateStaffInput,
  useAddGlaubachSeasonMutation,
  useGetPermissionsQuery,
  useGetRolesQuery,
  useUpdateStaffMutation
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

interface Props {
  regionId: number;
  updateStaff: (staff: UpdateStaffInput, staffViewModel: StaffCopy) => void;
  rolesPermissionsMap: { [k: string]: Permission[] };
  permissions: Permission[];
  selectablePermissions: Permission[];
  addGlaubachSeason: (staff: CreateFellowInput) => void;
  addingGlaubachSeason: boolean;
}

const updateStaffEnhancer = wrapComponent<Props, Pick<Props, 'updateStaff'>>((props) => {
  const { mutate } = useUpdateStaffMutation();

  return computed(() => ({
    updateStaff: (staff, staffViewModel) =>
      mutate(
        { staff, regionId: props.regionId },
        {
          optimisticResponse: {
            updateStaff: {
              AdvisorRegionId: staff.advisorRegionId!,
              roleId: staff.roleId || null,
              active: staff.active,
              staffType: staff.staffType || null,
              ...(staff.chapterId
                ? {
                    Chapter: {
                      chapterName: staff.chapterName!,
                      chapterId: staff.chapterId,
                      __typename: 'Chapter'
                    }
                  }
                : {
                    Chapter: null
                  }),
              MetaData: [],
              Permissions: staffViewModel.permissions.map((p) => ({
                ...p,
                __typename: 'Permission' as 'Permission'
              })),
              Region: {
                regionName: staff.regionName,
                regionId: props.regionId,
                __typename: 'Region'
              },
              Staff: {
                staffID: staff.staffId,
                firstName: staff.firstName,
                bio: staffViewModel.bio || '',
                lastName: staff.lastName,
                thumbnail: '',
                fullName: `${staff.firstName} ${staff.lastName}`,
                gender: staff.gender,
                street: staff.street || null,
                city: staff.city || null,
                state: staff.state || null,
                zipCode: staff.zipCode || null,
                country: staff.country || null,
                birthDate: staff.birthDate || null,
                primaryPhone: staff.primaryPhone || null,
                secondaryPhone: staff.secondaryPhone || null,
                email: staff.email || null,
                GlaubachFellows: [
                  {
                    __typename: 'GlaubachFellow',
                    id: 1,
                    staffId: 2,
                    community: '',
                    season: '2020-2021'
                  }
                ],
                EventStaff: [],
                __typename: 'Staff'
              },
              __typename: 'AdvisorRegion'
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<StaffQuery>({
              query: StaffDocument,
              variables: { staffID: staff.staffId, regionId: props.regionId }
            });

            if (data?.singleStaff && updateData?.updateStaff) {
              const singleStaff = {
                ...data.singleStaff,
                ...updateData.updateStaff
              };

              proxy.writeQuery<StaffQuery>({
                query: StaffDocument,
                variables: {
                  regionId: props.regionId,
                  staffID: staff.staffId
                },
                data: {
                  singleStaff
                }
              });
            }
          }
        }
      )
  }));
});

const getRolesEnhancer = wrapComponent<Props, Pick<Props, 'rolesPermissionsMap'>>(() => {
  const { result } = useGetRolesQuery();

  return computed(() => ({
    rolesPermissionsMap: result.value?.roles
      ? result.value.roles.reduce(
          (o, k) => ({
            ...o,
            [k.Name as string]: k.Permissions.map((p) => ({
              id: p.id,
              Name: p.Name,
              description: p.description
            }))
          }),
          {}
        )
      : {}
  }));
});

const getPermissionsEnhancer = wrapComponent<
  Props,
  Pick<Props, 'permissions' | 'selectablePermissions'>
>(() => {
  const { result } = useGetPermissionsQuery();

  return computed(() => {
    const data = (result.value?.permissions || []).map((p) => ({
      id: p.id,
      Name: p.Name,
      description: p.description
    }));
    return {
      permissions: data,
      selectablePermissions: data.filter((p) => p.Name !== 'GlaubachFellow')
    };
  });
});

const addGlaubachSeasonEnhancer = wrapComponent<
  Props,
  Pick<Props, 'addGlaubachSeason' | 'addingGlaubachSeason'>
>((props) => {
  const { loading, mutate } = useAddGlaubachSeasonMutation();

  return computed(() => ({
    addGlaubachSeason: (staff) =>
      mutate(
        { staff },
        {
          optimisticResponse: {
            addGlaubachSeason: {
              __typename: 'GlaubachFellow',
              id: -1,
              community: staff.community || '',
              season: staff.season || '',
              staffId: staff.staffId
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<StaffQuery>({
              query: StaffDocument,
              variables: { staffID: staff.staffId, regionId: props.regionId }
            });

            if (data?.singleStaff && updateData?.addGlaubachSeason) {
              const singleStaff = {
                ...data.singleStaff,
                Staff: {
                  ...data.singleStaff.Staff,
                  GlaubachFellows: [
                    ...data.singleStaff.Staff.GlaubachFellows,
                    updateData.addGlaubachSeason
                  ]
                }
              };

              proxy.writeQuery<StaffQuery>({
                query: StaffDocument,
                variables: {
                  regionId: props.regionId,
                  staffID: staff.staffId
                },
                data: {
                  singleStaff
                }
              });
            }
          }
        }
      ),
    addingGlaubachSeason: loading.value
  }));
});

export default compose(
  withRouter,
  updateStaffEnhancer,
  getRolesEnhancer,
  getPermissionsEnhancer,
  currentUser,
  addGlaubachSeasonEnhancer
)(Profile);
