import Emails from './Emails.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetEmailHistoryQuery, useGetEmailHistoryQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type EmailHistory = ArrayElement<GetEmailHistoryQuery['emailHistory']>;

interface Props {
  teen: {
    email: string | null;
  };
}

interface GetEmailHistoryProps {
  emailHistory: EmailHistory[];
  loading: boolean;
}

const getEmailHistoryEnhancer = wrapComponent<Props, GetEmailHistoryProps>((props) => {
  const { loading, result } = useGetEmailHistoryQuery(
    computed(() => ({ toAddress: props.teen.email || '' })),
    { enabled: computed(() => !!props.teen.email) }
  );

  return computed(() => ({
    emailHistory: result.value?.emailHistory || [],
    loading: loading.value
  }));
});

const enhancer = compose(getEmailHistoryEnhancer);

export default enhancer(Emails);
