import EventSubTypeLookup from './EventSubTypeLookup.vue';
import { computed } from 'vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetEventSubTypesQuery, useGetEventSubTypesQuery } from 'shared/generated/graphql-types';

type ChildProps = {
  loading: boolean;
  eventSubTypes: GetEventSubTypesQuery['eventSubTypes'];
};

export default wrapComponent<{}, ChildProps>(() => {
  const { loading, result } = useGetEventSubTypesQuery();

  return computed(() => ({
    eventSubTypes: result.value?.eventSubTypes || [],
    loading: loading.value
  }));
})(EventSubTypeLookup);
