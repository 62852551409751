import InteractionTypeLookup from './InteractionTypeLookup.vue';
import { Props } from './types';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetInteractionTypesQuery } from 'shared/generated/graphql-types';

const getInteractionTypes = wrapComponent<
  { personId: number },
  Pick<Props, 'interactionTypes' | 'loading'>
>(() => {
  const { loading, result } = useGetInteractionTypesQuery();

  return computed(() => ({
    interactionTypes: result.value?.interactionTypes.map((i) => ({
      id: i.id,
      name: i.name
    })),
    loading: loading.value
  }));
});

export default compose(getInteractionTypes)(InteractionTypeLookup);
