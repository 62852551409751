import Financials from './Financials.vue';
import { compose } from 'vue-compose';
import { withRouter } from 'shared/components/router';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetFinancialsQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';

const getRegistrations = wrapComponent<Props, Pick<Props, 'event'>>((props) => {
  const { result } = useGetFinancialsQuery(computed(() => ({ eventID: props.eventId })));

  return computed(() => ({
    event: result.value?.event
  }));
});

export const enhancer = compose(withRouter, getRegistrations);

export default enhancer(Financials);
