import JFEParents from './JFEParents.vue';
import { Props } from './types';
import { thisSchoolYear } from '../shared/seasonHelper';
import { ActiveFilters } from '../shared/types';
import Vue, { computed, VueConstructor } from 'vue';
import { normalizeProps, getOptions } from 'shared/util';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetJfeParentsQuery } from 'shared/generated/graphql-types';

const withFilters = (Component: VueConstructor) => {
	const props = normalizeProps(getOptions(Component).props);
	const { activeFilters, setFilters, ...propsToUse } = props;

	return Vue.extend<{ activeFilters: ActiveFilters}, {setFilters: (args: Partial<ActiveFilters>, cb?: () => void) => void}, {}, {}>({
		name: `${Component.name}WithFilters`,
		props: propsToUse,
		data() {
			return {
				activeFilters: {
					term: '',
          season: thisSchoolYear(),
					hasAllergyPlan: false,
					hasIEPPlan: false
				}
			};
		},
		methods: {
      setFilters(args: Partial<ActiveFilters>, cb?: () => void) {
        this.activeFilters = { ...this.activeFilters, ...args };
        if (cb) {
          cb();
        }
      }
		},
		render(h) {
			return h(Component, {
				props: {
					...this.$props,
					activeFilters: this.activeFilters,
					setFilters: this.setFilters
				},
				on: this.$listeners
			});
		}
	});
};

const getParentsEnhancer = wrapComponent<Pick<Props, 'activeFilters'>, Pick<Props, 'parents' | 'loading' | 'initialLoading' | 'fetchMore'>>((props) => {
	const { fetchMore, loading, result} = useGetJfeParentsQuery(computed(() => ({
		limit: 60,
		filter: {
			query: props.activeFilters && props.activeFilters.term,
			season: props.activeFilters && props.activeFilters.season,
		}
	})), {fetchPolicy: 'network-only'})

	return computed(() => ({
		initialLoading: loading.value && !result.value?.jfeParents,
		loading: loading.value,
		parents: result.value?.jfeParents || [],
		fetchMore: ({limit, offset}, stateChanger) => fetchMore({
			variables: {
				limit,
				offset,
				filter: {
					query: props.activeFilters && props.activeFilters.term,
					season: props.activeFilters && props.activeFilters.season,
				}
			},
			updateQuery(previousResult, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previousResult;
				}
				if (!fetchMoreResult.jfeParents.length || fetchMoreResult.jfeParents.length < limit) {
					stateChanger.complete();

					if (!fetchMoreResult.jfeParents.length) {
						return previousResult;
					}
				}

				const ids = previousResult.jfeParents.map(x => x.personID);
				const newData = fetchMoreResult.jfeParents.filter(x => !ids.includes(x.personID));
				return {
					jfeParents: [...previousResult.jfeParents, ...newData]
				};
			}
		}),
  }));
});

export default compose(
  withFilters,
  getParentsEnhancer
)(JFEParents);
