var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.report)?_c('li',{staticClass:"report"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"star-box"},[_c('label',{staticClass:"checkbox",class:{ active: _vm.starred }},[_c('input',{attrs:{"type":"checkbox","id":_vm.report.name,"name":_vm.report.name},domProps:{"checked":_vm.starred},on:{"input":function($event){return _vm.toggleStar(_vm.report.id)}}}),_c('span',{staticClass:"check"},[_c('font-awesome-icon',{staticClass:"star",class:{ starred: _vm.starred },attrs:{"icon":['fas', 'star']},on:{"click":function($event){}}})],1)])]),_c('div',{staticClass:"report-text",on:{"click":function($event){_setup.showCtrl = !_setup.showCtrl}}},[(_setup.error && _setup.showCtrl)?_c('span',{class:{ error: _setup.error && _setup.showCtrl }},[_vm._v("Please fill out all the fields")]):_vm._e(),_c('header',{staticClass:"report-title"},[_vm._v(" "+_vm._s(_vm.report.name)+" ")]),_c('div',{staticClass:"report-desc"},[_vm._v(" "+_vm._s(_vm.report.description)+" ")])])]),(_setup.showCtrl)?_c('footer',[_c('div',{staticClass:"report-action"},[_c(_setup.ReportForm,{attrs:{"report":_vm.report,"regionId":_vm.regionId,"staffId":_vm.staffId},on:{"input":function($event){_setup.newReport[$event.field] = $event.value}}})],1),_c('div',{staticClass:"report-file"},[_c('span',{staticClass:"file-name"},[_c(_setup.UIInput,{attrs:{"placeholder":"File Name"},model:{value:(_setup.title),callback:function ($$v) {_setup.title=$$v},expression:"title"}})],1),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Excel file'),expression:"'Excel file'"}],staticClass:"file-icon file-icon--excel",class:{ active: _setup.fileType === _setup.ReportFormat.Excel },on:{"click":() => {
            _setup.fileType = _setup.ReportFormat.Excel;
          }}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-excel']}})],1),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Word file'),expression:"'Word file'"}],staticClass:"file-icon file-icon--word",class:{ active: _setup.fileType === _setup.ReportFormat.Word },on:{"click":() => {
            _setup.fileType = _setup.ReportFormat.Word;
          }}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-word']}})],1),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('PDF file'),expression:"'PDF file'"}],staticClass:"file-icon file-icon--pdf",class:{ active: _setup.fileType === _setup.ReportFormat.Pdf },on:{"click":() => {
            _setup.fileType = _setup.ReportFormat.Pdf;
          }}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-pdf']}})],1),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('CSV file'),expression:"'CSV file'"}],staticClass:"file-icon file-icon--csv",class:{ active: _setup.fileType === _setup.ReportFormat.Csv },on:{"click":() => {
            _setup.fileType = _setup.ReportFormat.Csv;
          }}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-csv']}})],1),_c(_setup.UIButton,{attrs:{"data-test-id":"generate"},on:{"click":_setup.generateHandler}},[_vm._v(_vm._s(_vm.generating ? 'Generating...' : 'Generate'))])],1)]):_vm._e(),_c(_setup.Modal,{attrs:{"show":_setup.showErrorModal,"requestClose":() => (_setup.showErrorModal = false)},scopedSlots:_vm._u([{key:"default",fn:function({ requestClose }){return [_c('div',{staticClass:"action-modal"},[_c('h1',[_vm._v("Oops something went wrong")]),_c('p',[_vm._v("an error has occured")]),_c('p',[_vm._v(" email "),_c('a',{attrs:{"href":"mailto:support@ncsy.org"},on:{"click":requestClose}},[_vm._v("support@ncsy.org")]),_vm._v(" or send us a message ")]),_c('div',{staticClass:"actions"},[_c(_setup.UIButton,{attrs:{"tag":"span"},on:{"click":_setup.sendEmailHandler}},[_vm._v("Send message")]),_c(_setup.UIButton,{staticClass:"inverted",attrs:{"tag":"span"},on:{"click":function($event){return requestClose()}}},[_vm._v("No")])],1)])]}}],null,false,3033575581)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }