import Single from './Single.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useStaffQuery } from 'shared/generated/graphql-types';
import { staffViewModel } from '../../Staff/Single';

type StaffProps = Pick<Props, 'staff' | 'loading'>;

const getSingleStaff = wrapComponent<Props, StaffProps>((props) => {
  const { loading, result } = useStaffQuery(computed(() => ({ staffID: +props.staffId })));

  return computed(() => ({
    loading: loading.value,
    staff: result.value?.singleStaff ? staffViewModel(result.value.singleStaff) : null
  }));
});

export const enhancer = compose(getSingleStaff);

export default enhancer(Single);
