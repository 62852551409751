import { Store } from 'vuex';
import { RootState } from '..';

export const useOidcStore = (store: Store<RootState>) => {
  const signOutOidc = () => {
    store.dispatch('oidc/signOutOidc');
  };

  return { signOutOidc };
};
