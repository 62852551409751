import { defineComponent, h, ref } from 'vue';

export default defineComponent({
  props: {
    multiple: { type: Boolean, default: false },
    accept: { type: String, default: '*' }
  },
  emits: {
    fileSelected: (value: File[] | File) => true
  },
  setup(props, { emit, slots }) {
    const input = ref<HTMLInputElement | undefined>();

    function handleClick() {
      if (input.value) {
        input.value.click();
      }
    }

    function emitFile({ target }: { target: { files: File[] } }) {
      if (props.multiple) emit('fileSelected', target.files);
      else emit('fileSelected', target.files[0]);
    }

    return () =>
      h('div', { on: { click: handleClick } }, [
        ...((slots.default && slots.default()) || []),
        h('div', [
          h('input', {
            ref: input,
            on: {
              change: emitFile
            },
            style: { display: 'none' },
            attrs: {
              accept: props.accept,
              type: 'file',
              multiple: props.multiple
            }
          })
        ])
      ]);
  }
});
