import { Module } from 'vuex'
import { RootState } from '..'

export type RegionState = {
  currentRegion: number | null
}

const module: Module<RegionState, RootState> = {
  namespaced: true,
  state: {
    currentRegion: null
  },
  mutations: {
    updateCurrentRegion: (state, currentRegion: number | null) => {
      state.currentRegion = currentRegion
    }
  },
  actions: {
    getCurrentRegion: (context) => context.state.currentRegion,
    setCurrentRegion: (context, currentRegion: number | null) => {
      context.commit('updateCurrentRegion', currentRegion)
    }
  }
}

export default module
