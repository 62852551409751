import { compose, withData } from 'vue-compose';
import NotificationsContainer from './NotificationsContainer.vue';
import { useNotificationsStore } from 'store/notifications/useNotificationsStore';
import { createStore } from 'store/index';

const store = createStore();
const { notifications, removeNotification } = useNotificationsStore(store);

export default compose(
  withData({
    notifications: {
      initialValue: notifications
    },
    removeNotification: {
      initialValue: () => removeNotification
    }
  })
)(NotificationsContainer);
