import { computed } from 'vue'
import { Store } from 'vuex'
import { RootState } from '..'
import { NotificationsState } from './notifications'

export const useNotificationsStore = (store: Store<RootState>) => {
  const notifications = computed(() => (store.state.notifications as NotificationsState).notifications)

  const addNotification = (content: string) => {
    store.commit('notifications/addNotification', content)
  }

  const removeNotification = (id: string) => {
    store.commit('notifications/removeNotification', id)
  }

  return { notifications, addNotification, removeNotification}
}
