import Vue, { CreateElement, VNode } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import assign from 'lodash/assign';
import assignFP from 'lodash/fp/assign';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

export type ValidationResult = false | {
  [x: string]: any
};

export type StepData = null | {[x: string]: any};

export interface Step {
  active?: boolean,
  completed?: boolean,
  validating?: boolean,
  validations?: ValidationResult,
  data?: StepData
}

export interface Steps {
  [x: string]: Step;
}

interface Data {
  isControlled: boolean;
  state: { steps: Steps };
}

interface Props {
  steps: string[];
  validate: (step: string, date?: StepData, steps?: Steps) => ValidationResult;
  stepData: Steps;
  novalidate: boolean;
}

interface Methods {
  updateStepData: (nextStepData: object) => void;
  getSteps: () => string[];
  complete: (step: string, markInactive: boolean) => void;
  startValidatationProcces: (step: string, nextStepData: Steps) => Promise<ValidationResult>;
  setData: (step: string, key: string, value: any) => void;
  getData: (step: string, key: string) => StepData;
  toggleActive: (step: string) => void;
  activatable: (step: string) => boolean;
  finalStep: (step: string) => boolean;
  firstStep: (step: string) => boolean;
}

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'MultistepForm',
  data() {
    return {
      isControlled: !!this.stepData,
      state: this.stepData
      ? {
        steps: mapValues(cloneDeep(this.stepData), (step, key, collection) => ({
        ...step,
        active: step.active === undefined && key === Object.keys(collection)[0] ? true : step.active
        }))
      } : {
      steps: this.getSteps().reduce((steps: Steps, step: string, index: number) => ({
        ...steps,
        [step]: {
          active: index === 0,
          completed: false,
          validating: false,
          validations: false,
          data: null
        }
      }), {})
      }
    }
  },
  props: {
    steps: { default: () => [] },
    validate: {},
    stepData: {},
    novalidate: { default: false }
  },
  watch: {
    stepData(newV, oldV) {
      if (!isEqual(newV, oldV)) {
        this.state.steps = assignFP<Steps, Steps>(this.state.steps, this.stepData);
      }
    }
  },
  methods: {
    updateStepData (nextStepData) {
      if (!this.isControlled) {
        assign(this.state.steps, nextStepData);
      } else {
        this.$emit('update:stepData', assignFP(this.state.steps, nextStepData));
      }
    },
    getSteps() {
      if (this.isControlled) return Object.keys(this.state.steps);

      return this.steps;
    },
    async complete(step, markInactive = true) {
      if (!this.state.steps[step].active) {
        return;
      }
      let nextStepData = this.state.steps;
      // tslint:disable-next-line: forin
      for (const innerStep in this.state.steps) {
        nextStepData = set(`${innerStep}.validations`, false, nextStepData);
        this.updateStepData(nextStepData);
        if (this.state.steps[innerStep].active || this.finalStep(step)) {
          const validationResult = await this.startValidatationProcces(innerStep, nextStepData);

          if (!isEmpty(validationResult)) {
            await this.$nextTick();
            this.toggleActive(innerStep);
            await this.$nextTick();
            const errorElement = this.$el.querySelector('label.error-label')
            if (errorElement) {
              errorElement.scrollIntoView({block: 'center', inline: 'center', behavior: 'smooth'})
            }
            return;
          }
          nextStepData = set(`${innerStep}.completed`, true, nextStepData);
          if (markInactive) {
            nextStepData = set(`${innerStep}.active`, false, nextStepData);
          }
          this.updateStepData(nextStepData);
        }
      }

      if (this.finalStep(step)) {
        this.$emit(
          'submit',
          Object
            .entries(this.state.steps)
            .reduce((result, [step, data]) => ({
              ...result,
              [step]: data.data
            }), {})
        );
      } else {
        const currentStepIndex = this.getSteps().findIndex(x => x === step);
        const nextStep = this.getSteps()[currentStepIndex + 1];
        nextStepData = set(`${nextStep}.active`, true, nextStepData);
        this.updateStepData(nextStepData);
        await this.startValidatationProcces(nextStep, nextStepData);
      }
    },
    async startValidatationProcces (step, nextStepData) {
      return new Promise(async (resolve, reject) => {
        nextStepData = set(`${step}.validations`, false, nextStepData);
        nextStepData = set(`${step}.validating`, true, nextStepData);
        this.updateStepData(nextStepData);
  
        const validationResult = this.validate(step, this.state.steps[step].data, nextStepData);
    
        nextStepData = set(`${step}.validating`, false, nextStepData);
        this.updateStepData(nextStepData);
        if (typeof validationResult === 'object' && !isEmpty(validationResult)) {
          for (const validationStep of Object.keys(validationResult)) {
            nextStepData = set(`${validationStep}.validations`, false, nextStepData);
            this.updateStepData(nextStepData);
            await Vue.nextTick();
            nextStepData = set(`${validationStep}.validations`, validationResult[validationStep], nextStepData);
            this.updateStepData(nextStepData);
          }
          resolve(validationResult);
        }
        resolve(validationResult);
      });
    },
    async setData(step, key, value) {
      let nextStepData = this.state.steps;
  
      nextStepData = set(`${step}.data.${key}`, value, nextStepData);

      if (!isEqual(this.state.steps[step].data && this.state.steps[step].data![key], value)) {
        this.updateStepData(nextStepData);
        await Vue.nextTick();
        await this.startValidatationProcces(step, nextStepData);
        this.$emit('change', step, key, value, this);
      }
    },
    getData (step, key) {
      const data = this.state.steps[step].data;
  
      return data && get(data, key);
    },
    async toggleActive(step) {
      if (this.state.steps[step].active && !this.novalidate) {
        const result = this.validate(step, this.state.steps[step].data, this.state.steps);
        if (typeof result === 'object' && !isEmpty(result)) {
          return;
        }
      }
      const stepIndex = this.getSteps().findIndex(x => x === step);
  
      const prevStep = this.state.steps[this.getSteps()[stepIndex - 1]];
  
      if (!prevStep || prevStep.completed) {
        this.updateStepData(set(`${step}.active`, !this.state.steps[step].active, this.state.steps));
      }
    },
    activatable (step) {
      const stepIndex = this.getSteps().findIndex(x => x === step);
  
      const prevStep = this.state.steps[this.getSteps()[stepIndex - 1]];
  
      return !prevStep || !!prevStep.completed;
    },
    finalStep (step) {
      const stepIndex = this.getSteps().findIndex(x => x === step);
  
      return stepIndex + 1 === this.getSteps().length;
    },
    firstStep(step) {
      const stepIndex = this.getSteps().findIndex(x => x === step);
      return stepIndex === 0;
    }
  },
  render (h: CreateElement): VNode {
    const slot = this.$scopedSlots.default;

    if (!slot) {
      throw new Error('Please provide a scoped slot');
    }

    const result = slot({
      steps: this.state.steps,
      complete: this.complete,
      toggleActive: this.toggleActive,
      activatable: this.activatable,
      finalStep: this.finalStep,
      firstStep: this.firstStep,
      setData: this.setData,
      getData: this.getData
    });

    return !result || typeof result === 'string' || result.length > 0
      ? h('div', result)
      : result[0];
  }
})
