import { wrapComponent } from 'shared/apollo-hoc';
import AddPhone from './AddPhone.vue';
import { Props } from './types';
import { GetHoldersOfDuplicatePhoneDocument, GetHoldersOfDuplicatePhoneQuery, useCreatePhoneForDuplicateHolderMutation } from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { compose } from 'vue-compose';

type AddPhoneEnhancerProps = Pick<Props, 'addPhone' | 'addingPhone'>;
const addPhoneForDuplicateHolderEnhancer = wrapComponent<Props, AddPhoneEnhancerProps>((props) => {
  const { mutate, loading } = useCreatePhoneForDuplicateHolderMutation();

  return computed(() => ({
    addPhone: (args) => mutate({
      args,
      phoneNumber: props.selectedPhoneNumber
    }, {
      update: (proxy) => {
        const data = proxy.readQuery<GetHoldersOfDuplicatePhoneQuery>({
          query: GetHoldersOfDuplicatePhoneDocument,
          variables: {
            phoneNumber: props.selectedPhoneNumber
          }
        });
        if (data) {
          let local = data.holdersOfDuplicatePhone;
          const owner = local.find(h => h.personID === props.personId)!;
          if (!owner.Phones.find(p => p.phoneNumber === args.phoneNumber)) {
            owner.Phones.push({
              id: Math.round(Math.random() * -1000000),
              phoneNumber: args.phoneNumber,
              type: args.type!,
              dateCreated: new Date().toISOString(),
              dateUpdated: new Date().toISOString(),
              realType: args.type!,
              primary: false,
              __typename: 'Phone'
            });
          }

          proxy.writeQuery<GetHoldersOfDuplicatePhoneQuery>({
            query: GetHoldersOfDuplicatePhoneDocument,
            variables: {
              phoneNumber: props.selectedPhoneNumber
            },
            data: {
              holdersOfDuplicatePhone: local
            }
          });
        }
      }
    }),
    addingPhone: loading.value
  }));
});
export default compose(
  addPhoneForDuplicateHolderEnhancer
)(AddPhone);
