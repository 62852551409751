import InteractionForm from './InteractionForm.vue';
import { Props } from './types';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import padStart from 'lodash/padStart';
import { getCurrentSeason } from 'shared/util';
import { SimpleDate } from 'shared/util/types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  NoteCategory,
  TeenBaseFragment,
  TeenBaseFragmentDoc,
  TeenInteractionsFragment,
  TeenInteractionsFragmentDoc,
  useAddTeenInteractionMutation,
  useGetCurrentUserForInteractionsQuery,
  useUpdateTeenInteractionMutation
} from 'shared/generated/graphql-types';

function dateToString(date: SimpleDate) {
  return `${date.year}-${padStart(String(date.month), 2, '0')}-${padStart(
    String(date.day),
    2,
    '0'
  )}`;
}

const currentStaffEnhancer = wrapComponent<Props, Pick<Props, 'currentStaff'>>(() => {
  const { result } = useGetCurrentUserForInteractionsQuery();

  return computed(() => ({
    currentStaff: result.value?.me
  }));
});

const addInteractionEnhancer = wrapComponent<Props, Pick<Props, 'addInteraction'>>((props) => {
  const { mutate } = useAddTeenInteractionMutation();

  return computed(() => ({
    addInteraction: (args) =>
      mutate(
        {
          input: {
            staffId: args.staff.staffID,
            interactionTypeId: args.type.id,
            date: dateToString(args.date),
            // TODO we do need it on args to when we support this shared form from the staff screen - and in that case the staff id is from props
            personId: props.personId || 0,
            leadId: props.leadId || null,
            eventId: props.eventId || null,
            note: args.note
          }
        },
        {
          optimisticResponse: {
            addInteraction: {
              __typename: 'Interaction',
              Staff: {
                __typename: 'Staff',
                staffID: args.staff.staffID,
                fullName: args.staff.fullName
              },
              InteractionType: {
                __typename: 'InteractionType',
                ...args.type
              },
              Notes: [
                {
                  __typename: 'Note',
                  id: Math.round(Math.random() * -1000000),
                  category: NoteCategory.Interaction,
                  date: dateToString(args.date),
                  note: args.note || '',
                  Staff: {
                    __typename: 'Staff',
                    staffID: args.staff.staffID,
                    // can leave it empty since we show fullName
                    firstName: '',
                    lastName: '',
                    fullName: args.staff.fullName
                  }
                }
              ],
              date: dateToString(args.date),
              season: getCurrentSeason(),
              leadId: props.leadId || null,
              eventId: props.eventId || null,
              id: Math.round(Math.random() * -1000000),
              note: args.note || null
            }
          },
          update(client, { data: updateData }) {
            const interactionsData = client.readFragment<TeenInteractionsFragment>({
              fragment: TeenInteractionsFragmentDoc,
              id: `${props.personId}Teen`,
              fragmentName: 'TeenInteractions'
            });

            if (interactionsData && updateData?.addInteraction) {
              const interactions = interactionsData.Interactions.slice();
              interactions.push(updateData.addInteraction);
              client.writeFragment<TeenInteractionsFragment>({
                fragment: TeenInteractionsFragmentDoc,
                id: `${props.personId}Teen`,
                fragmentName: 'TeenInteractions',
                data: {
                  ...interactionsData,
                  Interactions: interactions
                }
              });
            }

            const teen = client.readFragment<TeenBaseFragment>({
              fragment: TeenBaseFragmentDoc,
              id: `${props.personId}Teen`,
              fragmentName: 'TeenBase'
            });

            if (teen && updateData?.addInteraction) {
              let notes = teen.Person.Notes.slice();
              notes = notes.concat(
                updateData.addInteraction.Notes.map((n) => ({
                  ...n,
                  staffId: n.Staff.staffID,
                  personId: props.personId
                }))
              );
              client.writeFragment<TeenBaseFragment>({
                fragment: TeenBaseFragmentDoc,
                id: `${props.personId}Teen`,
                fragmentName: 'TeenBase',
                data: {
                  ...teen,
                  Person: {
                    ...teen.Person,
                    Notes: notes
                  }
                }
              });
            }
          }
        }
      )
  }));
});

const editInteractionEnhancer = wrapComponent<Props, Pick<Props, 'editInteraction'>>((props) => {
  const { mutate } = useUpdateTeenInteractionMutation();

  return computed(() => ({
    editInteraction: (interaction, args) =>
      mutate(
        {
          input: {
            staffId: args.staff.staffID,
            interactionTypeId: args.type.id,
            date: dateToString(args.date),
            // TODO we do need it on args to when we support this shared form from the staff screen - and in that case the staff id is from props
            personId: props.personId || 0,
            note: args.note,
            // TODO have lookups for these fields, in the meantime, do not override
            leadId: interaction.leadId || null,
            eventId: interaction.eventId || null
          },
          interactionId: interaction.interactionId
        },
        {
          optimisticResponse: {
            updateInteraction: {
              __typename: 'Interaction',
              Staff: {
                __typename: 'Staff',
                staffID: args.staff.staffID,
                fullName: args.staff.fullName
              },
              Notes: [
                {
                  __typename: 'Note',
                  id: Math.round(Math.random() * -1000000),
                  category: NoteCategory.Interaction,
                  date: dateToString(args.date),
                  note: args.note || '',
                  Staff: {
                    __typename: 'Staff',
                    staffID: args.staff.staffID,
                    // can leave it empty since we show fullName
                    firstName: '',
                    lastName: '',
                    fullName: args.staff.fullName
                  }
                }
              ],
              InteractionType: {
                __typename: 'InteractionType',
                ...args.type
              },
              date: dateToString(args.date),
              // TODO Consider adding season to the fragment
              season: getCurrentSeason(),
              // TODO have lookups for these fields, in the meantime, do not override
              leadId: interaction.leadId || null,
              eventId: interaction.eventId || null,
              id: interaction.interactionId,
              note: args.note || null
            }
          },
          update(client, { data: updateData }) {
            const interactionsData = client.readFragment<TeenInteractionsFragment>({
              fragment: TeenInteractionsFragmentDoc,
              id: `${props.personId}Teen`,
              fragmentName: 'TeenInteractions'
            });

            if (interactionsData && updateData?.updateInteraction) {
              const interactions = interactionsData.Interactions.map((i) => {
                if (i.id === updateData.updateInteraction.id) {
                  return {
                    ...i,
                    Notes: updateData.updateInteraction.Notes,
                    note: i.Notes.map((n) => n.note).join('\n')
                  };
                } else {
                  return i;
                }
              });

              client.writeFragment<TeenInteractionsFragment>({
                fragment: TeenInteractionsFragmentDoc,
                id: `${props.personId}Teen`,
                fragmentName: 'TeenInteractions',
                data: {
                  ...interactionsData,
                  Interactions: interactions
                }
              });
            }

            const teen = client.readFragment<TeenBaseFragment>({
              fragment: TeenBaseFragmentDoc,
              id: `${props.personId}Teen`,
              fragmentName: 'TeenBase'
            });

            if (teen && updateData?.updateInteraction) {
              let notes = teen.Person.Notes.slice();
              notes = notes.concat(
                updateData.updateInteraction.Notes.map((n) => ({
                  ...n,
                  staffId: n.Staff.staffID,
                  personId: props.personId
                }))
              );

              client.writeFragment<TeenBaseFragment>({
                fragment: TeenBaseFragmentDoc,
                id: `${props.personId}Teen`,
                fragmentName: 'TeenBase',
                data: {
                  ...teen,
                  Person: {
                    ...teen.Person,
                    Notes: notes
                  }
                }
              });
            }
          }
        }
      )
  }));
});

export default compose(
  currentStaffEnhancer,
  addInteractionEnhancer,
  editInteractionEnhancer
)(InteractionForm);
