import { compose } from 'vue-compose'
import Vue, { VueConstructor } from 'vue';
import { getOptions, normalizeProps, getGrade } from 'shared/util';
import Layout from './Layout.vue';
import { teensPostHigeSchoolEnhancer, postHigeSchoolStatisticsEnhancer } from './teensPostHigeSchoolEnhancer';
import ActiveFilters, { ActiveFiltersType } from './shared/ActiveFilters';
import { filterOptionsEnhancer } from 'shared/enhancers/filterOptionsEnhancer';

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { activeFilters, setFilters, totalPages, resetFilters, setLimit, limit, ...propsToUse } = props;
  const today = new Date()
  const currentGraduationYear = today.getMonth() >= 6
    ? today.getFullYear() + 1
    : today.getFullYear()
  const { grade: displayValue, year: key } = getGrade(currentGraduationYear);

  return Vue.extend({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        limit: 50,
        activeFilters: new ActiveFilters({filters: { Grade: [{ key: String(key)!, displayValue }] } })
      };
    },
    methods: {
      setFilters(args: Partial<ActiveFiltersType>, cb?: () => void) {
        this.activeFilters = { ...this.activeFilters, ...args };
        if (cb) {
          cb();
        }
      },
      setLimit(limit: number) {
        this.limit = limit;
      },
      resetFilters() {
        this.activeFilters = new ActiveFilters();
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          limit: this.limit,
          activeFilters: this.activeFilters,
          setFilters: this.setFilters,
          resetFilters: this.resetFilters,
          setLimit: this.setLimit
        },
        on: this.$listeners
      });
    }
  });
};
export default compose(
  withFilters,
  teensPostHigeSchoolEnhancer,
  postHigeSchoolStatisticsEnhancer,
	filterOptionsEnhancer,
)(Layout);
