import { compose, withData } from 'vue-compose';
import SeriesDetails from './SeriesDetails.vue';

export const enhancer = compose(
  withData({
    modifyingRecurrence: {
      initialValue: false
    },
    addingEvent: {
      initialValue: false
    }
  })
);

export default enhancer(SeriesDetails);

