import { phoneInternational } from 'shared/util';
import {
  GetStaffProfileQuery,
  PermissionInput,
  UpdateStaffInput
} from 'shared/generated/graphql-types';

type Staff = NonNullable<GetStaffProfileQuery['singleStaff']>;

export interface Props {
  updateStaff: (staff: UpdateStaffInput, staffViewModel: StaffViewModel) => void;
  staffId: number;
  regionId: number;
  staff: StaffViewModel;
}
export enum StaffFieldsEnum {
  email = 'email',
  birthDate = 'birthDate',
  secondaryPhone = 'secondaryPhone',
  primaryPhone = 'primaryPhone',
  address = 'address'
}

export interface StaffViewModel {
  firstName: string;
  lastName: string;
  fullName: string;
  advisorRegionId: number;
  address: Address | null;
  formattedAddress: string | null;
  staffId: number;
  regionId: number;
  gender: number;
  street: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  country: string | null;
  email: string | null;
  primaryPhone: string | null;
  secondaryPhone: string | null;
  regionName: string;
  chapterId: number | null;
  chapterName: string | null;
  birthDate: Date | null;
  thumbnail: string;
  permissions: any[];
  original: Staff;
}

export class UpdateStaff {
  firstName: string;
  lastName: string;
  staffId: number;
  advisorRegionId: number;
  gender: number;
  street: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  country: string | null;
  email: string | null;
  primaryPhone: string | null;
  secondaryPhone: string | null;
  regionName: string;
  chapterId: number | null;
  regionId: number;
  chapterName: string | null;
  birthDate: Date | null;
  newPermissions: PermissionInput[] = [];
  removedPermissions: PermissionInput[] = [];
  active: boolean = true;

  constructor(args: StaffViewModel) {
    this.firstName = args.firstName;
    this.lastName = args.lastName;
    this.staffId = args.staffId;
    this.advisorRegionId = args.advisorRegionId;
    this.gender = args.gender;
    this.street = args.street;
    this.city = args.city;
    this.state = args.state;
    this.zipCode = args.zipCode;
    this.country = args.country;
    this.email = args.email;
    this.primaryPhone = args.primaryPhone ? phoneInternational(args.primaryPhone) : null;
    this.secondaryPhone = args.secondaryPhone ? phoneInternational(args.secondaryPhone) : null;
    this.regionName = args.regionName;
    this.chapterId = args.chapterId || null;
    this.regionId = args.regionId;
    (this.chapterName = args.chapterName || null), (this.birthDate = args.birthDate || null);
  }
}

export interface Address {
  city: string | null;
  state: string | null;
  country: string | null;
  zipCode: string | null;
  street: string | null;
}
