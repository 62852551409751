import {
  Address,
  CommunityMember,
  CommunityMemberViewModel,
  CommunityMemberUpdateArgs,
  CommunityMemberFieldsEnum
} from './types';
import {
  getCurrentEmail,
  getCurrentCellNumber,
  formatAddressToString,
  getCurrentLandlineNumber,
  getCurrentAddress
} from 'shared/util';
import trim from 'lodash/trim';
import { PhoneType } from 'shared/generated/graphql-types';

export const toCompactCommunityMember = (
  communityMember: CommunityMember
): CommunityMemberViewModel => {
  const currentAddressObject = getCurrentAddress(
    (communityMember && communityMember.Addresses) || []
  );
  return {
    address: currentAddressObject,
    formattedAddress: formatAddressToString(currentAddressObject),
    landline: getCurrentLandlineNumber(communityMember.Phones || [])
      ? getCurrentLandlineNumber(communityMember.Phones)!
      : null,
    cellphone: getCurrentCellNumber(communityMember.Phones || [])
      ? getCurrentCellNumber(communityMember.Phones)!
      : null,
    email: getCurrentEmail(communityMember.EmailAddresses || [])
      ? getCurrentEmail(communityMember.EmailAddresses)!.email
      : null,
    emails: communityMember.EmailAddresses,
    emailId: getCurrentEmail(communityMember.EmailAddresses || [])
      ? getCurrentEmail(communityMember.EmailAddresses)!.id
      : null,
    formattedLandline: getCurrentLandlineNumber(communityMember.Phones || [])
      ? getCurrentLandlineNumber(communityMember.Phones)!.phoneNumber
      : null,
    formattedCellphone: getCurrentCellNumber(communityMember.Phones || [])
      ? getCurrentCellNumber(communityMember.Phones)!.phoneNumber
      : null,
    firstName: communityMember.firstName,
    lastName: communityMember.lastName,
    fullName: trim(`${communityMember.firstName || ''} ${communityMember.lastName || ''}`),
    gender: communityMember.gender,
    olamiId: communityMember.olamiId,
    personId: communityMember.personID,
    dataOptOut: communityMember.dataOptOut,
    original: communityMember
  };
};

function insert<T extends Array<any>>(value: T[0], array: T, index: number) {
  return [...array.slice(0, index), value, ...array.slice(index + 1)];
}
export function calcCommunityMemberUpdate(
  communityMember: CommunityMember,
  update: CommunityMemberUpdateArgs
): CommunityMember {
  switch (update.fieldName) {
    case CommunityMemberFieldsEnum.address: {
      const currentAddress = getCurrentAddress(communityMember.Addresses);

      const index = communityMember.Addresses.findIndex((x) => x.id === currentAddress.id);
      const value: Address = JSON.parse(update.value!);

      (value.__typename = 'Address'), (value.dateCreated = new Date().toISOString());
      value.dateUpdated = new Date().toISOString();
      value.id = -1;
      value.primary = true;
      value.verified = false;

      if (index === -1) {
        return {
          ...communityMember,
          Addresses: [...communityMember.Addresses, value]
        };
      }

      return {
        ...communityMember,
        Addresses: insert(
          {
            ...currentAddress,
            ...value
          },
          communityMember.Addresses,
          index
        )
      };
    }
    case CommunityMemberFieldsEnum.cellphone:
      const cellPhone = getCurrentCellNumber(communityMember.Phones);
      const newPhone: CommunityMember = {
        ...communityMember,
        Phones: [
          {
            __typename: 'Phone',
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            description: null,
            doNotCall: null,
            id: -1,
            invalid: false,
            phoneNumber: update.value!,
            primary: true,
            type: PhoneType.Mobile
          }
        ]
      };

      if (!cellPhone) {
        return newPhone;
      }

      const index = communityMember.Phones.findIndex((x) => x.id === cellPhone.id);

      if (index === -1) {
        return newPhone;
      }

      return {
        ...communityMember,
        Phones: insert(
          {
            ...cellPhone,
            phoneNumber: update.value!
          },
          communityMember.Phones,
          index
        )
      };
    case CommunityMemberFieldsEnum.landline: {
      const homeNumber = getCurrentLandlineNumber(communityMember.Phones);

      const newPhone: CommunityMember = {
        ...communityMember,
        Phones: [
          {
            __typename: 'Phone',
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            description: null,
            doNotCall: null,
            id: -1,
            invalid: false,
            phoneNumber: update.value!,
            primary: true,
            type: PhoneType.Landline
          }
        ]
      };

      if (!homeNumber) {
        return newPhone;
      }

      const index = communityMember.Phones.findIndex((x) => x.id === homeNumber.id);

      if (index === -1) {
        return newPhone;
      }

      return {
        ...communityMember,
        Phones: insert(
          {
            ...homeNumber,
            phoneNumber: update.value!
          },
          communityMember.Phones,
          index
        )
      };
    }
    case CommunityMemberFieldsEnum.email: {
      const compactCommunityMember = toCompactCommunityMember(communityMember);
      const currentEmail = compactCommunityMember.email;

      const newEmail: CommunityMember = {
        ...communityMember,
        EmailAddresses: [
          {
            __typename: 'EmailAddress',
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            email: update.value!,
            id: -1,
            invalid: false,
            primary: true
          }
        ]
      };

      if (!currentEmail) {
        return newEmail;
      }
      const emailIndex = compactCommunityMember.emails.findIndex(
        (x) => x.id === compactCommunityMember.emailId
      );
      if (emailIndex === -1) {
        return newEmail;
      }
      return {
        ...communityMember,
        EmailAddresses: insert(
          {
            ...compactCommunityMember.emails[emailIndex],
            email: update.value!
          },
          communityMember.EmailAddresses,
          emailIndex
        )
      };
    }
    case CommunityMemberFieldsEnum.fullName:
    case CommunityMemberFieldsEnum.gender: {
      const { firstName, lastName, gender, olamiId } = JSON.parse(update.value!);
      return {
        ...communityMember,
        firstName,
        lastName,
        gender,
        olamiId
      };
    }
    default:
      return communityMember;
  }
}
