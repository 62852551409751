import Vue, { computed, VueConstructor } from "vue";
import { compose } from "vue-compose";
import JFEOrders from "./JFEOrders.vue";
import { thisSchoolYear } from "../shared/seasonHelper";
import { getOptions, normalizeProps } from "shared/util";
import { Props, ActiveJFEOrdersFilters } from "./types";
import {
  OrderType,
  ProgramType,
  useGetOrdersQuery,
} from "shared/generated/graphql-types";
import { wrapComponent } from "shared/apollo-hoc";

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { activeFilters, setFilters, clearFilters, ...propsToUse } = props;

  return Vue.extend<{ activeFilters: ActiveJFEOrdersFilters}, Pick<Props, 'clearFilters' | 'setFilters'>, {}, {}>({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        activeFilters: {
          season: thisSchoolYear()
        },
      };
    },
    methods: {
      async setFilters(args: Partial<ActiveJFEOrdersFilters>, cb?: () => void) {
        this.activeFilters = { ...this.activeFilters, ...args };
        if (cb) {
          cb();
        }
      },
      clearFilters() {
        this.activeFilters = { season: thisSchoolYear() }
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          activeFilters: this.activeFilters,
          setFilters: this.setFilters,
          clearFilters: this.clearFilters 
        },
        on: this.$listeners
      });
    }
  });
};

const getPaginatedOrdersEnhancer = wrapComponent<
  Pick<Props, "activeFilters">,
  Pick<Props, "orders" | "loading" | "initialLoading" |  "fetchMore">
>((props) => {
  const { fetchMore, loading, result } = useGetOrdersQuery(
    computed(() => ({
      filter: {
        season: (props.activeFilters && props.activeFilters.season) || thisSchoolYear(),
        orderType: OrderType.Membership,
        programType: ProgramType.Jfe,
      },
      limit: 60,
    })),
    { fetchPolicy: "network-only" }
  );

  return computed(() => ({
    initialLoading: loading.value && !result.value?.getOrders,
    loading: loading.value,
    orders: result.value?.getOrders || [],
    fetchMore: ({ limit, offset }, stateChanger) =>
      fetchMore({
        variables: {
          limit,
          offset,
          filter: {
            season: (props.activeFilters && props.activeFilters.season) || thisSchoolYear(),
            orderType: OrderType.Membership,
            programType: ProgramType.Jfe,
          },
        },
        updateQuery(previousResult, { fetchMoreResult }) {
          if (!fetchMoreResult) {
            return previousResult;
          }
          if (
            !fetchMoreResult.getOrders.length ||
            fetchMoreResult.getOrders.length < limit
          ) {
            stateChanger.complete();

            if (!fetchMoreResult.getOrders.length) {
              return previousResult;
            }
          }

          const ids = previousResult.getOrders.map((x) => x.id);
          const newData = fetchMoreResult.getOrders.filter(
            (x) => !ids.includes(x.id)
          );
          return {
            getOrders: [...previousResult.getOrders, ...newData],
          };
        },
      }),
  }));
});

export default compose(withFilters, getPaginatedOrdersEnhancer)(JFEOrders);
