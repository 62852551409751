import { VueConstructor, Component as VueComponent, computed, defineComponent, useListeners, h, watch, ref } from 'vue';
import { acceptProps, compose } from 'vue-compose';
import omit from 'lodash/omit';
import { Props } from './types';
import TeenSheet from './TeenSheet.vue';
import { getOptions, normalizeProps } from 'shared/util';
import { ActiveFilters } from 'shared/components/ServerSideFilters/types';
import isEmpty from 'lodash/isEmpty';
import { SortType, TeenFilterInput, useTeensForSheetQuery } from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

const convertFilters = (regionId: number, filters: ActiveFilters): TeenFilterInput => ({
  sortBy: {
    name: SortType.Name,
    ascending: true
  },
  query: (filters.term && filters.term[0] && (filters.term[0].key as string)) || '',
  regionId: regionId !== 33 ? regionId : null,
  filters: omit(filters, 'term'),
  // TODO is what we want to do here as well?
  ignoreEmail: true
});

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { activeFilters, setFilters, clearAllFilters, limit, persistState, ...propsToUse } = props;
  props.persistedFilters = {};

  return defineComponent({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    setup(props) {
      const listeners = useListeners()

      const persistedState = localStorage.getItem('teenSheetPersistance') || '{}';
      const { persistedFilters } = JSON.parse(persistedState);

      const activeFilters = ref(!isEmpty(persistedFilters)
        ? persistedFilters
        : {
            Alumni: [
              {
                displayValue: 'Current Teens',
                key: 'CurrentTeens'
              }
            ]
          })

      const limit = 60

      function persistState(filters: ActiveFilters) {
        localStorage.teenSheetPersistance = JSON.stringify({
          persistedFilters: { ...filters }
        });
      }

      function setFilters(filters: ActiveFilters) {
        activeFilters.value = filters;
      }

      function clearAllFilters() {
        activeFilters.value = activeFilters.value['term']
          ? {
              term: activeFilters.value['term']
            }
          : {};
      }

      return () => h(Component, {
        props: {
          ...props,
          activeFilters: activeFilters.value,
          setFilters,
          clearAllFilters,
          limit: limit,
          persistedFilters,
          persistState
        },
        on: listeners
      });
    }
  })
};
const persistFilters = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);
  props.persistState = {};

  const EnhancedComponent = defineComponent({
    name: 'TeenSheetPersistance',
    props,
    setup(props) {
      const listeners = useListeners()

      watch(() => props.activeFilters, () => {
        props.persistState(props.activeFilters)
      })

      return () => h(Component, {
        on: listeners,
        props
      })
    }
  })

  return EnhancedComponent;
};
type PropsForEnhancer = Pick<Props, 'teens' | 'isLoading' | 'fetchMore' | 'total'>;
const getTeensForSheetEnhancer = wrapComponent<Props, PropsForEnhancer>((props) => {
  const { fetchMore, loading, result } = useTeensForSheetQuery(
    computed(() => ({
      limit: props.limit,
      filter: convertFilters(props.regionId, props.activeFilters)
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    isLoading: loading.value,
    teens: result.value?.teens.teens || [],
    total: result.value?.teens.total || 0,
    fetchMore: ({ limit, offset }, stateChanger) =>
      fetchMore({
        variables: {
          limit,
          offset,
          filter: convertFilters(props.regionId, props.activeFilters)
        },
        updateQuery(previousResult, { fetchMoreResult }) {
          if (!fetchMoreResult) {
            return previousResult;
          }
          if (!fetchMoreResult.teens.teens.length || fetchMoreResult.teens.teens.length < limit) {
            stateChanger.complete();

            if (!fetchMoreResult.teens.teens.length) {
              return previousResult;
            }
          }

          return {
            teens: {
              __typename: 'TeenPage',
              total: fetchMoreResult.teens.total,
              teens: [...previousResult.teens.teens, ...fetchMoreResult.teens.teens]
            }
          };
        }
      })
  }));
});

export default compose(
  withFilters,
  acceptProps(['persistedFilters', 'persistState']),
  persistFilters,
  getTeensForSheetEnhancer
)(TeenSheet);
