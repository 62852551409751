var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.teen)?_c(_setup.BorderedList,[_c('li',{staticClass:"teen",class:{ active: _vm.selectedTeen === _vm.teen.personId },on:{"click":function($event){return _vm.$emit('update:selectedTeen', _vm.teen.personId)}}},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"thumb-container"},[_c(_setup.Avatar,{attrs:{"size":36,"username":_vm.teen.name,"src":_vm.teen.thumbnail,"backgroundColor":_setup.getGenderColor(_vm.teen.gender)}})],1),_c('div',[_c('span',{staticClass:"name__text"},[_vm._v(_vm._s(_vm.teen.name))]),_c('span',{staticClass:"subtext"},[_vm._v(" "+_vm._s(_vm.teen.chapterName ? `${_vm.teen.chapterName} | ` : "")+" "+_vm._s(_vm.teen.graduationYear ? _setup.getGrade(_vm.teen.graduationYear) : "No grade set")+" ")])])]),_c('div',{staticClass:"events"},[_vm._v(_vm._s(_vm.teen.totalEvents))]),_c('div',{staticClass:"impact"},[(_vm.teen.reached)?_c('font-awesome-icon',{staticClass:"check-icon",attrs:{"icon":"check","fixed-width":""}}):_vm._e()],1),_c('div',{staticClass:"impact",on:{"click":function($event){$event.stopPropagation();}}},[_c(_setup.AutoSaveField,{attrs:{"name":_setup.TeenField.relationshipImpacted,"entity":_setup.teenCopy,"transform":(c) => ({ value: c, fieldName: _setup.TeenField.relationshipImpacted })},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $listeners, $props }){return [_c(_setup.UICheckbox,_vm._g(_vm._b({},'UICheckbox',$props,false),$listeners))]}}],null,false,2651416311)})],1),_c('div',{staticClass:"impact"},[(_vm.teen.eventImpacted)?_c('font-awesome-icon',{staticClass:"check-icon",attrs:{"icon":"check","fixed-width":""}}):_vm._e()],1),_c('div',{staticClass:"staff",on:{"click":function($event){$event.stopPropagation();}}},[_c(_setup.AutoSaveField,{attrs:{"name":"primaryAdvisor","entity":_setup.teenCopy,"transform":(c) => ({
              value: c ? String(c.staffID) : null,
              fieldName: _setup.TeenField.primaryAdvisorId,
            })},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.UISelect,_vm._g(_vm._b({attrs:{"placeholder":"Select Staff","label":"fullName","options":_vm.staff}},'UISelect',$props,false),$listeners))]}}],null,false,3978247960)})],1),_c('div',{staticClass:"staff",on:{"click":function($event){$event.stopPropagation();}}},[_c(_setup.AutoSaveField,{attrs:{"name":"secondaryAdvisor","entity":_setup.teenCopy,"transform":(c) => ({
              value: c ? String(c.staffID) : null,
              fieldName: _setup.TeenField.secondaryAdvisorId,
            })},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.UISelect,_vm._g(_vm._b({attrs:{"placeholder":"Select Staff","label":"fullName","options":_vm.staff}},'UISelect',$props,false),$listeners))]}}],null,false,3978247960)})],1),_c('div',{staticClass:"notes",on:{"click":function($event){$event.stopPropagation();}}},[_c(_setup.AutoSaveField,{attrs:{"name":`${_setup.TeenField.relationshipImpactNote}.note`,"entity":_setup.teenCopy,"transform":(c) => ({ value: c, fieldName: _setup.TeenField.relationshipImpactNote })},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.UITextarea,_vm._g(_vm._b({staticClass:"note-input",class:{ disabled: !_vm.teen.relationshipImpact },attrs:{"disabled":!_vm.teen.relationshipImpact,"placeholder":"Enter Note"}},'UITextarea',$props,false),$listeners))]}}],null,false,1926733587)})],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }