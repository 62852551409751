import Attendances from "./Attendances.vue";
import { compose } from "vue-compose";
import { withRouter } from "shared/components/router";

import { wrapComponent } from "shared/apollo-hoc";
import {
  GetAttendancesQuery,
  useGetAttendancesQuery,
} from "shared/generated/graphql-types";
import { computed } from "vue";
import { ArrayElement } from "shared/util/types";

type Attendance = ArrayElement<GetAttendancesQuery["event"]["Attendances"]>;

interface Props {
  eventId: number;
}

interface GetAttendanceProps {
  attendances: Attendance[];
  isLoading: boolean;
  eventDate: string | null;
  eventTimezone: string | null;
}
const getAttendances = wrapComponent<Props, GetAttendanceProps>((props) => {
  const { loading, result } = useGetAttendancesQuery(
    computed(() => ({ eventId: props.eventId }))
  );

  return computed(() => ({
    attendances: result.value?.event.Attendances || [],
    eventDate: result.value?.event.startDate || null,
    eventTimezone: result.value?.event.TimeZone || null,
    isLoading: loading.value,
  }));
});

export default compose(withRouter, getAttendances)(Attendances);
