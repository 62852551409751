import { computed } from 'vue';
import { compose, acceptProps, withProps } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import EditEventContainer from './EditEventContainer.vue'
import { PropsEdit, Props } from './types';
import { withRouter } from 'shared/components/router';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import {
  DeleteZoomMeetingMutationVariables,
  useConvertEventToSeriesMutation,
  useDeleteAdditionalEventItemMutation,
  useDeleteEventMutation,
  useDeleteEventTicketMutation,
  useDeleteZoomMeetingMutation,
  useGetEventForEditQuery
} from 'shared/generated/graphql-types';
import { getRegionEnhancer } from 'shared/enhancers/getRegionEnhancer';

interface DeleteEventProps {
  deleteEvent: (eventId: number) => void;
  deletingEvent: boolean;
}

interface DeleteMeetingProps {
  deleteZoomMeeting: (zoomMeetingId: string, zoomOccurencyId?: string) => void;
  deletingZoomMeeting: boolean;
}

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const deleteEventEnhancer = wrapComponent<PropsEdit, DeleteEventProps>(() => {
  const { loading, mutate } = useDeleteEventMutation();

  return computed(() => ({
    deleteEvent: (eventId) =>
      mutate(
        { eventId },
        {
          optimisticResponse: {
            deleteEvent: eventId
          }
        }
      ),
    deletingEvent: loading.value
  }));
});

const deleteZoomMeetingEnhancer = wrapComponent<PropsEdit, DeleteMeetingProps>((props) => {
  const { loading, mutate } = useDeleteZoomMeetingMutation();

  return computed(() => ({
    deleteZoomMeeting: (zoomMeetingId, zoomOccurencyId) => {
      const { event } = props;
      const variables: DeleteZoomMeetingMutationVariables = {
        id: zoomMeetingId,
        occurrence_id: null
      };
      if (zoomOccurencyId) {
        variables.occurrence_id = new Date(zoomOccurencyId as string).getTime();
      }
      if (event!.seriesId && event!.zoomOccurencyId) {
        variables.occurrence_id = new Date(event!.zoomOccurencyId as string).getTime();
      }
      return mutate(
        { ...variables },
        {
          optimisticResponse: {
            deleteZoomMeeting: zoomMeetingId
          }
        }
      );
    },
    deletingZoomMeeting: loading.value
  }));
});

const convertEventToSeries = wrapComponent<{}, Pick<Props, 'convertEventToSeries'>>(() => {
  const { mutate } = useConvertEventToSeriesMutation();

  return computed(() => ({
    convertEventToSeries: (event, series) => mutate({ event, series })
  }));
});

export const enhancer = compose(
  withRouter,
  withProps<{ regionId: number | null }, {}>((props) => ({
    regionId: props.regionId || getCurrentRegion()
  })),
  getRegionEnhancer,
  wrapComponent<{ eventId: number }, Pick<PropsEdit, 'eventLoading' | 'event'>>((props) => {
    const { loading, result } = useGetEventForEditQuery(
      computed(() => ({ eventId: Number(props.eventId) })),
      { fetchPolicy: 'network-only' }
    );

    return computed(() => ({
      event: result.value?.event || null,
      eventLoading: loading.value
    }));
  }),
  wrapComponent<{}, Pick<PropsEdit, 'deleteEventTicket'>>(() => {
    const { mutate } = useDeleteEventTicketMutation();

    return computed(() => ({
      deleteEventTicket: (eventTicketId, eventId) => mutate({ eventId, eventTicketId })
    }));
  }),
  wrapComponent<{}, Pick<PropsEdit, 'deleteAdditionalEventItem'>>(() => {
    const { mutate } = useDeleteAdditionalEventItemMutation();

    return computed(() => ({
      deleteAdditionalEventItem: (additionalEventItemId, eventId) =>
        mutate({ additionalEventItemId, eventId })
    }));
  }),
  convertEventToSeries,
  deleteZoomMeetingEnhancer,
  deleteEventEnhancer,
  acceptProps(['regionId', 'eventId'])
);

export default enhancer(EditEventContainer);
