import { defineComponent, h, PropType, VNode } from 'vue';
import VisualProgressList from './ProgressList.vue';

export const ListItem = defineComponent({
  props: {
    done: { type: Boolean },
    label: { type: String },
    content: { type: Array as PropType<VNode[]> }
  }
});

export const ProgressList = defineComponent({
  setup(props, { slots }) {
    const vNodes = (slots.default && slots.default()) || [];

    const items = vNodes
      .filter(
        (x) =>
          x.componentOptions &&
          // TODO: Review this condition
          // !x.componentOptions.tag is used to work with Composition API
          (!x.componentOptions.tag || x.componentOptions.tag === "ListItem") &&
          x.componentOptions.propsData
      )
      .map((x, index) => ({
        done: (x.componentOptions!.propsData! as any).done,
        name: (x.componentOptions!.propsData! as any).label,
        content: x.componentOptions!.children
      }));

    return () =>
      h(
        VisualProgressList,
        { props: { items } },
        items.map((x, i) => h('template', { slot: `content_${i}` }, x.content))
      );
  }
});
