import { compose, withData } from 'vue-compose';
import Scholarships from './Scholarships.vue';
import { Props } from './types';
import sum from 'lodash/sum';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  AddNewScholarshipRequestMutationVariables,
  ScholarshipDeniedMutationVariables,
  ScholarshipFragmentFragment,
  ScholarshipFragmentFragmentDoc,
  ScholarshipGrantMutationVariables,
  StatusType,
  useAddNewScholarshipRequestMutation,
  useGetEventWithScholarshipsQuery,
  useScholarshipDeniedMutation,
  useScholarshipGrantMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { useApolloClient } from '@vue/apollo-composable';

type ScholarshipEnhancerProps = Pick<
  Props,
  | 'scholarshipsRequestedList'
  | 'eligibleForScholarshipsList'
  | 'scholarshipsApprovedSum'
  | 'regionId'
  | 'currencySymbol'
  | 'isLoading'
  | 'eventDate'
  | 'eventTimezone'
>;
const getEventWithScholarshipsEnhancer = wrapComponent<Props, ScholarshipEnhancerProps>((props) => {
  const { loading, result } = useGetEventWithScholarshipsQuery(
    computed(() => ({ eventId: props.eventId }))
  );

  return computed(() => ({
    currencySymbol: result.value?.event.Region.currencySymbol || '',
    isLoading: loading.value,
    scholarshipsRequestedList: (result.value?.event.Registrations || [])
      .filter((x) => x.scholarshipRequest !== null)
      .filter((x) => x.status !== StatusType.Cancelled),
    regionId: result.value?.event.regionId || null,
    eligibleForScholarshipsList: (result.value?.event.Registrations || [])
      .filter((x) => x.status !== StatusType.Cancelled)
      .filter((x) => x.scholarshipRequest === null),
    scholarshipsApprovedSum:
      sum(
        (result.value?.event.Registrations || [])
          .filter((x) => x.scholarshipGrant)
          .map((a) => a.scholarshipGrant)
      ) || 0,
    eventDate: result.value?.event.startDate || null,
    eventTimezone: result.value?.event.TimeZone || null
  }));
});

interface AddNewScholarshipRequestProps {
  addNewScholarshipRequest: (vars: AddNewScholarshipRequestMutationVariables) => void;
}
const addNewScholarshipRequestEnhancer = wrapComponent<Props, AddNewScholarshipRequestProps>(
  (props) => {
    const { mutate } = useAddNewScholarshipRequestMutation();
    const { client } = useApolloClient();

    return computed(() => ({
      addNewScholarshipRequest: (vars) => {
        try {
          const registrationFragment = client.readFragment<ScholarshipFragmentFragment>({
            fragment: ScholarshipFragmentFragmentDoc,
            id: `${vars.registrationId}Registration`,
            fragmentName: 'ScholarshipFragment'
          });
          mutate(
            { ...vars },
            {
              optimisticResponse: {
                addNewScholarshipRequest: {
                  ...(registrationFragment as ScholarshipFragmentFragment),
                  scholarshipRequest: vars.scholarshipRequest
                }
              }
            }
          );
        } catch (e) {
          console.log(e);
        }
      }
    }));
  }
);

type ScholarshipGrantProps = {
  approveScholarshipGrant: (vars: ScholarshipGrantMutationVariables) => void;
};
const scholarshipGrantEnhancer = wrapComponent<Props, ScholarshipGrantProps>((props) => {
  const { mutate } = useScholarshipGrantMutation();
  const { client } = useApolloClient();

  return computed(() => ({
    approveScholarshipGrant: ({
      registrationId,
      scholarshipGrant,
      disableNotification,
      disableTeenNotification
    }) => {
      const registrationFragment = client.readFragment<ScholarshipFragmentFragment>({
        fragment: ScholarshipFragmentFragmentDoc,
        id: `${registrationId}Registration`,
        fragmentName: 'ScholarshipFragment'
      });
      mutate(
        {
          registrationId,
          scholarshipGrant,
          disableNotification,
          disableTeenNotification: disableNotification ? true : disableTeenNotification
        },
        {
          optimisticResponse: {
            scholarshipGrant: {
              ...(registrationFragment as ScholarshipFragmentFragment),
              scholarshipGrant: +scholarshipGrant || 0
            }
          }
        }
      );
    }
  }));
});

type ScholarshipDeniedProps = {
  scholarshipDenied: (vars: ScholarshipDeniedMutationVariables) => void;
};
const scholarshipDeniedEnhancer = wrapComponent<Props, ScholarshipDeniedProps>((props) => {
  const { mutate } = useScholarshipDeniedMutation();
  const { client } = useApolloClient();

  return computed(() => ({
    scholarshipDenied: (args) => {
      const registrationFragment = client.readFragment<ScholarshipFragmentFragment>({
        fragment: ScholarshipFragmentFragmentDoc,
        id: `${args.registrationId}Registration`,
        fragmentName: 'ScholarshipFragment'
      });

      mutate(
        { ...args },
        {
          optimisticResponse: {
            scholarshipDenied: {
              ...(registrationFragment as ScholarshipFragmentFragment),
              scholarshipGrant: 0
            }
          }
        }
      );
    }
  }));
});

export const enhancer = compose(
  withData({
    addingScholarshipRequest: {
      initialValue: false
    },
    selectedTeen: {
      initialValue: null
    },
    historyMode: {
      initialValue: false
    },
    toShowContactInformation: {
      initialValue: false
    }
  }),
  getEventWithScholarshipsEnhancer,
  scholarshipGrantEnhancer,
  scholarshipDeniedEnhancer,
  addNewScholarshipRequestEnhancer
);

export default enhancer(Scholarships);
