import { render, staticRenderFns } from "./ProgressList.vue?vue&type=template&id=639ed060&scoped=true&"
import script from "./ProgressList.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ProgressList.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ProgressList.vue?vue&type=style&index=0&id=639ed060&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639ed060",
  null
  
)

export default component.exports