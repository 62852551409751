import { ActiveFiltersType } from './shared/ActiveFilters';
import { toCompactTeens } from './utils';
import {
  PostHigeSchoolStatisticsQuery,
  usePostHigeSchoolStatisticsQuery,
  useTeensPostHigeSchoolQuery
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { computed } from 'vue';
import CompactTeen from './shared/CompactTeen';
import { FetchMoreOptions } from 'shared/types';

interface Props {
  regionId: number;
  activeFilters: ActiveFiltersType;
  limit: number;
}

type PostHigeSchoolStatisticsProps = {
  postHigeSchoolStatistics: PostHigeSchoolStatisticsQuery['postHigeSchoolStatistics'] | null;
};
export const postHigeSchoolStatisticsEnhancer = wrapComponent<Props, PostHigeSchoolStatisticsProps>(
  (props) => {
    const { result } = usePostHigeSchoolStatisticsQuery(
      computed(() => ({
        filter: {
          regionId: props.regionId,
          ...props.activeFilters
        }
      })),
      { fetchPolicy: 'network-only' }
    );

    return computed(() => ({
      postHigeSchoolStatistics: result.value?.postHigeSchoolStatistics || null
    }));
  }
);

type TeensPostHigeSchoolProps = {
  teens: CompactTeen[];
  initialLoading: boolean;
  loading: boolean;
  total: number;
  fetchMore: (args: FetchMoreOptions) => void;
};
export const teensPostHigeSchoolEnhancer = wrapComponent<Props, TeensPostHigeSchoolProps>(
  (props) => {
    const { fetchMore, loading, result } = useTeensPostHigeSchoolQuery(
      computed(() => ({
        limit: props.limit,
        offset: 0,
        filter: {
          regionId: props.regionId,
          ...props.activeFilters
        },
        regionId: props.regionId
      })),
      { fetchPolicy: 'network-only' }
    );

    return computed(() => ({
      teens: toCompactTeens(result.value?.teens.teens || [], props.activeFilters),
      total: result.value?.teens.total || 0,
      initialLoading: loading.value && !result.value?.teens,
      loading: loading.value,
      fetchMore: ({ limit, offset }) =>
        fetchMore({
          variables: {
            limit,
            offset,
            filter: {
              regionId: props.regionId,
              isAlumni: props.activeFilters && props.activeFilters.isAlumni,
              query: props.activeFilters && props.activeFilters.query,
              sortBy: props.activeFilters && props.activeFilters.sortBy,
              missingCollege: props.activeFilters && props.activeFilters.missingCollege,
              missingIsraelProgram: props.activeFilters && props.activeFilters.missingIsraelProgram,
              filters: props.activeFilters && props.activeFilters.filters
            }
          },
          updateQuery(previousResult, { fetchMoreResult }) {
            const newData = (fetchMoreResult?.teens && fetchMoreResult.teens.teens) || [];
            return {
              teens: {
                __typename: 'TeenPage',
                total: fetchMoreResult?.teens.total || 0,
                teens: [...newData]
              }
            };
          }
        })
    }));
  }
);
