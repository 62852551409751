import { render, staticRenderFns } from "./AdditionalEventItem.vue?vue&type=template&id=dd0231b6&scoped=true&"
import script from "./AdditionalEventItem.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AdditionalEventItem.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AdditionalEventItem.vue?vue&type=style&index=0&id=dd0231b6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd0231b6",
  null
  
)

export default component.exports