import { computed } from 'vue';
import { compose, withProps } from 'vue-compose';
import ChapterLookup from './ChapterLookup.vue';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetChaptersCompactQuery } from 'shared/generated/graphql-types';
import { Chapter } from 'shared/types';

interface Props {
	regionId: number | null;
}

interface ChildProps {
	chapters: Chapter[]
	loading: boolean
}

const store = createStore()
const { getCurrentRegion } = useRegionStore(store)

export default compose(
	withProps<Props, Props>((props) => ({
		regionId: props.regionId || getCurrentRegion()
	})),
	wrapComponent<Props, ChildProps>((props) => {
		const { loading, result } = useGetChaptersCompactQuery(computed(() => ({regionId: props.regionId})))

		return computed(() => ({
			chapters: result.value?.chapters || [],
			loading: loading.value
		}))
	})
)(ChapterLookup);
