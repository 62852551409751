var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.checkedTeens.length > 1)?_c(_setup.ActionBar,{staticClass:"merge-teens",scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('button',{on:{"click":function($event){_setup.showMergeScreen = true}}},[_vm._v("Merge "+_vm._s(_setup.checkedTeens.length)+" Teens")]),_c('span',{staticClass:"clear",on:{"click":_setup.clearCheckedTeens}},[_vm._v("Clear")])]},proxy:true}],null,false,1361058707)}):_vm._e(),_c(_setup.Modal,{attrs:{"show":_setup.showMergeScreen,"width":"1000px"}},[[_c(_setup.SelectDuplicatesFields,{attrs:{"regionId":_vm.regionId,"teenIds":_setup.checkedTeens,"showMergeScreen":_setup.showMergeScreen,"updateRootQuery":{
          query: _setup.TeensListDocument,
          variables: _setup.teenListVaribalesQuery
        }},on:{"merged":_setup.onMerged,"update:showMergeScreen":function($event){_setup.showMergeScreen=$event},"update:show-merge-screen":function($event){_setup.showMergeScreen=$event}}})]],2),(!_vm.initialLoading)?_c(_setup.PanelsGrid,{attrs:{"bottomOffset":"65"}},[(!_setup.addingNewTeen)?_c(_setup.PanelsSidebar,[_c('div',{staticClass:"sidebar-header"},[_vm._v(" Teens "),_c('span',{staticClass:"teen-length"},[_vm._v("("+_vm._s(_vm.total || 0)+")")]),_c(_setup.RouterLink,{attrs:{"to":"/teens/AddNewTeen","exact":true}},[_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Add New Teen'),expression:"'Add New Teen'",modifiers:{"right":true}}],attrs:{"icon":"plus-circle"}})],1)],1),_c(_setup.UISearch,{attrs:{"data-test":"searchInput","value":_vm.activeFilters.term,"placeholder":"Search by name","icon":"none","inverted":"","debounce":300},on:{"input":function($event){return _setup.termChange($event)}}}),(_vm.loading)?_c('div',{staticClass:"spinner"}):_vm._e(),_c('span',{staticClass:"icn icn--filter",on:{"click":function($event){_setup.filtersOpen = !_setup.filtersOpen}}},[_c('font-awesome-icon',{attrs:{"icon":"filter"}})],1),_c(_setup.UIButton,{staticClass:"stripped clear",on:{"click":function($event){$event.preventDefault();return _setup.clearFilters.apply(null, arguments)}}},[_vm._v("Clear Filters")]),_c(_setup.ScrollablePanel,{directives:[{name:"fullheight",rawName:"v-fullheight"}]},[_c(_setup.List,{attrs:{"teens":_vm.teens,"fetchMore":_vm.fetchMore,"regionId":_vm.regionId,"activeFilters":_vm.activeFilters,"selectedTeen":+_vm.currentTeenId,"checkedTeens":_setup.checkedTeens},on:{"update:checkedTeens":function($event){_setup.checkedTeens=$event},"update:checked-teens":function($event){_setup.checkedTeens=$event},"selectedTeen":() => {
              _setup.filtersOpen = false;
              _setup.selectingTeen = true;
            }}})],1)],1):_vm._e(),(_setup.showFilterPanel)?_c(_setup.FiltersPanel,{key:_setup.keyForFiltersPanel,attrs:{"loading":_vm.loadingFilterOptions,"filterOptions":_vm.filterOptions,"activeFilters":_vm.activeFilters},on:{"filters":function($event){return _vm.setFilters($event)}}}):_c('div',{staticClass:"teen-main"},[_c(_setup.MatchFirst,[_c(_setup.Route,{attrs:{"path":`${_vm.path}/AddNewTeen`,"exact":true}},[_c(_setup.AddNewTeen,{attrs:{"regionId":_vm.regionId,"defaultCountry":_vm.country,"path":_vm.path,"basePath":_vm.path,"returnUrl":"/teens/","redirectToProfile":true,"router":_vm.router}})],1),_c(_setup.Route,{attrs:{"path":`${_vm.path}`},scopedSlots:_vm._u([{key:"default",fn:function({ url, router }){return [_c(_setup.Single,{attrs:{"url":url,"personId":+_vm.currentTeenId,"selectingTeen":_setup.selectingTeen,"regionId":_vm.regionId,"country":_vm.country,"router":router}})]}}],null,false,2559494376)})],1),(_setup.profileClose && !_setup.addingNewTeen)?_c('font-awesome-icon',{staticClass:"collapsable-hook--open",attrs:{"icon":['far', 'outdent']},on:{"click":_setup.closeProfile}}):_vm._e(),(!_setup.addingNewTeen && !_setup.profileClose)?_c('div',{staticClass:"collapsable",class:{ closed: _setup.profileClose }},[(!_setup.profileClose)?_c('span',{staticClass:"collapsable-hook",on:{"click":_setup.closeProfile}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'indent']}})],1):_vm._e(),_c(_setup.TeenProfile,{directives:[{name:"fullheight",rawName:"v-fullheight"}],key:_vm.currentTeenId,staticClass:"teen-profile",attrs:{"personID":+_vm.currentTeenId,"country":_vm.country}})],1):_vm._e()],1)],1):_c(_setup.Loading)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }