import GoalLookup from './GoalLookup.vue';
import { computed, defineComponent, h, ref, useListeners, VueConstructor } from 'vue';
import { compose } from 'vue-compose';
import { getCurrentSeason, getOptions, normalizeProps } from 'shared/util';
import { Props, Filters } from './types';
import { currentUserEnhancer } from 'shared/enhancers/currentUserEnhancer';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetGoalsQuery } from 'shared/generated/graphql-types';

export const filtersInit = ({ regionId, personId }: { regionId: number; personId: number }) => ({
  regionId,
  personId,
  chapterId: null,
  season: getCurrentSeason()
});

const getGoalEnhancer = wrapComponent<Props, Pick<Props, 'goals' | 'goalsTotal' | 'loading'>>(
  (props) => {
    const { loading, result } = useGetGoalsQuery(
      computed(() => ({
        filter: {
          ...props.filters,
          regionId: props.regionId
        },
        offset: (props.currentPage - 1) * props.limit,
        limit: props.limit
      })),
      { fetchPolicy: 'network-only' }
    );

    return computed(() => ({
      goals: result.value?.goals.goals || [],
      goalsTotal: result.value?.goals.total || 0,
      loading: loading.value
    }));
  }
);

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { currentPage, limit, filters, setFilter, clearFilters, ...propsToUse } = props;

  return defineComponent({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    setup(props) {
      const filters = ref<Filters>(
        filtersInit({ regionId: props.regionId, personId: props.personId })
      );
      const currentPage = ref<number>(1);
      const limit = 30;

      const listeners = useListeners();

      function setFilter<K extends keyof Filters>(key: K, value: Filters[K]) {
        filters.value = {
          ...filters.value,
          [key]: value
        };
      }

      function clearFilters() {
        filters.value = filtersInit({ regionId: props.regionId, personId: props.personId });
        currentPage.value = 1;
      }

      return () =>
        h(Component, {
          on: listeners,
          props: {
            ...props,
            filters: filters.value,
            currentPage: currentPage.value,
            limit,
            setFilter,
            clearFilters
          }
        });
    }
  });
};

export default compose(withFilters, getGoalEnhancer, currentUserEnhancer)(GoalLookup);
