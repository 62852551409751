import { compose } from 'vue-compose';
import { Component, defineComponent, h, ref, useListeners } from 'vue';
import { getOptions, normalizeProps } from 'shared/util';

const withTerm = (Component: Component) => {
  const props = normalizeProps(getOptions(Component).props);

  return defineComponent({
    name: 'withTerm',
    props,
    setup(props) {
      const listeners = useListeners();

      const localTerm = ref<string>('');

      return () =>
        h(Component, {
          props: {
            ...props,
            term: localTerm.value,
            setTerm: (term: string) => (localTerm.value = term),
            getTerm: () => localTerm.value
          },
          on: listeners
        });
    }
  });
};

export default (enhancer: Component | ((component: Component) => Component)) =>
  (Component: Component) =>
    compose(withTerm, enhancer)(Component);
