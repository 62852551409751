import { computed } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { sourceString } from '../shared/Participant';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetBusesForEventDocument,
  GetBusesForEventQuery,
  RemoveFromBusMutation,
  useRemoveFromBusMutation
} from 'shared/generated/graphql-types';

function getTypeNameFromType(type: sourceString) {
  switch (type) {
    case 'registration':
      return 'Registration';
    case 'eventguests':
      return 'EventGuest';
    case 'eventstaff':
      return 'EventStaff';
  }
}

export default wrapComponent<Props, Pick<Props, 'removeFromBus'>>((props) => {
  const { mutate } = useRemoveFromBusMutation();

  return computed(() => ({
    removeFromBus: (id, bus, type, p) =>
      mutate(
        { id, busId: bus.busID, type },
        {
          optimisticResponse: {
            removeFromBus: {
              ...p.model,
              __typename: getTypeNameFromType(type as sourceString),
              ToBus: bus.directionFlag === 'T' || bus.directionFlag === 'B' ? null : p.model.ToBus,
              FromBus:
                bus.directionFlag === 'R' || bus.directionFlag === 'B' ? null : p.model.FromBus
            } as RemoveFromBusMutation['removeFromBus']
          },
          update: (proxy) => {
            const data = proxy.readQuery<GetBusesForEventQuery>({
              query: GetBusesForEventDocument,
              variables: { eventId: props.event!.eventId }
            });

            if (data?.event) {
              const event = cloneDeep(data.event);
              const currentBusIndex = event.Buses.findIndex((b) => b.busID === bus.busID);
              if (bus.directionFlag === 'R') {
                switch (type) {
                  case 'registration': {
                    const index = event.Buses[
                      currentBusIndex
                    ].ReturnFromEventRegistrations.findIndex((r) => r.registrationID === id);
                    event.Buses[currentBusIndex].ReturnFromEventRegistrations.splice(index, 1);
                    break;
                  }
                  case 'eventguests': {
                    const index = event.Buses[currentBusIndex].ReturnFromEventEventGuests.findIndex(
                      (eg) => eg.eventGuestId === id
                    );
                    event.Buses[currentBusIndex].ReturnFromEventEventGuests.splice(index, 1);
                    break;
                  }
                  case 'eventstaff': {
                    const index = event.Buses[currentBusIndex].ReturnFromEventEventStaff.findIndex(
                      (es) => es.eventStaffId === id
                    );
                    event.Buses[currentBusIndex].ReturnFromEventEventStaff.splice(index, 1);
                    break;
                  }
                }
              } else if (bus.directionFlag === 'T') {
                switch (type) {
                  case 'registration': {
                    const index = event.Buses[currentBusIndex].TravelToEventRegistrations.findIndex(
                      (r) => r.registrationID === id
                    );
                    event.Buses[currentBusIndex].TravelToEventRegistrations.splice(index, 1);
                    break;
                  }
                  case 'eventguests': {
                    const index = event.Buses[currentBusIndex].TravelToEventEventGuests.findIndex(
                      (eg) => eg.eventGuestId === id
                    );
                    event.Buses[currentBusIndex].TravelToEventEventGuests.splice(index, 1);
                    break;
                  }
                  case 'eventstaff': {
                    const index = event.Buses[currentBusIndex].TravelToEventEventStaff.findIndex(
                      (es) => es.eventStaffId === id
                    );
                    event.Buses[currentBusIndex].TravelToEventEventStaff.splice(index, 1);
                    break;
                  }
                }
              } else if (bus.directionFlag === 'B') {
                switch (type) {
                  case 'registration': {
                    const travelTo = event.Buses[
                      currentBusIndex
                    ].TravelToEventRegistrations.findIndex((r) => r.registrationID === id);
                    event.Buses[currentBusIndex].TravelToEventRegistrations.splice(travelTo, 1);
                    const returnIndex = event.Buses[
                      currentBusIndex
                    ].ReturnFromEventRegistrations.findIndex((r) => r.registrationID === id);
                    event.Buses[currentBusIndex].ReturnFromEventRegistrations.splice(
                      returnIndex,
                      1
                    );
                    break;
                  }
                  case 'eventguests': {
                    const travelTo = event.Buses[
                      currentBusIndex
                    ].TravelToEventEventGuests.findIndex((eg) => eg.eventGuestId === id);
                    event.Buses[currentBusIndex].TravelToEventEventGuests.splice(travelTo, 1);
                    const returnIndex = event.Buses[
                      currentBusIndex
                    ].ReturnFromEventEventGuests.findIndex((eg) => eg.eventGuestId === id);
                    event.Buses[currentBusIndex].ReturnFromEventEventGuests.splice(returnIndex, 1);
                    break;
                  }
                  case 'eventstaff': {
                    const travelTo = event.Buses[currentBusIndex].TravelToEventEventStaff.findIndex(
                      (es) => es.eventStaffId === id
                    );
                    event.Buses[currentBusIndex].TravelToEventEventStaff.splice(travelTo, 1);
                    const returnIndex = event.Buses[
                      currentBusIndex
                    ].ReturnFromEventEventStaff.findIndex((es) => es.eventStaffId === id);
                    event.Buses[currentBusIndex].ReturnFromEventEventStaff.splice(returnIndex, 1);
                    break;
                  }
                }
              }

              event.Buses[currentBusIndex].passengerCount--;
              proxy.writeQuery<GetBusesForEventQuery>({
                query: GetBusesForEventDocument,
                variables: { eventId: props.event!.eventId },
                data: {
                  event
                }
              });
            }
          }
        }
      )
  }));
});
