import Dashboard from './Dashboard.vue';
import { compose } from 'vue-compose';
import moment from 'moment';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  EventFilterOrderByField,
  RegionWideFilter,
  useUpcomingEventsQuery
} from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { Events } from './types';
import { Me } from 'shared/types';

interface Props {
  regionId: number;
  loading: boolean;
  upcomingEvents: Events;
  upcomingEventsRegionWide: Events;
  user: Me;
}
interface UpcomingEventsProps {
  upcomingEvents: Events;
  loading: boolean;
}

const upcomingEventsEnahnacer = wrapComponent<Props, UpcomingEventsProps>((props) => {
  const { loading, result } = useUpcomingEventsQuery(
    computed(() => ({
      filter: {
        advisorID: [props.user.staffID],
        regionId: props.regionId,
        regionWide: RegionWideFilter.Show,
        nonSummer: true,
        range: {
          startDate: moment().toString(),
          endDate: moment().add(12, 'months').toString()
        },
        orderBy: {
          field: EventFilterOrderByField.Date,
          asc: true
        }
      },
      limit: 20
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    loading: loading.value,
    upcomingEvents: result.value?.events || []
  }));
});

export default compose(upcomingEventsEnahnacer)(Dashboard);
