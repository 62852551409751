import { getCurrentEmail, getCurrentCellNumber, getCurrentLandlineNumber, getCurrentAddress } from 'shared/util';
import trim from 'lodash/trim';
import { CompactFamily, CompactParent } from './types';
import { FamilyParentFragmentFragment as Parent, SingleFamilyQuery } from 'shared/generated/graphql-types';

type Family = SingleFamilyQuery['singleFamily']
type Children =  SingleFamilyQuery['singleFamily']['Children']

export function toCompactParent(parent: Parent, children: Children): CompactParent | null {
  if (!parent) return null
  const currentAddress = getCurrentAddress(parent.Addresses || []);

  return {
    address: currentAddress,
    addressId: currentAddress.id || null,
    street: currentAddress.street || '',
    street2: currentAddress.street2 || '',
    city: currentAddress.city || '',
    state: currentAddress.state || '',
    zipCode: currentAddress.zipCode || '',
    country: currentAddress.country || '',
    email: getCurrentEmail(parent.EmailAddresses || []) ? getCurrentEmail(parent.EmailAddresses)!.email : null,
    emailId: getCurrentEmail(parent.EmailAddresses || []) ? getCurrentEmail(parent.EmailAddresses)!.id : null,
    landline: getCurrentLandlineNumber(parent.Phones || []) ? getCurrentLandlineNumber(parent.Phones)!.phoneNumber : null,
    landlineId: getCurrentLandlineNumber(parent.Phones || []) ? getCurrentLandlineNumber(parent.Phones)!.id : null,
    cellphone: getCurrentCellNumber(parent.Phones || []) ? getCurrentCellNumber(parent.Phones)!.phoneNumber : null,
    cellphoneId: getCurrentCellNumber(parent.Phones || []) ? getCurrentCellNumber(parent.Phones)!.id : null,
    emails: parent.EmailAddresses,
    firstName: parent && parent.firstName || '',
    lastName: parent && parent.lastName || '',
    olamiId: parent && parent.olamiId || '',
    fullName: trim(`${parent.firstName || ''} ${parent.lastName || ''}`),
    gender: parent && parent.gender,
    personId: parent && parent.personID,
    original: {
      ...parent,
      Children: children || []
    }
  };
}

export function toCompactFamily(family: Family): CompactFamily | null {
  if (!family) return null;
  return {
    id: family.id,
    status: family.status,
    familyName: family.familyName,
    father: family.Father ? toCompactParent(family.Father, family.Children || []) : null,
    mother: family.Mother ? toCompactParent(family.Mother, family.Children || []) : null,
    children: family.Children || [],
    original: family
  };
}
