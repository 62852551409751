import GlaubachFellowItem from './GlaubachFellowItem.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { UpdateFellowInput, useUpdateFellowMutation } from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

interface Props {
  updateFellow: (staff: UpdateFellowInput) => void;
}
const updateFellowEnhancer = wrapComponent<Props, Pick<Props, 'updateFellow'>>((props) => {
  const { mutate } = useUpdateFellowMutation();

  return computed(() => ({
    updateFellow: (staff) => mutate({ staff })
  }));
});

export default compose(updateFellowEnhancer)(GlaubachFellowItem);
