import { computed, defineComponent, h } from 'vue';
import autocomplete from '../autocomplete';
import SchoolLookup from './SchoolLookup.vue';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  SchoolLookupByIdQuery,
  useGetSchoolByRegionAndTermQuery,
  useSchoolLookupByIdQuery
} from 'shared/generated/graphql-types';
import { School } from 'shared/types';

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

interface SchoolAutocompleteResult {
  schools: School[];
  loading: boolean;
}

const valueResolver = wrapComponent<{ value: number | number[] }, ResolvedSchool>((props) => {
  const variables = computed(() => ({
    id: Array.isArray(props.value) ? props.value[0] : props.value
  }));

  const { result } = useSchoolLookupByIdQuery(variables, {
    enabled: computed(() => {
      const value = Array.isArray(props.value) ? props.value[0] : props.value;
      return !!(value && typeof value !== 'object');
    })
  });

  return computed(() => {
    const value = Array.isArray(props.value) ? props.value[0] : props.value;
    return {
      value: typeof value === 'object' ? value : result.value && result.value.schoolLookupByID
    };
  });
});

const getSchools = wrapComponent<
  { regionId: number; value: ResolvedSchool['value']; term: string },
  SchoolAutocompleteResult
>((props) => {
  const variables = computed(() => ({
    regionId: props.regionId,
    term: props.term
  }));
  const enabled = computed(() => !!props.term);
  const { result, loading } = useGetSchoolByRegionAndTermQuery(variables, {
    enabled
  });

  const resolvedSchool = computed<School[]>(() => (props.value ? [props.value] : []));

  return computed(() => ({
    loading: loading.value,
    schools: [...(result.value?.schoolLookup || []), ...resolvedSchool.value]
  }));
});

const SchoolLookupAutoComplete = valueResolver(autocomplete(getSchools)(SchoolLookup));

interface ResolvedSchool {
  value?: SchoolLookupByIdQuery['schoolLookupByID'];
}

const SchoolLookupWrapper = defineComponent({
  props: ['value', 'regionId'],
  setup(props, { attrs, listeners }) {
    return () =>
      h(SchoolLookupAutoComplete, {
        props: { ...attrs, ...props, regionId: props.regionId || getCurrentRegion() },
        on: listeners
      });
  }
});

export default SchoolLookupWrapper;
