import { Template } from './types';

export const isBaseTemplate = (template: Template | null): Boolean => {
  if (!template) return false;
  if (template.chapterId || template.eventId) return false;
  return true;
};

export const unescapeVariables = (template: string, unescape: boolean) => {
  let searchValue = new RegExp(/{{(.*?)}}/, 'g');
  if (!unescape) {
    searchValue = new RegExp(/{{{(.*?)}}}/, 'g');
  }
  const variables = ['PaymentDetails', 'HousingDetails', 'BussingDetails'];
  return template.replace(searchValue, (match, p1, offset, string) => {
    if (unescape && variables.indexOf(p1) > -1) {
      return '{{{' + p1 + '}}}';
    }
    return '{{' + p1 + '}}';
  });
};
