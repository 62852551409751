import { StateChanger } from 'vue-infinite-loading';
import ActiveFilters from './shared/ActiveFilters';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetFilterOptionsQuery,
  TeensListQuery,
  useTeensListQuery
} from 'shared/generated/graphql-types';
import { ApolloQueryResult } from '@apollo/client';
import { computed } from 'vue';
import { ArrayElement } from 'shared/util/types';
import { FetchMoreOptions } from 'shared/types';

type Teen = ArrayElement<TeensListQuery['teens']['teens']>;

interface Props {
  regionId: number;
  activeFilters: ActiveFilters;
  filterOptions: GetFilterOptionsQuery;
  loadingFilterOptions: boolean;
}

type TeensListProps = {
  initialLoading: boolean;
  loading: boolean;
  teens: TeensListQuery['teens']['teens'];
  total: TeensListQuery['teens']['total'];
  fetchMore: (
    { limit, offset }: FetchMoreOptions,
    stateChanger: StateChanger
  ) => Promise<ApolloQueryResult<TeensListQuery>> | undefined;
};
export const teenListEnhancer = wrapComponent<Props, TeensListProps>((props) => {
  const { fetchMore, loading, result } = useTeensListQuery(
    computed(() => ({
      limit: 60,
      filter: {
        regionId: props.regionId,
        isAlumni: props.activeFilters.isAlumni,
        query: props.activeFilters.term,
        sortBy: props.activeFilters.sortBy,
        interests: props.activeFilters.interests,
        filters: props.activeFilters.filters
      },
      regionId: props.regionId
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    initialLoading: loading.value && !result.value?.teens,
    loading: loading.value,
    teens: result.value?.teens.teens || [],
    total: result.value?.teens.total || 0,
    fetchMore: ({ limit, offset }, stateChanger) =>
      fetchMore({
        variables: {
          limit,
          offset,
          filter: {
            regionId: props.regionId,
            query: props.activeFilters.term,
            isAlumni: props.activeFilters.isAlumni,
            filters: props.activeFilters.filters,
            interests: props.activeFilters.interests,
            sortBy: props.activeFilters.sortBy
          }
        },
        updateQuery(previousResult, { fetchMoreResult }) {
          if (!fetchMoreResult?.teens) {
            return previousResult;
          }

          const teens = previousResult?.teens?.teens || [];

          const ids = teens.map((x: Teen) => x.personID);
          const newData = fetchMoreResult.teens.teens.filter(
            (x: Teen) => !ids.includes(x.personID)
          );

          return {
            teens: {
              __typename: 'TeenPage',
              total: fetchMoreResult.teens.total,
              teens: [...teens, ...newData]
            }
          };
        }
      })
  }));
});
