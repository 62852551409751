import inView from 'in-view';
import { defineComponent, h, onMounted, ref } from 'vue';

export const InView = defineComponent({
  setup(props, { slots }) {
    const isInView = ref(false);
    const elm = ref();

    const checkIfInView = () => {
      if (elm.value) {
        isInView.value = inView.is(elm.value);
        window.requestAnimationFrame(checkIfInView);
      }
    };

    onMounted(() => {
      checkIfInView();
    });

    return () => {
      const $vnodes = slots.default && slots.default({ inView: isInView.value });

      if ($vnodes) {
        return h('div', { ref: elm }, $vnodes);
      }
      return h('div', { ref: elm });
    };
  }
});
