import Single from './Single.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { CompactCommunityMember } from './shared/CompactCommunityMember';
import { toCompactCommunityMember } from './utils';
import { useSingleCommunityMemberQuery } from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

interface Props {
  personId: string;
  regionId: number | null;
}

interface SingleCommunityMemberProps {
  communityMember?: CompactCommunityMember | null;
  loading: boolean;
}
const singleCommunityMemberEnhancer = wrapComponent<Props, SingleCommunityMemberProps>((props) => {
  const { loading, result } = useSingleCommunityMemberQuery(
    computed(() => ({
      personID: +props.personId
    }))
  );

  return computed(() => ({
    loading: loading.value,
    communityMember: result.value?.singleCommunityMember
      ? toCompactCommunityMember(result.value.singleCommunityMember)
      : null
  }));
});

export default compose(singleCommunityMemberEnhancer)(Single);
