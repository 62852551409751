import { computed } from 'vue';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  EditHouseInput,
  GetHousesForEventQuery,
  useEditHouseMutation
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type House = ArrayElement<GetHousesForEventQuery['houses']>;
interface EditHouseProps {
  editHouse: (eventId: number, input: EditHouseInput, house: House) => void;
}

export const editHouseEnhancer = wrapComponent<{}, EditHouseProps>(() => {
  const { mutate } = useEditHouseMutation();

  return computed(() => ({
    editHouse(eventId, input, house) {
      const { staffId, ...rest } = input;
      return mutate(
        { eventId, house: input },
        {
          optimisticResponse: {
            editHouse: {
              ...house,
              ...rest
            }
          }
        }
      );
    }
  }));
});
