import {
  ParentViewModel,
  ParentUpdateArgs,
  ParentFieldsEnum,
  Child,
  Parent,
  Address
} from './types';
import {
  getCurrentEmail,
  getCurrentCellNumber,
  formatAddressToString,
  getCurrentLandlineNumber,
  getCurrentAddress
} from 'shared/util';
import trim from 'lodash/trim';
import { PhoneType } from 'shared/generated/graphql-types';

const toCompactChildrenViewModel = (children: Child[]) => {
  return children.map((c) => ({
    landline: getCurrentLandlineNumber(c.Phones || [])
      ? getCurrentLandlineNumber(c.Phones || [])!.phoneNumber
      : null,
    cellphone: getCurrentCellNumber(c.Phones || [])
      ? getCurrentCellNumber(c.Phones || [])!.phoneNumber
      : null,
    email: getCurrentEmail(c.EmailAddresses || [])
      ? getCurrentEmail(c.EmailAddresses || [])!.email
      : null,
    firstName: c.firstName,
    lastName: c.lastName,
    personID: c.personID
  }));
};

export const toCompactParent = (parent: Parent): ParentViewModel => {
  const currentAddressObject = getCurrentAddress((parent && parent.Addresses) || []);
  return {
    address: currentAddressObject,
    formattedAddress: formatAddressToString(currentAddressObject),
    landline: getCurrentLandlineNumber(parent.Phones || [])
      ? getCurrentLandlineNumber(parent.Phones)!
      : null,
    cellphone: getCurrentCellNumber(parent.Phones || [])
      ? getCurrentCellNumber(parent.Phones)!
      : null,
    email: getCurrentEmail(parent.EmailAddresses || [])
      ? getCurrentEmail(parent.EmailAddresses)!.email
      : null,
    emails: parent.EmailAddresses,
    emailId: getCurrentEmail(parent.EmailAddresses || [])
      ? getCurrentEmail(parent.EmailAddresses)!.id
      : null,
    formattedLandline: getCurrentLandlineNumber(parent.Phones || [])
      ? getCurrentLandlineNumber(parent.Phones)!.phoneNumber
      : null,
    formattedCellphone: getCurrentCellNumber(parent.Phones || [])
      ? getCurrentCellNumber(parent.Phones)!.phoneNumber
      : null,
    firstName: parent.firstName,
    lastName: parent.lastName,
    fullName: trim(`${parent.firstName || ''} ${parent.lastName || ''}`),
    gender: parent.gender,
    olamiId: parent.olamiId,
    personId: parent.personID,
    dataOptOut: parent.dataOptOut,
    children:
      parent.Children && parent.Children.length ? toCompactChildrenViewModel(parent.Children) : [],
    original: parent
  };
};

function insert<T extends Array<any>>(value: T[0], array: T, index: number) {
  return [...array.slice(0, index), value, ...array.slice(index + 1)];
}
export function calcParentUpdate(parent: Parent, update: ParentUpdateArgs): Parent {
  switch (update.fieldName) {
    case ParentFieldsEnum.address: {
      const currentAddress = getCurrentAddress(parent.Addresses);

      const index = parent.Addresses.findIndex((x) => x.id === currentAddress.id);
      const value: Address = JSON.parse(update.value!);

      (value.__typename = 'Address'), (value.dateCreated = new Date().toISOString());
      value.dateUpdated = new Date().toISOString();
      value.id = -1;
      value.primary = true;
      value.verified = false;

      if (index === -1) {
        return {
          ...parent,
          Addresses: [...parent.Addresses, value]
        };
      }

      return {
        ...parent,
        Addresses: insert(
          {
            ...currentAddress,
            ...value
          },
          parent.Addresses,
          index
        )
      };
    }
    case ParentFieldsEnum.cellphone: {
      const cellPhone = getCurrentCellNumber(parent.Phones);
      const newPhone: Parent = {
        ...parent,
        Phones: [
          {
            __typename: 'Phone',
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            description: null,
            doNotCall: null,
            id: -1,
            invalid: false,
            phoneNumber: update.value!,
            primary: true,
            type: PhoneType.Mobile
          }
        ]
      };

      if (!cellPhone) {
        return newPhone;
      }

      const index = parent.Phones.findIndex((x) => x.id === cellPhone.id);

      if (index === -1) {
        return newPhone;
      }

      return {
        ...parent,
        Phones: insert(
          {
            ...cellPhone,
            phoneNumber: update.value!
          },
          parent.Phones,
          index
        )
      };
    }
    case ParentFieldsEnum.landline: {
      const homeNumber = getCurrentLandlineNumber(parent.Phones);

      const newPhone: Parent = {
        ...parent,
        Phones: [
          {
            __typename: 'Phone',
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            description: null,
            doNotCall: null,
            id: -1,
            invalid: false,
            phoneNumber: update.value!,
            primary: true,
            type: PhoneType.Landline
          }
        ]
      };

      if (!homeNumber) {
        return newPhone;
      }

      const index = parent.Phones.findIndex((x) => x.id === homeNumber.id);

      if (index === -1) {
        return newPhone;
      }

      return {
        ...parent,
        Phones: insert(
          {
            ...homeNumber,
            phoneNumber: update.value!
          },
          parent.Phones,
          index
        )
      };
    }
    case ParentFieldsEnum.email: {
      const compactParent = toCompactParent(parent);
      const currentEmail = compactParent.email;

      const newEmail: Parent = {
        ...parent,
        EmailAddresses: [
          {
            __typename: 'EmailAddress',
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            email: update.value!,
            id: -1,
            invalid: false,
            primary: true
          }
        ]
      };

      if (!currentEmail) {
        return newEmail;
      }
      const emailIndex = compactParent.emails.findIndex((x) => x.id === compactParent.emailId);
      if (emailIndex === -1) {
        return newEmail;
      }
      return {
        ...parent,
        EmailAddresses: insert(
          {
            ...compactParent.emails[emailIndex],
            email: update.value!
          },
          parent.EmailAddresses,
          emailIndex
        )
      };
    }
    case ParentFieldsEnum.fullName:
    case ParentFieldsEnum.gender:
    case ParentFieldsEnum.olamiId: {
      const { firstName, lastName, gender, olamiId } = JSON.parse(update.value!);
      return {
        ...parent,
        firstName,
        lastName,
        gender,
        olamiId
      };
    }
    default:
      return parent;
  }
}
