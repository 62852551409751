import { wrapComponent } from 'shared/apollo-hoc';
import { useCurrentUserWithRegionsQuery } from 'shared/generated/graphql-types';
import { Me } from 'shared/types';
import { computed } from 'vue';

export const currentUserEnhancer = wrapComponent<{}, { me: Me }>(
  (props) => {
    const { result } = useCurrentUserWithRegionsQuery();
    return computed(() => ({
      me: result.value?.me
    }));
  }
);
