import { render, staticRenderFns } from "./AddPhone.vue?vue&type=template&id=22b01eb5&scoped=true&"
import script from "./AddPhone.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AddPhone.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AddPhone.vue?vue&type=style&index=0&id=22b01eb5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22b01eb5",
  null
  
)

export default component.exports