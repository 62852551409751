import Vue, { Component as VueComponent, computed } from 'vue';
import { compose, acceptProps, withProps, withData } from 'vue-compose';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import omit from 'lodash/omit';
import EventTable from './EventTable.vue';
import { Props } from './types';
import { getCurrentSeason, normalizeProps, getOptions } from 'shared/util';
import { SimpleDate } from 'shared/util/types';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import {
  EventFilterInput,
  GetEventsTableDocument,
  GetEventsTableQuery,
  MergeSeriesMutationVariables,
  RegionWideFilter,
  useGetEventsTableQuery,
  useMergeSeriesMutation
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

export const filtersInit = (user?: number): EventFilterInput => ({
  advisorID: user ? [user] : null,
  showPendingAttendanceEvents: false,
  chapterId: undefined,
  EventSubTypeID: undefined,
  EventType: undefined,
  seriesID: undefined,
  trackId: undefined,
  term: undefined,
  regionWide: RegionWideFilter.Show,
  season: getCurrentSeason(),
  orderBy: {}
});

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const getEventsTableEnhancer = wrapComponent<Props, Pick<Props, 'events' | 'total' | 'fetching'>>(
  (props) => {
    const isSeason = props.filters.EventType === 'Series';

    const { loading, result } = useGetEventsTableQuery(
      computed(() => ({
        filter: {
          advisorID: props.filters.advisorID,
          EventSubTypeID: props.filters.EventSubTypeID,
          chapterId: props.filters.chapterId,
          showPendingAttendanceEvents: props.filters.showPendingAttendanceEvents,
          EventType: props.filters.EventType,
          seriesID: props.filters.seriesID,
          trackId: props.filters.trackId ? props.filters.trackId.filter(Boolean) : undefined,
          term: props.filters.term,
          regionWide: props.filters.regionWide,
          season: props.filters.season,
          orderBy: props.filters.orderBy,
          regionId: props.currentRegion
        },
        limit: isSeason ? 0 : props.limit,
        offset: isSeason ? 0 : (props.currentPage - 1) * props.limit
      })),
      { fetchPolicy: 'cache-and-network' }
    );

    return computed(() => ({
      events: result.value?.eventsTable?.events || [],
      total: result.value?.eventsTable?.total || 0,
      fetching: loading.value
    }));
  }
);

interface MergeSeriesProps {
  mergeSeries: (vars: MergeSeriesMutationVariables, filters: EventFilterInput) => void;
  mergingSeries: boolean;
}

const mergeSeriesEnhancer = wrapComponent<Props, MergeSeriesProps>((props) => {
  const { loading, mutate } = useMergeSeriesMutation();

  return computed(() => ({
    mergeSeries: ({ deleteIDs, remainingID }, filters) =>
      mutate(
        { deleteIDs, remainingID },
        {
          optimisticResponse: {
            mergeSeries: {
              seriesID: remainingID,
              __typename: 'Series'
            }
          },
          update: (proxy, response) => {
            const isSeason = props.filters.EventType === 'Series';
            const filter = {
              advisorID: props.filters.advisorID,
              EventSubTypeID: props.filters.EventSubTypeID,
              chapterId: props.filters.chapterId,
              showPendingAttendanceEvents: props.filters.showPendingAttendanceEvents,
              EventType: props.filters.EventType,
              seriesID: props.filters.seriesID,
              trackId: props.filters.trackId ? props.filters.trackId.filter(Boolean) : undefined,
              term: props.filters.term,
              regionWide: props.filters.regionWide,
              season: props.filters.season,
              orderBy: props.filters.orderBy,
              regionId: props.currentRegion
            };

            const data = proxy.readQuery<GetEventsTableQuery>({
              query: GetEventsTableDocument,
              variables: {
                filter,
                limit: isSeason ? 0 : props.limit,
                offset: isSeason ? 0 : (props.currentPage - 1) * props.limit
              }
            });

            if (data?.eventsTable) {
              const events = data.eventsTable.events.filter(
                (x) => !deleteIDs.includes(x.seriesId!)
              );

              proxy.writeQuery<GetEventsTableQuery>({
                query: GetEventsTableDocument,
                variables: {
                  filter,
                  limit: isSeason ? 0 : props.limit,
                  offset: isSeason ? 0 : (props.currentPage - 1) * props.limit
                },
                data: {
                  eventsTable: {
                    ...data.eventsTable,
                    events
                  }
                }
              });
            }
          }
        }
      ),
    mergingSeries: loading.value
  }));
});

const withFilters = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);

  const { filters, setFilters, clearFilters, ...propsToUse } = props;

  return Vue.extend({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        filters: filtersInit()
      };
    },
    methods: {
      setFilters(args: Partial<EventFilterInput>) {
        this.filters = { ...this.filters, ...args };
      },
      clearFilters(state: object) {
        this.filters = {
          ...filtersInit(this.user),
          ...(!isEmpty(state) ? state : {})
        };
        this.$emit('currentPage', 1);
      }
    },
    created() {
      this.filters = this.persistedFilters ? {...this.persistedFilters, ...filtersInit(this.user)} : {...this.filters, advisorID: [this.user]}
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          filters: this.filters,
          setFilters: this.setFilters,
          clearFilters: this.clearFilters
        },
        on: this.$listeners
      });
    }
  });
};

const withFiltersFromStorage = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);

  const ComponentWithPersistedFilters = Vue.extend({
    name: 'CalendarPersistance',
    props: omit(props, [
      'dates',
      'datesChanged',
      'currentDate',
      'currentPage',
      'setCurrentDate',
      'mode',
      'setMode',
      'persistState'
    ]),
    methods: {
      persistState(filters: EventFilterInput, date: SimpleDate, page: number) {
        localStorage.calendarPersistance = JSON.stringify({
          persistedFilters: {
            ...filters,
            ...(has(filters, 'regionWide') ? { regionWide: RegionWideFilter.Show } : {})
          },
          persistedDate: date,
          persistedPage: page
        });
      }
    },
    render(h) {
      const persistedState = localStorage.getItem('calendarPersistance') || '{}';

      const { persistedFilters, persistedDate, persistedPage } = JSON.parse(persistedState);

      return h(Component, {
        on: this.$listeners,
        props: {
          ...this.$props,
          persistedDate,
          persistedFilters,
          persistedPage,
          persistState: this.persistState
        }
      });
    }
  });

  return ComponentWithPersistedFilters;
};

const persistFilters = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);

  props.persistState = {};

  const EnhancedComponent = Vue.extend({
    name: 'CalendarPersistance',
    props,
    watch: {
      currentDate() {
        this.persistState(this.filters, this.currentDate, this.currentPage);
      },
      filters() {
        this.persistState(this.filters, this.currentDate, this.currentPage);
      },
      currentPage() {
        this.persistState(this.filters, this.currentDate, this.currentPage);
      }
    },
    render(h) {
      return h(Component, {
        on: this.$listeners,
        props: {
          ...this.$props
        }
      });
    }
  });

  return EnhancedComponent;
};

interface PersistedState {
  persistedFilters: EventFilterInput;
  persistedDate: SimpleDate;
  persistedPage: number;
}

export const enhancer = compose(
  withFiltersFromStorage,
  withFilters,
  withData<PersistedState & { user: number }, any>({
    currentPage: {
      initialValue: (props) => props.persistedPage || 1
    },
    limit: {
      initialValue: 30
    }
  }),
  acceptProps(['persistedDate', 'persistedFilters', 'persistedPage', 'persistState']),
  withProps<any, any>((props: any) => ({
    currentRegion: getCurrentRegion()
  })),
  persistFilters,
  getEventsTableEnhancer,
  mergeSeriesEnhancer,
);

export default enhancer(EventTable);
