import Single from './SingleEvent.vue';
import { compose } from 'vue-compose';
import currentUser from 'shared/components/CurrentUserEnhancer';
import { GetEventQuery } from 'shared/generated/graphql-types';
import { getEventEnhancer } from 'shared/enhancers/getEventEnhancer';

export type TProps = {
  eventId: string;
  regionId: number;
  event?: GetEventQuery['event'] | null;
  loading: boolean;
};

export default compose(currentUser, getEventEnhancer)(Single);
