var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.name)?_c('div',[_c('header',[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"actions"},[_c(_setup.UIButton,{staticClass:"red",attrs:{"disabled":_setup.deleting},on:{"click":_setup.tryDelete}},[_vm._v(" Delete ")]),_c(_setup.UIButton,{staticClass:"green",attrs:{"disabled":_setup.saving || !_setup.isValid},on:{"click":_setup.saveQuestions}},[_vm._v(" Save ")]),_c(_setup.UIButton,{staticClass:"inverted",attrs:{"disabled":_setup.saving},on:{"click":_setup.addQuestion}},[_vm._v(" Add Question ")]),_c(_setup.UIButton,{on:{"click":_setup.downloadAnswersReport}},[_vm._v(" Download Answers Report ")])],1)]),_c(_setup.ScrollablePanel,{directives:[{name:"fullheight",rawName:"v-fullheight"}]},[_c(_setup.draggable,{staticClass:"main",attrs:{"animation":300,"group":"questions","handle":".reorder"},on:{"update":_setup.updateOrder},model:{value:(_setup.newQuestions),callback:function ($$v) {_setup.newQuestions=$$v},expression:"newQuestions"}},_vm._l((_setup.newQuestions),function(question,index){return _c(_setup.QuestionEditor,{key:question.customQuestionId,attrs:{"isDeleting":_vm.deletingQuestion,"validations":_setup.validations[index]},on:{"remove":function($event){return _setup.removeQuestion(question)}},model:{value:(_setup.newQuestions[index]),callback:function ($$v) {_vm.$set(_setup.newQuestions, index, $$v)},expression:"newQuestions[index]"}})}),1)],1)],1):_vm._e(),_c(_setup.NoData,{attrs:{"condition":!_vm.loadingCustomQuestionsCollection && !_vm.name}},[_vm._v(" Sorry, custom questions are not found. ")]),(_vm.loadingCustomQuestionsCollection)?_c(_setup.Loading):_vm._e(),(_setup.deleting)?_c(_setup.DeleteCollectionModal,{attrs:{"collection-to-delete":{
      customQuestionsCollectionId: _vm.customQuestionsCollectionId,
      collectionName: _vm.name,
      eventName: _vm.eventName,
      questions: _vm.questions,
      questionsAmount: _vm.questionsAmount
    }},on:{"close":function($event){_setup.deleting = false},"delete":_setup.handleDeletion}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }