import EditRecurrence from './EditRecurrence.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { MutateResult } from '@vue/apollo-composable';
import { Filters, Dates } from '../../types';
import { Series } from './types';
import {
  GetSeriesEventsDocument,
  GetSeriesEventsQuery,
  GetSingleSeriesDocument,
  GetSingleSeriesQuery,
  ModifySeriesRecurrenceInput,
  ModifySeriesRecurrenceMutation,
  useModifySeriesRecurrenceMutation
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

interface ModifySeriesReccurenceProps {
  modifyRecurrence: (
    input: ModifySeriesRecurrenceInput
  ) => MutateResult<ModifySeriesRecurrenceMutation>;
  series: Series;
  dates: Dates;
  filters: Filters;
}

const modifyRecurrenceEnhancer = wrapComponent<
  ModifySeriesReccurenceProps,
  Pick<ModifySeriesReccurenceProps, 'modifyRecurrence'>
>((props) => {
  const { mutate } = useModifySeriesRecurrenceMutation();

  return computed(() => ({
    modifyRecurrence: (input) =>
      mutate(
        { input },
        {
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetSeriesEventsQuery>({
              query: GetSeriesEventsDocument,
              variables: {
                seriesId: props.series.seriesID,
                filter: {
                  upcoming: props.filters.upcoming,
                  range: {
                    startDate: props.dates.startDate,
                    endDate: props.dates.endDate
                  }
                }
              }
            });
            if (data?.seriesEvents && updateData?.modifySeriesRecurrence) {
              const events = updateData.modifySeriesRecurrence.Events;
              const total = updateData.modifySeriesRecurrence.Events.length;
              proxy.writeQuery<GetSeriesEventsQuery>({
                query: GetSeriesEventsDocument,
                variables: {
                  seriesId: props.series.seriesID,
                  filter: {
                    upcoming: props.filters.upcoming,
                    range: {
                      startDate: props.dates.startDate,
                      endDate: props.dates.endDate
                    }
                  }
                },
                data: {
                  seriesEvents: {
                    ...data.seriesEvents,
                    events,
                    total
                  }
                }
              });
            }

            const singleSeries = proxy.readQuery<GetSingleSeriesQuery>({
              query: GetSingleSeriesDocument,
              variables: {
                seriesID: props.series.seriesID
              }
            });
            if (singleSeries?.singleSeries && updateData?.modifySeriesRecurrence) {
              const events = updateData.modifySeriesRecurrence.Events;
              proxy.writeQuery<GetSingleSeriesQuery>({
                query: GetSingleSeriesDocument,
                variables: {
                  seriesID: props.series.seriesID
                },
                data: {
                  singleSeries: {
                    ...singleSeries.singleSeries,
                    Events: events
                  }
                }
              });
            }
          }
        }
      )
  }));
});

export default compose(modifyRecurrenceEnhancer)(EditRecurrence);
