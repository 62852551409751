import { render, staticRenderFns } from "./EditorContainer.vue?vue&type=template&id=65bd200c&scoped=true&"
import script from "./EditorContainer.vue?vue&type=script&setup=true&lang=ts&"
export * from "./EditorContainer.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./EditorContainer.vue?vue&type=style&index=0&id=65bd200c&prod&lang=css&"
import style1 from "./EditorContainer.vue?vue&type=style&index=1&id=65bd200c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65bd200c",
  null
  
)

export default component.exports