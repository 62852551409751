import Layout from './Layout.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetEmergencyContactsQuery } from 'shared/generated/graphql-types';

type GetEmergencyContactsProps = Pick<Props, 'emergencyContacts' | 'fetchingEmergencyContacts'>;

const getEmergencyContactsEnhancer = wrapComponent<Props, GetEmergencyContactsProps>((props) => {
  const { loading, result } = useGetEmergencyContactsQuery(
    computed(() => ({ personId: props.personId }))
  );

  return computed(() => ({
    emergencyContacts: result.value?.emergencyContacts || [],
    fetchingEmergencyContacts: loading.value
  }));
});

export default compose(getEmergencyContactsEnhancer)(Layout);
