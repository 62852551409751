import { Address as AddressType } from './types';

export default class Address {
  street: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  country: string | null;

  constructor(args: AddressType) {
    this.street = args.street || null;
    this.city = args.city || null;
    this.state = args.state || null;
    this.zipCode = args.zipCode || null;
    this.country = args.country || null;
  }
}
