import Single from './Single.vue';
import { compose } from 'vue-compose';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetJfeSingleTeenQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';

const getTeenEnhancer = wrapComponent<{ teenId: number }, Pick<Props, 'teen' | 'loading'>>(
  (props) => {
    const { loading, result } = useGetJfeSingleTeenQuery(
      computed(() => ({ teenId: props.teenId }))
    );

    return computed(() => ({
      loading: loading.value,
      teen: result.value?.jfeTeen
    }));
  }
);

export default compose(getTeenEnhancer)(Single);
