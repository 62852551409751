import Lists from './MyLists.vue';
import Vue, { computed, VueConstructor } from 'vue';
import { compose } from 'vue-compose';
import { getOptions, normalizeProps } from 'shared/util';
import { Props, Filters } from './types';
import { enhancer as addTeenToListEnhancer } from 'shared/components/AddTeenToList';
import { enhancer as markAsLeadEnhancer } from 'shared/components/MarkAsLead';
import { enhancer as removeTeenFromListEnhancer } from './RemoveFromList';
import { currentUserEnhancer } from 'shared/enhancers/currentUserEnhancer';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetTeenListQuery,
  GetTeenListsQuery,
  useGetTeenListQuery,
  useGetTeenListsQuery
} from 'shared/generated/graphql-types';

export const filtersInit = (regionId: number) => ({
  regionId,
  chapterId: null,
  term: ''
});

type TeenListsProps = {
  teenListsLoading: boolean;
  teenLists: GetTeenListsQuery['teenLists']['teenLists'];
  teenListsTotal: number;
};
const getTeenListsEnhancer = wrapComponent<Props, TeenListsProps>((props) => {
  const { loading, result } = useGetTeenListsQuery(
    computed(() => ({
      filter: {
        ...props.filters,
        regionId: props.regionId !== 33 ? props.regionId : null
      },
      offset: (props.currentPage - 1) * props.limit,
      limit: props.limit
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    teenLists: result.value?.teenLists.teenLists || [],
    teenListsLoading: loading.value,
    teenListsTotal: result.value?.teenLists.total || 0
  }));
});

type TeenListProps = {
  teenListLoading: boolean;
  teenList: GetTeenListQuery['teenList'];
};
const getTeenListEnhancer = wrapComponent<Props, TeenListProps>((props) => {
  const { loading, result } = useGetTeenListQuery(
    computed(() => ({ teenListId: Number(props.teenListId) })),
    {
      enabled: computed(() => !!props.teenListId),
      fetchPolicy: 'network-only'
    }
  );

  return computed(() => ({
    teenList: result.value?.teenList,
    teenListLoading: loading.value
  }));
});

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { currentPage, limit, filters, setFilter, clearFilters, ...propsToUse } = props;

  interface Data {
    filters: Filters;
    currentPage: number;
    limit: number;
  }

  return Vue.extend<
    Data,
    Pick<Props, 'setFilter' | 'clearFilters'>,
    {},
    Pick<Props, 'limit' | 'setFilter' | 'clearFilters'>
  >({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        filters: filtersInit(this.regionId),
        currentPage: 1,
        limit: 30
      };
    },
    methods: {
      setFilter(key, value) {
        this.filters = {
          ...this.filters,
          [key]: value
        };
      },
      clearFilters() {
        this.filters = filtersInit(this.regionId);
        this.currentPage = 1;
      }
    },
    render(h) {
      return h(Component, {
        on: this.$listeners,
        props: {
          ...this.$props,
          filters: this.filters,
          currentPage: this.currentPage,
          limit: this.limit,
          setFilter: this.setFilter,
          clearFilters: this.clearFilters
        }
      });
    }
  });
};

export default compose(
  withFilters,
  getTeenListsEnhancer,
  getTeenListEnhancer,
  addTeenToListEnhancer,
  markAsLeadEnhancer,
  removeTeenFromListEnhancer,
  currentUserEnhancer
)(Lists);
