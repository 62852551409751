var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"root",staticClass:"popover-container",class:{
    open: _setup.show,
    'align-right': _vm.alignRight,
    'align-left': _vm.alignLeft,
    'align-bottom': _vm.alignBottom
  }},[(_setup.hasTriggerSlot)?_c('span',{staticClass:"trigger",on:{"click":() => (_setup.show === true ? (_setup.show = false) : (_setup.show = true))}},[_vm._t("trigger",null,{"close":() => (_setup.show = false)})],2):_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus-circle']},on:{"click":() => (_setup.show === true ? (_setup.show = false) : (_setup.show = true))}}),(_setup.show)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_setup.close),expression:"close"}],ref:"popover",staticClass:"popover",class:_setup.getClass(_setup.dimensions),style:({
      width: _vm.width,
      marginLeft: _setup.marginLeft + 'px',
      maxHeight: _vm.height ? `${_vm.height}px` : undefined
    }),on:{"click":() => (_vm.stopToggeling ? (_setup.show = true) : (_setup.show = !_setup.show))}},[_vm._t("content",null,{"open":_setup.open,"close":_setup.close})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }