import { Props } from '../../types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  BusForEventFragment,
  BusForEventFragmentDoc,
  EventWithRegistrationsDocument,
  EventWithRegistrationsQuery,
  RegistrationPaymentStatus,
  SelectedRegistrationFragment,
  SelectedRegistrationFragmentDoc,
  StatusType,
  useCancelRegistrationMutation,
  useCompleteRegistrationMutation,
  useMoveRegistrationToWaitListMutation,
  useProduceInvoiceMutation,
  usePromoteRegistrationMutation,
  useResendRegistrationEmailsMutation,
  useResendWaiverEmailsMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

export const moveRegistrationToWaitListEnhancer = wrapComponent<
  Props,
  Pick<Props, 'moveToWaitList' | 'movingRegistrationToWaitingList'>
>((props) => {
  const { loading, mutate } = useMoveRegistrationToWaitListMutation();

  return computed(() => ({
    moveToWaitList: (args) => {
      try {
        const selectedRegistrationFragmentData =
          props.client!.readFragment<SelectedRegistrationFragment>({
            fragment: SelectedRegistrationFragmentDoc,
            id: `${args.registrationId}Registration`,
            fragmentName: 'SelectedRegistration'
          });
        return mutate(
          {
            ...args
          },
          {
            optimisticResponse: {
              moveRegistrationToWaitList: {
                ...selectedRegistrationFragmentData!,
                status: StatusType.Cancelled,
                paymentStatuses: [RegistrationPaymentStatus.WaitingList]
              }
            }
          }
        );
      } catch {
        return mutate(
          {
            ...args
          },
          {
            optimisticResponse: {
              moveRegistrationToWaitList: null
            }
          }
        );
      }
    },
    movingRegistrationToWaitingList: loading.value
  }));
});

export const promoteRegistrationEnhancer = wrapComponent<
  Props,
  Pick<Props, 'promoteRegistration' | 'promotingRegistration'>
>((props) => {
  const { loading, mutate } = usePromoteRegistrationMutation();

  return computed(() => ({
    promoteRegistration: (args) => {
      try {
        const selectedRegistrationFragmentData =
          props.client!.readFragment<SelectedRegistrationFragment>({
            fragment: SelectedRegistrationFragmentDoc,
            id: `${args.registrationId}Registration`,
            fragmentName: 'SelectedRegistration'
          });
        return mutate(
          {
            ...args
          },
          {
            optimisticResponse: {
              promoteRegistration: {
                ...selectedRegistrationFragmentData!,
                // To make life simpler we are just picking completed, even if the actual reponse might be different
                status: StatusType.Completed,
                paymentStatuses: [RegistrationPaymentStatus.Completed]
              }
            }
          }
        );
      } catch {
        return mutate(
          {
            ...args
          },
          {
            optimisticResponse: {
              promoteRegistration: null
            }
          }
        );
      }
    },
    promotingRegistration: loading.value
  }));
});

export const cancelRegistrationEnhancer = wrapComponent<
  Props,
  Pick<Props, 'cancelRegistration' | 'cancelingRegistration'>
>((props) => {
  const { loading, mutate } = useCancelRegistrationMutation();

  return computed(() => ({
    cancelRegistration: (args) => {
      try {
        const selectedRegistrationFragmentData =
          props.client!.readFragment<SelectedRegistrationFragment>({
            fragment: SelectedRegistrationFragmentDoc,
            id: `${args.registrationId}Registration`,
            fragmentName: 'SelectedRegistration'
          });
        return mutate(
          {
            ...args
          },
          {
            optimisticResponse: {
              cancelRegistration: {
                ...selectedRegistrationFragmentData!,
                status: StatusType.Cancelled,
                paymentStatuses: [RegistrationPaymentStatus.Cancelled]
              }
            },
            update: (proxy) => {
              const busesIds: number[] = [];
              const eventWithRegistrationsData = proxy.readQuery<EventWithRegistrationsQuery>({
                query: EventWithRegistrationsDocument,
                variables: {
                  eventID: props.eventId || props.event!.eventId
                }
              });
              if (eventWithRegistrationsData && eventWithRegistrationsData.event) {
                const registration = eventWithRegistrationsData.event.Registrations.find(
                  (r) => r.registrationID === args.registrationId
                );
                if (registration) {
                  if (registration.FromBus) {
                    busesIds.push(registration.FromBus.busID);
                  }
                  if (registration.ToBus) {
                    busesIds.push(registration.ToBus.busID);
                  }
                }
                proxy.writeQuery<EventWithRegistrationsQuery>({
                  query: EventWithRegistrationsDocument,
                  variables: {
                    eventID: props.eventId || props.event!.eventId
                  },
                  data: {
                    event: {
                      ...eventWithRegistrationsData.event,
                      Registrations: eventWithRegistrationsData.event.Registrations.filter(
                        (r) => r.registrationID !== args.registrationId
                      )
                    }
                  }
                });
              }
              if (busesIds[0] === busesIds[1]) {
                busesIds.pop();
              }

              try {
                const busesData = busesIds.map((bId) =>
                  proxy.readFragment<BusForEventFragment>({
                    id: `${bId}Bus`,
                    fragment: BusForEventFragmentDoc,
                    fragmentName: 'BusForEvent'
                  })
                );

                busesData.forEach((bus) => {
                  if (bus && bus.passengerCount) {
                    proxy.writeFragment<BusForEventFragment>({
                      id: `${bus.busID}Bus`,
                      fragment: BusForEventFragmentDoc,
                      fragmentName: 'BusForEvent',
                      data: {
                        ...bus,
                        passengerCount: bus.passengerCount - 1
                      }
                    });
                  }
                });
              } catch (e) {
                console.log('Skip cache');
              }
            }
          }
        );
      } catch (e) {
        return mutate(
          {
            ...args
          },
          {
            optimisticResponse: {
              cancelRegistration: null
            },
            update: (proxy) => {
              const busesIds: number[] = [];
              const eventWithRegistrationsData = proxy.readQuery<EventWithRegistrationsQuery>({
                query: EventWithRegistrationsDocument,
                variables: {
                  eventID: props.eventId || props.event!.eventId
                }
              });
              if (eventWithRegistrationsData?.event) {
                const registration = eventWithRegistrationsData.event.Registrations.find(
                  (r) => r.registrationID === args.registrationId
                );

                if (registration) {
                  if (registration.FromBus) {
                    busesIds.push(registration.FromBus.busID);
                  }
                  if (registration.ToBus) {
                    busesIds.push(registration.ToBus.busID);
                  }
                }
                proxy.writeQuery<EventWithRegistrationsQuery>({
                  query: EventWithRegistrationsDocument,
                  variables: {
                    eventID: props.eventId || props.event!.eventId
                  },
                  data: {
                    event: {
                      ...eventWithRegistrationsData.event,
                      Registrations: eventWithRegistrationsData.event.Registrations.filter(
                        (r) => r.registrationID !== args.registrationId
                      )
                    }
                  }
                });
              }
              if (busesIds[0] === busesIds[1]) {
                busesIds.pop();
              }

              try {
                const busesData = busesIds.map((bId) =>
                  proxy.readFragment<BusForEventFragment>({
                    id: `${bId}Bus`,
                    fragment: BusForEventFragmentDoc,
                    fragmentName: 'BusForEvent'
                  })
                );

                busesData.forEach((bus) => {
                  if (bus && bus.passengerCount) {
                    proxy.writeFragment<BusForEventFragment>({
                      id: `${bus.busID}Bus`,
                      fragment: BusForEventFragmentDoc,
                      fragmentName: 'BusForEvent',
                      data: {
                        ...bus,
                        passengerCount: bus.passengerCount - 1
                      }
                    });
                  }
                });
              } catch (e) {
                console.log('Skip cache');
              }
            }
          }
        );
      }
    },
    cancelingRegistration: loading.value
  }));
});

export const completeRegistrationEnhancer = wrapComponent<
  Props,
  Pick<Props, 'completeRegistration' | 'completingRegistration'>
>((props) => {
  const { loading, mutate } = useCompleteRegistrationMutation();

  return computed(() => ({
    completeRegistration: (args) =>
      mutate(
        {
          ...args
        },
        {
          ...(props.registration
            ? {
                optimisticResponse: {
                  completeRegistration: {
                    ...props.registration,
                    status: StatusType.Completed,
                    paymentStatuses: [RegistrationPaymentStatus.Completed]
                  }
                }
              }
            : {})
        }
      ),
    completingRegistration: loading.value
  }));
});

export const resendRegistrationEmailsEnhancer = wrapComponent<
  Props,
  Pick<Props, 'resendRegistrationEmails' | 'sendingRegistrationEmails'>
>(() => {
  const { loading, mutate } = useResendRegistrationEmailsMutation();

  return computed(() => ({
    resendRegistrationEmails: (args) => mutate({ ...args }),
    sendingRegistrationEmails: loading.value
  }));
});

export const resendWaiverEmailsEnhancer = wrapComponent<
  Props,
  Pick<Props, 'resendWaiverEmails' | 'sendingWaiverEmails'>
>(() => {
  const { loading, mutate } = useResendWaiverEmailsMutation();

  return computed(() => ({
    resendWaiverEmails: (args) => mutate({ ...args }),
    sendingWaiverEmails: loading.value
  }));
});

export const produceInvoiceEnhancer = wrapComponent<
  Props,
  Pick<Props, 'produceInvoice' | 'producingInvoice'>
>(() => {
  const { loading, mutate } = useProduceInvoiceMutation();

  return computed(() => ({
    produceInvoice: async (args) => {
      const result = await mutate({ ...args });
      return result?.data?.produceInvoice || null;
    },
    producingInvoice: loading.value
  }));
});
