import { render, staticRenderFns } from "./note-form.vue?vue&type=template&id=683f8796&scoped=true&"
import script from "./note-form.vue?vue&type=script&setup=true&lang=ts&"
export * from "./note-form.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./note-form.vue?vue&type=style&index=0&id=683f8796&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683f8796",
  null
  
)

export default component.exports