
import { defineComponent, h, onMounted, PropType } from 'vue';
import LogRocket from 'logrocket';
import { Me } from 'shared/types';

export default defineComponent({
  props: {
    me: { type: Object as PropType<Me>, required: true }
  },
  setup(props) {
    onMounted(() => {
      LogRocket.identify(String(props.me.staffID), {
        name: `${props.me.firstName} ${props.me.lastName}`,
        email: props.me.email || ''
      });
    });
    return () => h();
  }
});
