import { compose, withData } from 'vue-compose';
import FamilyLookup from './FamilyLookup.vue';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetFamiliesQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';

const familyLookupEnhancer = wrapComponent<Props, Pick<Props, 'families' | 'familiesLoading'>>(
  (props) => {
    const { loading, result } = useGetFamiliesQuery(
      computed(() => ({
        filter: {
          regionId: props.regionId,
          query: props.term
        }
      })),
      { fetchPolicy: 'network-only', enabled: computed(() => !!props.term) }
    );

    return computed(() => ({
      families: result.value?.families.families || [],
      familiesLoading: loading.value
    }));
  }
);

export default compose(
  withData({
    term: {
      initialValue: null
    }
  }),
  familyLookupEnhancer
)(FamilyLookup);
