var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"update-parent-info"},[(_vm.teen.fatherId)?_c('div',{staticClass:"parent"},[_vm._m(0),_c('div',{staticClass:"form-row bigger"},[_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Name")]),_c(_setup.AutoSaveField,{attrs:{"name":"fatherName","entity":_setup.teenCopy,"transform":(c) => ({
              parentId: _setup.teenCopy.fatherId,
              value: JSON.stringify(_setup.splitName(c)),
              fieldName: _setup.UpdateTeenField.FatherName
            }),"validators":[{ name: 'fullName', validator: (name) => !name || _setup.validateName(name) }]},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.UIInput,_vm._g(_vm._b({attrs:{"data-test":"fatherNameField","type":"text","placeholder":"Father Name"}},'UIInput',$props,false),$listeners)),(hasError('fullName'))?_c('span',{staticClass:"error-label"},[_vm._v("Please enter the first and last name")]):_vm._e()]}}],null,false,2311951569)})],1),_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Email Address")]),_c(_setup.AutoSaveField,{attrs:{"name":"fatherEmail","entity":_setup.teenCopy,"transform":(c) => ({
              parentId: _setup.teenCopy.fatherId,
              value: c,
              fieldName: _setup.UpdateTeenField.FatherEmail
            })},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError, addErrors }){return [_c(_setup.EmailInput,_vm._g(_vm._b({attrs:{"ownerType":"father","placeholder":"Father Email","narrow":true,"ownerId":_setup.teenCopy.fatherId,"showErrors":true},on:{"populate":($event) => _setup.connectParentToTeenHandler($event.values)}},'EmailInput',$props,false),$listeners))]}}],null,false,3636797807)})],1)]),_c('div',{staticClass:"form-row bigger"},[_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Cellphone Number")]),_c(_setup.AutoSaveField,{attrs:{"name":"fatherCellPhone","entity":_setup.teenCopy,"toForm":(c) => (_setup.validateNumber(c) ? _setup.phoneFormat(c) : ''),"toEntity":_setup.phoneInternational,"transform":(c) => ({
              parentId: _setup.teenCopy.fatherId,
              value: c,
              fieldName: _setup.UpdateTeenField.FatherCellPhone
            }),"validators":[
            { name: 'validateNumber', validator: (value) => _setup.validateNumber(value, true) }
          ]},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.PhoneInput,_vm._g(_vm._b({attrs:{"type":"tel","name":"fatherCellPhone","placeholder":"Father CellPhone"}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Cellphone Number must be a number")]):_vm._e()]}}],null,false,3303801614)})],1),_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Homephone Number")]),_c(_setup.AutoSaveField,{attrs:{"name":"fatherHomePhone","entity":_setup.teenCopy,"toForm":(c) => (_setup.validateNumber(c) ? _setup.phoneFormat(c) : ''),"toEntity":_setup.phoneInternational,"transform":(c) => ({
              parentId: _setup.teenCopy.fatherId,
              value: c,
              fieldName: _setup.UpdateTeenField.FatherHomePhone
            }),"validators":[
            { name: 'validateNumber', validator: (value) => _setup.validateNumber(value, true) }
          ]},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.PhoneInput,_vm._g(_vm._b({attrs:{"type":"tel","name":"fatherHomePhone","placeholder":"Father Home Phone"}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Home Phone Number must be a number")]):_vm._e()]}}],null,false,2844708167)})],1)])]):_c('div',[_c('span',{staticClass:"icn"},[_c('font-awesome-icon',{attrs:{"icon":"male","fixed-width":""}})],1),_vm._v(" No Father - "),_c('a',{staticClass:"parent-link",on:{"click":function($event){_setup.showAddFather = true}}},[_vm._v("Add Father")]),_c(_setup.Modal,{attrs:{"show":_setup.showAddFather,"width":"400px","requestClose":() => (_setup.showAddFather = false)},scopedSlots:_vm._u([{key:"default",fn:function({ requestClose }){return [_c('div',{staticClass:"parent-modal"},[_c(_setup.QuickAddParent,{attrs:{"name":"Father","associateHandler":_setup.handleAssociate,"familyId":_vm.teen.primaryFamily.id,"sourceGender":_setup.Gender.Male,"sourceMaritalStatus":_vm.teen.primaryFamily.status,"ownerType":_setup.EntityUnionEnum.Father,"modalMode":true,"addingParent":_vm.addingParentToFamily || _vm.connectingParentToTeen},on:{"requestClose":requestClose}})],1)]}}])})],1),(_vm.teen.motherId)?_c('div',{staticClass:"parent"},[_vm._m(1),_c('div',{staticClass:"form-row bigger"},[_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Name")]),_c(_setup.AutoSaveField,{attrs:{"name":"motherName","entity":_setup.teenCopy,"transform":(c) => ({
              parentId: _setup.teenCopy.motherId,
              value: JSON.stringify(_setup.splitName(c)),
              fieldName: _setup.UpdateTeenField.MotherName
            }),"validators":[{ name: 'fullName', validator: (name) => !name || _setup.validateName(name) }]},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.UIInput,_vm._g(_vm._b({attrs:{"data-test":"motherNameField","type":"text","placeholder":"Mother Name"}},'UIInput',$props,false),$listeners)),(hasError('fullName'))?_c('span',{staticClass:"error-label"},[_vm._v("Please enter the first and last name")]):_vm._e()]}}],null,false,1405867217)})],1),_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Email Address")]),_c(_setup.AutoSaveField,{attrs:{"name":"motherEmail","entity":_setup.teenCopy,"transform":(c) => ({
              parentId: _setup.teenCopy.motherId,
              value: c,
              fieldName: _setup.UpdateTeenField.MotherEmail
            })},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError, addErrors }){return [_c(_setup.EmailInput,_vm._g(_vm._b({attrs:{"ownerType":"mother","placeholder":"Mother Email","narrow":true,"left":true,"ownerId":_setup.teenCopy.motherId,"showErrors":true,"validateOnBlur":true},on:{"populate":($event) => _setup.connectParentToTeenHandler($event.values)}},'EmailInput',$props,false),$listeners))]}}],null,false,4133236159)})],1)]),_c('div',{staticClass:"form-row bigger"},[_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Cellphone Number")]),_c(_setup.AutoSaveField,{attrs:{"name":"motherCellPhone","entity":_setup.teenCopy,"toForm":(c) => (_setup.validateNumber(c) ? _setup.phoneFormat(c) : ''),"toEntity":_setup.phoneInternational,"transform":(c) => ({
              parentId: _setup.teenCopy.motherId,
              value: c,
              fieldName: _setup.UpdateTeenField.MotherCellPhone
            }),"validators":[
            { name: 'validateNumber', validator: (value) => _setup.validateNumber(value, true) }
          ]},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.PhoneInput,_vm._g(_vm._b({attrs:{"type":"tel","name":"motherCellPhone","placeholder":"Mother CellPhone"}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Cellphone Number must be a number")]):_vm._e()]}}],null,false,2498617870)})],1),_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Homephone Number")]),_c(_setup.AutoSaveField,{attrs:{"name":"motherHomePhone","entity":_setup.teenCopy,"toForm":(c) => (_setup.validateNumber(c) ? _setup.phoneFormat(c) : ''),"toEntity":_setup.phoneInternational,"transform":(c) => ({
              parentId: _setup.teenCopy.motherId,
              value: c,
              fieldName: _setup.UpdateTeenField.MotherHomePhone
            }),"validators":[
            { name: 'validateNumber', validator: (value) => _setup.validateNumber(value, true) }
          ]},on:{"update:entity":function($event){_setup.teenCopy=$event},"save":_setup.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.PhoneInput,_vm._g(_vm._b({attrs:{"type":"tel","name":"motherHomePhone","placeholder":"Mother Home Phone"}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Home Phone Number must be a number")]):_vm._e()]}}],null,false,1701083015)})],1)])]):_c('div',[_c('span',{staticClass:"icn"},[_c('font-awesome-icon',{attrs:{"icon":"female","fixed-width":""}})],1),_vm._v(" No Mother - "),_c('a',{staticClass:"parent-link",on:{"click":function($event){_setup.showAddMother = true}}},[_vm._v("Add Mother")]),_c(_setup.Modal,{attrs:{"show":_setup.showAddMother,"width":"400px","requestClose":() => (_setup.showAddMother = false)},scopedSlots:_vm._u([{key:"default",fn:function({ requestClose }){return [_c('div',{staticClass:"parent-modal"},[_c(_setup.QuickAddParent,{attrs:{"name":"Mother","associateHandler":_setup.handleAssociate,"familyId":_vm.teen.primaryFamily.id,"sourceGender":_setup.Gender.Female,"sourceMaritalStatus":_vm.teen.primaryFamily.status,"ownerType":_setup.EntityUnionEnum.Mother,"modalMode":true,"addingParent":_vm.addingParentToFamily || _vm.connectingParentToTeen},on:{"requestClose":requestClose}})],1)]}}])})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Father")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Mother")])])
}]

export { render, staticRenderFns }