import { computed } from 'vue';
import {
  GetHousesForEventDocument,
  GetHousesForEventQuery,
  UpdateHouseRequestsMutationVariables,
  useUpdateHouseRequestsMutation
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { wrapComponent } from 'shared/apollo-hoc';

type House = ArrayElement<GetHousesForEventQuery['houses']>;

interface Props {
  eventId: number;
}

interface UpdateHouseRequestsProps {
  updateHouseRequests: (vars: UpdateHouseRequestsMutationVariables, house: House) => void;
}

export const updateHouseRegistrationEnhancer = wrapComponent<Props, UpdateHouseRequestsProps>(
  (props) => {
    const { mutate } = useUpdateHouseRequestsMutation();

    return computed(() => ({
      updateHouseRequests: (
        { housingRequest1, housingRequest2, housingRequest3, housingRequest4, registrationId },
        house
      ) =>
        mutate(
          { housingRequest1, housingRequest2, housingRequest3, housingRequest4, registrationId },
          {
            optimisticResponse: {
              updateHouseRequests: {
                __typename: 'Registration',
                registrationID: registrationId,
                housingRequest1,
                housingRequest2,
                housingRequest3,
                housingRequest4
              }
            },
            update: (proxy) => {
              const data = proxy.readQuery<GetHousesForEventQuery>({
                query: GetHousesForEventDocument,
                variables: { eventId: props.eventId }
              });

              if (data?.houses) {
                const houses = data.houses.slice();
                const currentHouseIndex = houses.findIndex((h) => h.houseId === house.houseId);
                const registrations = houses[currentHouseIndex].Registrations.slice();
                const registrationIndex = registrations.findIndex(
                  (r) => r.registrationID === registrationId
                );

                registrations[registrationIndex] = {
                  ...registrations[registrationIndex],
                  housingRequest1,
                  housingRequest2,
                  housingRequest3,
                  housingRequest4
                };

                houses[currentHouseIndex] = {
                  ...houses[currentHouseIndex],
                  Registrations: registrations
                };

                proxy.writeQuery<GetHousesForEventQuery>({
                  query: GetHousesForEventDocument,
                  variables: { eventId: props.eventId },
                  data: { houses }
                });
              }
            }
          }
        )
    }));
  }
);
