import { computed } from 'vue';
import { compose, withData } from 'vue-compose';
import gql from 'graphql-tag';
import {
  EventWithRegistrationsDocument,
  EventWithRegistrationsQuery,
  GetEventForEditQuery,
  GetEventTicketsForAddRegistrationQuery,
  RegisterTeenInput,
  useAddRegistrationMutation,
  useGetEventTicketsForAddRegistrationQuery,
  useGetTeenProfileQuery
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { wrapComponent } from 'shared/apollo-hoc';
import { toCompactTeen } from 'shared/components/TeenProfile/utils';
import { TeenViewModel } from 'shared/components/TeenProfile/types';
import { addParentToFamilyEnhancer } from 'shared/components/AddParentToFamily';
import { connectParentToTeenEnhancer } from 'shared/enhancers/connectParentToTeenEnhancer';
import AddRegistrationContainer from './AddRegistrationContainer.vue';

type EventTicket = ArrayElement<GetEventForEditQuery['event']['EventTickets']>;
type EventTickets = GetEventTicketsForAddRegistrationQuery['event']['EventTickets'];

interface Props {
  eventId: number;
  eventStartDate: string;
  regionId: number;
  selectedTeenId: number | null;
  selectedTeenLoading: boolean;
  selectedTeen: TeenViewModel | null;
}

interface AddRegistrationProps {
  registerTeen: (input: RegisterTeenInput) => any;
  registeringTeen: boolean;
}

interface EventTicketProps {
  eventTickets: EventTickets;
}

const getTicketsEnhancer = wrapComponent<Props, EventTicketProps>((props) => {
  const { result } = useGetEventTicketsForAddRegistrationQuery(
    computed(() => ({ eventID: props.eventId }))
  );

  return computed(() => ({
    eventTickets: result.value?.event.EventTickets || []
  }));
});

const getTeenProfileEnhancer = wrapComponent<Props, Pick<Props, 'selectedTeen' | 'selectedTeenLoading'>>((props) => {
  const { loading, result } = useGetTeenProfileQuery(
    computed(() => ({ personID: props.selectedTeenId! })),
    { enabled: computed(() => !!props.selectedTeenId) }
  );

  return computed(() => ({
    selectedTeen: result.value?.singleTeen ? toCompactTeen(result.value.singleTeen) : null,
    selectedTeenLoading: loading.value
  }));
});

const addEventRegistrationEnhancer = wrapComponent<Props, AddRegistrationProps>((props) => {
  const { loading, mutate } = useAddRegistrationMutation();

  return computed(() => ({
    registerTeen: (input) =>
      mutate(
        { input },
        {
          update(client, result) {
            const data = client.readQuery<EventWithRegistrationsQuery>({
              query: EventWithRegistrationsDocument,
              variables: {
                eventID: props.eventId
              }
            });

            if (
              data &&
              result.data &&
              result.data.registerTeen &&
              result.data.registerTeen.registration !== null
            ) {
              const registrations = data.event.Registrations.slice();
              registrations.push(result.data.registerTeen.registration);

              client.writeQuery<EventWithRegistrationsQuery>({
                query: EventWithRegistrationsDocument,
                variables: {
                  eventID: props.eventId
                },
                data: {
                  event: {
                    ...data.event,
                    Registrations: registrations
                  }
                }
              });
            }

            const eventForEditDataFragment = client.readFragment<{ EventTickets: EventTicket[] }>({
              id: `${props.eventId}Event`,
              fragment: gql`
                fragment Event on Event {
                  EventTickets {
                    EventTicketID
                    registrationsCount
                  }
                }
              `
            });

            if (
              eventForEditDataFragment &&
              eventForEditDataFragment.EventTickets.length &&
              result.data &&
              result.data.registerTeen &&
              result.data.registerTeen.registration !== null
            ) {
              const eventTickets = eventForEditDataFragment.EventTickets.slice();
              const ticketIndex = eventTickets.findIndex(
                (e) => e.EventTicketID === input.eventTicketID
              );
              if (ticketIndex > -1) {
                eventTickets[ticketIndex] = {
                  ...eventTickets[ticketIndex],
                  registrationsCount:
                    (eventForEditDataFragment.EventTickets[ticketIndex].registrationsCount || 0) + 1
                };
              }

              client.writeFragment<{ EventTickets: EventTicket[] }>({
                id: `${props.eventId}Event`,
                fragment: gql`
                  fragment Event on Event {
                    EventTickets {
                      EventTicketID
                      registrationsCount
                    }
                  }
                `,
                data: {
                  EventTickets: eventTickets
                }
              });
            }
          }
        }
      ),
    registeringTeen: loading.value
  }));
});

export default compose(
  withData({ selectedTeenId: { initialValue: null } }),
  addParentToFamilyEnhancer,
  connectParentToTeenEnhancer,
  addEventRegistrationEnhancer,
  getTicketsEnhancer,
  getTeenProfileEnhancer
)(AddRegistrationContainer);
