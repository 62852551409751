import AddNewList from './AddNewList.vue';
import { Props } from './types';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetTeenListsDocument,
  GetTeenListsQuery,
  useCreateTeenListMutation
} from 'shared/generated/graphql-types';

const createTeenListEnhancer = wrapComponent<Props, Pick<Props, 'createTeenList'>>((props) => {
  const { mutate } = useCreateTeenListMutation();

  return computed(() => ({
    createTeenList: (args) => {
      const { chapter, staff, ...rest } = args.values!;

      return mutate(
        {
          input: {
            ...rest,
            ...(chapter ? { chapterId: chapter.chapterId } : {}),
            ...(staff ? { staffId: staff.staffID } : {}),
            regionId: props.regionId
          }
        },
        {
          update(proxy, { data: updateData }) {
            const data = proxy.readQuery<GetTeenListsQuery>({
              query: GetTeenListsDocument,
              variables: {
                filter: props.filters,
                offset: (props.currentPage - 1) * props.limit,
                limit: props.limit
              }
            });

            if (data?.teenLists && updateData?.createTeenList) {
              const teenLists = data.teenLists.teenLists.slice();
              teenLists.push(updateData.createTeenList);
              proxy.writeQuery<GetTeenListsQuery>({
                query: GetTeenListsDocument,
                variables: {
                  filter: props.filters,
                  offset: (props.currentPage - 1) * props.limit,
                  limit: props.limit
                },
                data: {
                  teenLists: {
                    ...data.teenLists,
                    teenLists,
                    total: data.teenLists.total + 1
                  }
                }
              });
            }
          }
        }
      );
    }
  }));
});

export default compose(createTeenListEnhancer)(AddNewList);
