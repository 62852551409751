import { computed } from 'vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetEventQuery, useGetEventQuery } from 'shared/generated/graphql-types';

export interface TChildProps {
  eventId: number;
  event?: GetEventQuery['event'] | null;
  loading: boolean;
}

export const getEventEnhancer = wrapComponent<TChildProps, Pick<TChildProps, 'event' | 'loading'>>((props) => {
  const { loading, result } = useGetEventQuery(
    computed(() => ({ eventId: Number(props.eventId) }))
  );

  return computed(() => ({
    event: result.value?.event,
    loading: loading.value
  }));
});
