import { CommunityMember, CompactCommunityMember } from './shared/CompactCommunityMember';
import { getCurrentEmail, getCurrentCellNumber, getCurrentLandlineNumber, getCurrentAddress } from 'shared/util';
import trim from 'lodash/trim';

export function toCompactCommunityMember(communityMember: CommunityMember): CompactCommunityMember | null {
  if (!communityMember) return null
  const currentAddress = getCurrentAddress(communityMember.Addresses || []);

		return {
      address:                 currentAddress,
      addressId:               currentAddress.id || null,
			street:                  currentAddress.street || '',
			street2:                 currentAddress.street2 || '',
			city:                    currentAddress.city || '',
			state:                   currentAddress.state || '',
			zipCode:                 currentAddress.zipCode || '',
      country:                 currentAddress.country || '',
      email:                   getCurrentEmail(communityMember.EmailAddresses || []) ? getCurrentEmail(communityMember.EmailAddresses)!.email : null,
      emailId:                 getCurrentEmail(communityMember.EmailAddresses || []) ? getCurrentEmail(communityMember.EmailAddresses)!.id : null,
      landline:                getCurrentLandlineNumber(communityMember.Phones || []) ? getCurrentLandlineNumber(communityMember.Phones)!.phoneNumber : null,
      landlineId:              getCurrentLandlineNumber(communityMember.Phones || []) ? getCurrentLandlineNumber(communityMember.Phones)!.id : null,
      cellphone:               getCurrentCellNumber(communityMember.Phones || []) ? getCurrentCellNumber(communityMember.Phones)!.phoneNumber : null,
      cellphoneId:             getCurrentCellNumber(communityMember.Phones || []) ? getCurrentCellNumber(communityMember.Phones)!.id : null,
      emails:                  communityMember.EmailAddresses,
			firstName:               communityMember && communityMember.firstName || '',
      lastName:                communityMember && communityMember.lastName || '',
      olamiId:                communityMember && communityMember.olamiId || '',
      fullName:                trim(`${communityMember.firstName || ''} ${communityMember.lastName || ''}`),
      gender:                  communityMember && communityMember.gender,
      personId:                communityMember && communityMember.personID,
      original:                communityMember
		}
  }
