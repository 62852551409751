import { CompactTeen } from './shared/CompactTeen';
import every from 'lodash/every';
import trim from 'lodash/trim';
import {
  dateFormat,
  formatAddressToString,
  getCurrentEmail,
  getCurrentCellNumber,
  getCurrentLandlineNumber,
  getCurrentAddress
} from 'shared/util';
import {
  AddTeenMutation,
  ExtendedPersonFragment,
  Gender,
  TeenLookupQuery
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

export type Teen = ArrayElement<TeenLookupQuery['teens']['teens']>;
type Address = ArrayElement<ExtendedPersonFragment['Addresses']>;

function getGender(gender: number): Gender | null {
  if (gender === 1) return Gender.Female;
  else if (gender === 0) {
    return Gender.Male;
  }
  return null;
}
const currentAddress = (addresses: Address[]) => getCurrentAddress(addresses || []);
const formattedAddress = (currentAddress: any) => formatAddressToString(currentAddress);

export type LookupTeen = Pick<Teen, 'personID' | 'thumbnail' | '__typename'> & {
  Person: Pick<Teen['Person'], 'personID' | 'firstName' | 'lastName'>;
};

export function toCompactTeen(
  teen: Teen | ExtendedPersonFragment | LookupTeen | AddTeenMutation['addTeen']
): CompactTeen | undefined {
  if (!teen) return;
  if (teen.__typename === 'Teen') {
    const teenInput = {
      birthDate: null,
      ...teen,
      Person: {
        gender: null,
        Addresses: [],
        Phones: [],
        EmailAddresses: [],
        ...teen.Person
      }
    } as Teen;

    return {
      personID: teenInput.personID,
      gender: getGender(teenInput.Person.gender)!,
      firstName: teenInput.Person.firstName || '',
      lastName: teenInput.Person.lastName || '',
      fullName: trim(`${teenInput.Person.firstName || ''} ${teenInput.Person.lastName || ''}`),
      birthDate: dateFormat(teenInput.birthDate),
      address: currentAddress(teenInput.Person.Addresses || []),
      landline: getCurrentLandlineNumber(teenInput.Person.Phones || [])
        ? getCurrentLandlineNumber(teenInput.Person.Phones)!.phoneNumber
        : null,
      cellphone: getCurrentCellNumber(teenInput.Person.Phones || [])
        ? getCurrentCellNumber(teenInput.Person.Phones)!.phoneNumber
        : null,
      email: (getCurrentEmail(teenInput.Person.EmailAddresses) || { email: '' }).email,
      thumbnail: teenInput.thumbnail || null,
      formattedAddress: formattedAddress(currentAddress(teenInput.Person.Addresses))
    };
  } else if (teen.__typename === 'Person') {
    return {
      personID: teen.personID,
      gender: getGender(teen.gender)!,
      firstName: teen.firstName || '',
      lastName: teen.lastName || '',
      fullName: trim(`${teen.firstName || ''} ${teen.lastName || ''}`),
      birthDate: null,
      address: currentAddress(teen.Addresses),
      landline: getCurrentLandlineNumber(teen.Phones || [])
        ? getCurrentLandlineNumber(teen.Phones)!.phoneNumber
        : null,
      cellphone: getCurrentCellNumber(teen.Phones || [])
        ? getCurrentCellNumber(teen.Phones)!.phoneNumber
        : null,
      email: (getCurrentEmail(teen.EmailAddresses) || { email: '' }).email,
      thumbnail: null,
      formattedAddress: formattedAddress(currentAddress(teen.Addresses))
    };
  }
}

export function toCompactTeens(
  teens: Teen[] | ExtendedPersonFragment[] | LookupTeen[]
): CompactTeen[] {
  if (teens && teens.length && every(teens)) {
    // @ts-ignore
    return teens.map((teen: Teen | ExtendedPerson) => toCompactTeen(teen));
  }
  return [];
}
