import get from 'lodash/get';
import { VNode, defineComponent, h } from 'vue';

export const RadioGroup = defineComponent({
  name: 'RadioGroup',
  props: {
    value: { type: [String, Number] },
    multiple: { type: Boolean }
  },
  emits: {
    change: (value: string) => true,
    input: (value: string) => true,
    blur: (value: string) => true
  },
  setup(props, { emit, slots }) {
    const injectProps = (vNode: VNode): VNode => {
      const value = get(vNode, 'componentOptions.propsData.value') as unknown as string;

      if (vNode.tag && vNode.tag.match(/RadioGroupItem/) && vNode.componentOptions) {
        vNode.componentOptions = {
          ...vNode.componentOptions,
          propsData: {
            ...vNode.componentOptions!.propsData,
            set: () => {
              if (props.multiple) {
                const mValue = (props.value && String(props.value).split(',')) || [];
                const mValueIdx = mValue.indexOf(value);
                if (mValueIdx > -1) {
                  mValue.splice(mValueIdx, 1);
                } else {
                  mValue.push(value);
                }
                emit('input', mValue.join(','));
                emit('change', mValue.join(','));
                emit('blur', mValue.join(','));
                return;
              }

              emit('input', value);
              emit('change', value);
              emit('blur', value);
            },
            selected: props.multiple
              ? (props.value ? String(props.value) : '').split(',').includes(value)
              : props.value === value
          }
        };
      }

      if (vNode.children) {
        vNode.children = vNode.children.map(injectProps);
      }

      return vNode;
    };

    return () => {
      const vNodes: VNode[] = (slots.default && slots.default()) || [];

      return h('div', vNodes.map(injectProps));
    };
  }
});

export const RadioGroupItem = defineComponent({
  name: 'RadioGroupItem',
  props: {
    set: { type: Function, required: true },
    selected: { type: Boolean, required: true },
    value: { type: [String, Number] },
    label: { type: String },
    name: { type: String }
  },
  setup(props, { slots }) {
    const maybeWrap = (vnodes: string | VNode[]) =>
      !vnodes || typeof vnodes === 'string' || vnodes.length > 1 ? h('div', vnodes) : vnodes[0];

    const scopedSlot = slots.default;

    if (!scopedSlot) {
      return () => {
        const $input = h('input', {
          attrs: {
            type: 'radio',
            name: props.name
          },
          domProps: { checked: props.selected },
          on: { click: props.set }
        });

        return h('label', [$input, props.label]);
      };
    }

    return () => {
      const $vnode = scopedSlot({
        props: {
          selected: props.selected,
          name: props.name,
          set: props.set
        },
        listeners: {
          click: props.set
        },
        label: props.label
      });

      return maybeWrap($vnode);
    };
  }
});
