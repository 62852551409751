
import { computed, defineComponent, h, onMounted, PropType, ref, watch } from 'vue';
import { useApolloClient } from '@vue/apollo-composable';
import tz from 'timezone/loaded';
import EditEvent from './EditEvent.vue';
import { EditEventPayload, Props } from './types';
import { Steps } from '../shared/MultistepForm';
import { FormState } from '../shared/types';
import getStepData from './getStepData';
import {
  CreateZoomMeetingDocument,
  CreateZoomMeetingMutation,
  CreateZoomMeetingMutationVariables,
  EditEventDescriptionDocument,
  EditEventDescriptionMutation,
  EditEventDescriptionMutationVariables,
  EditEventDocument,
  EditEventInput,
  EditEventMutation,
  EditEventMutationVariables,
  GetZoomMeetingDocument,
  GetZoomMeetingMutation,
  GetZoomMeetingMutationVariables,
  UpdateZoomMeetingDocument,
  UpdateZoomMeetingMutation,
  UpdateZoomMeetingMutationVariables
} from 'shared/generated/graphql-types';
import AllowComponent from 'shared/components/AllowComponent';
import NoData from 'shared/components/NoData.vue';
import config from '../../../../../config';
import { calculateEventDuration, isFutureEvent } from '../shared/util';
import { getZoomTimeZone } from 'shared/util';

type ZoomMeeting = CreateZoomMeetingMutation['createZoomMeeting'];

export default defineComponent({
  props: {
    event: { type: Object as PropType<Props['event'] | null>, default: null },
    region: { type: Object as PropType<Props['region'] | null>, default: null },
    returnPath: { type: String, required: true },
    deletingEvent: { type: Boolean, required: true },
    deleteEvent: { type: Function as PropType<Props['deleteEvent']>, required: true },
    deletingZoomMeeting: { type: Boolean, required: true },
    deleteZoomMeeting: { type: Function as PropType<Props['deleteZoomMeeting']>, required: true },
    convertEventToSeries: {
      type: Function as PropType<Props['convertEventToSeries']>,
      required: true
    },
    router: { type: Object as PropType<Props['router']>, required: true },
    eventLoading: { type: Boolean, required: true },
    regionLoading: { type: Boolean, required: true },
    deleteEventTicket: { type: Function as PropType<Props['deleteEventTicket']>, required: true },
    deleteAdditionalEventItem: {
      type: Function as PropType<Props['deleteAdditionalEventItem']>,
      required: true
    }
  },
  setup(props, { expose }) {
    const eventType = computed<string>(() => {
      if (props.eventLoading) {
        return '';
      }
      const { eventSubTypeId, type } = (props.event && props.event.EventSubType) || {
        eventSubTypeId: null,
        type: ''
      };

      if (eventSubTypeId === 290) {
        return 'JSU';
      }
      if (eventSubTypeId === 296) {
        return 'LNL';
      }
      if (type === 'Shabbaton') {
        return 'Shabbaton';
      }
      if (
        (eventSubTypeId &&
          [295, 304, 305, 325, 326, 328, 329, 323, 331, 324, 334, 327, 339].includes(
            eventSubTypeId
          )) ||
        type === 'Shabbat' ||
        type === 'Holiday'
      ) {
        return 'ShabbatHoliday';
      }

      return 'Other';
    });

    const state = ref<FormState>('idle');
    const stepData = ref<Steps | null>(getStepData(eventType.value, props.event!));

    onMounted(() => {
      stepData.value = getStepData(eventType.value, props.event!);
    })

    function onStepData(data: Steps) {
      stepData.value = data;
    }

    async function handleSubmit(payload: EditEventPayload) {
      const { client } = useApolloClient();

      state.value = 'submitting';
      const eventId = props.event!.eventId;

      await Promise.all(
        (payload.ticketIdsToDelete || []).map((i) => props.deleteEventTicket(i, eventId))
      );
      await Promise.all(
        (payload.additionalEventItemIdsToDelete || []).map((i) =>
          props.deleteAdditionalEventItem(i, eventId)
        )
      );

      let meeting: ZoomMeeting | null = null;
      const eventData: EditEventInput = payload.event;

      if (payload.event.image === `${config.graphql}/eventimage/${eventId}`) {
        delete payload.event.image;
        delete eventData.image;
      }

      if (
        stepData.value &&
        stepData.value.details.data &&
        !isFutureEvent(
          stepData.value.details.data.FirstEvent,
          stepData.value.details.data.StartTime,
          stepData.value.details.data.TimeZone
        )
      ) {
        eventData.TeensParticipating = null;
      }

      if (!eventData.isVirtual) {
        eventData.zoomMeetingId = null;
        eventData.zoomUserId = null;
        eventData.zoomPasscode = null;
        eventData.zoomPasscodeHashed = null;
        eventData.streamLink = null;
      }

      if (eventData.zoomUserId && !eventData.zoomMeetingId) {
        let zoomStartDate = eventData.startDate;
        let zoomTimeZone = 'UTC';
        if (getZoomTimeZone(eventData.timeZone) !== 'UTC') {
          zoomTimeZone = getZoomTimeZone(eventData.timeZone);
          zoomStartDate = tz(tz(eventData.startDate as string), '%FT%T', zoomTimeZone);
        }
        const meetingInput: CreateZoomMeetingMutationVariables = {
          id: eventData.zoomUserId as string,
          input: {
            start_time: zoomStartDate,
            duration: calculateEventDuration(
              eventData.startDate as string,
              eventData.endDate as string
            ),
            password: eventData.zoomPasscode,
            timezone: getZoomTimeZone(eventData.timeZone),
            topic: eventData.eventName
          }
        };
        const result = await client.mutate<CreateZoomMeetingMutation>({
          mutation: CreateZoomMeetingDocument,
          variables: meetingInput
        });
        meeting = (result.data && result.data.createZoomMeeting) || null;
      }

      if (eventData.zoomMeetingId) {
        let zoomStartDate = eventData.startDate;
        let zoomTimeZone = 'UTC';
        if (getZoomTimeZone(eventData.timeZone) !== 'UTC') {
          zoomTimeZone = getZoomTimeZone(eventData.timeZone);
          zoomStartDate = tz(tz(eventData.startDate as string), '%FT%T', zoomTimeZone);
        }
        const meetingInput: UpdateZoomMeetingMutationVariables = {
          id: eventData.zoomMeetingId,
          input: {
            start_time: zoomStartDate,
            duration: calculateEventDuration(
              eventData.startDate as string,
              eventData.endDate as string
            ),
            password: eventData.zoomPasscode,
            timezone: zoomTimeZone,
            topic: eventData.eventName
          }
        };

        if (props.event && props.event.seriesId && props.event.zoomOccurencyId) {
          meetingInput.input.occurrence_id = new Date(
            props.event.zoomOccurencyId as string
          ).getTime();
        }

        await client.mutate<UpdateZoomMeetingMutation, UpdateZoomMeetingMutationVariables>({
          mutation: UpdateZoomMeetingDocument,
          variables: meetingInput
        });

        const result = await client.mutate<GetZoomMeetingMutation, GetZoomMeetingMutationVariables>(
          {
            mutation: GetZoomMeetingDocument,
            variables: {
              id: props.event!.zoomMeetingId!
            }
          }
        );

        meeting = (result.data && result.data.getZoomMeeting) || null;
      }

      if (props.event && props.event.zoomMeetingId && !eventData.zoomMeetingId) {
        await props.deleteZoomMeeting(
          props.event.zoomMeetingId,
          props.event.seriesId ? props.event.zoomOccurencyId : null
        );
      }

      if (meeting && meeting.id) {
        payload.event.zoomMeetingId = meeting.id;
        payload.event.zoomPasscode = meeting.password;
        payload.event.zoomPasscodeHashed = meeting.encrypted_password;
      }

      if (payload.series) {
        await props.convertEventToSeries(
          {
            eventID: eventId,
            ...payload.event
          },
          payload.series
        );
      } else {
        await client.mutate<EditEventMutation, EditEventMutationVariables>({
          mutation: EditEventDocument,
          variables: {
            eventId,
            event: {
              ...payload.event,
              isActive: true,
              regionID: props.region!.regionId,
              seriesID: props.event!.seriesId
            }
          }
        });
      }

      state.value = 'submitted';
      if (props.event!.seriesId) {
        props.router.history.push(`/events/series/${props.event!.seriesId}/summary`);
      } else {
        props.router.history.push(`/events/${eventId}/dashboard`);
      }
      setTimeout(() => {
        state.value = 'idle';
      }, 1000);
    }

    async function handleSubmitDescription(description: string) {
      const { client } = useApolloClient();

      state.value = 'submitting';

      const result = await client.mutate<
        EditEventDescriptionMutation,
        EditEventDescriptionMutationVariables
      >({
        mutation: EditEventDescriptionDocument,
        variables: {
          eventId: props.event!.eventId,
          description
        }
      });

      if (result.data && result.data.editEventDescription) {
        stepData.value = getStepData(eventType.value, result.data.editEventDescription);
      }

      state.value = 'submitted';
      setTimeout(() => {
        state.value = 'idle';
      }, 1000);
    }

    watch(eventType, (value) => {
      if (value) {
        stepData.value = getStepData(value, props.event!);
      }
    })

    expose({ handleSubmit });

    return () =>
      h(AllowComponent, {
        props: {
          isAllowed:
            (props.event && props.event.regionId) === (props.region && props.region.regionId),
          loading: props.eventLoading || props.regionLoading || !stepData.value
        },
        scopedSlots: {
          default: () => {
            return [
              h(EditEvent, {
                props: {
                  stepData: stepData.value,
                  eventType: eventType.value,
                  state: state.value,
                  region: props.region,
                  router: props.router,
                  deleteEvent: props.deleteEvent,
                  deletingEvent: props.deletingEvent,
                  deletingZoomMeeting: props.deletingZoomMeeting,
                  deleteZoomMeeting: props.deleteZoomMeeting,
                  returnPath: `${props.returnPath}/${(props.event || {}).eventId}`,
                  loading: props.eventLoading || props.regionLoading || !stepData.value,
                  event: props.event,
                  eventId: props.event && props.event.eventId,
                  chapterId: props.event && props.event.chapter && props.event.chapter.chapterId
                },
                on: {
                  stepData: onStepData,
                  submit: handleSubmit,
                  submitDescription: handleSubmitDescription
                }
              })
            ];
          },
          restricted: () => {
            return [
              h(NoData, {
                props: {
                  condition: true
                },
                scopedSlots: {
                  default: () => {
                    return [
                      h('div', props.event ? 'Event is in a different region.' : 'Event not found.')
                    ];
                  }
                }
              })
            ];
          }
        }
      });
  }
});
