import get from 'lodash/get';
import set from 'lodash/fp/set';
import every from 'lodash/every';
import values from 'lodash/values';
import identity from 'lodash/identity';
import { defineComponent, h, PropType, ref } from 'vue';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';

function eventOrValue(e: Event | string) {
  if (e instanceof Event) {
    if ((e.target as HTMLSelectElement).options) {
      const selectedOption = (e.target as HTMLSelectElement).options[
        (e.target as HTMLSelectElement).selectedIndex
      ];

      // `any` is the only cast that wil work
      return (selectedOption as any)._value !== undefined
        ? (selectedOption as any)._value
        : selectedOption.value;
    }
    return (e.target as HTMLInputElement).value;
  }

  return e;
}

type ObjectOrString = object | string;

export default defineComponent({
  name: 'AutoSaveField',
  props: {
    name: { type: String, required: true },
    entity: { type: Object, required: true },
    validators: {
      type: Array as PropType<
        { name: string; validator: (val: ObjectOrString) => boolean | Promise<boolean> }[]
      >
    },
    toForm: {
      type: Function as PropType<(a: ObjectOrString) => ObjectOrString>,
      default: identity
    },
    toEntity: {
      type: Function as PropType<(a: ObjectOrString) => ObjectOrString>,
      default: identity
    },
    transform: {
      type: Function as PropType<(a: ObjectOrString) => ObjectOrString>,
      default: identity
    },
    extract: {
      type: Function as PropType<(a: object, b?: string) => ObjectOrString>,
      default: get
    },
    insert: {
      type: Function as PropType<(name: string, value: any, entity: object) => object>,
      default: set
    }
  },
  emits: {
    'update:entity': (value: object) => true,
    save: (value: SaveEventArgs) => true
  },
  setup(props, { emit, slots }) {
    const errors = ref<{ [k: string]: boolean }>({});

    async function handleInput(event: Event | string) {
      const toEntity = props.toEntity || identity;
      const insert = props.insert || set;
      const transform = props.transform || identity;
      const value = toEntity(eventOrValue(event));

      emit('update:entity', insert(props.name, value, props.entity));

      errors.value = await validate(value);

      const args: SaveEventArgs = {
        name: props.name,
        value,
        transformed: transform(value),
        update: (update: object) => emit('update:entity', update),
        addErrors: (err: { [k: string]: boolean }) => {
          errors.value = { ...errors.value, ...err };
        }
      };
      if (every(values(errors.value))) {
        emit('save', args);
      }
    }

    async function validate(value: ObjectOrString) {
      return (props.validators || []).reduce(
        async (errors, x) => ({ ...(await errors), [x.name]: await x.validator(value) }),
        Promise.resolve(errors.value)
      );
    }

    function hasError(error: string) {
      return errors.value[error] === false;
    }

    function addErrors(err: { [k: string]: boolean }) {
      errors.value = { ...errors.value, ...err };
    }

    return () => {
      // For some reason, default doesn't seem to be working in storybook ¯\_(ツ)_/¯
      const extract = props.extract || get;
      const toForm = props.toForm || identity;

      const $vnodes =
        slots.default &&
        slots.default({
          hasError,
          handleInput,
          addErrors,
          $props: {
            value: toForm(extract(props.entity, props.name)),
            name: props.name
          },
          $listeners: {
            blur: handleInput
          }
        });

      if ($vnodes) {
        return $vnodes[0] && !$vnodes[1] ? $vnodes[0] : h('div', $vnodes);
      }
      return h('div');
    };
  }
});
