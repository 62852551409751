import DateTimezone from 'date-timezone';
import compact from 'lodash/compact';
import memoize from 'lodash/memoize';
import range from 'lodash/range';
import uniq from 'lodash/uniq';
import has from 'lodash/has';
import zip from 'lodash/zip';
import tz from 'timezone/loaded';
import { Day } from 'shared/components/Calendar';
import { ArrayElement, SimpleDate, SimpleTime } from 'shared/util/types';
import { compareDates, compareDateFromParts, convertTo24HourFormat } from 'shared/util';
import { StepData } from './MultistepForm';
import {
  AdditionalEventItem,
  AdditionalEventItemWithIdInput,
  EventTicket,
  GetEventsQuery,
  RecurrenceType
} from 'shared/generated/graphql-types';

type Event = ArrayElement<GetEventsQuery['events']>;

// 4Mb * (4 / 3) - base64 overhead
export const THUMBNAIL_SIZE_LIMIT = 4;
export const THUMBNAIL_SIZE_LIMIT_BASE64 = Math.ceil(THUMBNAIL_SIZE_LIMIT * 1024 * 1024 * (4 / 3));

export const normalizedDate = (date: string, timezone: string) => {
  if (!date || !timezone) return '';
  return tz(tz(date), '%Y-%m-%d', timezone);
};

export const repeatInterval = (props: StepData): string => {
  if (!props || !has(props, 'steps.details.data')) return '';

  const repeatInterval = props.steps.details.data.RepeatInterval;
  const repeatType = props.steps.details.data.RepeatType;
  const repeat = props.steps.details.data.Repeat;
  const active = props.steps.details.active;

  if (repeat === 'custom_recurrence' && active) {
    const intervals: any = {
      '1': 'weekly',
      '2': 'biweekly',
      '3': 'triweekly'
    };
    if (repeatType === 'day') {
      return `Frequency: every ${repeatInterval} ${repeatInterval == 1 ? 'day' : 'days'}`;
    } else if (repeatType === 'week') {
      if (intervals[repeatInterval]) {
        return `Frequency: ${intervals[repeatInterval]}`;
      } else {
        return `Frequency: every ${repeatInterval} weeks`;
      }
    } else {
      return `Frequency: every ${repeatInterval} ${repeatInterval == 1 ? 'month' : 'monthes'}`;
    }
  } else {
    return '';
  }
};

export const normalizedEventTime = (event: Event) => {
  if (!event) return;
  return `${tz(tz(event.startDate), '%R%p', event.TimeZone)} - ${tz(
    tz(event.endDate),
    '%R%p',
    event.TimeZone
  )}`;
};

export const totalEventTime = (event: Event) => {
  if (!event) return;
  var date1 = new Date(event.startDate);
  var date2 = new Date(event.endDate);
  var timeDiff = Math.abs(date2.getTime() - date1.getTime());
  var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return { timeDiff: timeDiff / 1000 / 60 / 60, diffDays };
};

export const calculateEventDuration = (startDate: string, endDate: string) => {
  return (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60);
};

export function toDate(date: string) {
  const [year, month, day] = date.split('T')[0].split('-');

  return new Date(+year, +month - 1, +day);
}

export function fromDate(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const pad = (n: number) => (n < 10 ? `0${n}` : `${n}`);

  return `${year}-${pad(month)}-${pad(day)}`;
}

const dateDiff = (date1: string, date2: string) =>
  (Number(toDate(date2)) - Number(toDate(date1))) / 1000 / 60 / 60 / 24;

const addDay = (date: Date, inc: number) => {
  const copy = new Date(date);
  copy.setDate(date.getDate() + inc);

  return copy;
};

export const dateRange = (date1: string, date2: string) =>
  range(0, dateDiff(date1, date2) + 1).map((i) => fromDate(addDay(toDate(date1), i)));

export const eventOnDate = (event: Event, date: string) =>
  dateRange(
    normalizedDate(event.startDate, event.TimeZone),
    normalizedDate(event.endDate, event.TimeZone)
  ).some((x) => x === date);

export type EventWithStartAndEnd = Event & { startDoW: number; endDoW: number };
export function splitEventsByWeek(events: Event[], weeks: Day[][]): EventWithStartAndEnd[][] {
  const eventsByWeek = events.map((e) => {
    const startDate = toDate(normalizedDate(e.startDate, e.TimeZone));
    const endDate = toDate(normalizedDate(e.endDate, e.TimeZone));

    const startDoW = startDate.getDay();
    const endDoW = endDate.getDay();
    const currentWeek = weeks.indexOf(
      weeks.find((x) => x.map((x) => x.date).includes(normalizedDate(e.startDate, e.TimeZone)))!
    );

    const length = (Number(endDate) - Number(startDate)) / 1000 / 60 / 60 / 24;

    const weekSpan = Math.ceil((length + startDoW + 1) / 7);

    const eventsByWeek = range(0, weekSpan).map<EventWithStartAndEnd>((week, i, weeks) => ({
      ...e,
      startDoW: week === 0 ? startDoW : 0,
      endDoW: weeks.length === 1 ? endDoW : week === weeks.length - 1 ? endDoW : 6
    }));

    return [...range(0, currentWeek).map((x) => null), ...eventsByWeek];
  });

  return zip(...eventsByWeek)
    .map(compact)
    .slice(0, weeks.length);
}

function intersects(e1: EventWithStartAndEnd, e2: EventWithStartAndEnd) {
  const e1StartDate = toDate(normalizedDate(e1.startDate, e1.TimeZone));
  const e1EndDate = toDate(normalizedDate(e1.endDate, e1.TimeZone));
  const e2StartDate = toDate(normalizedDate(e2.startDate, e2.TimeZone));
  const e2EndDate = toDate(normalizedDate(e2.endDate, e2.TimeZone));

  if (e1StartDate.getDate() === e2StartDate.getDate()) return true;

  if (e1StartDate.getDate() <= e2StartDate.getDate()) {
    return e1EndDate >= e2StartDate;
  } else {
    return e2EndDate >= e1StartDate;
  }
}

function eventLength(event: Event) {
  return dateDiff(
    normalizedDate(event.startDate, event.TimeZone),
    normalizedDate(event.endDate, event.TimeZone)
  );
}

function getDay(event: Event) {
  return toDate(normalizedDate(event.startDate, event.TimeZone)).getDate();
}

export function getLocalStartDate(event: Event) {
  return normalizedDate(event.startDate, event.TimeZone);
}

export function getLocalTimeDate(utc: string, timezone: string) {
  return normalizedDate(utc, timezone);
}

function getEventDays(event: EventWithStartAndEnd) {
  const startDay = getDay(event);
  return range(0, dateDiff(event.startDate, event.endDate) + 1).map((x) => x + startDay);
}

export function placeEvents(
  events: EventWithStartAndEnd[],
  week: Day[]
): [EventWithStartAndEnd[], EventWithStartAndEnd[], EventWithStartAndEnd[], number[]] {
  events
    .sort((a, b) => {
      const aLen = eventLength(a);
      const bLen = eventLength(b);

      if (aLen < bLen) return -1;

      if (aLen > bLen) return 1;

      return 0;
    })
    .reverse();

  const row1: EventWithStartAndEnd[] = [];
  const row2: EventWithStartAndEnd[] = [];
  const row3: EventWithStartAndEnd[] = [];
  const row4: number[] = [];

  const seenDates: { [k: string]: number | number[] } = {};

  const dateHash = memoize((e: Event) => {
    const start = normalizedDate(e.startDate, e.TimeZone);
    const end = normalizedDate(e.endDate, e.TimeZone);

    return `${start}-${end}`;
  });

  const row1dates: { [k: string]: true } = {};
  const row2dates: { [k: string]: true } = {};
  const row3dates: { [k: string]: true } = {};

  for (const nextEvent of events) {
    const seenOnRow = seenDates[dateHash(nextEvent)];

    if (
      row1.length === 0 ||
      (seenOnRow !== 0 && !row1dates[nextEvent.startDoW] && !row1dates[nextEvent.endDoW])
    ) {
      row1.push(nextEvent);
      for (let i = nextEvent.startDoW; i <= nextEvent.endDoW; i++) {
        row1dates[i] = true;
      }
      seenDates[dateHash(nextEvent)] = 0;
    } else if (
      row2.length === 0 ||
      (seenOnRow !== 1 && !row2dates[nextEvent.startDoW] && !row2dates[nextEvent.endDoW])
    ) {
      row2.push(nextEvent);
      for (let i = nextEvent.startDoW; i <= nextEvent.endDoW; i++) {
        row2dates[i] = true;
      }
      seenDates[dateHash(nextEvent)] = 1;
    } else if (
      row3.length === 0 ||
      (seenOnRow !== 2 && !row3dates[nextEvent.startDoW] && !row3dates[nextEvent.endDate])
    ) {
      row3.push(nextEvent);
      for (let i = nextEvent.startDoW; i <= nextEvent.endDoW; i++) {
        row3dates[i] = true;
      }
      seenDates[dateHash(nextEvent)] = 2;
    } else {
      const hash = dateHash(nextEvent);
      const seenOnRow = seenDates[hash];
      let days;

      if (seenOnRow === 0 || seenOnRow === 1 || seenOnRow === 2 || !seenOnRow) {
        days = getEventDays(nextEvent).filter((x) => {
          if (week[0].day < week[6].day) {
            return x >= week[0].day && x <= week[6].day;
          } else {
            return x >= week[0].day || x <= week[6].day;
          }
        });
      } else {
        days = seenOnRow;
      }

      seenDates[hash] = days;
      row4.push(...(days as number[]));
    }
  }

  return [row1, row2, row3, uniq(row4)];
}

interface Rect {
  top: number;
  bottom: number;
  left: number;
  right: number;
  width: number;
  height: number;
}

export function getEventPopoverPosition(eventPos: Rect, popoverPos: Rect, orientation: string) {
  const pos = (() => {
    switch (orientation) {
      case 'under': {
        return {
          left: eventPos.width / 2 - popoverPos.width / 2,
          top: eventPos.height + 15
        };
      }
      case 'left': {
        return {
          left: -popoverPos.width - 15,
          top: -popoverPos.height / 4
        };
      }
      case 'over': {
        return {
          left: eventPos.width / 2 - popoverPos.width / 2,
          top: -popoverPos.height - 15
        };
      }
      case 'left over': {
        return {
          left: -popoverPos.width - 15,
          top: -((popoverPos.height / 4) * 3)
        };
      }
      default: {
        return {
          left: 0,
          top: 0
        };
      }
    }
  })();

  return {
    left: `${pos.left + eventPos.left}px`,
    top: `${pos.top + eventPos.top}px`
  };
}

interface EventTicketWithIdInput {
  EventTicketID?: number | null;
  Description?: string | null;
  deposit?: number | null;
  price: number;
  startDate?: string | null;
  endDate?: string | null;
  quantity?: number | null;
}

export function mapToEventTicketInputs(eventTickets: EventTicket[]): EventTicketWithIdInput[] {
  return (eventTickets || []).map((et) => ({
    ...et,
    quantity: et.quantity ? Number(et.quantity) : null,
    startDate: et.startDate
      ? new Date(
          Date.UTC(et.startDate.year, et.startDate.month - 1, et.startDate.day, 0, 0, 0, 0)
        ).toISOString()
      : null,
    endDate: et.endDate
      ? new Date(
          Date.UTC(et.endDate.year, et.endDate.month - 1, et.endDate.day, 0, 0, 0, 0)
        ).toISOString()
      : null,
    price: et.price && !Number.isNaN(Number(et.price)) ? Number(et.price) : 0,
    deposit: et.deposit && !Number.isNaN(Number(et.deposit)) ? Number(et.deposit) : 0,
    __typename: undefined,
    registrationsCount: undefined
  }));
}

export function mapToAdditionalEventItemInputs(
  additionalEventItems: AdditionalEventItem[]
): AdditionalEventItemWithIdInput[] {
  return (additionalEventItems || []).map((aei) => ({
    ...aei,
    __typename: undefined,
    purchasedCount: undefined
  }));
}

export type Intervals =
  | 'weekly'
  | 'biweekly'
  | 'triweekly'
  | 'monthly'
  | 'custom'
  | 'custom_dates'
  | 'custom_recurrence';

export const regionsWithOutState: number[] = [30, 29, 32];
export const calculateEventsBetween = (
  interval: Intervals,
  firstEvent?: SimpleDate,
  lastEvent?: SimpleDate
): number | null => {
  if (!firstEvent || !lastEvent) return null;

  if (interval === 'custom' || interval === 'custom_dates' || interval === 'custom_recurrence')
    return 1;

  const repeats = {
    weekly: 1,
    biweekly: 2,
    triweekly: 3,
    monthly: 4
  };

  const repeat = repeats[interval] * 7;

  const diffDaysCount = diffDays(firstEvent, lastEvent);

  if (!diffDaysCount) return 1;

  return Math.floor(diffDaysCount / repeat! + 1);
};

export const diffDays = (firstEvent?: SimpleDate, lastEvent?: SimpleDate): number | null => {
  if (!firstEvent || !lastEvent) return null;

  const { day: day1, month: month1, year: year1 } = firstEvent;
  const { day: day2, month: month2, year: year2 } = lastEvent;

  const firstDate = Date.UTC(year1, month1 - 1, day1);
  const secondDate = Date.UTC(year2, month2 - 1, day2);

  const oneDay = 24 * 60 * 60 * 1000;
  return Math.floor((secondDate - firstDate) / oneDay);
};

export const isFutureEvent = (
  startDate?: SimpleDate,
  startTime?: SimpleTime,
  timezone?: string
): boolean => {
  if (!timezone || !startDate || !startTime) {
    return false;
  }

  DateTimezone.setGlobalTimezone(timezone);

  const eventDate = new DateTimezone.DateTimezone(
    startDate.year,
    startDate.month - 1,
    startDate.day,
    convertTo24HourFormat(startTime),
    +startTime.minutes
  );

  return eventDate.getTime() > new Date().getTime();
};

export const generateCustomDates = (step: { [x: string]: any }) => {
  const dates: number[] = [];

  let startStamp = new Date(
    Date.UTC(step.FirstEvent.year, step.FirstEvent.month - 1, step.FirstEvent.day)
  ).getTime();
  const endStamp = step.LastEvent
    ? new Date(
        Date.UTC(step.LastEvent.year, step.LastEvent.month - 1, step.LastEvent.day)
      ).getTime()
    : startStamp;
  let weekCounter = 0;
  let monthCounter = 0;
  const repeatCondition = () => {
    if (step.RepeatEndType === 'date') {
      return startStamp <= endStamp;
    } else if (step.RepeatEndType === 'occurrence') {
      return dates.length < step.RepeatEndTimes;
    }
    return false;
  };
  while (repeatCondition()) {
    if (step.RepeatType === 'day') {
      dates.push(startStamp);
      const nextDate = new Date(startStamp);
      nextDate.setUTCDate(new Date(startStamp).getUTCDate() + Number(step.RepeatInterval));
      startStamp = nextDate.getTime();
    } else if (step.RepeatType === 'week') {
      const checkDate = new Date(startStamp);
      if (
        step.RepeatWeekDays.indexOf(checkDate.getUTCDay() + 1) > -1 &&
        weekCounter % step.RepeatInterval === 0
      ) {
        dates.push(startStamp);
      }
      if (checkDate.getUTCDay() === 6 && dates.length > 0) {
        weekCounter++;
      }
      const nextDate = new Date(startStamp);
      nextDate.setUTCDate(new Date(startStamp).getUTCDate() + 1);
      startStamp = nextDate.getTime();
    } else if (step.RepeatType === 'month') {
      const checkDate = new Date(startStamp);
      const checkDateMonthLength = new Date(
        Date.UTC(checkDate.getUTCFullYear(), checkDate.getUTCMonth() + 1, 0)
      ).getUTCDate();
      if (monthCounter % step.RepeatInterval === 0) {
        if (
          Number(step.RepeatMonthDay) >= checkDateMonthLength &&
          checkDate.getUTCDate() === checkDateMonthLength
        ) {
          dates.push(startStamp);
        } else if (Number(step.RepeatMonthDay) === checkDate.getUTCDate()) {
          dates.push(startStamp);
        }
      }
      if (checkDate.getUTCDate() === checkDateMonthLength && dates.length > 0) {
        monthCounter++;
      }
      const nextDate = new Date(startStamp);
      nextDate.setUTCDate(new Date(startStamp).getUTCDate() + 1);
      startStamp = nextDate.getTime();
    }
  }

  return dates.map((d) => {
    const date = new Date(d);
    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1,
      day: date.getUTCDate()
    };
  });
};

export const repeatTypes: { [x: string]: RecurrenceType } = {
  day: RecurrenceType.Daily,
  week: RecurrenceType.Weekly,
  month: RecurrenceType.Monthly
};

export const startTimeBeforeEndTime = (data: StepData, useLastEvent?: boolean) => {
  if (
    !data ||
    !data.TimeZone ||
    !(data.FirstEvent || data.StartDate) ||
    !data.StartTime ||
    !data.LastEvent ||
    !data.EndTime
  ) {
    return -1;
  }

  DateTimezone.setGlobalTimezone(data.TimeZone);

  const sDate = data.FirstEvent || data.StartDate;

  const eDate = useLastEvent ? data.LastEvent : sDate;

  const startDate = new DateTimezone.DateTimezone(
    sDate.year,
    sDate.month - 1,
    sDate.day,
    convertTo24HourFormat(data.StartTime),
    +data.StartTime.minutes
  );

  const endDate = new DateTimezone.DateTimezone(
    eDate.year,
    eDate.month - 1,
    eDate.day,
    convertTo24HourFormat(data.EndTime),
    +data.EndTime.minutes
  );

  return compareDates(startDate, endDate);
};

export const startTimeBeforeEndTimeParts = (
  data: StepData,
  firstDateSource: any,
  secondDateSource: any,
  firstTimeSource: any,
  secondTimeSource: any
) => {
  if (
    !data ||
    !data.TimeZone ||
    !firstDateSource ||
    !secondDateSource ||
    !firstTimeSource ||
    !secondTimeSource
  ) {
    return -1;
  }

  DateTimezone.setGlobalTimezone(data.TimeZone);

  const startDate = {
    date: {
      year: firstDateSource.year,
      month: firstDateSource.month,
      day: firstDateSource.day
    },
    time: {
      hours: convertTo24HourFormat(firstTimeSource),
      minutes: firstTimeSource.minutes
    }
  };

  const endDate = {
    date: {
      year: secondDateSource.year,
      month: secondDateSource.month,
      day: secondDateSource.day
    },
    time: {
      hours: convertTo24HourFormat(secondTimeSource),
      minutes: secondTimeSource.minutes
    }
  };

  return compareDateFromParts(data.TimeZone, startDate, endDate);
};

export const formatRepeat = (interval: Intervals, startDate: SimpleDate, endDate: SimpleDate) => {
  if (interval === 'custom' || interval === 'custom_dates' || interval === 'custom_recurrence')
    return '';

  const numberOfEvents = calculateEventsBetween(interval, startDate, endDate);

  if (numberOfEvents === null) return '';

  return `Repeats <b>${interval}</b> for a total of <b>${numberOfEvents}</b> event${
    numberOfEvents > 1 ? 's' : ''
  }`;
};

export const formatDate = (FirstEvent: SimpleDate, LastEvent: SimpleDate) => {
  const months = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  if (FirstEvent && LastEvent) {
    return `${months[FirstEvent.month]} ${String(FirstEvent.day).padStart(2, '0')}, ${
      FirstEvent.year
    } -
      ${months[LastEvent.month]} ${String(LastEvent.day).padStart(2, '0')}, ${LastEvent.year}
    `;
  }
  return '';
};

export const formatTime = (StartTime: SimpleTime, EndTime: SimpleTime) => {
  if (StartTime && EndTime) {
    return `${String(StartTime.hours).padStart(2, '0')}:${String(StartTime.minutes).padStart(
      2,
      '0'
    )} ${StartTime.ampm} - ${String(EndTime.hours).padStart(2, '0')}:${String(
      EndTime.minutes
    ).padStart(2, '0')} ${EndTime.ampm} `;
  }
  return '';
};

export const formatDateAndTime = (date: SimpleDate, time: SimpleTime) => {
  const months = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  if (date && time) {
    return `${months[date.month]} ${String(date.day).padStart(2, '0')}, ${date.year} ${String(
      time.hours
    ).padStart(2, '0')}:${String(time.minutes).padStart(2, '0')} ${time.ampm}`;
  }
  return '';
};

export const generateZoomPasscode = () => {
  return Math.random().toString().slice(2, 8);
};
