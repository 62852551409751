import { computed } from 'vue';
import { compose } from 'vue-compose';
import SynagogueLookup from './SynagogueLookup.vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { SynagoguesCompactQuery, useSynagoguesCompactQuery } from 'shared/generated/graphql-types';

type ChildProps = {
  loading: boolean;
  synagogues: SynagoguesCompactQuery['synagogues'];
};

const getSynagogues = wrapComponent<{}, ChildProps>((props) => {
  const { loading, result } = useSynagoguesCompactQuery();

  return computed(() => ({
    loading: loading.value,
    synagogues: result.value?.synagogues || []
  }));
});

export default compose(getSynagogues)(SynagogueLookup);
