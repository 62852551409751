import { computed } from 'vue'
import { Store } from 'vuex'
import { RootState } from '..'
import { RegionState } from './region'

export const useRegionStore = (store: Store<RootState>) => {
  const currentRegion = computed(() => (store.state.region as RegionState).currentRegion)

  const getCurrentRegion = () => {
    return currentRegion.value
  }

  const setCurrentRegion = (regionId: number) => {
    if (currentRegion.value !== regionId) {
      localStorage.setItem('selectedRegion', String(regionId))
    }
    store.dispatch('region/setCurrentRegion', regionId)
  }

  return { setCurrentRegion, getCurrentRegion, currentRegion }
}
