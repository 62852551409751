import { EntityUnionViewModel } from './shared/EntityUnionViewModel';
import {
  dateFormat,
  getCurrentEmail,
  getCurrentAddress,
  getCurrentCellNumber,
  formatAddressToString,
  getCurrentLandlineNumber,
  splitName,
  phoneFormat
} from 'shared/util';
import trim from 'lodash/trim';
import startCase from 'lodash/startCase';
import flatten from 'lodash/flatten';
import { Person, Staff } from './types';
import {
  BaseFamilyFragment,
  EntityUnionEnum,
  FamilyParentFragmentFragment,
  Gender
} from 'shared/generated/graphql-types';

function getFatherOrMother(person: Person): EntityUnionEnum {
  if (person.gender === 1) {
    return EntityUnionEnum.Mother;
  } else {
    return EntityUnionEnum.Father;
  }
}
function getGender(gender: number): Gender | null {
  if (gender === 1) return Gender.Female;
  else if (gender === 0) {
    return Gender.Male;
  }
  return null;
}
function personType(person: Person) {
  if ((startCase(person.type) as EntityUnionEnum) === EntityUnionEnum.Parent) {
    return getFatherOrMother(person);
  } else {
    return startCase(person.type) as EntityUnionEnum;
  }
}

export function toCompactStaff(staff: Staff | undefined | null): EntityUnionViewModel | null {
  if (!staff || !staff.staffID) return null;
  const { street, city, state, zipCode, country } = staff;
  const address = { street, city, state, zipCode, country };
  const formattedAddress = formatAddressToString(address);
  const { firstName, lastName } = splitName(staff.fullName);
  return {
    firstName: startCase(firstName),
    lastName: startCase(lastName),
    type: EntityUnionEnum.Staff,
    id: staff.staffID,
    gender: getGender(staff.gender)!,
    isParent: false,
    isStaff: true,
    isTeen: false,
    fullName: staff.fullName,
    birthDate: dateFormat(staff.birthDate),
    emailId: null,
    email: staff.email!,
    emails: [],
    landline: null,
    cellphone: null,
    primaryPhone: phoneFormat(staff.primaryPhone),
    secondaryPhone: phoneFormat(staff.secondaryPhone),
    thumbnail: null,
    address: formattedAddress ? address : null,
    formattedAddress,
    advisorRegions: staff.AdvisorRegions.length
      ? staff.AdvisorRegions.filter((ar) => ar.active)
      : [],
    mostRecentRegion: staff.AdvisorRegions.length ? staff.AdvisorRegions[0].regionId : null,
    region: null,
    regionId: null,
    parents: [],
    children: [],
    primaryFamilyId: null,
    original: staff,
    primaryFamily: null
  };
}

export function toCompactPerson(person: Person | undefined | null): EntityUnionViewModel | null {
  if (!person || !person.personID) return null;

  const currentAddress = getCurrentAddress(person.Addresses || []);
  const formattedAddress = formatAddressToString(currentAddress);
  const s = person.ChildOf || ([] as BaseFamilyFragment[]);

  return {
    type: personType(person)!,
    id: person.personID,
    gender: getGender(person.gender)!,
    isParent:
      personType(person) === EntityUnionEnum.Father ||
      personType(person) === EntityUnionEnum.Mother,
    isStaff: false,
    isTeen: personType(person) === EntityUnionEnum.Teen,
    firstName: startCase(person.firstName || ''),
    lastName: startCase(person.lastName || ''),
    fullName: trim(`${startCase(person.firstName || '')} ${startCase(person.lastName || '')}`),
    birthDate: dateFormat(person.Teen && person.Teen.birthDate),
    address: currentAddress,
    landline: phoneFormat(
      getCurrentLandlineNumber(person.Phones || [])
        ? getCurrentLandlineNumber(person.Phones)!.phoneNumber
        : null
    ),
    cellphone: phoneFormat(
      getCurrentCellNumber(person.Phones || [])
        ? getCurrentCellNumber(person.Phones)!.phoneNumber
        : null
    ),
    email: (getCurrentEmail(person.EmailAddresses) || { email: '' }).email,
    emailId: (getCurrentEmail(person.EmailAddresses) || { id: null }).id || null,
    emails: person.EmailAddresses || [],
    primaryPhone: null,
    secondaryPhone: null,
    thumbnail: (person.Teen && person.Teen.thumbnail) || null,
    formattedAddress,
    advisorRegions: [],
    mostRecentRegion: null,
    region: (person.Teen && person.Teen.Region) || null,
    regionId: (person.Teen && person.Teen.regionId) || null,
    parents: flatten((person.ChildOf || []).map((c) => [c.Father, c.Mother]))
      .filter((c): c is FamilyParentFragmentFragment => c !== null)
      .map((c) => ({ personID: c.personID })),
    children: person.Children,
    original: person,
    primaryFamilyId: (person.PrimaryFamily && person.PrimaryFamily.id) || null,
    primaryFamily: person.PrimaryFamily
  };
}
