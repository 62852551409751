import { computed } from 'vue';
import { compose } from 'vue-compose';
import PushNotifications from './PushNotifications.vue';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  PushNotificationInput,
  useSendPushNotificationMutation
} from 'shared/generated/graphql-types';

interface Props {
  sendPushNotifications: (input: PushNotificationInput) => void;
}

const sendPushNotificationEnhancer = wrapComponent<{}, Pick<Props, 'sendPushNotifications'>>(
  () => {
    const { mutate } = useSendPushNotificationMutation();

    return computed(() => ({
      sendPushNotifications: (input) => mutate({ input })
    }));
  }
);

const enhancer = compose(sendPushNotificationEnhancer);

export default enhancer(PushNotifications);
