import CompactTeen from './shared/CompactTeen';
import { getFullName } from 'shared/util';
import { ActiveFiltersType } from './shared/ActiveFilters';
import { TeensImpactQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Teen = ArrayElement<TeensImpactQuery['teens']['teens']>
type Advisor = ArrayElement<ArrayElement<TeensImpactQuery['teens']['teens']>['Advisors']>

function getAdvisor (advisors: Advisor[], type: string) {
  if (!advisors.length) return null;

  if (type === 'primary') {
    const primaryFiltered = advisors.filter(a => a.isPrimary);
    if (!primaryFiltered.length) return null;
    const { Advisor } = primaryFiltered[0];
    return Advisor;
  }
  else if (type === 'secondary') {
    const secondaryFiltered = advisors.filter(a => !a.isPrimary);
    if (!secondaryFiltered.length) return null;
    const { Advisor } = secondaryFiltered[secondaryFiltered.length - 1];
    return Advisor;
  }
  return null;
}
export function toCompactTeens(teens: Teen[], activeFilters: ActiveFiltersType): CompactTeen[] {
    if (!teens.length) return []
    return teens.map(teen => ({
        name: getFullName(teen.Person.firstName, teen.Person.lastName),
        personId: teen.personID,
        gender: teen.Person.gender,
        thumbnail: teen.thumbnail || null,
        chapterName: teen.Chapter ? teen.Chapter.chapterName : null,
        graduationYear: teen.graduationYear || null,
        primaryAdvisor:          getAdvisor(teen.Advisors, 'primary'),
        secondaryAdvisor:        getAdvisor(teen.Advisors, 'secondary'),
        relationshipImpact:       teen.RelationshipImpacts.find(r => r.season === activeFilters.season) || null,
        relationshipImpacted:     !!teen.RelationshipImpacts.find(r => r.season === activeFilters.season),
        relationshipImpactNote:   (teen.RelationshipImpacts.find(r => r.season === activeFilters.season) || { Notes: [] }).Notes[0],
        eventImpact:       teen.EventImpacts.find(r => r.season === activeFilters.season) || null,
        eventImpacted:     !!teen.EventImpacts.find(r => r.season === activeFilters.season),
        reached:           !!teen.Events.find(r => r.season === activeFilters.season),
        totalEvents:  +(teen.statistics[1] || {}).value! || 0,
        original: teen
    }));
}
