import { splitName } from 'shared/util';
import {
  EventGuestBaseFragment as EventGuest,
  EventStaffBaseFragment as EventStaff,
  RegistrationBaseFragment as Registration
} from 'shared/generated/graphql-types';

export type sourceString = 'registration' | 'eventguests' | 'eventstaff';
type ParticipantType<T = {}> = (Registration | EventGuest | EventStaff) & T;
export default class Participant<T = {}> {
  name: string = this.getDisplayName();
  firstName: string = splitName(this.getDisplayName()).firstName;
  lastName: string = splitName(this.getDisplayName()).lastName;
  id!: string;
  entityId!: number;
  displaySource: string = this.getSourceDisplay();
  staffId!: number | null;
  teenId!: number | null;

  constructor(public model: ParticipantType<T>, public source: sourceString) {
    this.init();
  }

  private init() {
    switch (this.source) {
      case 'registration':
        const registration = this.model as Registration;
        this.entityId = registration.registrationID;
        this.teenId = (registration.Teen && registration.Teen.personID) || null;
        break;
      case 'eventguests':
        const guest = this.model as EventGuest;
        this.entityId = guest.eventGuestId;
        break;
      case 'eventstaff':
        const staff = this.model as EventStaff;
        this.entityId = staff.eventStaffId;
        this.staffId = (staff.Staff && staff.Staff.staffID) || null;
        break;
    }
    this.id = `${this.source}~${this.entityId}`;
  }
  private getDisplayName() {
    switch (this.source) {
      case 'registration':
        const person = (this.model as Registration).Teen.Person;
        return `${person.firstName} ${person.lastName}`;
      case 'eventguests':
        const guest = this.model as EventGuest;
        return `${guest.firstName} ${guest.lastName}`;
      case 'eventstaff':
        const staff = this.model as EventStaff;
        return `${staff.Staff.firstName} ${staff.Staff.lastName}`;
    }
    return '';
  }

  private getSourceDisplay() {
    switch (this.source) {
      case 'registration':
        return 'Registration';
      case 'eventguests':
        return 'Event Guest';
      case 'eventstaff':
        return 'Event Staff';
    }
  }
}
