import { phoneInternational, asUTCWithZerotime, splitName } from "shared/util";
import { StaffCopy } from './types';

export default class StaffInput {
  staffId: number;
  email?: string | null;
  firstName: string;
  lastName: string;
  birthDate?: any | null;
  gender: number;
  primaryPhone?: string | null;
  secondaryPhone?: string | null;
  street?: string | null = null;
  city?: string | null = null;
  state?: string | null = null;
  zipCode?: string | null = null;
  country?: string | null = null;
  regionId?: number | null;
  staffType?: string | null;
  advisorRegionId?: number | null;
  chapterId: number | null = null;
  chapterName: string | null = null;
  regionName: string;
  bio: string;
  roleId: number | null = null;
  active: boolean;

  constructor(args: StaffCopy, userRegionId: number) {
    const {
      firstName,
      lastName
    } = splitName(args.fullName);
    this.staffId = args.staffId;
    this.email = args.email;
    this.bio = args.bio;
    this.firstName = firstName;
    this.lastName = lastName;
    this.birthDate = asUTCWithZerotime(args.birthDate);
    this.gender = args.gender;
    this.primaryPhone = args.primaryPhone ? phoneInternational(args.primaryPhone) : null;
    this.secondaryPhone = args.secondaryPhone ? phoneInternational(args.secondaryPhone) : null;
    this.street = args.addressObjectFormat && args.addressObjectFormat.street || this.street;
    this.city = args.addressObjectFormat && args.addressObjectFormat.city || this.city;
    this.state = args.addressObjectFormat && args.addressObjectFormat.state || this.state;
    this.zipCode = args.addressObjectFormat && args.addressObjectFormat.zipCode || this.zipCode;
    this.country = args.addressObjectFormat && args.addressObjectFormat.country || this.country;
    this.regionId = userRegionId || null;
    this.staffType = args.staffType;
    this.advisorRegionId = args.advisorRegionId;
    this.chapterId = args.chapterId || this.chapterId;
    this.chapterName = args.chapterName || this.chapterName;
    this.regionName = args.regionName;
    this.roleId = args.roleId || this.roleId;
    this.active = args.active;
  }
}
