import PotentialHousee from './shared/PotentialHousee';
import { sourceString } from '../shared/Participant';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetHousesForEventDocument,
  GetHousesForEventQuery,
  SetHouseMutation,
  useSetHouseMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { ArrayElement } from 'shared/util/types';

type House = ArrayElement<GetHousesForEventQuery['houses']>;
type Registration = Extract<SetHouseMutation['setHouse'], { registrationID: number }>;
type EventGuest = Extract<SetHouseMutation['setHouse'], { eventGuestId: number }>;
type EventStaff = Extract<SetHouseMutation['setHouse'], { eventStaffId: number }>;

interface Props {
  eventId: number;
}

interface SetHouseProps {
  setHouse: (id: number, house: House, type: string, p: PotentialHousee) => void;
}

function getTypeNameFromType(type: sourceString) {
  switch (type) {
    case 'registration':
      return 'Registration';
    case 'eventguests':
      return 'EventGuest';
    case 'eventstaff':
      return 'EventStaff';
  }
}

export const setHouseEnhancer = wrapComponent<Props, SetHouseProps>((props) => {
  const { mutate } = useSetHouseMutation();

  return computed(() => ({
    setHouse: (id, house, type, p) =>
      mutate(
        { id, houseId: house.houseId, type },
        {
          optimisticResponse: {
            setHouse: {
              ...p.model,
              __typename: getTypeNameFromType(type as sourceString),
              House: house
            } as SetHouseMutation['setHouse']
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetHousesForEventQuery>({
              query: GetHousesForEventDocument,
              variables: { eventId: props.eventId }
            });

            if (data && updateData?.setHouse) {
              const houses = data.houses.slice();
              const houseToInsertIn = houses.findIndex((h) => h.houseId === house.houseId);

              let houseToMoveFrom = houses.findIndex((h) =>
                h.Registrations.map((x) => x.registrationID).includes(
                  (updateData.setHouse as Registration).registrationID
                )
              );

              if (houseToMoveFrom === -1) {
                houseToMoveFrom = houses.findIndex((h) =>
                  h.EventGuests.map((x) => x.eventGuestId).includes(
                    (updateData.setHouse as EventGuest).eventGuestId
                  )
                );
              }

              if (houseToMoveFrom === -1) {
                houseToMoveFrom = houses.findIndex((h) =>
                  h.EventStaff.map((x) => x.eventStaffId).includes(
                    (updateData.setHouse as EventStaff).eventStaffId
                  )
                );
              }

              switch (updateData.setHouse.__typename) {
                case 'Registration':
                  const registrationsToInsert = houses[houseToInsertIn].Registrations.slice();
                  registrationsToInsert.push(updateData.setHouse);
                  if (houseToMoveFrom !== -1) {
                    const registrationsToMove = houses[houseToMoveFrom].Registrations.slice();
                    const i = registrationsToMove.findIndex(
                      (x) =>
                        x.registrationID === (updateData.setHouse as Registration).registrationID
                    );
                    registrationsToMove.splice(i, 1);
                    houses[houseToMoveFrom] = {
                      ...houses[houseToMoveFrom],
                      Registrations: registrationsToMove
                    };
                  }
                  houses[houseToInsertIn] = {
                    ...houses[houseToInsertIn],
                    Registrations: registrationsToInsert
                  };
                  break;
                case 'EventGuest':
                  const eventGuestsToInsert = houses[houseToInsertIn].EventGuests.slice();
                  eventGuestsToInsert.push(updateData.setHouse);
                  if (houseToMoveFrom !== -1) {
                    const eventGuestsToMove = houses[houseToMoveFrom].EventGuests.slice();
                    const i = eventGuestsToMove.findIndex(
                      (x) => x.eventGuestId === (updateData.setHouse as EventGuest).eventGuestId
                    );
                    eventGuestsToMove.splice(i, 1);
                    houses[houseToMoveFrom] = {
                      ...houses[houseToMoveFrom],
                      EventGuests: eventGuestsToMove
                    };
                  }
                  houses[houseToInsertIn] = {
                    ...houses[houseToInsertIn],
                    EventGuests: eventGuestsToInsert
                  };
                  break;
                case 'EventStaff':
                  const eventStaffToInsert = houses[houseToInsertIn].EventStaff.slice();
                  eventStaffToInsert.push(updateData.setHouse);
                  if (houseToMoveFrom !== -1) {
                    const eventStaffToMove = houses[houseToMoveFrom].EventStaff.slice();
                    const i = eventStaffToMove.findIndex(
                      (x) => x.eventStaffId === (updateData.setHouse as EventStaff).eventStaffId
                    );
                    eventStaffToMove.splice(i, 1);
                    houses[houseToMoveFrom] = {
                      ...houses[houseToMoveFrom],
                      EventStaff: eventStaffToMove
                    };
                  }
                  houses[houseToInsertIn] = {
                    ...houses[houseToInsertIn],
                    EventStaff: eventStaffToInsert
                  };
                  break;
              }

              houses[houseToInsertIn] = {
                ...houses[houseToInsertIn],
                registrationCount:
                  houses[houseToInsertIn].EventGuests.length +
                  houses[houseToInsertIn].EventStaff.length +
                  houses[houseToInsertIn].Registrations.length
              };

              if (houseToMoveFrom > -1) {
                houses[houseToMoveFrom] = {
                  ...houses[houseToMoveFrom],
                  registrationCount:
                    houses[houseToMoveFrom].EventGuests.length +
                    houses[houseToMoveFrom].EventStaff.length +
                    houses[houseToMoveFrom].Registrations.length
                };
              }

              proxy.writeQuery<GetHousesForEventQuery>({
                query: GetHousesForEventDocument,
                variables: { eventId: props.eventId },
                data: {
                  houses
                }
              });
            }
          }
        }
      )
  }));
});
