var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"edit-event"},[(_vm.loading)?_c(_setup.Loading):[_c('main',[_c('nav',[_c('div',[_c(_setup.RouterLink,{staticClass:"event-back",attrs:{"to":`${_vm.returnPath}`}},[_c('font-awesome-icon',{attrs:{"icon":"caret-left"}})],1),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.stepData.details.data.name))]),_c('a',{class:{ selected: _setup.main === 'Details' },on:{"click":function($event){_setup.main = 'Details'}}},[_vm._v("Details")]),_c('a',{class:{ selected: _setup.main === 'Description' },on:{"click":function($event){_setup.main = 'Description'}}},[_vm._v("Description")]),(
              _vm.stepData.registrationAndTickets &&
              _vm.stepData.registrationAndTickets.data &&
              (_vm.stepData.registrationAndTickets.data.registrationRequired ||
                _vm.stepData.registrationAndTickets.data
                  .registrationAbsoluteRequired)
            )?_c('a',{class:{ selected: _setup.main === 'email' },on:{"click":function($event){_setup.main = 'email'}}},[_vm._v("Templates")]):_vm._e()],1),_c(_setup.UIButton,{staticClass:"cancel-btn",on:{"click":function($event){_setup.isCanRemove
              ? (_setup.showCancelEventModal = true)
              : (_setup.showWarningModal = true)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"trash"}}),_vm._v("Cancel this Event ")],1)],1),_c(_setup.ScrollablePanel,{directives:[{name:"fullheight",rawName:"v-fullheight"}]},[_c('div',{staticClass:"type-form"},[_c(_setup.eventComponent,{key:_vm.eventType,tag:"component",attrs:{"stepData":_vm.stepData,"state":_vm.state,"region":_vm.region,"main":_setup.main,"eventId":_vm.eventId,"seriesId":_vm.event.seriesId,"chapterId":_vm.chapterId,"openDescriptionTab":_setup.openDescriptionTab},on:{"stepData":function($event){return _setup.emit('stepData', $event)},"submit":function($event){return _setup.emit('submit', $event)},"submitDescription":function($event){return _setup.emit('submitDescription', $event)}}}),_c('br')],1)])],1),_c(_setup.Modal,{attrs:{"show":_setup.showCancelEventModal,"requestClose":() => (_setup.showCancelEventModal = false)},scopedSlots:_vm._u([{key:"default",fn:function({ requestClose }){return [_c('div',{staticClass:"action-modal"},[_c('p',[_vm._v("Are you sure you want to delete this event?")]),_c('div',{staticClass:"actions"},[_c(_setup.UIButton,{attrs:{"loading":_vm.deletingEvent || _vm.deletingZoomMeeting,"tag":"span"},on:{"click":_setup.deleteEventHandler}},[_vm._v("Yes")]),_c(_setup.UIButton,{staticClass:"inverted",attrs:{"tag":"span"},on:{"click":function($event){return requestClose()}}},[_vm._v("No")])],1)])]}}])}),_c(_setup.Modal,{attrs:{"show":_setup.showWarningModal,"requestClose":() => (_setup.showWarningModal = false)},scopedSlots:_vm._u([{key:"default",fn:function({ requestClose }){return [_c('div',{staticClass:"action-modal"},[_c('p',[_vm._v(" This event has "+_vm._s(_vm.event.registrationRequired ? `${_setup.teensRegistered} registrations` : `${_setup.teensAttended} attendances`)+" recorded. Please remove them before deleting this event. ")]),_c('div',{staticClass:"actions"},[_c(_setup.RouterLink,{attrs:{"to":`/events/${_vm.eventId}/${
                _vm.event.registrationRequired ? 'registrations' : 'Attendances'
              }`}},[_c(_setup.UIButton,{attrs:{"tag":"span"}},[_vm._v("View "+_vm._s(_vm.event.registrationRequired ? "registrations" : "attendances"))])],1),_c(_setup.UIButton,{staticClass:"inverted",attrs:{"tag":"span"},on:{"click":function($event){return requestClose()}}},[_vm._v("Cancel")])],1)])]}}])})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }