var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Collapsible,{staticClass:"step",attrs:{"startOpen":true},scopedSlots:_vm._u([{key:"default",fn:function({ open }){return [_c('header',[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('main',[_vm._t("default",null,null,{
          getData: _setup.getData,
          setData: _setup.setData,
          step: _setup.instance.proxy.$parent.steps[_vm.name],
          steps: _setup.instance.proxy.$parent.steps,
          displayErrors: _setup.displayErrors,
        })],2),(_setup.instance.proxy.$parent.finalStep(_vm.name))?_c('footer',[_c(_setup.UIButton,{attrs:{"data-test":"editEventButton","type":"button","disabled":_vm.state === 'submitting'},on:{"click":_setup.handleClick}},[_vm._v("Save Event")])],1):_vm._e(),_vm._t("confirmation",null,null,{ isConfirm: _setup.isConfirm, saveHandler: _setup.saveHandler, cancelHandler: _setup.cancelHandler, isConfirmCondition: _setup.isConfirmCondition })]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }