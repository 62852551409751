import EmergencyContact from './EmergencyContact.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { Props } from '../../types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  AddEmergencyContactMutationVariables,
  DeleteEmergencyContactMutationVariables,
  EditEmergencyContactMutationVariables,
  GetEmergencyContactsDocument,
  GetEmergencyContactsQuery,
  useAddEmergencyContactMutation,
  useDeleteEmergencyContactMutation,
  useEditEmergencyContactMutation
} from 'shared/generated/graphql-types';

interface AddEmergencyContactProps {
  addEmergencyContact: (args: AddEmergencyContactMutationVariables) => void;
}
const addEmergencyContactEnhancer = wrapComponent<Props, AddEmergencyContactProps>((props) => {
  const { mutate } = useAddEmergencyContactMutation();

  return computed(() => ({
    addEmergencyContact: ({ data }) =>
      mutate(
        { data },
        {
          optimisticResponse: {
            createEmergencyContact: {
              personId: data.teenId,
              name: data.name,
              phoneNumber: data.phoneNumber,
              relationship: data.relationship,
              __typename: 'EmergencyContact',
              emergencyContactId: Math.round(Math.random() * -1000000)
            }
          },
          update(client, { data: updateData }) {
            try {
              const data = client.readQuery<GetEmergencyContactsQuery>({
                query: GetEmergencyContactsDocument,
                variables: {
                  personId: props.personId
                }
              });
              if (data?.emergencyContacts && updateData?.createEmergencyContact) {
                const emergencyContacts = data.emergencyContacts.slice();
                emergencyContacts.push(updateData.createEmergencyContact);
                client.writeQuery<GetEmergencyContactsQuery>({
                  query: GetEmergencyContactsDocument,
                  variables: {
                    personId: props.personId
                  },
                  data: {
                    emergencyContacts
                  }
                });
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
      )
  }));
});

interface EditEmergencyContactProps {
  editEmergencyContact: (args: EditEmergencyContactMutationVariables) => void;
}
const editEmergencyContactEnhancer = wrapComponent<Props, EditEmergencyContactProps>((props) => {
  const { mutate } = useEditEmergencyContactMutation();

  return computed(() => ({
    editEmergencyContact: ({ data, emergencyContactId }) =>
      mutate(
        { data, emergencyContactId },
        {
          optimisticResponse: {
            updateEmergencyContact: {
              personId: data.teenId,
              name: data.name,
              phoneNumber: data.phoneNumber,
              relationship: data.relationship,
              emergencyContactId,
              __typename: 'EmergencyContact'
            }
          }
        }
      )
  }));
});

interface DeleteEmergencyContactProps {
  deleteEmergencyContact: (args: DeleteEmergencyContactMutationVariables) => void;
}
const deleteEmergencyContactEnhancer = wrapComponent<Props, DeleteEmergencyContactProps>(
  (props) => {
    const { mutate } = useDeleteEmergencyContactMutation();

    return computed(() => ({
      deleteEmergencyContact: ({ emergencyContactId }) =>
        mutate(
          { emergencyContactId },
          {
            update(client) {
              try {
                const data = client.readQuery<GetEmergencyContactsQuery>({
                  query: GetEmergencyContactsDocument,
                  variables: {
                    personId: props.personId
                  }
                });

                if (data) {
                  const emergencyContacts = data.emergencyContacts.slice();
                  const index = data.emergencyContacts.findIndex(
                    (e) => e.emergencyContactId === emergencyContactId
                  );

                  if (index > -1) {
                    emergencyContacts.splice(index, 1);
                  }

                  client.writeQuery<GetEmergencyContactsQuery>({
                    query: GetEmergencyContactsDocument,
                    variables: {
                      personId: props.personId
                    },
                    data: {
                      ...data,
                      emergencyContacts
                    }
                  });
                }
              } catch (e) {
                console.log(e);
              }
            }
          }
        )
    }));
  }
);

export default compose(
  addEmergencyContactEnhancer,
  editEmergencyContactEnhancer,
  deleteEmergencyContactEnhancer
)(EmergencyContact);
