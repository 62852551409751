import { wrapComponent } from 'shared/apollo-hoc';
import {
  MergeTeenDuplicatesMutationVariables,
  useMergeTeenDuplicatesMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface Props {
  regionId: number;
}

interface MergeTeenDuplicatesProps {
  mergeTeenDuplicates: (args: MergeTeenDuplicatesMutationVariables) => void;
  merging: boolean;
}

export const mergeTeenDuplicatesEnhancer = wrapComponent<Props, MergeTeenDuplicatesProps>(
  (props) => {
    const { loading, mutate } = useMergeTeenDuplicatesMutation();

    return computed(() => ({
      mergeTeenDuplicates: ({ input }) =>
        mutate(
          { input },
          {
            refetchQueries: ['getTeenDuplicates', 'getTeenDuplicatesTotal']
          }
        ),
      merging: loading.value
    }));
  }
);
