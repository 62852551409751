import NoteView from './Note.vue';
import { computed } from 'vue';
import { compose, withData } from 'vue-compose';
import UpdatePersonNoteArgs from './UpdatePersonNoteArgs';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetNotesByStaffDocument,
  GetNotesByStaffQuery,
  useDeletePersonNoteMutation,
  useUpdatePersonNoteMutation
} from 'shared/generated/graphql-types';

interface Props {
  staffId: number;
}

interface DeletePersonNoteProps {
  deletePersonNote: (noteId: number, regionId: number) => void;
}
const deletePersonNoteEnhancer = wrapComponent<Props, DeletePersonNoteProps>((props) => {
  const { mutate } = useDeletePersonNoteMutation();

  return computed(() => ({
    deletePersonNote: (noteId, regionId) =>
      mutate(
        { noteId },
        {
          optimisticResponse: {
            deletePersonNote: true
          },
          update: (proxy, response) => {
            const data = proxy.readQuery<GetNotesByStaffQuery>({
              query: GetNotesByStaffDocument,
              variables: {
                advisorId: props.staffId,
                regionId
              }
            });

            if (data?.notesByStaff) {
              const notesByStaff = data.notesByStaff.slice();
              const index = notesByStaff.findIndex((i) => i!.id === noteId);

              if (index > -1) {
                notesByStaff.splice(index, 1);
              }

              proxy.writeQuery<GetNotesByStaffQuery>({
                query: GetNotesByStaffDocument,
                variables: {
                  advisorId: props.staffId,
                  regionId
                },
                data: {
                  notesByStaff
                }
              });
            }
          }
        }
      )
  }));
});

interface UpdatePersonNoteProps {
  updatePersonNote: (vars: UpdatePersonNoteArgs) => void;
}

const updatePersonNoteEnhancer = wrapComponent<Props, UpdatePersonNoteProps>((props) => {
  const { mutate } = useUpdatePersonNoteMutation();

  return computed(() => ({
    updatePersonNote: ({
      category,
      chapterId,
      contactDate,
      firstName,
      lastName,
      note,
      noteId,
      regionId,
      staffId,
      teenId
    }) =>
      mutate(
        {
          category,
          contactDate,
          note,
          noteId,
          staffId,
          teenId
        },
        {
          optimisticResponse: {
            updatePersonNote: {
              note,
              category,
              date: contactDate,
              id: noteId,
              Person: {
                personID: teenId,
                firstName,
                lastName,
                __typename: 'Person',
                Teen: {
                  chapterId,
                  personID: teenId,
                  __typename: 'Teen'
                }
              },
              __typename: 'Note'
            }
          },

          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetNotesByStaffQuery>({
              query: GetNotesByStaffDocument,
              variables: { advisorId: staffId, regionId }
            });

            if (data?.notesByStaff && updateData?.updatePersonNote) {
              const notesByStaff = data.notesByStaff.slice();
              const index = notesByStaff.findIndex((i) => i.id === noteId);

              notesByStaff[index] = updateData.updatePersonNote;

              proxy.writeQuery<GetNotesByStaffQuery>({
                query: GetNotesByStaffDocument,
                variables: { advisorId: staffId, regionId },
                data: {
                  notesByStaff
                }
              });
            }
          }
        }
      )
  }));
});

export default compose(
  deletePersonNoteEnhancer,
  updatePersonNoteEnhancer,
  withData({
    editingNote: {
      initialValue: false
    }
  })
)(NoteView);
