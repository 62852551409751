import { User } from 'oidc-client';
import { key } from 'store/index';
import { computed, defineComponent, h, inject, onBeforeMount, PropType, ref, VNode } from 'vue';

export default defineComponent({
  props: {
    url: { type: String, required: true },
    changeLocation: { type: Function as PropType<(url: string) => void>, required: true }
  },
  setup(props, { slots }) {
    const store = inject(key);

    const redirecting = ref(false);
    const error = ref(false);

    const isAuthenticated = computed<boolean>(() => store?.getters['oidc/oidcIsAuthenticated']);
    const user = computed<User | null>(() => store?.getters['oidc/oidcUser']);

    const authenticate = async (url: string): Promise<void> =>
      store?.dispatch('oidc/authenticateOidc', url);

    const signInCallback = (): Promise<string | null | undefined> =>
      store?.dispatch('oidc/oidcSignInCallback') as Promise<string | null | undefined>;

    onBeforeMount(async () => {
      if (props.url === '/callback') {
        redirecting.value = true;
        try {
          const result = await signInCallback();
          props.changeLocation(result ?? '/');
        } catch (e) {
          error.value = true;
        }
        redirecting.value = false;
      }

      if (!isAuthenticated.value || !user.value) {
        await authenticate(props.url);
      }
    });

    return () => {
      const maybeWrap = (vnodes: string | VNode[]): VNode =>
        !vnodes || typeof vnodes === 'string' || vnodes.length > 1 ? h('div', vnodes) : vnodes[0];

      if (!user.value) {
        return maybeWrap(
          slots.default ? slots.default({ authenticating: true }) : 'Authenticating'
        );
      }

      if (props.url === '/callback') {
        return maybeWrap(slots.default ? slots.default({ redirecting: true }) : 'Redirecting');
      }

      return maybeWrap(slots.default ? slots.default({}) : 'Error');
    };
  }
});
