import PostHS from './PostHS.vue';
import { compose } from 'vue-compose';
import TeenUpdateArgs from './shared/TeenUpdateArgs';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useUpdateTeenMutation } from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface UpdateTeenProps {
  updateTeen: (args: TeenUpdateArgs) => void;
  updatingTeen: boolean;
}

const updateTeenEnhancer = wrapComponent<Props, UpdateTeenProps>(() => {
  const { loading, mutate } = useUpdateTeenMutation();

  return computed(() => ({
    updateTeen: (args) =>
      mutate({
        personId: args.personId,
        fieldName: args.fieldName,
        value: args.value
      }),
    updatingTeen: loading.value
  }));
});

export default compose(updateTeenEnhancer)(PostHS);
