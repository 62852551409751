import { inject } from 'vue';
import { withProps } from 'vue-compose';

export type RouterType = {
  history: {
    push: (location: string) => void;
    replace: (location: string | { search: string | null }) => void;
  };
  location: { pathname: string; search: string };
};

export const withRouter = withProps<any, { router: RouterType }>(function () {
  const router = inject<RouterType>('router');

  if (!router) {
    throw new Error('Router is not provided');
  }

  return { router };
});
