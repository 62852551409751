import { wrapComponent } from 'shared/apollo-hoc';
import Single from './Single.vue';
import { Props } from './types';
import { GetHoldersOfDuplicatePhoneDocument, GetHoldersOfDuplicatePhoneQuery, useDeletePhoneFromDuplicateHolderMutation, useGetHoldersOfDuplicatePhoneQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { compose } from 'vue-compose';

type GetHoldersEnhancerProps = Pick<Props, 'loading' | 'holders'>;
const getDuplicateDataEnhancer = wrapComponent<Props, GetHoldersEnhancerProps>((props) => {
  const { loading, result } = useGetHoldersOfDuplicatePhoneQuery(
    computed(() => ({
      phoneNumber: props.phoneNumber
    }))
  );
  return computed(() => ({
    loading: loading.value,
    holders: result.value?.holdersOfDuplicatePhone || []
  }));
});

type DeleteEnhancerProps = Pick<Props, 'deletePhone' | 'deletingPhone'>;
const deletePhoneFromDuplicateHolderEnhancer = wrapComponent<Props, DeleteEnhancerProps>((props) => {
  const { mutate, loading } = useDeletePhoneFromDuplicateHolderMutation();

  return computed(() => ({
    deletePhone: (id) => {
      const owner = props.holders.find(h => h.Phones.filter(p => p.id === id).length > 0)!;
      const phone = owner.Phones.find(p => p.id === id)!;
      return mutate({
        id,
        phoneNumber: props.phoneNumber
      }, {
        optimisticResponse: {
          deletePhoneFromDuplicateHolder: {
            ...props.holders.filter(h => h.Phones.filter(p => p.id === id).length === 0),
            ...(phone.phoneNumber === props.phoneNumber ? {} : {
              ...owner,
              Phones: owner.Phones.filter(p => p.id !== id)
            })
          }
        },
        update: (proxy) => {
          const data = proxy.readQuery<GetHoldersOfDuplicatePhoneQuery>({
            query: GetHoldersOfDuplicatePhoneDocument,
            variables: {
              phoneNumber: props.phoneNumber
            }
          });
          if (data) {
            let local = props.holders;
            if (phone.phoneNumber === props.phoneNumber) {
              local = local.filter(h => h.Phones.filter(p => p.id === id).length === 0);
            } else {
              local = local.filter(h => h.Phones.filter(p => p.id === id).length === 0);
              if (local.filter(h => h.personID === owner.personID).length === 0) {
                local.push({
                  ...owner,
                  Phones: owner.Phones.filter(p => p.id !== id)
                });
              }
            }

            proxy.writeQuery<GetHoldersOfDuplicatePhoneQuery>({
              query: GetHoldersOfDuplicatePhoneDocument,
              variables: {
                phoneNumber: props.phoneNumber
              },
              data: {
                holdersOfDuplicatePhone: local
              }
            });
          }
        }
      })
    },
    deletingPhone: loading.value
  }));
});

export default compose(
  getDuplicateDataEnhancer,
  deletePhoneFromDuplicateHolderEnhancer
)(Single);
