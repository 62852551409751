import Impact from './Impact.vue';
import { compose } from 'vue-compose';
import TeenUpdateArgs from './shared/TeenUpdateArgs';
import { Props } from './types';
import {
  RelationshipImpactInput,
  RelationshipImpactNoteInput,
  TeensImpactStatisticsDocument,
  TeensImpactStatisticsQuery,
  useAddRelationshipImpactMutation,
  useDeleteRelationshipImpactMutation,
  useDeleteRelationshipImpactNoteMutation,
  useSetRelationshipImpactNoteMutation,
  useUpdateTeenMutation
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { computed } from 'vue';

interface AddRelationshipImpactProp {
  addRelationshipImpact: (args: RelationshipImpactInput) => void;
}
const addRelationshipImpactEnhancer = wrapComponent<Props, AddRelationshipImpactProp>((props) => {
  const { mutate } = useAddRelationshipImpactMutation();

  return computed(() => ({
    addRelationshipImpact: (args) =>
      mutate(
        { args },
        {
          optimisticResponse: {
            addRelationshipImpact: {
              ...props.teen.original,
              RelationshipImpacts: [
                ...props.teen.original.RelationshipImpacts,
                {
                  id: -1,
                  Notes: [],
                  ...args,
                  __typename: 'RelationshipImpact'
                }
              ]
            }
          },
          update: (client, { data: updateData }) => {
            const data = client.readQuery<TeensImpactStatisticsQuery>({
              query: TeensImpactStatisticsDocument,
              variables: {
                filter: {
                  regionId: props.regionId,
                  ...props.activeFilters
                }
              }
            });

            if (data?.teensImpactStatistics && updateData?.addRelationshipImpact) {
              client.writeQuery<TeensImpactStatisticsQuery>({
                query: TeensImpactStatisticsDocument,
                variables: {
                  filter: {
                    regionId: props.regionId,
                    ...props.activeFilters
                  }
                },
                data: {
                  teensImpactStatistics: {
                    ...data.teensImpactStatistics,
                    totalRelationshipImpacts:
                      data.teensImpactStatistics.totalRelationshipImpacts + 1
                  }
                }
              });
            }
          }
        }
      )
  }));
});

interface DeleteRelationshipImpactProps {
  deleteRelationshipImpact: (args: { personId: number; staffId: number; date: string }) => void;
}
const deleteRelationshipImpactEnhancer = wrapComponent<Props, DeleteRelationshipImpactProps>(
  (props) => {
    const { mutate } = useDeleteRelationshipImpactMutation();

    return computed(() => ({
      deleteRelationshipImpact: (args) =>
        mutate(
          { ...args },
          {
            optimisticResponse: {
              deleteRelationshipImpact: {
                ...props.teen.original,
                RelationshipImpacts: [
                  ...(props.teen.original.RelationshipImpacts.length
                    ? props.teen.original.RelationshipImpacts.filter(
                        (ri) => !(ri.personId === args.personId && ri.date === args.date)
                      )
                    : [])
                ]
              }
            },
            update: (client, { data: updateData }) => {
              const data = client.readQuery<TeensImpactStatisticsQuery>({
                query: TeensImpactStatisticsDocument,
                variables: {
                  filter: {
                    regionId: props.regionId,
                    ...props.activeFilters
                  }
                }
              });

              if (data?.teensImpactStatistics && updateData?.deleteRelationshipImpact) {
                client.writeQuery<TeensImpactStatisticsQuery>({
                  query: TeensImpactStatisticsDocument,
                  variables: {
                    filter: {
                      regionId: props.regionId,
                      ...props.activeFilters
                    }
                  },
                  data: {
                    teensImpactStatistics: {
                      ...data.teensImpactStatistics,
                      totalRelationshipImpacts:
                        data.teensImpactStatistics.totalRelationshipImpacts - 1
                    }
                  }
                });
              }
            }
          }
        )
    }));
  }
);

interface DeleteRelationshipImpactNoteProps {
  deleteRelationshipImpactNote: (noteId: number) => void;
}
const deleteRelationshipImpactNoteEnhancer = wrapComponent<
  Props,
  DeleteRelationshipImpactNoteProps
>(() => {
  const { mutate } = useDeleteRelationshipImpactNoteMutation();

  return computed(() => ({
    deleteRelationshipImpactNote: (noteId) => mutate({ noteId })
  }));
});

interface SetRelationshipImpactNoteProps {
  setRelationshipImpactNote: (args: RelationshipImpactNoteInput) => void;
}
const setRelationshipImpactNoteEnhancer = wrapComponent<Props, SetRelationshipImpactNoteProps>(
  () => {
    const { mutate } = useSetRelationshipImpactNoteMutation();

    return computed(() => ({
      setRelationshipImpactNote: (args) => mutate({ args })
    }));
  }
);

interface UpdateTeenProps {
  updateTeen: (args: TeenUpdateArgs) => void;
  updatingTeen: boolean;
}
const updateTeenEnhancer = wrapComponent<Props, UpdateTeenProps>(() => {
  const { loading, mutate } = useUpdateTeenMutation();

  return computed(() => ({
    updateTeen: ({ fieldName, personId, value }) => mutate({ fieldName, personId, value }),
    updatingTeen: loading.value
  }));
});

export default compose(
  updateTeenEnhancer,
  addRelationshipImpactEnhancer,
  deleteRelationshipImpactEnhancer,
  setRelationshipImpactNoteEnhancer,
  deleteRelationshipImpactNoteEnhancer
)(Impact);
