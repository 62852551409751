import range from 'lodash/range';
import chunk from 'lodash/chunk';
import last from 'lodash/last';
import { computed, defineComponent, h, watch } from 'vue';

const formatDate = (date: Date) =>
  `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
    date.getDate()
  ).padStart(2, '0')}`;

const totalDays = (year: number, month: number) => new Date(year, month, 0).getDate();

function getLastDate(weeks: Day[][]) {
  const lastWeek = last(weeks);

  if (lastWeek) {
    const lastDay = last(lastWeek);

    if (lastDay) return lastDay.date;
  }
}

export interface Day {
  day: number;
  date: string;
  thisMonth: boolean;
}

export default defineComponent({
  props: {
    month: { type: Number, required: true },
    year: { type: Number, required: true },
    isSixWeeks: { type: Boolean }
  },
  emits: {
    'update:dates': (value: { startDate: string; endDate: string | undefined }) => true
  },
  setup(props, { emit, slots }) {
    const date = computed(() => {
      return new Date(props.year, props.month - 1, 1);
    });

    const weeks = computed(() => {
      const totalDaysThisMonth = totalDays(props.year, props.month);
      const totalDaysLastMonth = totalDays(props.year, props.month - 1);
      const dayThisMonthStarts = date.value.getDay();
      const dayThisMonthEnds = new Date(props.year, props.month - 1, totalDaysThisMonth).getDay();

      const daysFromLastMonth = range(
        totalDaysLastMonth -
          (props.isSixWeeks
            ? dayThisMonthStarts > 0
              ? dayThisMonthStarts
              : 7
            : dayThisMonthStarts) +
          1,
        totalDaysLastMonth + 1
      ).map((x) => ({
        day: x,
        date: formatDate(new Date(props.year, props.month - 2, x)),
        thisMonth: false
      }));

      const daysFromThisMonth = range(1, totalDaysThisMonth + 1).map((x) => ({
        day: x,
        date: formatDate(new Date(props.year, props.month - 1, x)),
        thisMonth: true
      }));

      const daysFromNextMonth = range(
        1,
        (props.isSixWeeks &&
        (dayThisMonthStarts > 0 || (dayThisMonthStarts === 0 && totalDaysThisMonth === 28))
          ? 14
          : 7) - dayThisMonthEnds
      ).map((x) => ({
        day: x,
        date: formatDate(new Date(props.year, props.month, x)),
        thisMonth: false
      }));

      const daysInCalendar = [...daysFromLastMonth, ...daysFromThisMonth, ...daysFromNextMonth];

      return chunk(daysInCalendar, 7);
    });

    watch(
      () => ({
        month: props.month,
        year: props.year
      }),
      () => {
        emit('update:dates', {
          startDate: weeks.value[0][0].date,
          endDate: getLastDate(weeks.value)
        });
      },
      { immediate: true }
    );

    return () => {
      const $vnode =
        slots.default &&
        slots.default({
          weeks: weeks.value,
          date: date.value
        });

      return h('div', $vnode);
    };
  }
});
