import { MutateResult } from '@vue/apollo-composable';
import EditPayment from './EditPayment.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import {
  EditPaymentInput,
  EditPaymentMutation,
  useEditPaymentMutation
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

interface Props {
  registrationId: number;
}

interface EditPaymentProps {
  editPayment: (vars: EditPaymentInput) => MutateResult<EditPaymentMutation>;
  editing: boolean;
}

const editPaymentEnhancer = wrapComponent<Props, EditPaymentProps>((props) => {
  const { loading, mutate } = useEditPaymentMutation();

  return computed(() => ({
    editing: loading.value,
    editPayment: (input) => mutate({ input, registrationId: props.registrationId })
  }));
});

export default compose(editPaymentEnhancer)(EditPayment);
