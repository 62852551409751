import Single from './Single.vue';
import { compose } from 'vue-compose';
import { Props } from './types';
import { enhancer as markAsLeadEnhancer } from 'shared/components/MarkAsLead';
import { enhancer as removeLeadEnhancer } from 'shared/components/RemoveLead';
import { currentUserEnhancer } from 'shared/enhancers/currentUserEnhancer';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetGoalQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';

const getGoalEnhancer = wrapComponent<Props, Pick<Props, 'goalLoading' | 'goal'>>((props) => {
  const { loading, result } = useGetGoalQuery(computed(() => ({ id: Number(props.id) })));

  return computed(() => ({
    goal: result.value?.goal,
    goalLoading: loading.value
  }));
});

export default compose(
  getGoalEnhancer,
  markAsLeadEnhancer,
  removeLeadEnhancer,
  currentUserEnhancer
)(Single);
