import Email from './Email.vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetEmailHistoryQuery, useGetEmailHistoryQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface Props {
  staff: {
    email: string | null;
  };
}

interface ChildProps {
  emailHistory: GetEmailHistoryQuery['emailHistory'];
  loading: boolean;
}

export default wrapComponent<Props, ChildProps>((props) => {
  const { loading, result } = useGetEmailHistoryQuery(
    computed(() => ({ toAddress: props.staff.email || '' })),
    { enabled: computed(() => !!props.staff?.email) }
  );

  return computed(() => ({
    emailHistory: result.value?.emailHistory || [],
    loading: loading.value
  }));
})(Email);
