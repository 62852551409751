import Duplicates from './ManualTeenMerge.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  useGetTeensToMergeByIdQuery,
  GetTeensToMergeByIdQuery
} from 'shared/generated/graphql-types';
import { Props } from './types';
import { withRouter } from "shared/components/router";
import has from 'lodash/has';
import camelCase from 'lodash/camelCase';
import isString from 'lodash/isString';
import QueryString from 'query-string';
import toLower from 'lodash/toLower';

const getTeenDuplicatesEnhancer = wrapComponent<Props,Props>((props) => {
  const str = has(props.router, 'location.search') ? props.router.location.search : '';
  const queryFilters = Object.entries(QueryString.parse(str, { parseNumbers: true })).reduce(
    (collection, [key, value]) => ({
      ...collection,
      ...({ [camelCase(key)]: isString(value) ? toLower(value) : value })
    }),
    {}
  ) as { [key: string]: any };

  const personIds = queryFilters.personIds || [];
  const { loading, result } = useGetTeensToMergeByIdQuery(
    computed(() => {
      return {
        personIds,
      };
    }),
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  return computed(() => ({
    loading: loading.value,
    duplicateTeens: mapResult(result.value?.peopleById! || []),
    personIds
  }));
});

function mapResult(people: GetTeensToMergeByIdQuery['peopleById']) : Props['duplicateTeens'] {
  return people.map((p) => ({
    personId: p.personID,
    regionId: p.Teen!.Region.regionId,
    chapterId: p.Teen!.Chapter!.chapterId,
    firstName: p.firstName!,
    lastName: p.lastName!,
    Teen: {
      ...p.Teen!,
      Person: p,
    },
    __typename: 'TeenDuplicate'
  }));
  return [];
}

export default compose(
  withRouter,
  getTeenDuplicatesEnhancer
)(Duplicates);
