import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetHousesForEventDocument,
  GetHousesForEventQuery,
  useRemoveHouseMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface RemoveHouseProps {
  removeHouse: (id: number) => void;
}

interface Props {
  eventId: number;
}

export const removeHouseEnhancer = wrapComponent<Props, RemoveHouseProps>((props) => {
  const { mutate } = useRemoveHouseMutation();

  return computed(() => ({
    removeHouse: (id) =>
      mutate(
        { id },
        {
          optimisticResponse: {
            removeHouse: id
          },
          update: (proxy) => {
            const data = proxy.readQuery<GetHousesForEventQuery>({
              query: GetHousesForEventDocument,
              variables: { eventId: props.eventId }
            });

            if (data) {
              const houses = data.houses.slice();
              const currentHouseIndex = houses.findIndex((h) => h.houseId === id);

              if (currentHouseIndex > -1) {
                houses.splice(currentHouseIndex, 1);
              }

              proxy.writeQuery<GetHousesForEventQuery>({
                query: GetHousesForEventDocument,
                variables: { eventId: props.eventId },
                data: {
                  houses
                }
              });
            }
          }
        }
      )
  }));
});
