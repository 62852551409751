import { computed } from 'vue';
import { compose, acceptProps } from 'vue-compose';
import BusLookup from './BusLookup.vue';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetBusesForLookupQuery } from 'shared/generated/graphql-types';

interface AcceptedProps {
  eventId: number;
  direction: string;
}
const enhancer = wrapComponent<AcceptedProps, Pick<Props, 'buses' | 'loading'>>((props) => {
  const { loading, result } = useGetBusesForLookupQuery(
    computed(() => ({ eventId: props.eventId }))
  );

  return computed(() => ({
    buses: (result.value?.event.Buses || [])
      .filter((b) => b.directionFlag === props.direction || b.directionFlag === 'B')
      .map((b) => ({
        busId: b.busID,
        description: b.description,
        direction: b.directionFlag,
        meetingAddress: b.meetingAddress
      })),
    loading: loading.value
  }));
});

export default compose(enhancer, acceptProps(['eventId', 'direction']))(BusLookup);
