import { compose, withData, withProps } from 'vue-compose';
import Vue, { computed, VueConstructor } from 'vue';
import Financials from './Financials.vue';
import { getCurrentSeason, getOptions, normalizeProps } from 'shared/util';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import {
  EmailParentsMutation,
  GetOwedRegistrationsQuery,
  OwedRegistrationFilter,
  useEmailParentsMutation,
  useGetOwedRegistrationsQuery
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { ArrayElement } from 'shared/util/types';
import { MutateResult } from '@vue/apollo-composable';

type OwedRegistration = ArrayElement<
  GetOwedRegistrationsQuery['owedRegistrations']['registrations']
>;

export const filtersInit = (): OwedRegistrationFilter => ({
  includeAlumni: false,
  waitingList: false,
  minRange: 1,
  maxRange: 200,
  event: null,
  chapters: [],
  searchByName: null,
  season: getCurrentSeason(),
  sortColumn: { column: 'date', ascending: false }
});
interface Props {
  regionId?: number | null;
  currentPage: number;
  limit: number;
  filters: OwedRegistrationFilter;
}

interface OwedRegistrations {
  owedRegistrations?: OwedRegistration[];
  isLoading: boolean;
  total?: number;
  min?: number;
  max?: number;
}

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const getOwedRegistrationsEnhancer = wrapComponent<Props, OwedRegistrations>((props) => {
  const { loading, result } = useGetOwedRegistrationsQuery(
    computed(() => ({
      limit: props.limit,
      offset: (props.currentPage - 1) * props.limit,
      filter: { ...props.filters, regionID: props.regionId }
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    owedRegistrations: result.value?.owedRegistrations.registrations || [],
    total: result.value?.owedRegistrations.total,
    max: result.value?.owedRegistrations.max,
    min: result.value?.owedRegistrations.min,
    isLoading: loading.value
  }));
});

type EmailParentProps = {
  emailParents: (registrationIds: number[]) => MutateResult<EmailParentsMutation>;
  sendingEmail: boolean;
};

const emailParentsEnhancer = wrapComponent<Props, EmailParentProps>((props) => {
  const { loading, mutate } = useEmailParentsMutation();

  return computed(() => ({
    emailParents: (registrationIds) => mutate({ registrationIds }),
    sendingEmail: loading.value
  }));
});

const withFilters = (Component: VueConstructor): VueConstructor => {
  const props = normalizeProps(getOptions(Component).props);

  const { currentPage, filters, setFilters, clearFilters, setPage, ...propsToUse } = props;

  return Vue.extend({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        filters: filtersInit(),
        currentPage: 1
      };
    },
    methods: {
      setFilters(args: Partial<OwedRegistrationFilter>) {
        this.filters = { ...this.filters, ...args };
        this.setPage(1);
      },
      clearFilters() {
        this.filters = filtersInit();
        this.setPage(1);
      },
      setPage(page: number) {
        this.currentPage = page;
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          filters: this.filters,
          setFilters: this.setFilters,
          clearFilters: this.clearFilters,
          currentPage: this.currentPage,
          setPage: this.setPage
        },
        on: this.$listeners
      });
    }
  });
};

export const enhancer = compose(
  withFilters,
  withData({
    selectedTeen: {
      initialValue: null
    }
  }),
  withProps<any, any>((props: any) => ({
    regionId: getCurrentRegion(),
    limit: 60
  })),
  getOwedRegistrationsEnhancer,
  emailParentsEnhancer
);

export default enhancer(Financials);
