import Registrations from './Registrations.vue';
import { compose, withData } from 'vue-compose';
import { withRouter } from 'shared/components/router';
import { Props } from './types';
import {
  moveRegistrationToWaitListEnhancer,
  cancelRegistrationEnhancer,
  resendRegistrationEmailsEnhancer,
  produceInvoiceEnhancer,
  resendWaiverEmailsEnhancer,
  promoteRegistrationEnhancer
} from '../Registrations/RegistrationInfo/shared/enhancers';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  useBulkNotifyParentsMutation,
  useEventWithRegistrationsQuery
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

const getRegistrations = wrapComponent<Props, Pick<Props, 'event'>>((props) => {
  const { result } = useEventWithRegistrationsQuery(computed(() => ({ eventID: props.eventId })));

  return computed(() => ({
    event: result.value?.event
  }));
});

export const bulkNotifyParentsEnhancer = wrapComponent<
  Props,
  Pick<Props, 'bulkNotifyParents' | 'sendingConsentEmails'>
>((props) => {
  const { loading, mutate } = useBulkNotifyParentsMutation();

  return computed(() => ({
    bulkNotifyParents: (args) => mutate({ ...args }),
    sendingConsentEmails: loading.value
  }));
});

export const enhancer = compose(
  withData({
    addingNewTeen: {
      initialValue: false
    }
  }),
  withRouter,
  getRegistrations,
  cancelRegistrationEnhancer,
  moveRegistrationToWaitListEnhancer,
  promoteRegistrationEnhancer,
  resendRegistrationEmailsEnhancer,
  produceInvoiceEnhancer,
  resendWaiverEmailsEnhancer,
  bulkNotifyParentsEnhancer
);

export default enhancer(Registrations);
