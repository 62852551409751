import { render, staticRenderFns } from "./EmailInput.vue?vue&type=template&id=c96e2898&scoped=true&"
import script from "./EmailInput.vue?vue&type=script&setup=true&lang=ts&"
export * from "./EmailInput.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./EmailInput.vue?vue&type=style&index=0&id=c96e2898&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c96e2898",
  null
  
)

export default component.exports