import ScheduleEventContainer from './ScheduleEventContainer.vue';
import { computed } from 'vue';
import { compose, withProps } from 'vue-compose';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetEventSubTypesQuery,
  useGetEventSubTypesQuery,
  useMeQuery
} from 'shared/generated/graphql-types';
import { getRegionEnhancer } from 'shared/enhancers/getRegionEnhancer';

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const eventSubTypesEnhancer = wrapComponent<
  {},
  { eventSubTypes: GetEventSubTypesQuery['eventSubTypes'] }
>(() => {
  const { result } = useGetEventSubTypesQuery();

  return computed(() => ({
    eventSubTypes: result.value?.eventSubTypes || []
  }));
});

const getCurrentUser = wrapComponent<{ regionId: number }, {}>((props) => {
  const { result } = useMeQuery();

  return computed(() => ({
    user: result.value?.me
      ? (result.value.me.AdvisorRegions || [])
          .filter((ar) => ar.active)
          .map((ar) => {
            const { AdvisorRegions, ...staff } = result.value!.me!;
            return { ...ar, Staff: staff };
          })
          .find((Obj) => Obj.Region.regionId === props.regionId)
      : {}
  }));
});

export default compose(
  withProps(() => ({
    regionId: getCurrentRegion()
  })),
  getRegionEnhancer,
  getCurrentUser,
  eventSubTypesEnhancer
)(ScheduleEventContainer);
