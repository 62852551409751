import { Report } from './types'
import groupBy from 'lodash/groupBy'


type SubCatTuple = [number, string]
type CatTuple = [string, SubCatTuple[]]

export type Categories = CatTuple[] 

export function extractCategories (reports: Report[]): Categories {
  const groupedByCategory = Object.entries(groupBy(reports, 'category'))

  return groupedByCategory.map<CatTuple>(([cat, reports]) => [cat, extractSubCategories(reports)])
}

function extractSubCategories (reports: Report[]): SubCatTuple[] {
  const groupedByCategory = Object.entries(groupBy(reports, 'subcategory'))

  return groupedByCategory.map<SubCatTuple>(([subCat, reports]) => [
    reports.length,
    subCat
  ])
}