import { wrapComponent } from 'shared/apollo-hoc';
import EditJFEOrderPayment from './EditJFEOrderPayment.vue';
import { Props } from './types';
import { useEditJfeOrderPaymentMutation } from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { compose } from 'vue-compose';

const editPaymentEnhancer = wrapComponent<Props, Pick<Props, 'editPayment' | 'editing'>>((props) => {
  const { loading, mutate } = useEditJfeOrderPaymentMutation();

  return computed(() => ({
    editing: loading.value,
    editPayment: (input) => mutate({ input, orderId: props.orderId })
  }));
});

export default compose(editPaymentEnhancer)(EditJFEOrderPayment);
