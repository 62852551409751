import Waivers from './Waivers.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import {
  GetSignedWaiversQuery,
  GetWaiversQuery,
  SubmitWaiverMutationVariables,
  ToggleNcsyWaiverMutationVariables,
  UnSubmitWaiverMutationVariables,
  useGetSignedWaiversQuery,
  useGetWaiversQuery,
  useSubmitWaiverMutation,
  useToggleNcsyWaiverMutation,
  useUnSubmitWaiverMutation
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

interface Props {
  eventId: number;
  registrationID: number;
}

interface SubmitWaiverProps {
  submitWaiver: (vars: SubmitWaiverMutationVariables) => any;
}

interface UnsubmitWaiverProps {
  unSubmitWaiver: (vars: UnSubmitWaiverMutationVariables) => any;
}

interface ToggleWaiverProps {
  toggleNCSYWaiver: (vars: ToggleNcsyWaiverMutationVariables) => any;
}

interface GetWaiverProps {
  waivers: GetWaiversQuery['waivers'];
}

interface RegistrationWaiversProps {
  registration: GetSignedWaiversQuery['registration'];
}

export default compose(
  wrapComponent<Props, GetWaiverProps>((props) => {
    const { result } = useGetWaiversQuery(computed(() => ({ eventId: props.eventId })));

    return computed(() => ({
      waivers: result.value?.waivers || []
    }));
  }),
  wrapComponent<Props, RegistrationWaiversProps>((props) => {
    const { result } = useGetSignedWaiversQuery(
      computed(() => ({ registrationID: props.registrationID }))
    );

    return computed(() => ({
      registration: result.value?.registration
    }));
  }),
  wrapComponent<Props & RegistrationWaiversProps, SubmitWaiverProps>((props) => {
    const { mutate } = useSubmitWaiverMutation();

    return computed(() => ({
      submitWaiver: ({ registrationID, waiverID }) =>
        mutate(
          { registrationID, waiverID },
          {
            optimisticResponse: {
              submitWaiver: {
                __typename: 'Registration',
                registrationID,
                submittedWaivers: [
                  ...props.registration!.submittedWaivers,
                  {
                    __typename: 'Waiver',
                    id: waiverID
                  }
                ]
              }
            }
          }
        )
    }));
  }),
  wrapComponent<Props & RegistrationWaiversProps, UnsubmitWaiverProps>((props) => {
    const { mutate } = useUnSubmitWaiverMutation();

    return computed(() => ({
      unSubmitWaiver: ({ registrationID, waiverID }) =>
        mutate(
          { registrationID, waiverID },
          {
            optimisticResponse: {
              unSubmitWaiver: {
                __typename: 'Registration',
                registrationID,
                submittedWaivers: [
                  ...props.registration!.submittedWaivers.filter((x) => x.id !== waiverID)
                ]
              }
            }
          }
        )
    }));
  }),
  wrapComponent<Props & RegistrationWaiversProps, ToggleWaiverProps>((props) => {
    const { mutate } = useToggleNcsyWaiverMutation();

    return computed(() => ({
      toggleNCSYWaiver: ({ registrationID }) =>
        mutate(
          { registrationID },
          {
            optimisticResponse: {
              toggleNCSYWaiver: {
                __typename: 'Registration',
                registrationID,
                ncsyWaiverReceived: !props.registration!.ncsyWaiverReceived
              }
            }
          }
        )
    }));
  })
)(Waivers);
