import { computed } from 'vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { CurrentUserQuery, useCurrentUserQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

interface TProps {
  eventId: string;
  regionId: number;
}

interface TChildProps {
  currentUser?: ArrayElement<CurrentUserQuery['me']['AdvisorRegions']>;
}

const currentUser = wrapComponent<TProps, Pick<TChildProps, 'currentUser'>>((props) => {
  const { result } = useCurrentUserQuery();

  return computed(() => ({
    currentUser: result.value?.me.AdvisorRegions.find((a) => a.Region.regionId === props.regionId)
  }));
});

export default currentUser;
