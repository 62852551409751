var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.PanelsGrid,[_c(_setup.ScrollablePanel,{directives:[{name:"fullheight",rawName:"v-fullheight"}]},[[_c('div',{staticClass:"dashboard-main"},[_c('div',{staticClass:"welcome"},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+","),_c('br'),_vm._v(" Welcome to NCSY Event managment ")]),_c('section',{staticClass:"dashboard-section"},[_c('header',[_vm._v("Upcoming Events")]),_c('div',{staticClass:"flex-container upcoming-events"},[_c('div',{staticClass:"card",attrs:{"tag":"div"}},[_c('header',[_vm._v("My Events")]),_c('ul',{staticClass:"chart-list clickable"},_vm._l((_setup.myEvents),function(event){return _c('li',{key:event.eventId},[_c(_setup.RouterLink,{attrs:{"to":`/events/${event.eventId}`}},[_c('span',{staticClass:"mini-time"},[_vm._v(_vm._s(_setup.getLocalStartDate(event)))]),_vm._v(" "+_vm._s(event.eventName)+" ")])],1)}),0)]),_c('div',{staticClass:"card",attrs:{"tag":"div"}},[_c('header',[_vm._v("RegionWide")]),_c('ul',{staticClass:"chart-list clickable"},_vm._l((_setup.regionWideEvents),function(event){return _c('li',{key:event.eventId},[_c(_setup.RouterLink,{attrs:{"to":`/events/${event.eventId}`}},[_c('span',{staticClass:"mini-time"},[_vm._v(_vm._s(_setup.getLocalStartDate(event)))]),_vm._v(" "+_vm._s(event.eventName)+" ")])],1)}),0)]),_c('div',{staticClass:"card",attrs:{"tag":"div"}},[_c('header',[_vm._v("ICal Feed")]),_c('div',{staticClass:"form-row"},[_c('div',[_c('label',[_vm._v("Chapter")]),_c(_setup.ChapterLookup,{attrs:{"regionId":_vm.regionId},model:{value:(_setup.chapter),callback:function ($$v) {_setup.chapter=$$v},expression:"chapter"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',[_c('label',[_vm._v("Event Categorie")]),_c(_setup.UISelect,{attrs:{"options":[
                      { label: 'JSU', value: 'JSU' },
                      {
                        label: 'Latte & Learning',
                        value: 'Latte%20%26%20Learning',
                      },
                      {
                        label: 'Shabbaton/Overnight',
                        value: 'Shabbaton/Overnight',
                      },
                      { label: 'Shabbat/Holiday', value: 'Shabbat/Holiday' },
                    ],"placeholder":"Event Categorie"},model:{value:(_setup.eventCategory),callback:function ($$v) {_setup.eventCategory=$$v},expression:"eventCategory"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"eventTypeLookup"},[_c('label',[_vm._v("Event Type")]),_c(_setup.EventTypeLookup,{model:{value:(_setup.eventType),callback:function ($$v) {_setup.eventType=$$v},expression:"eventType"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',[_c('label',[_vm._v("Event Sub Type")]),_c(_setup.EventSubTypeLookup,{model:{value:(_setup.eventSubType),callback:function ($$v) {_setup.eventSubType=$$v},expression:"eventSubType"}})],1)]),_c('a',{attrs:{"href":_setup.iCalLink}},[_vm._v(_vm._s(_setup.iCalLink))])])])])])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }