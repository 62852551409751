import DateTimezone from 'date-timezone';
import { SimpleDate, SimpleTime } from 'shared/util/types';
import { convertTo24HourFormat, compareDates } from 'shared/util';
import { Event } from './types'

export function toISOString(event: Event, date: SimpleDate, time: SimpleTime) {
  DateTimezone.setGlobalTimezone(event.TimeZone);

  const dateObject = new DateTimezone.DateTimezone(
    date.year,
    date.month - 1,
    date.day,
    convertTo24HourFormat({...time, hours: time.hours.toString(), minutes: time.minutes.toString()}),
    +time.minutes
  );

  return dateObject.toISOString();
}

export function startTimeBeforeEndTime(timezone: string, date: SimpleDate, startTime: SimpleTime, endTime: SimpleTime) {
  DateTimezone.setGlobalTimezone(timezone);

  const startDate = new DateTimezone.DateTimezone(
    date.year,
    date.month - 1,
    date.day,
    convertTo24HourFormat({...startTime, hours: startTime.hours.toString(), minutes: startTime.minutes.toString()}),
    +startTime.minutes,
  );

  const endDate = new DateTimezone.DateTimezone(
    date.year,
    date.month - 1,
    date.day,
    convertTo24HourFormat({...endTime, hours: endTime.hours.toString(), minutes: endTime.minutes.toString()}),
    +endTime.minutes,
  );

  return compareDates(startDate, endDate);
}
