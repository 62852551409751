import EmailBuilder from './EmailBuilder.vue';
import { computed } from 'vue';
import { compose, withData } from 'vue-compose';
import { isBaseTemplate, unescapeVariables } from './util';
import { Props, Template, TemplateType } from './types';
import {
  useGetOverridesQuery,
  useGetTemplateQuery,
  useGetTemplateTypesQuery
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

interface GetTemplateTypesProps {
  templateTypes?: TemplateType[];
}

const getTemplateTypes = wrapComponent<Props, GetTemplateTypesProps>((props) => {
  const { result } = useGetTemplateTypesQuery(
    computed(() => ({
      category: props.category,
      chapterId: props.templateProps.chapterId,
      regionId: props.region.regionId
    }))
  );

  return computed(() => ({
    templateTypes: result.value?.templateTypes || []
  }));
});

interface GetTemplateProps {
  template: Template;
  fetchingTemplate: boolean;
  templateProps: Props['templateProps'];
}

const getTemplate = wrapComponent<Props, GetTemplateProps>((props) => {
  const { loading, result } = useGetTemplateQuery(
    computed(() => ({
      templateId: props.templateProps.templateId,
      templateTypeId: props.selectedTemplateTypeId,
      chapterId: (props.eventProps && props.eventProps.chapterId) || props.templateProps.chapterId,
      regionId: props.region.regionId,
      eventId: props.eventId
    })),
    {
      fetchPolicy: 'network-only',
      enabled: computed(() => !(!props.selectedTemplateTypeId && !props.templateProps.templateId))
    }
  );

  return computed(() => ({
    fetchingTemplate: loading.value,
    template: result.value?.template && {
      ...result.value.template,
      body: unescapeVariables(result.value.template.body, false)
    },
    templateProps: {
      ...props.templateProps,
      chapterId:
        (result.value?.template &&
          (isBaseTemplate(result.value.template || null)
            ? props.templateProps.chapterId
            : result.value.template && result.value.template.chapterId)) ||
        null
    }
  }));
});

interface GetOverridesProps {
  overrides?: Template[];
  loading: boolean;
}

const getOverrides = wrapComponent<Props, GetOverridesProps>((props) => {
  const { loading, result } = useGetOverridesQuery(
    computed(() => ({
      regionId: props.region.regionId,
      templateTypeId: props.selectedTemplateTypeId
    })),
    { fetchPolicy: 'network-only', enabled: computed(() => !!props.selectedTemplateTypeId) }
  );

  return computed(() => {
    let overrides: NonNullable<Template>[] | undefined;
    if (result.value?.overrides) {
      overrides = (result.value.overrides.slice() || []).map((o) => ({
        ...o,
        body: unescapeVariables(o.body, false)
      }));
      if (overrides.find((o) => !!o.regionId && !!!o.eventId && !!!o.chapterId)) {
        const baseIndex = overrides.findIndex(
          (o) => !!!o.regionId && !!!o.eventId && !!!o.chapterId
        );
        if (baseIndex > -1) {
          overrides.splice(baseIndex, 1);
        }
      }
    }
    return {
      loading: loading.value,
      overrides
    };
  });
});

export const enhancer = compose(
  withData({
    templateProps: {
      initialValue: (props) => {
        return {
          chapterId: (props.eventProps && props.eventProps.chapterId) || null,
          templateId: null
        };
      }
    },
    selectedTemplateTypeId: {
      initialValue: null
    }
  }),
  getOverrides,
  getTemplate,
  getTemplateTypes
);

export default enhancer(EmailBuilder);
