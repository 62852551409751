import CompactTeen from './shared/CompactTeen';
import { getCurrentEmail, getFullName } from 'shared/util';
import { TeensMissingSchoolQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Teen = ArrayElement<TeensMissingSchoolQuery['teens']['teens']>;

export function toCompactTeens(teens: Teen[]): CompactTeen[] {
  if (!teens.length) return [];
  return teens.map((teen) => ({
    name: getFullName(teen.Person.firstName, teen.Person.lastName),
    personId: teen.personID,
    gender: teen.Person.gender,
    chapterName: teen.Chapter ? teen.Chapter.chapterName : null,
    chapter: teen.Chapter ? teen.Chapter : null,
    schoolName: teen.School ? teen.School.name : null,
    school: teen.School ? teen.School : null,
    regionId: teen.Region.regionId || null,
    email: getCurrentEmail(teen.Person.EmailAddresses || [])
      ? getCurrentEmail(teen.Person.EmailAddresses)!.email
      : null
  }));
}
