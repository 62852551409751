import { computed } from 'vue';
import { compose } from 'vue-compose';
import UpdateParentInfo from './UpdateParentInfo.vue';
import { addParentToFamilyEnhancer } from 'shared/components/AddParentToFamily';
import { connectParentToTeenEnhancer } from 'shared/enhancers/connectParentToTeenEnhancer';
import { wrapComponent } from 'shared/apollo-hoc';
import { useEditTeenMutation } from 'shared/generated/graphql-types';
import { Props, TeenUpdateArgs } from './types';
import { calcTeenUpdate } from '../TeenProfile/utils';

interface UpdateTeenProps {
  updateTeen: (args: TeenUpdateArgs) => void;
}
const updateTeenEnhancer = wrapComponent<Props, UpdateTeenProps>((props) => {
  const { loading, mutate } = useEditTeenMutation();

  return computed(() => ({
    updateTeen: async (args) => {
      mutate(
        {
          fieldName: args.fieldName,
          personId: args.personId,
          value: args.value
        },
        {
          optimisticResponse: {
            updateTeen: {
              ...calcTeenUpdate(props.teen.original, args)
            }
          }
        }
      );
    },
    updatingTeen: loading.value
  }));
});

export default compose(
  updateTeenEnhancer,
  addParentToFamilyEnhancer,
  connectParentToTeenEnhancer
)(UpdateParentInfo);
