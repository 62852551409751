import EventTrackLookup from './EventTrackLookup.vue';
import { GetEventTracksQuery, useGetEventTracksQuery } from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { computed } from 'vue';
import { ArrayElement } from 'shared/util/types';

export type EventTrack = ArrayElement<GetEventTracksQuery['eventTracks']>;

interface TChildProps {
  eventTracks: GetEventTracksQuery['eventTracks'];
  loading: boolean;
}
export default wrapComponent<{}, TChildProps>(() => {
  const { loading, result } = useGetEventTracksQuery();

  return computed(() => ({
    eventTracks: result.value?.eventTracks || [],
    loading: loading.value
  }));
})(EventTrackLookup);
