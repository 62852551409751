import { MutateResult } from '@vue/apollo-composable';
import { wrapComponent } from 'shared/apollo-hoc';
import { EntityUnionViewModel } from 'shared/components/EmailInput/shared/EntityUnionViewModel';
import {
  ExtendedTeenSideBarFragment,
  ExtendedTeenSideBarFragmentDoc,
  SearchDuplicateEmailsQuery,
  Gender,
  MaritalStatus,
  useConnectParentToTeenMutation,
  ConnectParentToTeenMutationVariables,
  ConnectParentToTeenMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

type Parent = Extract<
  NonNullable<SearchDuplicateEmailsQuery['searchDuplicateEmails']>,
  { personID: number }
>;

type Props = {
  connectingParentToTeen: boolean;
  connectParentToTeen: (
    args: ConnectParentToTeenMutationVariables,
    optimisticResponse: EntityUnionViewModel
  ) => MutateResult<ConnectParentToTeenMutation>;
};

const GenderMapped = {
  0: Gender.Male,
  1: Gender.Female,
  Male: 0,
  Female: 1
};

export const connectParentToTeenEnhancer = wrapComponent<
  Props,
  Pick<Props, 'connectParentToTeen' | 'connectingParentToTeen'>
>((props) => {
  const { loading, mutate } = useConnectParentToTeenMutation();

  return computed(() => ({
    connectingParentToTeen: loading.value,
    connectParentToTeen: ({ childId, parentId }, parent) =>
      mutate(
        { childId, parentId },
        {
          optimisticResponse: {
            connectTeenToParent: {
              __typename: 'Family',
              id: -1,
              status: MaritalStatus.Unknown,
              familyName: '',
              Mother:
                parent.gender === Gender.Female
                  ? {
                      personID: parentId,
                      firstName: parent.firstName,
                      lastName: parent.lastName,
                      gender: GenderMapped[parent.gender],
                      mediaConsentSigned: null,
                      dataConsentSigned: null,
                      liabilitySigned: false,
                      dataOptOut: null,
                      origin: null,
                      teudatZehut: null,
                      olamiId: null,
                      aliyahDate: null,
                      Phones: (parent.original as Parent).Phones,
                      EmailAddresses: (parent.original as Parent).EmailAddresses,
                      Addresses: [],
                      __typename: 'Person'
                    }
                  : null,
              Father:
                parent.gender === Gender.Male
                  ? {
                      personID: parentId,
                      firstName: parent.firstName,
                      lastName: parent.lastName,
                      gender: GenderMapped[parent.gender],
                      mediaConsentSigned: null,
                      dataConsentSigned: null,
                      liabilitySigned: false,
                      dataOptOut: null,
                      origin: null,
                      teudatZehut: null,
                      olamiId: null,
                      aliyahDate: null,
                      Phones: (parent.original as Parent).Phones,
                      EmailAddresses: (parent.original as Parent).EmailAddresses,
                      Addresses: [],
                      __typename: 'Person'
                    }
                  : null,
              Children: []
            }
          },
          update(client, { data: updateData }) {
            const extendedTeenSideBarData = client.readFragment<ExtendedTeenSideBarFragment>({
              id: `${childId}Teen`,
              fragment: ExtendedTeenSideBarFragmentDoc,
              fragmentName: 'ExtendedTeenSideBar'
            });

            if (extendedTeenSideBarData && updateData?.connectTeenToParent) {
              let teenProfileData;
              if (!extendedTeenSideBarData.Person.ChildOf) {
                teenProfileData = {
                  ...extendedTeenSideBarData,
                  Person: {
                    ...extendedTeenSideBarData.Person,
                    ChildOf: [updateData.connectTeenToParent]
                  }
                };
              } else if (
                extendedTeenSideBarData.Person.ChildOf &&
                !extendedTeenSideBarData.Person.ChildOf.find(
                  (c) => c.id === updateData.connectTeenToParent.id
                )
              ) {
                teenProfileData = {
                  ...extendedTeenSideBarData,
                  Person: {
                    ...extendedTeenSideBarData.Person,
                    ChildOf: [
                      ...extendedTeenSideBarData.Person.ChildOf,
                      updateData.connectTeenToParent
                    ]
                  }
                };
              }
              if (teenProfileData) {
                client.writeFragment<ExtendedTeenSideBarFragment>({
                  id: `${childId}Teen`,
                  fragment: ExtendedTeenSideBarFragmentDoc,
                  fragmentName: 'ExtendedTeenSideBar',
                  data: teenProfileData
                });
              }
            }
          }
        }
      )
  }));
});
