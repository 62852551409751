import { Props } from './types';
import {
  RecentReportsFragment,
  RecentReportsFragmentDoc,
  ReportInput,
  useGenerateReportMutation
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { computed } from 'vue';

interface GenerateReportProps {
  generateReport: (report: ReportInput) => void;
  generating: boolean;
}

export type OuterProps = Pick<Props, 'staffId'>;

export default wrapComponent<OuterProps, GenerateReportProps>((props) => {
  const { loading, mutate } = useGenerateReportMutation();

  return computed(() => ({
    generateReport: (report) =>
      mutate(
        { report },
        {
          update(client) {
            const staff = client.readFragment<RecentReportsFragment>({
              fragment: RecentReportsFragmentDoc,
              fragmentName: 'RecentReports',
              id: `${props.staffId}AdvisorRegion`
            });

            if (staff && !staff.recentReports.includes(report.ReportName!)) {
              const recentReports = staff.recentReports.slice();
              recentReports.push(report.ReportName!);

              client.writeFragment<RecentReportsFragment>({
                fragment: RecentReportsFragmentDoc,
                fragmentName: 'RecentReports',
                id: `${props.staffId}AdvisorRegion`,
                data: {
                  ...staff,
                  recentReports
                }
              });
            }
          }
        }
      ),
    generating: loading.value
  }));
});
