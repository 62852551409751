import Guests from './Guests.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  AddNewGuestMutationVariables,
  GetEventDocument,
  GetEventQuery,
  useAddNewGuestMutation,
  useRemoveGuestMutation
} from 'shared/generated/graphql-types';

type TProps = {
  guests: GetEventQuery['event']['EventGuests'];
  eventId: number;
};

type AddNewGuestProps = {
  addNewGuest: (vars: AddNewGuestMutationVariables) => void;
};

const addNewGuestEnhancer = wrapComponent<TProps, AddNewGuestProps>((props) => {
  const { mutate } = useAddNewGuestMutation();

  return computed(() => ({
    addNewGuest: ({ eventId, firstName, lastName }) =>
      mutate(
        { eventId, firstName, lastName },
        {
          optimisticResponse: {
            addNewGuest: {
              __typename: 'EventGuest',
              firstName,
              lastName,
              eventId: props.eventId,
              eventGuestId: Math.round(Math.random() * -1000000),
              House: null
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetEventQuery>({
              query: GetEventDocument,
              variables: { eventId: props.eventId }
            });

            if (data?.event && updateData?.addNewGuest) {
              const guests = data.event.EventGuests.slice() || [];
              guests.push(updateData.addNewGuest);

              proxy.writeQuery<GetEventQuery>({
                query: GetEventDocument,
                variables: { eventId: props.eventId },
                data: {
                  event: {
                    ...data.event,
                    EventGuests: guests
                  }
                }
              });
            }
          }
        }
      )
  }));
});

type RemoveGuestProps = {
  removeGuest: (eventGuestId: number) => void;
};

const removeGuestEnhancer = wrapComponent<TProps, RemoveGuestProps>((props) => {
  const { mutate } = useRemoveGuestMutation();

  return computed(() => ({
    removeGuest: (eventGuestId) =>
      mutate(
        { eventGuestId },
        {
          optimisticResponse: {
            removeGuest: eventGuestId
          },
          update: (proxy, response) => {
            const data = proxy.readQuery<GetEventQuery>({
              query: GetEventDocument,
              variables: {
                eventId: props.eventId
              }
            });

            if (data) {
              const guests = data.event!.EventGuests.slice() || [];
              const index = guests.findIndex((guest) => guest!.eventGuestId === eventGuestId);

              if (index > -1) {
                guests.splice(index, 1);
              }

              proxy.writeQuery<GetEventQuery>({
                query: GetEventDocument,
                variables: {
                  eventId: props.eventId
                },
                data: {
                  event: {
                    ...data.event,
                    EventGuests: guests
                  }
                }
              });
            }
          }
        }
      )
  }));
});

export default compose(addNewGuestEnhancer, removeGuestEnhancer)(Guests);
