import {
  getCurrentEmail,
  getFullName,
  getCurrentCellNumber,
  getCurrentLandlineNumber
} from 'shared/util';
import { BaseFamilyFragment as Family, FamilyParentFragmentFragment as Parent, Gender, MaritalStatus } from 'shared/generated/graphql-types';
import { ArrayElement } from './types';

type ParentEmail = ArrayElement<Parent['EmailAddresses']>
type ParentPhone = ArrayElement<Parent['Phones']>

export interface ParentViewModel {
  parentId: number;
  name: string;
  homePhone: string | null;
  cellPhone: string | null;
  email: string | null;
  mediaConsentSigned: boolean | null;
  dataConsentSigned: boolean | null;
  liabilitySigned: boolean | null;
  gender: Gender;
}
export interface FamilyViewModel {
  id: number;
  familyName: string;
  primary: boolean;
  status: MaritalStatus;
  mother: ParentViewModel | null;
  father: ParentViewModel | null;
}
interface MinimumShapeForPrimaryFamily {
  primaryFamilyId: number | null;
  ChildOf: Family[] | null;
}

function getParentCellPhone(phones: ParentPhone[]) {
  return phones && getCurrentCellNumber(phones)
    ? getCurrentCellNumber(phones)!.phoneNumber
    : null;
}

function getParentHomePhone(phones: ParentPhone[]) {
  return phones && getCurrentLandlineNumber(phones)
    ? getCurrentLandlineNumber(phones)!.phoneNumber
    : null;
}

function getParentEmail(emails: ParentEmail[]) {
  if (!emails) {
    emails = [];
  }
  return getCurrentEmail(emails) ? getCurrentEmail(emails)!.email : null;
}

function toParentViewModel(parent: Parent, gender: Gender): ParentViewModel {
  return {
    parentId: parent.personID,
    name: getFullName(parent.firstName, parent.lastName),
    dataConsentSigned: parent.dataConsentSigned,
    mediaConsentSigned: parent.mediaConsentSigned,
    liabilitySigned: parent.liabilitySigned,
    email: getParentEmail(parent.EmailAddresses),
    homePhone: getParentHomePhone(parent.Phones),
    cellPhone: getParentCellPhone(parent.Phones),
    gender
  };
}

function toFamilyViewModel(family: Family, primaryFamilyId: number | null): FamilyViewModel {
  return {
    id: family.id,
    familyName: family.familyName,
    primary: family.id === primaryFamilyId,
    status: family.status,
    mother: family.Mother ? toParentViewModel(family.Mother, Gender.Female) : null,
    father: family.Father ? toParentViewModel(family.Father, Gender.Male) : null
  };
}

export function toFamilyViewModels(families: Family[], primaryFamilyId: number | null): FamilyViewModel[] {
  return families.map(f => toFamilyViewModel(f, primaryFamilyId));
}

export function getParents(data: MinimumShapeForPrimaryFamily) {
  const families = toFamilyViewModels(data.ChildOf || [], data.primaryFamilyId);
  const primaryFamily = families.find(f => f.primary) || families[0];
  const father = primaryFamily && primaryFamily.father;
  const mother = primaryFamily && primaryFamily.mother;

  return {
    father,
    mother
  };
}

export function getParentsArray(data: MinimumShapeForPrimaryFamily) {
  const { father, mother } = getParents(data);
  return [father, mother].filter((p): p is ParentViewModel => p !== null && p !== undefined);
}

export function getFieldOfParent<T>(data: Family[], index: number | null, motherFather: 'Father' | 'Mother', fieldName: keyof Parent, defaultValue: T): T {
  return index && data[index] && data[index][motherFather] && data[index][motherFather]![fieldName] || defaultValue;
}

export function getDefaultParent(gender: number): Parent {
  return {
    origin: null,
    dataOptOut: null,
    teudatZehut: null,
    aliyahDate: null,
    Addresses: [],
    olamiId: null,
    EmailAddresses: [],
    Phones: [],
    gender,
    firstName: '',
    lastName: '',
    personID: -1,
    mediaConsentSigned: false,
    dataConsentSigned: null,
    liabilitySigned: false,
    __typename: 'Person'
  };
}
