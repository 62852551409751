// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import { provideApolloClient } from '@vue/apollo-composable';
import App from './App.vue';
import client from './client';
import config from './config';
import { createStore, key } from './store';
import { getUserInfoFromSessionStorage } from 'shared/util';
import Vuebar from 'vuebar';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VTooltip from 'v-tooltip';
import VCalendar from 'v-calendar';
import VueApexCharts from 'vue-apexcharts';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/vue';
import { Event, EventHint } from '@sentry/types';
import VueIntercom from 'vue-intercom';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { useRegionStore } from 'store/region/useRegionStore';

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'production') {
  LogRocket.init('orthodox-union/iceburg3', {
    network: {
      requestSanitizer (request) {
        if (request.method === 'GET') return request;
        if (request.headers.authorization) {
          request.headers.authorization = '';
        }
        return request;
      }
    }
  });
  Sentry.init({
    Vue,
    dsn: config.sentry_dsn,
    attachProps: true,
    logErrors: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      'graphql-ncsy.oudev.org',
      'graphql.ncsy.org'
    ],
    beforeSend: (event: Event, hint?: EventHint | undefined): Event | PromiseLike <Event | null> | null => getUserInfoFromSessionStorage().then(user => ({...event, user})).catch(() => event),
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    environment: process.env.NODE_ENV,
    maxBreadcrumbs: 100
  });

  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra("LogRocketSessionURL", sessionURL);
    });
  });

  Vue.config.errorHandler = (err: Error, vm: Vue, info: string): void => {
    Sentry.captureException(err);
    LogRocket.captureException(err);
    throw err;
  };
}

Vue.use(VTooltip);
Vue.use(Vuebar);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.component('font-awesome-icon', FontAwesomeIcon);
if (process.env.NODE_ENV === 'production') {
  Vue.use(VueIntercom, { appId: 'ssufsmxo' });
}
Vue.use(VCalendar);
Vue.config.ignoredElements = ['asana-feature-upvoting'];
Vue.config.productionTip = false;

const store = createStore();
const { setCurrentRegion } = useRegionStore(store);

if (localStorage.selectedRegion) {
  setCurrentRegion(+localStorage.selectedRegion);
}

new Vue({
  provide: { apolloProvider: provideApolloClient(client), [key as symbol]: store },
  store,
  render(h) {
    return h(App);
  }
}).$mount('#app');
