import { computed } from "vue";
import { wrapComponent } from "shared/apollo-hoc";
import { GetFilterOptionsQuery, useGetFilterOptionsQuery } from "shared/generated/graphql-types";

type Props = {
  regionId: number;
  loadingFilterOptions: boolean;
  filterOptions: GetFilterOptionsQuery;
};

export const filterOptionsEnhancer = wrapComponent<Props, Pick<Props, 'filterOptions' | 'loadingFilterOptions'>>((props) => {
  const { loading, result } = useGetFilterOptionsQuery(
    computed(() => ({ regionId: props.regionId })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    loadingFilterOptions: loading.value,
    filterOptions: result.value
  }));
});
