import { DayOfWeek } from "shared/generated/graphql-types";

export const days = [
  DayOfWeek.Sunday,
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
];

export const subtractDaysAndTimeFromDate = (date: Date, days?: number, hours?: number, minutes?: number) => {
  const result = new Date(date);
  if (days) result.setDate(result.getDate() - days);
  if (hours) result.setHours(result.getHours() - hours);
  if (minutes) result.setMinutes(result.getMinutes() - minutes);
  return result;
};
export const addDaysAndTimeFromDate = (date: Date, days?: number, hours?: number, minutes?: number) => {
  const result = new Date(date);
  if (days) result.setDate(result.getDate() + days);
  if (hours) result.setHours(result.getHours() + hours);
  if (minutes) result.setMinutes(result.getMinutes() + minutes);
  return result;
};
export const isFutureDate = (date: Date) => {
  const today = new Date ().setHours(0, 0, 0, 0);
  return date.setHours(0, 0, 0, 0) > today;
};
export const getDayOfWeek = (date: Date) => {
  if (typeof date === 'string') {
    return days[new Date(date).getDay()];
  }
  if (typeof date === 'object') {
    return days[date.getDay()];
  }
  return null;
};

export const changeDay = (currentDate: Date, newDay: string | null) => {

  if (!currentDate || !newDay) return currentDate;
  const currentDayOfWeek = getDayOfWeek(currentDate);

  const currentStartDayIndex = days.findIndex(x => x === currentDayOfWeek);
  const selectedStartDayIndex = days.findIndex(x => x === newDay);

  const delta = Math.abs(selectedStartDayIndex - currentStartDayIndex);
  if (currentStartDayIndex < selectedStartDayIndex) {
    return addDaysAndTimeFromDate(currentDate, delta);
  }

  if (currentStartDayIndex > selectedStartDayIndex && isFutureDate(subtractDaysAndTimeFromDate(currentDate, delta))) {
    return subtractDaysAndTimeFromDate(currentDate, delta);
  }

  return currentDate;
};

export const convertAMPMto24 = ({ ampm, hours }: { ampm: string, hours: number }) => {
  let convertedHoursTo24 = null;
  if (ampm.toLowerCase() === 'pm' && hours <= 12) {
    if (hours == 12) {
      convertedHoursTo24 = hours.toString();
    }
    else {
      convertedHoursTo24 = (+hours + 12).toString();
    }
  }
  else if (ampm.toLowerCase() === 'am' && hours <= 12) {
    if (hours == 12) {
      convertedHoursTo24 = '00';
    }
    else {
      convertedHoursTo24 = hours.toString();
    }
  }
  return convertedHoursTo24;
};
