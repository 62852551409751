import Summary from './Summary.vue';
import { compose } from 'vue-compose';
import {
  updatePersonEnhancer,
  updateEmailEnhancer,
  updatePhoneEnhancer,
  deletePhoneEnhancer,
  updateAddressEnhancer,
  createEmailEnhancer,
  createPhoneEnhancer,
  createAddressEnhancer
} from 'shared/enhancers/communityMembersEnhancer';

export const enhancer = compose(
  updatePersonEnhancer,
  updateEmailEnhancer,
  updatePhoneEnhancer,
  deletePhoneEnhancer,
  updateAddressEnhancer,
  createEmailEnhancer,
  createPhoneEnhancer,
  createAddressEnhancer,
);

export default enhancer(Summary);
