import { compose, withProps } from 'vue-compose';
import orderBy from 'lodash/orderBy';
import TeenAdvisorsLookup from './TeenAdvisorsLookup.vue';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  TeenAdvisorsLookupQuery,
  useTeenAdvisorsLookupQuery
} from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { ArrayElement } from 'shared/util/types';

export type Staff = ArrayElement<TeenAdvisorsLookupQuery['teenAdvisorsLookup']>;

interface StateToProps {
  regionId: number | null;
}

interface TeenAdvisorsLookupProps {
  staff: Staff[];
  loading: boolean;
}

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

export const teenAdvisorsLookupEnhancer = wrapComponent<
  StateToProps & { type: number },
  TeenAdvisorsLookupProps
>((props) => {
  const { loading, result } = useTeenAdvisorsLookupQuery(
    computed(() => ({ regionId: props.regionId!, type: props.type })),
    { enabled: computed(() => !!props.regionId) }
  );

  return computed(() => ({
    loading: loading.value,
    staff: orderBy(result.value?.teenAdvisorsLookup || [], ['lastName'], ['desc'])
  }));
});

export default compose(
  withProps<StateToProps, StateToProps>((props) => ({
    regionId: props.regionId || getCurrentRegion()
  })),
  teenAdvisorsLookupEnhancer
)(TeenAdvisorsLookup);
