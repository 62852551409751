import { defineComponent, h, PropType, reactive, ref, watch } from 'vue';

export default defineComponent({
  props: {
    open: { type: Boolean as PropType<boolean | undefined>, default: undefined }
  },
  emits: {
    'update:open': (value: boolean) => true
  },
  setup(props, { emit, slots }) {
    const state = reactive({
      open: true
    });
    const controlled = ref(typeof props.open != 'undefined');

    if (controlled.value) {
      state.open = !!props.open;
    }

    props.open !== undefined ? props.open : true;

    function toggle() {
      state.open = !state.open;
      if (controlled.value) {
        emit('update:open', state.open);
      }
    }

    watch(
      () => props.open,
      () => {
        if (controlled.value) {
          state.open = !!props.open;
        }
      }
    );

    return () => {
      const result =
        slots.default &&
        slots.default({
          open: state.open,
          toggle
        });

      return !result || typeof result === 'string' || result.length > 0
        ? h('div', result)
        : result[0];
    };
  }
});
