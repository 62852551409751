import { useApolloClient } from '@vue/apollo-composable';
import { VueEditor } from "vue2-editor";
import axios from "axios";
import config from "../../../config";
import { GetSignedUrlForS3ImageUploadDocument, GetSignedUrlForS3ImageUploadMutation } from 'shared/generated/graphql-types';

function getFileName(url: string) {
  return url.split("?")[0].split("/").slice(-1)[0];
}
export default async (
  file: File,
  Editor: VueEditor,
  cursorLocation: { x: number; y: number }
) => {
  const { client } = useApolloClient()

  const result = await client.mutate<GetSignedUrlForS3ImageUploadMutation>({
    mutation: GetSignedUrlForS3ImageUploadDocument,
    variables: {
      fileName: file.name,
    },
  });

  if (
    !result.data ||
    result.data.getSignedURLForS3ImageUpload === "An error occured"
  ) {
    alert("An error occured.");
  } else {
    await axios.put(result.data.getSignedURLForS3ImageUpload, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    Editor.insertEmbed(
      cursorLocation,
      "image",
      `${config.cloudflareUrl}${getFileName(result.data.getSignedURLForS3ImageUpload)}`
    );
  }
};
