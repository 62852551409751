import Layout from './Layout.vue';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetCellphonesDuplicatedAcrossAcountsQuery } from 'shared/generated/graphql-types';
import { compose } from 'vue-compose';
import { computed } from 'vue';

type EnhancerProps = Pick<Props, 'loading' | 'initialLoading' | 'phones' | 'total' | 'fetchMore'>;
const getPhonesEnhancer = wrapComponent<Props, EnhancerProps>((props) => {
  const { loading, result, fetchMore } = useGetCellphonesDuplicatedAcrossAcountsQuery(
    computed(() => ({
      limit: 60,
      offset: 0
    })),
    {
      fetchPolicy: 'network-only'
    }
  );

  return computed(() => ({
    initialLoading: !!(loading.value && !result.value?.cellphonesDuplicatedAcrossAcounts),
    loading: loading.value,
    phones: result.value?.cellphonesDuplicatedAcrossAcounts.phones || [],
    total: result.value?.cellphonesDuplicatedAcrossAcounts.total || 0,
    fetchMore: ({ limit, offset }, stateChanger) => {
      return fetchMore({
        variables: {
          limit,
          offset,
        },
        updateQuery(previousResult, { fetchMoreResult }) {
          if (!fetchMoreResult || !fetchMoreResult.cellphonesDuplicatedAcrossAcounts) {
            return previousResult;
          }
          if (!fetchMoreResult.cellphonesDuplicatedAcrossAcounts.phones.length || fetchMoreResult.cellphonesDuplicatedAcrossAcounts.phones.length < limit) {
            stateChanger.complete();

            if (!fetchMoreResult.cellphonesDuplicatedAcrossAcounts.phones.length) {
              return previousResult;
            }
          }
          const numbers = previousResult.cellphonesDuplicatedAcrossAcounts.phones.map(x => x.phoneNumber);
          const newData = fetchMoreResult.cellphonesDuplicatedAcrossAcounts.phones.filter(x => !numbers.includes(x.phoneNumber));
          return {
            cellphonesDuplicatedAcrossAcounts: {
              __typename: 'PhonePage',
              total: fetchMoreResult.cellphonesDuplicatedAcrossAcounts.total,
              phones: [
                ...previousResult.cellphonesDuplicatedAcrossAcounts!.phones,
                ...newData
              ]
            }
          };
        }
      });
    }
  }));
});

export default compose(
  getPhonesEnhancer
)(Layout);
