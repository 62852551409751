import { computed } from 'vue';
import Layout from './Layout.vue';
import { compose } from 'vue-compose';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  useGetChaptersAndStaffQuery,
  useGetSingleChapterQuery,
  useUpdateChapterMutation
} from 'shared/generated/graphql-types';

type GetChapterProps = Pick<Props, 'chapters' | 'isLoading' | 'staff'>;

const getChapters = wrapComponent<{ regionID: number }, GetChapterProps>((props) => {
  const { loading, result } = useGetChaptersAndStaffQuery(computed(() => ({
    regionId: props.regionID
  })));

  return computed(() => ({
    isLoading: loading.value,
    chapters: (result.value?.region.Chapters || []).map((c) => ({
      id: c.chapterId,
      name: c.chapterName
    })),
    staff: (result.value?.region.Staff || []).map((s) => ({
      id: s.Staff.staffID,
      label: `${s.Staff.firstName} ${s.Staff.lastName}`
    }))
  }));
});

type SingleChapterProps = Pick<Props, 'currentChapter'>;

const getSingleChapter = wrapComponent<Pick<Props, 'currentChapterId'>, SingleChapterProps>(
  (props) => {
    const { loading, result } = useGetSingleChapterQuery(
      computed(() => ({
        chapterID: props.currentChapterId!
      })),
      { enabled: computed(() => !!props.currentChapterId) }
    );

    return computed(() => ({
      currentChapter:
        result.value?.chapter && !loading.value
          ? {
              address: result.value.chapter.address,
              chapterUrl: result.value.chapter.chapterUrl,
              contact: result.value.chapter.contact,
              email: result.value.chapter.email,
              facebook: result.value.chapter.facebookPage,
              fax: result.value.chapter.fax,
              id: result.value.chapter.chapterId,
              instagram: result.value.chapter.instagramUser,
              name: result.value.chapter.chapterName,
              phone: result.value.chapter.phone,
              primaryStaff: result.value.chapter.PrimaryAdvisor
                ? {
                    id: result.value.chapter.PrimaryAdvisor.staffID,
                    label: `${result.value.chapter.PrimaryAdvisor.firstName} ${result.value.chapter.PrimaryAdvisor.lastName}`
                  }
                : null,
              twitter: result.value.chapter.twitterUser,
              notificationEmails: result.value.chapter.notificationEmails
                ? result.value.chapter.notificationEmails
                    .split(',')
                    .filter((i) => i)
                    .map((i) => i.trim())
                : []
            }
          : null
    }));
  }
);

type UpdateChapterProps = Pick<Props, 'updateChapter'>;

const updateChapter = wrapComponent<Pick<Props, 'currentChapter'>, UpdateChapterProps>((props) => {
  const { mutate: updateChapter } = useUpdateChapterMutation();

  return computed(() => ({
    updateChapter: ({ field, value }) =>
      updateChapter({
        chapter: {
          chapterId: props.currentChapter!.id,
          ...(field === 'address' ? { address: value } : {}),
          ...(field === 'chapterUrl' ? { chapterUrl: value } : {}),
          ...(field === 'contact' ? { contact: value } : {}),
          ...(field === 'email' ? { email: value } : {}),
          ...(field === 'facebook' ? { facebookPage: value } : {}),
          ...(field === 'fax' ? { fax: value } : {}),
          ...(field === 'instagram' ? { instagramUser: value } : {}),
          ...(field === 'phone' ? { phone: value } : {}),
          ...(field === 'twitter' ? { twitterUser: value } : {}),
          ...(field === 'notificationEmails'
            ? { notificationEmails: (value || []).join(',') }
            : {}),
          ...(field === 'primaryStaff'
            ? value
              ? { primaryAdvisor: value.id }
              : { primaryAdvisor: null }
            : {})
        }
      })
  }));
});

export const enhancer = compose(
  getChapters,
  getSingleChapter,
  updateChapter
);

export default enhancer(Layout);
