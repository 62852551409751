import Vue, { computed, VueConstructor } from 'vue';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import Duplicates from './Duplicates.vue';
import { compose, withData, withProps } from 'vue-compose';
import { getOptions, normalizeProps } from 'shared/util';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  Origin,
  useGetTeenDuplicatesQuery,
  useGetTeenDuplicatesTotalQuery
} from 'shared/generated/graphql-types';
import { TeenDuplicates } from './types';
import { Chapter } from 'shared/types';

interface Props {
  regionId: number;
  teensPerPage: number;
  currentPage: number;
  chapters: Chapter[];
  origins: Origin[];
  total: number;
  loading: boolean;
  teenDuplicates: TeenDuplicates[];
  searchTerm: string;
}

interface GetTeenDuplicatesProps {
  teenDuplicates: TeenDuplicates;
  loading: boolean;
}
interface GetTeenDuplicatesTotalProps {
  total: number;
}

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const getTeenDuplicatesEnhancer = wrapComponent<Props, GetTeenDuplicatesProps>((props) => {
  const { loading, result } = useGetTeenDuplicatesQuery(
    computed(() => {
      const chapters = map(props.chapters, (c) => c.chapterId);

      return {
        chapters,
        regionId: props.regionId,
        name: props.searchTerm,
        origins: props.origins,
        limit: props.teensPerPage,
        offset: (props.currentPage - 1) * props.teensPerPage
      };
    }),
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  return computed(() => ({
    loading: loading.value,
    teenDuplicates: flatten(result.value?.teenDuplicates?.rows || []) || []
  }));
});

const getTeenDuplicatesTotalEnhancer = wrapComponent<Props, GetTeenDuplicatesTotalProps>(
  (props) => {
    const { result } = useGetTeenDuplicatesTotalQuery(
      computed(() => {
        const chapters = map(props.chapters, (c) => c.chapterId);

        return {
          chapters,
          regionId: props.regionId,
          name: props.searchTerm,
          origins: props.origins
        };
      }),
      {
        fetchPolicy: 'cache-and-network'
      }
    );

    return computed(() => ({
      total: result.value?.teenDuplicatesTotal?.total || 0
    }));
  }
);

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { chapters, setChapters, origins, setOrigins, ...propsToUse } = props;

  return Vue.extend<
    { chapters: any[], origins: Origin[] },
    { setChapters: (args: any[], cb?: () => void) => void, setOrigins: (value: Origin[]) => void },
    {},
    {}
  >({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        chapters: [],
        origins: []
      };
    },
    methods: {
      setOrigins(value: Origin[]) {
        this.origins = value
      },
      setChapters(args: any[], cb?: () => void) {
        this.chapters = args;
        if (cb) {
          cb();
        }
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          origins: this.origins,
          chapters: this.chapters,
          setOrigins: this.setOrigins,
          setChapters: this.setChapters
        },
        on: this.$listeners
      });
    }
  });
};

export default compose(
  withFilters,
  withData({
    currentPage: {
      initialValue: 1
    },
    searchTerm: {
      initialValue: ''
    }
  }),
  withProps<any, any>((props: any) => ({
    teensPerPage: 10,
    regionId: getCurrentRegion()
  })),
  getTeenDuplicatesEnhancer,
  getTeenDuplicatesTotalEnhancer
)(Duplicates);
