import Bussing from './Bussing.vue';
import { Props, BusViewModel } from './types';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  BusForRegistrationFragment,
  useAddToBusOnRegistrationPageMutation,
  useRemoveFromBusOnRegistrationPageMutation
} from 'shared/generated/graphql-types';

function viewModelToGQLObject(bus: BusViewModel): BusForRegistrationFragment {
  return {
    busID: bus.busId,
    meetingAddress: bus.meetingAddress,
    description: bus.description,
    directionFlag: bus.direction,
    __typename: 'Bus'
  };
}

const addToBusEnhancer = wrapComponent<Pick<Props, 'registration'>, Pick<Props, 'addToBus'>>(
  (props) => {
    const { mutate } = useAddToBusOnRegistrationPageMutation();

    return computed(() => ({
      addToBus: (id, busId, direction, bus) =>
        mutate(
          { id, busId },
          {
            optimisticResponse: {
              addToBus: {
                ...props.registration,
                ToBus: direction === 'T' ? viewModelToGQLObject(bus) : props.registration.ToBus,
                FromBus: direction === 'R' ? viewModelToGQLObject(bus) : props.registration.FromBus,
                __typename: 'Registration'
              }
            }
            // There is no update to try and update buses in the cache due to difference in fieldsets returned between bussing and registration pages
          }
        )
    }));
  }
);

const removeFromBusEnhancer = wrapComponent<
  Pick<Props, 'registration'>,
  Pick<Props, 'removeFromBus'>
>((props) => {
  const { mutate } = useRemoveFromBusOnRegistrationPageMutation();

  return computed(() => ({
    removeFromBus: (id, busId, direction) =>
      mutate(
        { id, busId },
        {
          optimisticResponse: {
            removeFromBus: {
              ...props.registration,
              ToBus: direction === 'T' ? null : props.registration.ToBus,
              FromBus: direction === 'R' ? null : props.registration.FromBus,
              __typename: 'Registration'
            }
          }
          // There is no update to try and update buses in the cache due to difference in fieldsets returned between bussing and registration pages
        }
      )
  }));
});

export default compose(addToBusEnhancer, removeFromBusEnhancer)(Bussing);
