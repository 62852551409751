import { computed } from 'vue';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  CreateTeenInput,
  SingleFamilyDocument,
  SingleFamilyQuery,
  TeensByRegionDocument,
  TeensByRegionQuery,
  useAddTeenMutation
} from 'shared/generated/graphql-types';

interface Props {
  regionId: number;
  familyId?: number;
}

interface AddTeenProps {
  addTeen: (vars: CreateTeenInput, parentId?: number, familyId?: number) => void;
}

export const addTeenEnhancer = wrapComponent<Props, AddTeenProps>((props) => {
  const { mutate: addTeen, loading, error } = useAddTeenMutation();

  return computed(() => ({
    addTeen: (teen) =>
      addTeen(
        {
          teen: {
            ...teen,
            familyId: teen.familyId || props.familyId
          }
        },
        {
          update: (proxy, { data: updateData }) => {
            if (updateData?.addTeen) {
              try {
                const data = proxy.readQuery<TeensByRegionQuery>({
                  query: TeensByRegionDocument,
                  variables: {
                    filter: {
                      isAlumni: false,
                      regionId: props.regionId,
                      limit: 60
                    }
                  }
                });
                if (data?.teens) {
                  const teens = data.teens.teens.slice();
                  teens.push(updateData.addTeen);

                  proxy.writeQuery<TeensByRegionQuery>({
                    query: TeensByRegionDocument,
                    variables: {
                      filter: {
                        isAlumni: false,
                        regionId: props.regionId,
                        limit: 60
                      }
                    },
                    data: {
                      teens: {
                        ...data.teens,
                        teens,
                        total: data.teens.total + 1
                      }
                    }
                  });
                }
              } catch {
                // Remove message into console since in most cases query will be not found in the cache
                // console.log(e);
              }
              try {
                if (teen.familyId || props.familyId) {
                  const familyId = teen.familyId || props.familyId;
                  const singleFamily = proxy.readQuery<SingleFamilyQuery>({
                    query: SingleFamilyDocument,
                    variables: {
                      familyId: familyId
                    }
                  });
                  if (singleFamily && singleFamily.singleFamily) {
                    const newTeenWithPrimaryFamilyId = {
                      ...updateData.addTeen.Person,
                      primaryFamilyId: singleFamily.singleFamily.id
                    };
                    if (!singleFamily.singleFamily.Children) {
                      singleFamily.singleFamily.Children = [newTeenWithPrimaryFamilyId];
                    } else {
                      const index = singleFamily.singleFamily.Children.findIndex(
                        (c) => c.personID === updateData.addTeen.Person.personID
                      );
                      if (index === -1) {
                        singleFamily.singleFamily.Children.push(newTeenWithPrimaryFamilyId);
                      } else {
                        singleFamily.singleFamily.Children[index] = newTeenWithPrimaryFamilyId;
                      }
                    }

                    proxy.writeQuery<SingleFamilyQuery>({
                      query: SingleFamilyDocument,
                      variables: {
                        id: familyId
                      },
                      data: singleFamily
                    });
                  }
                }
              } catch {
                // Essentially all cases are a matter of query not being in cache
              }
            }
          }
        }
      ),
    addingTeen: loading.value,
    addTeenError: error.value
  }));
});
