import TeenDuplicates from './TeenDuplicates.vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  NonDuplicatesMutationVariables,
  useNonDuplicatesMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface Props {
  regionId: number;
}
interface NonDuplicatesProps {
  nonDuplicates?: (ids: NonDuplicatesMutationVariables) => void;
  markingAsSeperate: boolean;
}

const nonDuplicatesEnhancer = wrapComponent<Props, NonDuplicatesProps>((props) => {
  const { mutate, loading } = useNonDuplicatesMutation();

  return computed(() => ({
    markingAsSeperate: loading.value,
    nonDuplicates({ ids }) {
      mutate(
        { ids },
        {
          refetchQueries: ['getTeenDuplicates', 'getTeenDuplicatesTotal']
        }
      );
    }
  }));
});

export default compose(nonDuplicatesEnhancer)(TeenDuplicates);
