import AddJFEOrderPayment from './AddJFEOrderPayment.vue';
import { useAddJfeOrderPaymentMutation } from "shared/generated/graphql-types";
import { computed } from 'vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { Props } from './types';
import { compose, withData } from 'vue-compose';

const addPaymentEnhancer = wrapComponent<Props, Pick<Props, "adding" | "addPayment">>((props) => {
  const { loading, mutate } = useAddJfeOrderPaymentMutation();

  return computed(() => ({
    adding: loading.value,
    addPayment: (input) => mutate({ input, orderId: props.orderId })
  }));
});

export default compose(
  withData({
    paymentInput: {
      initialValue: {}
    }
  }),
  addPaymentEnhancer
)(AddJFEOrderPayment);
