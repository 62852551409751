import { render, staticRenderFns } from "./AddNewFamily.vue?vue&type=template&id=414f50b1&scoped=true&"
import script from "./AddNewFamily.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AddNewFamily.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AddNewFamily.vue?vue&type=style&index=0&id=414f50b1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "414f50b1",
  null
  
)

export default component.exports