import CompactTeen from '../../shared/CompactTeen';
import TeenUpdateArgs from './shared/TeenUpdateArgs';
import { ActiveFiltersType } from '../../shared/ActiveFilters';
import {
  DeleteRelationshipImpactMutationVariables,
  RelationshipImpactInput,
  RelationshipImpactNoteInput
} from 'shared/generated/graphql-types';

export interface Props {
  teen: CompactTeen;
  updatingTeen: boolean;
  selectedTeen: number;
  season: string;
  user: number;
  regionId: number;
  activeFilters: ActiveFiltersType;
  addRelationshipImpact: (args: RelationshipImpactInput) => void;
  deleteRelationshipImpact: (args: DeleteRelationshipImpactMutationVariables) => void;
  deleteRelationshipImpactNote: (noteId: number) => void;
  setRelationshipImpactNote: (args: RelationshipImpactNoteInput) => void;
  updateTeen: (args: TeenUpdateArgs) => void;
}

export enum TeenField {
  relationshipImpacted = 'relationshipImpacted',
  primaryAdvisorId = 'primaryAdvisorId',
  secondaryAdvisorId = 'secondaryAdvisorId',
  relationshipImpactNote = 'relationshipImpactNote'
}
