import SchoolTypeLookup from './SchoolTypeLookup.vue';
import { Props } from './types';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetSchoolTypesQuery } from 'shared/generated/graphql-types';

const getSchoolTypes = wrapComponent<{}, Pick<Props, 'loading' | 'schoolTypes'>>(() => {
  const { loading, result } = useGetSchoolTypesQuery();

  return computed(() => ({
    loading: loading.value,
    schoolTypes: result.value?.getSchoolTypes || []
  }));
});

export default compose(getSchoolTypes)(SchoolTypeLookup);
