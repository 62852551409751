import { getParents } from 'shared/util/families';
import { dateFormat, getCurrentCellNumber, getCurrentEmail, phoneFormat } from 'shared/util';
import { GetOwedRegistrationsQuery } from 'shared/generated/graphql-types';

type OwedRegistration = GetOwedRegistrationsQuery['owedRegistrations'];

export function getOwedRegistrationCSVRows(data: OwedRegistration) {
  return data.registrations.map(r => {
    const { father, mother } = getParents(r.Teen.Person);
    return [
      r.Teen.Person.firstName,
      r.Teen.Person.lastName,
      r.Event!.eventName,
      r.Teen.Chapter && r.Teen.Chapter!.chapterName || '',
      phoneFormat((getCurrentCellNumber(r.Teen.Person.Phones) || { phoneNumber: '' }).phoneNumber) || '',
      (getCurrentEmail(r.Teen.Person.EmailAddresses) || { email: '' }).email || '',
      father && phoneFormat(father.cellPhone || '') || '',
      father && father.email || '',
      mother && phoneFormat(mother.cellPhone) || '',
      mother && mother.email || '',
      dateFormat(r.registrationDate),
      r.balance,
      r.Event!.registrationLink,
      r.Event!.registrationLinkWhenRegistrationIsClosed
    ].join(',');
  }).join('\n');
}
